import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CognitoLoginData, CognitoQuery, CognitoRefreshToken } from '@models/auth/cognito.model';
import { LoginResponse } from '@models/auth/login.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { ErrorCodeHelper } from '@shared/utils/helpers/siq-errors.helper';
import { CognitoLogin, Logout, ReLogin, ReSiqLogin } from '@store/auth/auth.actions';
import { AuthSelectors } from '@store/auth/auth.selectors';
import { SetTacoState } from '@store/taco/taco.actions';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, first, map, retry, switchMap, take } from 'rxjs/operators';
import { Handle409Service } from './handle-409.service';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';
export const UseAuthHeader = 'X-Use-Auth';

enum SiqHeader {
  Cloud = 'siq',
  Cognito = 'cognito',
  NativeSiq = 'nsiq',
  Digital = 'sn'
}
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private isRefreshingIdToken = false;
  private isRefreshingNativeToken = false;
  private refreshTokenSubject: BehaviorSubject<CognitoQuery<CognitoRefreshToken> | null> = new BehaviorSubject<CognitoQuery<CognitoRefreshToken> | null>(null);
  private refreshingIdTokenSubject: BehaviorSubject<CognitoQuery<CognitoRefreshToken> | null> = new BehaviorSubject<CognitoQuery<CognitoRefreshToken> | null>(null);
  private refreshingNativeTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private store: Store, private handle409Service: Handle409Service) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authHeader = req.headers.get(UseAuthHeader);

    switch (authHeader) {
      case SiqHeader.Cloud:
        req = this.removeHeader(req, UseAuthHeader);
        return this.interceptSiqRequest(req, next, false);
      case SiqHeader.Cognito:
        req = this.removeHeader(req, UseAuthHeader);
        return this.interceptCognitoRequest(req, next);
      case SiqHeader.NativeSiq:
        req = this.removeHeader(req, UseAuthHeader);
        return this.interceptNativeSiqRequest(req, next);
      case SiqHeader.Digital:
        req = this.removeHeader(req, UseAuthHeader);
        return this.interceptSleepNumberRequest(req, next);
      default:
        req = this.removeHeader(req, UseAuthHeader);
        return this.interceptSiqRequest(req, next, true);
    }
  }

  interceptCognitoRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    type AuthType = CognitoQuery<CognitoLoginData | CognitoRefreshToken> | null;
    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({ headers })).pipe(
        retry({ count: 3, delay: FunctionsHelper.genericRetryStrategy() }),
        catchError(error => {
          // refreshToken catchError
          if (req.method === 'PUT' && error.status === 400 && Object.prototype.hasOwnProperty.call(error.error.data, 'message') && error.error.data.message.includes('Refresh Token has been revoked')) {
            const login = this.store.selectSnapshot(AuthSelectors.login);
            if (login) {
              this.store.dispatch(new CognitoLogin(login));
            } else {
              this.store.dispatch(new Logout());
              return EMPTY;
            }
          }
          return throwError(() => error);
        })
      );
    }

    return this.store.select(AuthSelectors.cognitoAuth).pipe(
      first(),
      filter((auth: AuthType) => !!auth),
      switchMap((auth: AuthType) => {

        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${auth?.data.IdToken}`
          }
        });
        return next.handle(req).pipe(
          catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              return this.handleIdTokenExpired(req, next);
            }
            return throwError(() => error);
          }));
      })
    );
  }

  interceptSiqRequest(req: HttpRequest<any>, next: HttpHandler, isEdp: boolean): Observable<HttpEvent<any>> {
    type AuthType = CognitoQuery<CognitoLoginData | CognitoRefreshToken> | null;

    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }
    return this.store.select(AuthSelectors.cognitoAuth).pipe(
      first(),
      filter((auth: AuthType) => !!auth),
      switchMap((auth: AuthType) => {

        req = req.clone({
          setHeaders: {
            Authorization: isEdp ? `Bearer ${auth?.data.AccessToken}` : `${auth?.data.AccessToken}`
          }
        });

        return this.handle409Service.isProcessing409Error$.pipe(
          filter(isProcessing => !isProcessing), // Continue only if not processing 409
          take(1), // Take only one value to ensure it doesn't keep waiting
          switchMap(() => next.handle(req)),
          catchError(error => {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 401) {
                return this.handleTokenExpired(req, next, isEdp);
              } else if (error.status === 409) {
                this.handle409Error(error);
              }
            }
            return throwError(() => error);
          })
        );
      })
    );
  }

  interceptNativeSiqRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }
    return this.store.select(AuthSelectors.siqAuth).pipe(
      first(),
      filter((auth: LoginResponse | null) => !!auth),
      switchMap((auth: LoginResponse | null) => {
        //eslint-disable-next-line no-extra-boolean-cast
        const authReq = !!auth ? this.addToken(req, auth.key) : req;

        return next.handle(authReq).pipe(catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.handleNative401Error(req, next);
          }
          return throwError(() => error);
        }
        ));
      })
    );
  }

  interceptSleepNumberRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    type AuthType = CognitoQuery<CognitoLoginData | CognitoRefreshToken> | null;

    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }
    return this.store.select(AuthSelectors.cognitoAuth).pipe(
      first(),
      filter((auth: AuthType) => !!auth),
      switchMap((auth: AuthType) => {

        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${auth?.data.IdToken}`
          }
        });
        return next.handle(req).pipe(
          catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              return this.handleIdTokenExpired(req, next);
            }
            return throwError(() => error);
          }));
      })
    );
  }

  addToken(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
    let authKey = '?_k=';
    if (request.url.indexOf('?') >= 0) {
      authKey = '&_k=';
    }
    const req = request.clone({
      url: request.url + authKey + token
    });
    return req;
  }

  private removeHeader(req: HttpRequest<any>, header: string): HttpRequest<any> {
    const headers = req.headers.delete(header);
    return req.clone({ headers });
  }

  private handleNative401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshingNativeToken) {
      this.isRefreshingNativeToken = true;
      this.refreshingNativeTokenSubject.next(null);

      return this.refreshNativeToken().pipe(
        switchMap((response: LoginResponse) => {
          this.isRefreshingNativeToken = false;
          this.refreshingNativeTokenSubject.next(response.key);
          return next.handle(this.addToken(request, response.key));
        }),
        finalize(() => {
          this.isRefreshingNativeToken = false;
        }));
    } else {
      return this.refreshingNativeTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => next.handle(this.addToken(request, token))));
    }
  }

  private handleTokenExpired(request: HttpRequest<any>, next: HttpHandler, isEdp: boolean): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.refreshToken().pipe(
        switchMap((response: CognitoQuery<CognitoRefreshToken>) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(response);
          request = request.clone({
            setHeaders: {
              Authorization: isEdp ? `Bearer ${response.data.AccessToken}` : `${response.data.AccessToken}`
            }
          });
          return next.handle(request);
        }),
        finalize(() => {
          this.isRefreshing = false;
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        switchMap(token => {
          request = request.clone({
            setHeaders: {
              Authorization: isEdp ? `Bearer ${token?.data.AccessToken}` : `${token?.data.AccessToken}`
            }
          });
          return next.handle(request);
        }));
    }
  }

  private handleIdTokenExpired(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshingIdToken) {
      this.isRefreshingIdToken = true;
      this.refreshingIdTokenSubject.next(null);

      return this.refreshToken().pipe(
        switchMap((response: CognitoQuery<CognitoRefreshToken>) => {
          this.isRefreshingIdToken = false;
          this.refreshingIdTokenSubject.next(response);
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${response.data.IdToken}`
            }
          });
          return next.handle(request);
        }),
        finalize(() => {
          this.isRefreshingIdToken = false;
        }));

    } else {
      return this.refreshingIdTokenSubject.pipe(
        filter(token => token != null),
        switchMap(token => {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token?.data.IdToken}`
            }
          });
          return next.handle(request);
        }));
    }
  }

  private refreshToken(): Observable<CognitoQuery<CognitoRefreshToken>> {
    return this.store.select(AuthSelectors.cognitoAuth).pipe(
      first(),
      switchMap(() => this.store.dispatch(new ReLogin()).pipe(map(resp => FunctionsHelper.decrypt<CognitoQuery<CognitoRefreshToken>>(resp.auth.cognitoAuth))))
    );
  }

  private refreshNativeToken(): Observable<LoginResponse> {
    return this.store.select(AuthSelectors.siqAuth).pipe(
      first(),
      switchMap(() => this.store.dispatch(new ReSiqLogin()).pipe(map(resp => FunctionsHelper.decrypt<LoginResponse>(resp.auth.siqAuth))))
    );
  }

  // The function sets the correct TACO code, so the correct checkboxes can be made on the terms screen
  // Navigates to terms screen
  // Stops other request from being made
  private handle409Error(error: HttpErrorResponse): void {

    switch (error.error.Error.Code) {
      case ErrorCodeHelper.tacoCodes.license:
        this.store.dispatch(new SetTacoState(ErrorCodeHelper.tacoCodes.license, false));
        break;
      case ErrorCodeHelper.tacoCodes.privacyPolicy:
        this.store.dispatch(new SetTacoState(ErrorCodeHelper.tacoCodes.privacyPolicy, false));
        break;
      case ErrorCodeHelper.tacoCodes.licenseAndPrivacy:
        this.store.dispatch(new SetTacoState(ErrorCodeHelper.tacoCodes.license, false));
        this.store.dispatch(new SetTacoState(ErrorCodeHelper.tacoCodes.privacyPolicy, false));
        break;
    }

    this.store.dispatch(new Navigate(['auth/terms-of-use']));

    this.handle409Service.setProcessing409Error(true);
  }
}
