export interface LoginModel {
  login: string;
  password: string;
}

export class LoginResponse {
  userId!: string;
  registrationState!: number;
  key!: string;
  edpLoginStatus!: number;
  edpLoginMessage!: string;
  cognitoUser!: boolean;

  constructor(loginResponse: LoginResponse) {
    Object.assign(this, loginResponse);
  }
}