import { BannerData, SetupSleeperIntroduction, SetupSleeperQuestionsData, SetupSleeperValues } from "@models/app/helpers.model";
import { FormControlParams } from "@models/auth/register.model";
import { HeightHelper } from "./height.helper";
import { WeightHelper } from "./weight.helper";
import { YearHelper } from "./year.helper";
import { SleepGoalHelper } from "./sleep-goal.helper";
import { LinksHelper } from "./links-helper";

export class RegistrationStringResource {
  static ValidateOrderNumberError = {
    title: 'We are having trouble finding your account',
    text: 'Please try entering your information again. If you are still having trouble, please contact customer support.'
  };

  static getFormControlParams(isOrderAndEmailValidation: boolean): FormControlParams {
    return Object.assign({
      type: isOrderAndEmailValidation ? 'email' : 'mask',
      placeholder: isOrderAndEmailValidation ? 'Email used at purchase' : 'Phone number used at purchase',
      label: isOrderAndEmailValidation ? 'Email address' : 'Phone number'
    }) as FormControlParams;
  }

  static bannerData(isBiqUser: boolean): BannerData {
    const bannerData = {
      title: 'Your sleeper profile helps us personalize your experience.',
      text: 'Providing your height, weight, and age helps ensure the most accurate sleep data possible.',
      icon: 'info-primary',
      type: 'blue'
    };

    if (isBiqUser) {
      bannerData.text = 'We use information from your BreatheIQ profile to make it easier to register your new smart bed. Providing your height, weight, and age helps ensure the most accurate sleep data possible.';
    }

    return bannerData as BannerData;
  }

  static sleeperData(): SetupSleeperQuestionsData {
    return {
      birthYearOptions: YearHelper.birthYearArr,
      birthYearValues: YearHelper.birthYearValuesArr,
      birthMonthOptions: YearHelper.monthOptionsArr,
      birthMonthValues: YearHelper.monthValuesArr,
      heightValues: HeightHelper.heightValuesArr,
      heightOptions: HeightHelper.heightOptionsArr,
      weightValues: WeightHelper.weightValuesArr,
      weightOptions: WeightHelper.weightOptionsArr,
      genderOptions: ['Please Select ...', 'Male', 'Female', 'Non-Binary'],
      genderValues: [9, 1, 0, 2],
      sleepGoalValues: SleepGoalHelper.sleepGoalValuesAdultArr,
      sleepGoalOptions: SleepGoalHelper.sleepGoalOptionsAdultArr,
    } as SetupSleeperQuestionsData;
  }

  static sleeperDataDefaultValues(isKid: boolean): SetupSleeperValues {
    return {
      birthYear: YearHelper.currentYear - 50,
      birthMonth: isKid ? 1 : null,
      height: 65, // `5' 5''`,
      weight: 150, // '150 lbs',
      gender: 9, // 'Please Select ...',
      sleepGoal: 420, // '7h 0m'
      duration: isKid ? 30 : 0
    } as SetupSleeperValues;
  }

  static sleeperSetupQuestions(isPartnerSetup: boolean | null): { [key: string]: string; } {
    const pronoun = !isPartnerSetup ? 'you' : 'they';
    const possessivePronoun = !isPartnerSetup ? 'your' : 'their';
    return {
      firstName: `What’s ${possessivePronoun} name?`,
      birthMonth: `What month were ${pronoun} born?`,
      birthYear: `What year were ${pronoun} born?`,
      height: `How tall are ${pronoun}?`,
      weight: `How much do ${pronoun} weigh?`,
      gender: `What gender do ${pronoun} identify as?`,
      sleepGoal: `What is ${possessivePronoun} sleep goal?`
    };
  }

  static optInInstructions(email: string): string {
    return `After you opt-in, check ${email} to confirm participation.`;
  }

  static get passwordHint(): string {
    return 'Must contain at least 8 characters, including 1 uppercase, 1 lowercase, 1 number and 1 special character.';
  }

  static get passwordsDoNotMatch(): string {
    return 'The passwords you entered do not match';
  }

  static get kidPrivacyPolicyPopupData(): object {
    return {
      title: 'Privacy Notice',
      text: `If you create a Sleep Number sleeper profile for your child we may collect personal data that requires parental consent. This could include name, birth year, email address, sleep data and certain other types of information. Our <a class="siq-text-400 fs-14 primary-300 text-underline" href="${LinksHelper.privacyPolicy}" target="_blank">Sleep Health Data Privacy Policy</a> details the personal data we may collect and how we use this information. Creating a profile for a child is your consent for us to collect and use information from that child. We will not knowingly collect or use the information of a child without this consent.`
    };
  }

  static get downloadTheAppCardText(): string {
    return 'Control your smart bed and access your sleep history using the mobile experience.';
  }

  static setupSleeperIntroduction(isBiqUser: boolean, isPartnerSetup: boolean): SetupSleeperIntroduction {
    if (isBiqUser) {
      return {
        title: isPartnerSetup ? 'Confirm Sleep Partner' : 'Confirm Sleeper Profile',
        introduction: isPartnerSetup ? 'We’ve made it easier for you to set up your sleep partner’s profile' : 'We’ve made it easier for you to set up your profile'
      } as SetupSleeperIntroduction;
    } else {
      return {
        title: isPartnerSetup ? 'Set Up Sleep Partner' : 'Set Up Sleeper',
        introduction: isPartnerSetup ? 'Next, let’s set up your sleep partner’s profile' : 'Now let’s set up your sleeper profile'
      } as SetupSleeperIntroduction;
    }
  }
}