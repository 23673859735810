import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { BamkeyClient } from '../fuzion/client';
import { BedPresenceFlag, Side, SleepiqDataState } from '../fuzion/enums';
import { BKCommand, GetSleepIQPrivacyStateCommand, GetSleepIQPrivacyStateResponse, GetWifiInformationCommand, GetWifiInformationResponse, SetBedPresenceCommand, SetBedPresenceResponse, SetSleepIQPrivacyStateCommand, SetSleepIQPrivacyStateResponse } from '../fuzion/model';
import { FuzionDeviceService } from './fuzion-device.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FuzionService {

  bamkeyClient: BamkeyClient;

  constructor(private fuzionDeviceService: FuzionDeviceService) {
    this.bamkeyClient = new BamkeyClient(this.fuzionDeviceService);
  }

  private executeCommand<T>(accountId: string, bedId: string, command: BKCommand): Observable<T> {
    return this.bamkeyClient.executeCommand<T>(accountId, bedId, command);
  }

  setBedPresence(accountId: string, bedId: string, side: Side, bedPresence: BedPresenceFlag): Observable<SetBedPresenceResponse> {
    return this.executeCommand<SetBedPresenceResponse>(accountId, bedId, new SetBedPresenceCommand(side, bedPresence)).pipe(
      map((response: SetBedPresenceResponse | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  getPauseMode(accountId: string, bedId: string): Observable<GetSleepIQPrivacyStateResponse> {
    return this.executeCommand<GetSleepIQPrivacyStateResponse>(accountId, bedId, new GetSleepIQPrivacyStateCommand()).pipe(
      map((response: GetSleepIQPrivacyStateResponse | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  setPauseMode(accountId: string, bedId: string, mode: SleepiqDataState): Observable<SetSleepIQPrivacyStateResponse> {
    return this.executeCommand<GetSleepIQPrivacyStateResponse>(accountId, bedId, new SetSleepIQPrivacyStateCommand(mode)).pipe(
      map((response: SetSleepIQPrivacyStateResponse | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  getWifiStatus(accountId: string, bedId: string): Observable<GetWifiInformationResponse> {
    return this.executeCommand<GetWifiInformationResponse>(accountId, bedId, new GetWifiInformationCommand()).pipe(
      map((response: GetWifiInformationResponse | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }
}
