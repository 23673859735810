<app-top-bar-navigation title="Select Smart Bed" />
<div siqGrid fx-direction="column" class="py-32 px-24">
  <span *ngIf="hasSetupBeds" class="siq-text-900 fs-24 white text-center px-12 mb-48">Would you like to set up another bed now?</span>
  <div *ngFor="let bed of bedsForSetup$ | async; index as i" class="w-100-p">
    <div class="cursor-pointer" [class.mt-48]="i !== 0" (click)="selectBed(bed)">
      <app-siq-card theme="medium" [headerText]="bed.customerFriendlyName" textSize="fs-18" btnText="Set Up Smart Bed" btnType="primary-btn">
        <div class="body w-100-p my-24" siqGrid fx-direction="column" fx-layout-align="center center">
          <div [ngClass]="bed.dualSleep ? 'dual-bed-icon big' : 'single-bed-icon big'"></div>
        </div>
      </app-siq-card>
    </div>
  </div>
  <div siqGrid fx-direction="row" fx-layout-align="end center" fx-layout-align-sm="center center" class="mt-48 px-12">
    <app-siq-button
      *ngIf="hasSetupBeds"
      text="Skip"
      btnType="secondary-btn"
      width="208px"
      (buttonClicked)="skip()" />
  </div>
</div>

