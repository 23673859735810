<app-top-bar-navigation [showBackButton]="true" [title]="title" (backBtnClicked)="back()" />
<div siqGrid fx-direction="column" class="pt-32 px-24">
  <div *ngIf="!isChild" siqGrid fx-direction="column" fx-layout-align="center center" [class.mb-24]="!isChild">
    <div siqGrid fx-direction="column" fx-layout-align="center center" class="siq-text-900 fs-24 white text-center">
      <span>{{category?.name}}</span>
    </div>
    <span class="siq-text-400 fs-16 white text-center mt-12" [innerHTML]="category?.description">​</span>
  </div>

  <ng-container *ngIf="selectedSleeper && isSleeperCategory">
    <app-sleeper-questions [questions]="sleeperQuestions" (errorSleeperName)="setSleeperError($event)" (sleeperUpdatedAnswers)="handleSleeperAnswers($event)"></app-sleeper-questions>
  </ng-container>
  
  <ng-container *ngIf="wellnessQuestionForm && selectedSleeper && !isChild">
    <ng-container *ngFor="let question of category?.questions; index as i">
      <ng-container *ngIf="question.isDropdownQuestion else singleMultipleQuestions">
        <div [class.mt-24]="i > 0">
          <app-siq-dropdown 
            [dropdownOptions]="question.answersText" 
            [dropdownValues]="question.answersIds"
            [value]="getSleeperSelectedValue(question.name)"
            [dropdownInputValue]="getWellnessProfileAnswer(question)" 
            paddingClass="p-24"
            [dropdownInputPlaceholder]="notAnsweredQuestion"
            [dropdownInputLabel]="question.text" 
            [description]="question.info_text"
            (selectedValue)="handleDropdownSelect($event, question.name)" />
        </div>
      </ng-container>
      <ng-template #singleMultipleQuestions>
        <app-single-multiple-questions 
          [question]="question" 
          [singleMultipleQuestions]="getSingleMultipleQuestion(question.name)"
          [wellnessProfile]="selectedSleeper.wellnessProfile"
          (updatedAnswers)="handleSingleMultipleChoiceQuestions($event, question.name)"/>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
<div siqGrid fx-direction="row" fx-layout-align="end center" [class.pb-76]="(breakpoints$ | async)?.isMobile" class="mt-48 pb-32 px-24 position-sticky align-bottom align-right background-secondary">
  <app-siq-button
    class="mr-12"
    text="Cancel and Exit"
    btnType="tertiary-btn"
    (buttonClicked)="back()" />
  <app-siq-button
    text="Save and Finish"
    btnType="primary-btn"
    [isDisabled]="disableSaveBtn"
    (buttonClicked)="saveAndFinish()" />
</div>
