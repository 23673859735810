import { EndpointService } from "@shared/utils/helpers/enum.helper";

export class HrvConfig {
  formula: string;
  max: number;
  min: number;

  constructor(hrvConfig: HrvConfig) {
    Object.assign(this, hrvConfig);
  }
}

export class ApplicationConfig {
  hrv: HrvConfig;
  integrationEndpoints: Array<IntegrationEndpoint>;
  chargebeeSiteName: string;

  constructor(config: ApplicationConfig) {
    this.hrv = new HrvConfig(config.hrv);
    this.integrationEndpoints = config.integrationEndpoints.map((it) => new IntegrationEndpoint(it));
    this.chargebeeSiteName = config.chargebeeSiteName;
  }
}

export class IntegrationEndpoint {
  endpoint = '';
  service: EndpointService;

  constructor(config: IntegrationEndpoint) {
    Object.assign(this, config);
  }
}
