import { SiqError } from "@models/app/helpers.model";
import { WifiNetwork, WifiStatus } from "@models/wifi-network/wifi-network.model";

export interface SettingsStateModel {
  selectedBedId: string | null;
  wifiNetworks: Array<WifiNetwork>;
  wifiStatus: Array<WifiStatus>;
  baselineInProgress: boolean;
  loading: boolean;
  error: SiqError | null;
}

export const defaultState = {
  selectedBedId: null,
  wifiNetworks: [],
  wifiStatus: [],
  baselineInProgress: false,
  loading: false,
  error: null
};