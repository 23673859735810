<app-top-bar-navigation [showBackButton]="true"  title="Change Bed Name" (backBtnClicked)="back()" />
<div class="py-32 px-24">
  <div siqGrid fx-direction="column">
    <div siqGrid fx-direction="row" fx-layout-align="center center">
      <span [ngClass]="selectedBed.dualSleep ? 'dual-bed-icon' : 'single-bed-icon'"></span>
    </div>
    <span class="siq-text-900 fs-24 white text-center mt-32">What would you like to name your smart bed?</span>
    
    <form siqGrid fx-direction="row" [formGroup]="changeBedNameForm" class="w-100-p">
      <app-siq-input
      formControlName="name" 
      name="name"
      class="mt-24 px-12 w-100-p" 
      type="text" 
      [isInvalid]="hasErrors || isBedNameTooLong"
      (controlFocus)="setControlFocusState($event)"/>
    </form>
    <ng-container *ngIf="hasErrors && !isBedNameTooLong">
      <span class="siq-text-400 fs-14 red px-12 mt-12"> {{bedNameError}} </span>
    </ng-container>
    <ng-container *ngIf="showCharacterCount && !hasErrors">
      <div siqGrid fx-direction="row" fx-layout-align="end center" class="w-100-p mt-12">
        <span class="siq-text-400 fs-14 primary-200 px-12" [class.red]="isBedNameTooLong">{{characterCount}}/22</span>
      </div>
    </ng-container>
    <div siqGrid fx-direction="row" fx-layout-align="end center" fx-layout-align-sm="center center" class="mt-48 px-12">
      <app-siq-button
      text="Done"
      btnType="primary-btn"
      width="178px"
      [isDisabled]="!changeBedNameForm.valid || isBedNameTooLong"
      (buttonClicked)="done()" />
  </div>
</div>