// A.k.a Personalized Tips
import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";

interface DYKTipProps {
  categories: Array<string>;
  id: number;
  image: string;
  tags: Array<string>;
  title: string;
  header?: string;
}
export class DYKTipEntity {
  tips: Array<DYKTip>;
  sleeperId: string;

  constructor(dyk: DYKTipEntity) {
    dyk.tips = dyk.tips.map((tip) => new DYKTip(tip));
    Object.assign(this, dyk);
  }
}

export class DYKTip {
  categories: Array<string>;
  id: number;
  image: string;
  tags: Array<string>;
  title: string;
  header: string;

  constructor(tip: DYKTipProps) {
    Object.assign(this, tip);
  }

  get message(): string {
    return FunctionsHelper.addTrademarkToText(this.title);
  }
}