import { IccMessage } from "@models/health/sleep-health-icc.model";
import { CircadianRhythm } from "@models/sessions/circadian-rhythm.model";
import { Rolling30DaysSession, SleepDataAggregates } from "@models/sessions/rolling-data-aggregates";
import { NoDataSession, SessionModel } from "@models/sessions/session.model";
import { SleepData, SleepDataSessions, SleepDataStructure, SleepHistoryMonth, YearSleepDataEntityModel } from "@models/sessions/sleep-data.model";
import { Selector, createSelector } from "@ngxs/store";
import { SiqDateFormats } from "@shared/utils/helpers/date-formats.helper";
import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import { SleepersStateModel } from "@store/sleepers/sleepers.model";
import { SleepersState } from "@store/sleepers/sleepers.state";
import * as moment from "moment";
import { SessionsStateModel } from "./sessions.model";
import { SessionsState } from "./sessions.state";

export class SessionsSelectors {

  @Selector([SessionsState])
  static loading(state: SessionsStateModel): boolean {
    return state.loading;
  }

  @Selector([SessionsState, SleepersState])
  static lastSessionFetched(state: SessionsStateModel, sleeperState: SleepersStateModel): boolean {
    return !!state.sleepData.find(s => s.sleeperId === sleeperState.selectedSleeper?.sleeperId)?.sleepData;
  }

  @Selector([SessionsState])
  static selectedSession(state: SessionsStateModel): SessionModel | NoDataSession | null {
    return state.selectedSession;
  }

  @Selector([SessionsState, SleepersState])
  static latestSleepNumberSetting(state: SessionsStateModel, sleeperState: SleepersStateModel): number | undefined | null {
    const sessions = state.sleepData.find(s => s.sleeperId === sleeperState?.selectedSleeper?.sleeperId)?.sleepData;
    if (sessions) {
      const mergedSleepSessions = FunctionsHelper.getSessions(sessions);
      return mergedSleepSessions.find(session => session.sleepNumber)?.sleepNumber;
    }
    return 0;
  }


  @Selector([SessionsState, SleepersState])
  static circadianRhythm(state: SessionsStateModel, sleeperState: SleepersStateModel): CircadianRhythm | null {
    const originalStartDate = state.selectedSession?.originalStartDate;
    const originalEndDate = state.selectedSession?.originalEndDate;
    const sleeperCr = state.circadianRhythm.find((cr) => cr.sleeperId === sleeperState.selectedSleeper?.sleeperId && cr.circadianRhythm.originalSleepSession.start === originalStartDate && cr.circadianRhythm.originalSleepSession.end === originalEndDate);
    return sleeperCr ? sleeperCr.circadianRhythm : null;
  }

  static getIccMessageForCategory(category: string): (state: SessionsStateModel) => IccMessage | null {
    return createSelector([SessionsState], (sessionState: SessionsStateModel) => {
      const iccMessages: IccMessage[] | undefined = sessionState.iccMessages.find((icc) =>
        icc.startDate === sessionState?.selectedSession?.startDate && icc.endDate === sessionState.selectedSession.endDate
      )?.messages;
      return iccMessages ? iccMessages[category] : null;
    });
  }

  @Selector([SessionsState, SleepersState])
  static rolling30Days(state: SessionsStateModel, sleeperState: SleepersStateModel): Rolling30DaysSession | null {
    const selectedSleeper30DaysRolling = state.rolling30Days.find((rolling30Days) => rolling30Days.sleeperId === sleeperState.selectedSleeper?.sleeperId);
    // since we are only fetching one day, there will always be one element in an array
    const rollingData = selectedSleeper30DaysRolling?.sleepData[0].sessions.find((it) => it.originalStartDate === state.selectedSession?.originalStartDate && it.originalEndDate === state.selectedSession.originalEndDate);
    return rollingData ? rollingData : null;
  }

  @Selector([SessionsState, SleepersState])
  static rollingDataAggregates(state: SessionsStateModel, sleeperState: SleepersStateModel): SleepDataAggregates | null {
    const selectedSleeperRollingDataAggregates = state.rollingDataAggregates.find((rollingAggregates) => rollingAggregates.sleeperId === sleeperState.selectedSleeper?.sleeperId);
    return selectedSleeperRollingDataAggregates ? selectedSleeperRollingDataAggregates.sleepDataAggregates : null;
  }

  @Selector([SessionsState, SleepersState])
  static weekSleepData(state: SessionsStateModel, sleepersState: SleepersStateModel): Array<SleepDataStructure> {
    return state.sleepDataWeek.filter(data => data.sleeperId === sleepersState.selectedSleeper?.sleeperId);
  }

  static monthSleepData(month: string): (sessionsState: SessionsStateModel, sleeperState: SleepersStateModel) => Array<SleepHistoryMonth> {
    return createSelector([SessionsState, SleepersState], (sessionsState: SessionsStateModel, sleeperState: SleepersStateModel): Array<SleepHistoryMonth> => {
      const sleeperId = sleeperState.selectedSleeper?.sleeperId;
      const sleeperData = sessionsState.sleepData.find((data) => data.sleeperId === sleeperId);
      const sleeperSleepDataFiltered = sleeperData?.sleepData.filter((data) => moment(data.date).isSame(month, 'month'))[0];
      const sleeperSleepData = sleeperSleepDataFiltered ? JSON.parse(JSON.stringify(sleeperSleepDataFiltered)) : null;
      if (sleeperSleepData?.sleepData?.length) {
        sleeperSleepData.sleepData = sleeperSleepData.sleepData.reverse();
      }
      return sleeperSleepData && sleeperSleepData.sleepData.length > 0 ? [new SleepHistoryMonth(month, sleeperSleepData.sleepData, sleeperSleepData.sleepIQAvg, sleeperSleepData.sleepIQMax, sleeperSleepData.inBedAvg)] : [new SleepHistoryMonth(month, [], 0, 0, 0)];
    });
  }

  @Selector([SessionsState, SleepersState])
  static yearSleepData(state: SessionsStateModel, sleepersState: SleepersStateModel): Array<YearSleepDataEntityModel> {
    return state.sleepDataYear.filter(data => data.sleeperId === sleepersState.selectedSleeper?.sleeperId);
  }

  static rollingDays(period: number): (sessionState: SessionsStateModel, sleeperState: SleepersStateModel) => Array<SessionModel | NoDataSession | undefined> | null {
    return createSelector([SessionsState, SleepersState], (sessionState: SessionsStateModel, sleeperState: SleepersStateModel): Array<SessionModel | NoDataSession | undefined> | null => {
      const sleeperSessions: Array<SleepData> = JSON.parse(JSON.stringify(sessionState.sleepData.find(s => s.sleeperId === sleeperState?.selectedSleeper?.sleeperId)?.sleepData));
      let mergedSleepSessions: Array<SleepDataSessions> = [];
      sleeperSessions.map((it) => {
        mergedSleepSessions = mergedSleepSessions.concat(it.sleepData);
      });
      mergedSleepSessions = mergedSleepSessions.filter((ss) => moment(ss.date).isSameOrBefore(sessionState.selectedSession?.date));
      const periodData = mergedSleepSessions?.splice(0, period).reverse();
      const rollingDaysData = periodData.map((it) => {
        if (it.sessions.length > 0) {
          const longestSleepSession = it.sessions.find((ss) => ss.longest);
          if (longestSleepSession) {
            return new SessionModel(longestSleepSession);
          }
        }
        return new NoDataSession(moment(it.date).subtract(1, 'day').format(SiqDateFormats.ISO8601), moment(it.date).format(SiqDateFormats.ISO8601));
      });
      if (rollingDaysData.length < period) {
        while (rollingDaysData.length < period) {
          rollingDaysData.unshift(new NoDataSession(moment(rollingDaysData[0].date).format(SiqDateFormats.ISO8601), moment(rollingDaysData[0].date).subtract(1, 'day').format(SiqDateFormats.ISO8601)));
        }
      }
      return rollingDaysData;
    });
  }
}