import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { RoutesHelper } from '@shared/utils/helpers/routes.helper';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MixpanelService } from '@services/mixpanel.service';
import { SiqCommonModule } from '@shared/siq-common.module';
import { SplashScreenComponent } from '@shared/standalone-components/splash-screen/splash-screen.component';
import { AppComponent } from './app.component';
import { AppCoreModule } from './core/app-core.module';
import { EnvServiceProvider } from './core/env.service.provider';
import { ModalModule } from '@shared/modal.module';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppCoreModule,
    BrowserModule,
    SiqCommonModule,
    NoopAnimationsModule,
    SplashScreenComponent,
    ModalModule
  ],
  providers: [
    EnvServiceProvider,
    MixpanelService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    provideRouter(RoutesHelper.appRoutes),
    provideHttpClient(
      withInterceptorsFromDi()
    )
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
