import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MiddleColumnScrollService {

  isScrolledToBottom$ = new BehaviorSubject<boolean>(false);

  setIsScrolledToBottom(isScrolledToBottom: boolean): void {
    this.isScrolledToBottom$.next(isScrolledToBottom);
  }
}