import { SetSleepersPassword } from "@models/app/helpers.model";
import { ValidateOrderModel } from "@models/auth/register.model";
import { OrderNumberValidation } from "@shared/utils/helpers/enum.helper";

export class ValidateOrderNumber {
  static readonly type = '[Register] ValidateOrderNumber';
  constructor(public data: ValidateOrderModel, public validationType: OrderNumberValidation) {}
}

export class SetPasswordAndActivate {
  static readonly type = '[App] SetPasswordAndActivate';
  constructor(public data: SetSleepersPassword) {}
}

export class ResetRegisterState {
  static readonly type = '[Register] ResetRegisterState';
}
