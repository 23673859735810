import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { ResizeService } from '@services/resize.service';
import { SiqCommonModule } from '@shared/siq-common.module';
import { SetSiqNotification } from '@store/app/app.actions';
import { AppSelectors } from '@store/app/app.selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-siq-notification',
  templateUrl: './siq-notification.component.html',
  styleUrls: ['./siq-notification.component.scss'],
  standalone: true,
  imports: [SiqCommonModule]
})
export class SiqNotificationComponent implements AfterViewInit, OnDestroy {
  @ViewChild('siqNotification') siqNotification: ElementRef;
  @Input() isSuccess: boolean;
  @Input() text: string;

  private _unsubscribeAll = new Subject<void>();

  constructor(
    private renderer2: Renderer2, 
    private resizeService: ResizeService, 
    private store: Store,
    private mixpanelService: MixpanelService) { }

  ngAfterViewInit(): void {
    this.resizeService.isMobile.pipe((
      takeUntil(this._unsubscribeAll)
    )).subscribe(() => {
      const siqNotification = this.store.selectSnapshot(AppSelectors.siqNotification);
      if(siqNotification)
        this.mixpanelService.trackToastDisplay(siqNotification.mixpanelSourceScreen, siqNotification.text, siqNotification.mixpanelFlow);
      const middleColumnWidth = document.getElementsByClassName('middle-column')[0].clientWidth;
      const notification = this.siqNotification.nativeElement as HTMLElement;
      this.renderer2.setStyle(notification, 'width', `${middleColumnWidth - 24}px`);
    });
    setTimeout(() => {
      this.store.dispatch(new SetSiqNotification(null));
    }, 2500);
  }

  get icon(): string {
    return `assets/icons/${this.isSuccess ? 'dark-checkmark' : ''}.svg`;
    // To do: Add icon title for unsuccess notification
  }

  get colorClass(): string {
    return this.isSuccess ? 'background-electric' : 'unsuccess-color';
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
