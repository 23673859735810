<app-top-bar-navigation [showBackButton]="true" title="Register Smart Bed" (backBtnClicked)="back()" />
<div siqGrid fx-direction="column" fx-layout-align="center center" class="py-32 px-24">
  <span class="siq-text-900 fs-20 white">Let's Get Started</span>
  <span class="siq-text-400 fs-16 white mt-12">Have your receipt handy to make it easier.</span>
  <ng-container *ngIf="registerForm">
    <form siqGrid fx-direction="column" [formGroup]="registerForm" class="w-100-p my-48">
      <app-siq-input
        class="px-12"
        [formControl]="registerForm.controls['orderNumber']"  
        name="orderNumber"
        type="text" 
        placeholder="11-digit order number"
        [maxLength]="11"
        label="Order number"
        [isInvalid]="isInvalidField(registerForm, 'orderNumber')"
        (controlFocus)="setControlFocusState($event, 'orderNumber')"/>
      <span *ngIf="isInvalidField(registerForm, 'orderNumber')" class="siq-text-400 fs-14 red mt-12 px-12">{{ errorMessages.orderNumber }}</span>
 
      <app-siq-input
        class="mt-24 px-12"
        [formControl]="registerForm.controls['validationProp']"
        name="validationProp"
        [type]="formControlParams.type" 
        [placeholder]="formControlParams.placeholder"
        [label]="formControlParams.label"
        [isInvalid]="isInvalidField(registerForm, 'validationProp')"
        (controlFocus)="setControlFocusState($event, 'validationProp')" />
      <span *ngIf="isInvalidField(registerForm, 'validationProp')" class="siq-text-400 fs-14 red mt-12 px-12">{{ validationPropErrorMessage }}</span>
    </form>
  </ng-container>
  <div siqGrid fx-direction="row" fx-layout-align="end center" fx-layout-align-sm="center center" class="w-100-p">
    <app-siq-button
      text="Find My Order"
      btnType="primary-btn"
      width="208px"
      [isDisabled]="!isFormValid"
      (buttonClicked)="onSubmit()" />
  </div>

</div>