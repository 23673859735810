import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { OpenWhyIsThisContent, ResetWhyIsThisState, SetRedirectToTab } from "./why-is-this.actions";
import { WhyIsThisStateModel, defaultWhyIsThisState } from "./why-is-this.model";
import { Navigate } from "@ngxs/router-plugin";

@State<WhyIsThisStateModel>({
  name: 'whyIsThis',
  defaults: defaultWhyIsThisState
})
@Injectable()
export class WhyIsThisState {

  @Action(OpenWhyIsThisContent)
  openWhyIsThisContent(ctx: StateContext<WhyIsThisStateModel>, action: OpenWhyIsThisContent): void {
    ctx.patchState({ content: action.whyIsThisContent });
    ctx.dispatch(new Navigate([`pages/why-is-this`]));
  }

  @Action(SetRedirectToTab)
  setRedirectToTab(ctx: StateContext<WhyIsThisStateModel>, action: SetRedirectToTab): void {
    ctx.patchState({ redirectToTab: action.tabId });
  }

  @Action(ResetWhyIsThisState)
  resetWhyIsThisState(ctx: StateContext<WhyIsThisStateModel>): void {
    ctx.setState({ ...defaultWhyIsThisState });
  }
}