import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SetupAddSleeperResponse, SetupSleeperData } from '@models/app/helpers.model';
import { Sleeper, UpdateSleeperProperty } from '@models/sleeper/sleeper.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { AccountSettingsStringResource } from '@shared/utils/helpers/account-settings.helper';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { ResetSleeperData, SetSettingFirstSleeper, SetupAddSleeper } from '@store/setup/setup.actions';
import { SetupSelectors } from '@store/setup/setup.selectors';
import { UpdateSleeper } from '@store/sleepers/sleepers.actions';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-create-login',
  templateUrl: './create-login.component.html'
})
export class CreateLoginComponent implements OnInit, OnDestroy {
  sleeperData: SetupSleeperData | null;
  selectedSleeperId: string | null;
  setupSleeperData$: Observable<SetupSleeperData | null>;
  isSettingFirstSleeper: boolean;

  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private mixpanelService: MixpanelService) { }

  get sleeperName(): string {
    return this.sleeperData?.firstName ?? '';
  }

  get title(): string {
    return this.isSettingFirstSleeper ? 'Set Up Sleeper': 'Set Up Sleep Partner';
  }

  ngOnInit(): void {
    FunctionsHelper.scrollToTop();
    // first sleeper on the bed, that is not account owner
    this.isSettingFirstSleeper = this.store.selectSnapshot(SetupSelectors.isSettingFirstSleeper);
    this.store.select(SetupSelectors.sleeperData).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(sleeperData => this.sleeperData = sleeperData);

    // this flag will be used to check if we already added a sleeper
    // since adding sleeper is done on skip or on invite
    // if invite fails the sleeperId will stay inside the state and this way the app will
    // know which action should be called (Update or Add)
    // upon successful the sleeper data is reset
    // this is so we ensure that correct sleeperId is tracked
    this.store.select(SetupSelectors.selectedSleeperId).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(sleeperId => this.selectedSleeperId = sleeperId);
  }

  back(): void {
    if(this.isSettingFirstSleeper) {
      this.store.dispatch(new Navigate(['pages/setup/setup-sleeper']));
    } else {
      this.store.dispatch(new Navigate(['pages/setup/setup-partner']));
    }
  }

  skip(): void {
    this.mixpanelService.trackProceedSkipAction('set up sleep partner invite email', FunctionsHelper.getProceedSkipActionFlow());
    const addUpdateSleeperRequest = this.getAddUpdateSleeperReq();

    addUpdateSleeperRequest.subscribe({
      next: () => {
        this.store.dispatch(new SetSettingFirstSleeper(false));
        this.store.dispatch(new ResetSleeperData());
        FunctionsHelper.redirectToTheNextStepInRegistration(this.store);
      }
    });
  }

  sendInvitation(data: UpdateSleeperProperty): void {
    const addUpdateSleeperRequest = this.getAddUpdateSleeperReq();

    addUpdateSleeperRequest.subscribe({
      next: () => {
        this.store.dispatch(new SetSettingFirstSleeper(false));
        if (this.selectedSleeperId) {
          // sending invite
          this.store.dispatch(new UpdateSleeper(this.selectedSleeperId, data)).subscribe({
            next: () => {
              const setupSleepersFrom = localStorage.getItem('setupSleepersFrom') === 'pages/sleep' ? 'sleeper_switcher' : 'registration';
              this.mixpanelService.trackSleeperEmailInvite(setupSleepersFrom);
              this.store.dispatch(new ResetSleeperData());
              FunctionsHelper.redirectToTheNextStepInRegistration(this.store, true);
            },
            error: (initialError) => {
              // if this specific error appears the user should stay on the screen
              if(!initialError.error.Error.Message.includes(AccountSettingsStringResource.InviteSleeperError.message)) {
                FunctionsHelper.redirectToTheNextStepInRegistration(this.store);
              }
            }
          });
        }
      }
    });
  }

  private getAddUpdateSleeperReq(): Observable<SetupAddSleeperResponse | Sleeper | HttpErrorResponse | void> {
    return  this.selectedSleeperId && this.sleeperData ?
    this.store.dispatch(new UpdateSleeper(this.selectedSleeperId, this.sleeperData)) :
    this.store.dispatch(new SetupAddSleeper());
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
