import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckboxControlValueAccessor } from '@angular/forms';
import { SiqCommonModule } from '@shared/siq-common.module';

@Component({
  selector: 'app-siq-slide-toggle',
  templateUrl: './siq-slide-toggle.component.html',
  styleUrls: ['./siq-slide-toggle.component.scss'],
  standalone: true,
  imports: [SiqCommonModule]
})
export class SiqSlideToggleComponent extends CheckboxControlValueAccessor {
  @Input() checked: boolean;
  @Output() toggleState = new EventEmitter<boolean>();

  override onChange = (checked: boolean): void => {
    this.checked = checked;
    this.toggleState.emit(this.checked);
  };
}
