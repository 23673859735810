export interface CognitoLoginModel {
  Email: string;
  Password: string;
}

export class CognitoQuery<T> {
  status!: string;
  data!: T;

  constructor(status: string, data: T) {
    this.status = status;
    this.data = data;
  }
}

// Login models
// in case user was redirected from the mobile
// there will be only AccessToken and IdToken provided
export class CognitoLoginData {
  AccessToken: string;
  IdToken: string;
  RefreshToken?: string;
  ExpiresIn?: number;
  TokenType?: string;
}

// Refresh token models

export class CognitoRefreshTokenModel {
  RefreshToken!: string;

  constructor(refreshToken: CognitoRefreshTokenModel) {
    Object.assign(this, refreshToken);
  }
}

export class CognitoRefreshToken {
  AccessToken!: string;
  IdToken!: string;
  ExpiresIn!: number;
  TokenType!: string;

  constructor(cognitoRefreshAuth: CognitoRefreshToken) {
    Object.assign(this, cognitoRefreshAuth);
  }
}

export class UserInfo {
  userId!: string;
  accountId!: string;
  sleeperId!: string;
  registrationState!: number;
  licenseAccepted!: boolean;
  privacyPolicyAccepted!: boolean;

  constructor(userInfo: UserInfo) {
    Object.assign(this, userInfo);
  }
} 
