export enum QuestionType {
  dropdown,
  checkbox,
  radioButton
}

export enum Category {
  Sleeper = 1,
  Home = 2,
  Habits = 3,
  SleepHealth = 4
}

export enum AnswersType {
  text,
  id
}
export class WellnessProfileResponse {
  wellnessProfile: WellnessProfile;
}

export class WellnessProfile {
  bedroomLightSetting: number | null = null;
  bodyTemperatureWhileAsleep: number | null = null;
  caffeineTime: number | null = null;
  childrenInBed: number | null = null;
  chronicHealthIssues: string | null = null;
  consumeAlcohol: number | null = null;
  consumeCaffeine: number | null = null;
  exercise: number | null = null;
  exerciseTime: number | null = null;
  exerciseType: number | null = null;
  haveChildren: number | null = null;
  havePets: number | null = null;
  mealBeforeBed: number | null = null;
  morningWakeUp: number | null = null;
  noiseWhileAsleep: number | null = null;
  petsInBed: number | null = null;
  sleepEnvironment: number | null = null;
  sleepGoals: string | null = null;
  sleepIssues: number | null = null;
  smoker: number | null = null;
  stressLevel: number | null = null;
  takeNaps: number | null = null;
  temporaryHealthIssues: string | null = null;
  troubleFallingOrStayingAsleep: number | null = null;
  workSchedule: number | null = null;
  uniqueRhythm: number | null = null;
  sleepDisruptions: Array<number> = [];
  sleepProblems: Array<RadioButtonQuestion> = [new RadioButtonQuestion({ troubleFallingAsleep: null }), new RadioButtonQuestion({ wakingUpDuringNight: null }), new RadioButtonQuestion({ returningToSleepAfterWaking: null }), new RadioButtonQuestion({ wakingUpEarly: null })];
  diagnosis: Array<number> = [];
  cpapMachineUsages: number | null = null;
  sleepAidsUsages: Array<RadioButtonQuestion> = [new RadioButtonQuestion({ prescribedSleepMedications: null }), new RadioButtonQuestion({ overTheCounterSleepMedications: null }), new RadioButtonQuestion({ naturalRemedies: null }), new RadioButtonQuestion({ drugFreeAids: null }), new RadioButtonQuestion({ behavioralProgram: null })];
  sleepPosition: number | null = null;

  constructor(profile?: WellnessProfile) {
    if(profile) {
      Object.assign(this, profile);
      this.sleepProblems = profile.sleepProblems.map((it) => new RadioButtonQuestion(it));
      this.sleepAidsUsages = profile.sleepAidsUsages.map((it) => new RadioButtonQuestion(it));
    }
  }
}

export class RadioButtonQuestion {
  [key: string]: number | null;

  constructor(question: RadioButtonQuestion) {
    Object.assign(this, question);
  }
}

export class WellnessCategory {
  id: number;
  name: string;
  description: string;
  questions: Array<QuestionProperties>;

  constructor(id: number, name: string, description: string, questions: Question) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.questions = [];
    for (const question in questions) {
      this.questions.push(new QuestionProperties({ ...questions[question], name: question } as QuestionProperties));
    }
  }

  get icon(): string {
    return this.name.toLowerCase().split(' ').join('-');
  }

  isCategory(category: Category): boolean {
    return this.id === category;
  }
}

export class Question {
  [key: string]: QuestionProperties;

  constructor(question: Question) {
    Object.assign(this, question);
  }
}

export class QuestionProperties {
  index: number;
  category: number;
  text: string;
  info_text: string;
  type: QuestionType;
  answers?: Array<Answer>;
  subquestions?: Array<SubQuestionsAnswer>;
  name: string;

  constructor(question: QuestionProperties) {
    Object.assign(this, question);
    if (question.answers) {
      this.answers = question.answers.map((it) => new Answer(it));
    }
    if (question.subquestions) {
      this.subquestions = question.subquestions.map((it) => new SubQuestionsAnswer(it));

    }
  }

  get answersText(): Array<string> {
    const arr = ['Please select...'];
    this.answers?.forEach(answer => {
      arr.push(answer.text);
    });
    return arr;
  }

  get answersIds(): Array<number> {
    // The -1 represent id of 'Please select...'. Sending -1 to the API, indicates that the question is not answered
    const arr = [-1];
    this.answers?.forEach(answer => {
      arr.push(answer.id);
    });
    return arr;
  }

  get isSleepHealthQuestion(): boolean {
    return this.category === 4;
  }

  get isDropdownQuestion(): boolean {
    return !this.isSleepHealthQuestion || (this.isSleepHealthQuestion && this.type === QuestionType.dropdown);
  }

  get isMultipleChoiceQuestion(): boolean {
    return this.type === QuestionType.checkbox;
  }

  get isAStringAnswer(): boolean {
    return this.name === 'sleepGoals' || this.name === 'temporaryHealthIssues' || this.name === 'chronicHealthIssues';
  }
}

class Answer {
  id: number;
  text: string;

  constructor(answer: Answer) {
    Object.assign(this, answer);
  }
}

class SubQuestionsAnswer {
  id: string;
  text: string;
  answers: Array<Answer>;

  constructor(answer: SubQuestionsAnswer) {
    Object.assign(this, answer);
    this.answers = answer.answers.map((it) => new Answer(it));
  }
}

export class SleeperWellnessQuestion {
  property: string;
  dropdownOptions: Array<string>;
  dropdownValues: Array<number>;
  text: string;
  infoText: string;
  value: string | number;
  type = 1; // defaults to dropdown question type

  constructor(property: string, options: Array<string>, values: Array<number>, text: string, infoText: string, value: number | string, type?: number) {
    this.property = property;
    this.dropdownOptions = options;
    this.dropdownValues = values;
    this.text = text;
    this.infoText = infoText;
    if (type) {
      this.type = type;
    }
    this.value = value;
  }

  get questionValue(): number {
    return typeof this.value === 'string' ? parseInt(this.value, 10) : this.value;
  }

  get isDropdownQuestion(): boolean {
    return this.type === 1;
  }
}
