/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ResizeService } from '@services/resize.service';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { Subject, takeUntil } from 'rxjs';

enum ScreenSize {
  'smallScreen' = 'xs',
  'mobile' = 'sm',
  'tablet' = 'lt-sm'
}
interface CssProperties {
  prop: string;
  cssValue: Array<string> | null;
  cssClasses: Array<string>;
}

// list of properties the directive is supporting
const cssPropertyList = [{
  key: 'direction', cssClass: ['flex-direction']
}, {
  key: 'layout-align', cssClass: ['justify-content', 'align-items']
}, {
  key: 'show', cssClass: ['display'],
}, {
  key: 'wrap', cssClass: ['flex-wrap']
}];

@Directive({
  selector: '[siqGrid]'
})
export class GridDirective implements OnInit, OnChanges, OnDestroy {

  @Input('siqGrid')
  public set display(value: string) {
    this._display = value || 'flex';
  }

  @Input('fx-direction') flexDirection = '';
  @Input('fx-direction-lt-sm') flexDirectionLTSM = '';
  @Input('fx-direction-sm') flexDirectionSM = '';
  @Input('fx-direction-xs') flexDirectionXS = '';

  @Input('fx-layout-align') flexLayoutAlign = '';
  @Input('fx-layout-align-lt-sm') flexLayoutAlignLTSM = '';
  @Input('fx-layout-align-sm') flexLayoutAlignSM = '';
  @Input('fx-layout-align-xs') flexLayoutAlignXS = '';


  @Input('fx-show')
  public set show(value: string) {
    if (value === '') {
      this.flexShow = 'true';
    } else {
      this.flexShow = value;
    }
  }

  @Input('fx-show-lt-sm')
  public set showLTSM(value: string) {
    if (value === '') {
      this.flexShowLTSM = 'true';
    } else {
      this.flexShowLTSM = value;
    }
  }

  @Input('fx-show-sm')
  public set showSM(value: string) {
    if (value === '') {
      this.flexShowSM = 'true';
    } else {
      this.flexShowSM = value;
    }
  }

  @Input('fx-show-xs')
  public set showXS(value: string) {
    if (value === '') {
      this.flexShowXS = 'true';
    } else {
      this.flexShowXS = value;
    }
  }

  @Input('fx-wrap') flexWrap = '';
  @Input('fx-wrap-lt-sm') flexWrapLTSM = '';
  @Input('fx-wrap-sm') flexWrapSM = '';
  @Input('fx-wrap-xs') flexWrapXS = '';


  private _display = 'flex';
  private flexShow = '';
  private flexShowLTSM = '';
  private flexShowSM = '';
  private flexShowXS = '';
  private _unsubscribeAll = new Subject<void>();
  private cssProperties: Array<CssProperties> = [];
  private _screenSize: string;

  constructor(private resizeService: ResizeService, private el: ElementRef) {
    this._screenSize = this.resizeService.getScreenSize(self.innerWidth);
  }

  ngOnInit(): void {
    // set initial css
    this.cssProperties = [];
    this._screenSize = this.resizeService.getScreenSize(self.innerWidth)
    this.createPropertyList('desktop');
    this.createPropertyList('tablet');
    this.createPropertyList('mobile');
    this.createPropertyList('smallScreen');
    this.setProps();
    this.resizeService.isMobile.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(breakpoint => {
      this._screenSize = breakpoint.screenSize;
      this.onChanges(breakpoint.screenSize);
    });
  }

  public ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.clearStyles();
  }

  // this will cover cases where for example
  // [fx-direction]="getDirection"
  // so if direction on one breakpoint changes due to some condition
  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach((property) => {
      if (changes[property].currentValue !== changes[property].previousValue) {
        this.onChanges(this._screenSize);
      }
    });
  }

  private onChanges(breakpoint: string): void {
    // update on changes property
    this.clearStyles();
    this.updatePropertyList(breakpoint);
    this.setProps();
  }

  private createPropertyList(point: string): void {
    cssPropertyList.forEach(it => {
      // camelize property 
      // flex direction xs -> flexDirectionXS
      // flex layout align lt sm -> flexLayoutAlignLTSM
      const camelizedProp = this.formatCSSProperty(it.key, point);
      const cssValue = this.createCSSValue(camelizedProp, it.key);
      const propertyIndex = this.cssProperties.findIndex(css => css.prop === it.key);
      // if property is created already
      //example: direction is already set on the desktop screen
      // the existing property will be updated
      if (propertyIndex !== -1) {
        if (cssValue) {
          this.cssProperties[propertyIndex] = {
            prop: it.key, cssValue, cssClasses: it.cssClass
          };
        }
      } else {
        // create new property
        this.cssProperties.push({
          prop: it.key, cssValue, cssClasses: it.cssClass
        });
      }

    });
  }

  private updatePropertyList(point: string): void {
    cssPropertyList.forEach((cssProp) => {
      const camelizedProp = this.formatCSSProperty(cssProp.key, point);
      const cssValue = this.createCSSValue(camelizedProp, cssProp.key);
      if (cssValue) {
        this.cssProperties = this.getUpdatedPropertyArray(cssProp.key, cssValue);
      } else {
        // if there is no value, check higher points
        // this will cover case if we are going from mobile to desktop
        this.checkHigherPoints(point, cssProp.key);
      }
    });
  }

  // checks if there is css set for bigger sizes
  // example
  // <div siqGrid fx-direction="row" fx-direction-xs="column">
  // in example above there is no explicit direction set for tablet
  // since the direction property is set for desktop it should translate to tablet as well
  private checkHigherPoints(point: string, key: string): void {
    switch (point) {
      case 'smallScreen':
        this.checkForUpdatedProperty('mobile', key);
        break;
      case 'mobile':
        this.checkForUpdatedProperty('tablet', key);
        break;
      case 'tablet':
        this.checkForUpdatedProperty('desktop', key);
        break;
      default:
        return;
    }
  }

  // checks is there is property set for the screen
  private checkForUpdatedProperty(screen: string, key: string): void {
    const camelizedProp = this.formatCSSProperty(key, screen);
    const cssValue = this.createCSSValue(camelizedProp, key);
    if (cssValue) {
      this.cssProperties = this.getUpdatedPropertyArray(key, cssValue);
    } else {
      this.checkHigherPoints(screen, key);
    }
  }

  private formatCSSProperty(key: string, screenSize: string): string {
    const breakpoint = screenSize !== 'desktop' ? ScreenSize[screenSize] : '';
    return FunctionsHelper.camelize(`flex ${(key.split('-').join(' '))} ${breakpoint.split('-').join(' ').toUpperCase()}`);
  }

  private createCSSValue(propValue: string, key: string): Array<string> | null {
    if (this[propValue]) {
      // these are special cases for example
      // show property uses true as indicator to trigger the property, but property value itself is block | flex ...
      switch (key) {
        case 'show': {
          const showToBoolean = FunctionsHelper.booleanify(this[propValue]);
          if (showToBoolean) {
            return [this._display];
          }
          return ['none']; // if its false then `display: none` should be added
        }
        case 'layout-align': {
          const replaceStart = this[propValue].replace('start', 'flex-start');
          const replaceEnd = replaceStart.replace('end', 'flex-end');
          return replaceEnd.split(' ');
        }
        default:
          return this[propValue].split(' ');
      }
    }
    return null;
  }

  private getUpdatedPropertyArray(key: string, cssValue: Array<string>): Array<CssProperties> {
    return this.cssProperties.map((property) => {
      if (property.prop === key && cssValue) {
        return {
          ...property,
          cssValue
        };
      }
      return property;
    });
  }

  // adds css classes 
  private setProps(): void {
    this.el.nativeElement.style.setProperty('display', this._display);

    this.cssProperties.forEach(it => {
      if (it.cssValue) {
        it.cssClasses.forEach((key, index) => {
          this.el.nativeElement.style.setProperty(key, it.cssValue ? it.cssValue[index] : null);
        });
      }
    });
  }

  private clearStyles(): void {
    for (const { cssClass } of cssPropertyList) {
      for (const it of cssClass) {
        this.el.nativeElement.style.removeProperty(it);
      }
    }
  }
}