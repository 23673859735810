import { DYKTipEntity } from "@models/edp/dyk-tips.model";
import { SiqError } from "@models/app/helpers.model";

export interface EdpStateModel {
  dykTips: Array<DYKTipEntity>;
  loading: boolean;
  error: SiqError | null;
}

export const defaultEdpState = {
  dykTips: [],
  loading: false,
  error: null
}