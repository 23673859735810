import { SiqError } from "@models/app/helpers.model";
import { SleepHealthIccEntity } from "@models/health/sleep-health-icc.model";
import { SleepHealthEntity } from "@models/health/sleep-health.model";
import { SleeperDownloadedReport, WellnessReportEntityModel } from "@models/health/wellness-report.model";

export interface HealthStateModel {

  sleepHealth: Array<SleepHealthEntity>;
  sleepHealthIcc: SleepHealthIccEntity | null;
  loadingSleepHealthIcc: boolean; // since they are fetched upon screen entry, we need to have a loading indicator
  sleeperDownloadedReport: Array<SleeperDownloadedReport>;
  wellnessReport: Array<WellnessReportEntityModel>;
  loading: boolean;
  error: SiqError | null;
}

export const defaultHealthState = {
  sleepHealth: [],
  sleepHealthIcc: null,
  loadingSleepHealthIcc: false,
  sleeperDownloadedReport: [],
  wellnessReport: [],
  loading: false,
  error: null
};