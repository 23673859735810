<div siqGrid fx-layout-align="center center">
  <div siqGrid="block" fx-direction="column" fx-layout-align="center center" class="pt-96 px-24 pb-24">
    <div id="welcome-text" aria-label="Welcome to Sleep Number" tabindex="1" role="region" siqGrid fx-layout-align="center center" fx-direction="column">
      <div class="sn-logo login"></div>
      <span class="siq-text-900 fs-20 white mt-32">{{loginText}}</span>
    </div>
    <form siqGrid fx-direction="column" [formGroup]="loginForm" role="region" class="mt-48 px-12">
      <app-siq-input
        [tabIndex]="2"
        formControlName="Email"  
        type="email" 
        placeholder="Enter your email address" 
        label="Email address"
        [isInvalid]="hasErrors('Email')" />
      <span *ngIf="hasErrors('Email')" aria-hidden="true" tabindex="-1" class="siq-text-400 fs-14 red mt-12">{{ invalidValueErrorMsg('Email') }}</span>
      <app-siq-input
        [tabIndex]="3"
        formControlName="Password" 
        class="mt-24" 
        type="password" 
        [isPasswordField]="true"
        placeholder="Enter your password" 
        label="Password"
        [isInvalid]="hasErrors('Password')">
      </app-siq-input>
      <span *ngIf="hasErrors('Password')" aria-hidden="true" tabindex="-1" class="siq-text-400 fs-14 red mt-12">{{ invalidValueErrorMsg('Password') }}</span>
      <div role="region" siqGrid fx-direction="row" fx-layout-align="space-between center" class="mt-24">
        <div siqGrid fx-direction="row" role="region" fx-layout-align="start center">
          <app-siq-checkbox 
            [tabIndex]="4" 
            ariaLabel="Remember me" 
            [checked]="rememberMe" 
            (checkboxState)="setRememberMeClicked($event)" />
          <span aria-hidden="true" class="siq-text-400 fs-16 white ml-8">Remember me</span>
        </div>
        <app-siq-button
          [tabIndex]="5"
          ariaLabel="Forgot Password"
          text="Forgot Password"
          btnType="tertiary-btn"
          (buttonClicked)="forgotPassword()"
          (keyup.enter)="forgotPassword()" />
      </div>
      <div siqGrid fx-direction="row" role="region" fx-layout-align="center center" class="mt-24">
        <app-siq-button
          [tabIndex]="6"
          ariaLabel="Login"
          text="Login"
          btnType="primary-btn"
          width="208px"
          (buttonClicked)="login()"
          (keyup.enter)="login()" />
      </div>
    </form>

      <!-- DYK -->
      <ng-container *ngIf="hasECIMIdentity else registerBed">
        <div class="mt-48 mb-48">
          <app-siq-card
            [isDYK]="true"
            theme="transparent"
            [hasBorder]="true"
            infoBoxType="light"
            infoBoxIcon="siq-logo"
            headerText="Existing Sleep Number Users"
            text="If you have an account with Sleep Number, use your login information to sign in."
          />
        </div>
      </ng-container>
      <ng-template #registerBed>
        <div siqGrid fx-layout-align="center center" fx-direction="column" class="mt-12 my-48">
          <app-siq-button
            [tabIndex]="7"
            ariaLabel="Register New Smart Bed"
            class="mt-48 w-100-p"
            width="100%"
            text="Register New Smart Bed"
            subText="Smart bed owners start here."
            btnType="secondary-btn border-primary"
            icon="right-arrow-btn-icon" 
            [iconPosition]="iconPosition"
            [isListItemBtn]="true"
            (buttonClicked)="register()"
            (keyup.enter)="register()" />
        </div>
      </ng-template>
      <app-download-card aria-hidden="true" class="w-100-p mt-48"/>
  </div>
</div>
