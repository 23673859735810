import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RadioButton } from '@models/app/helpers.model';

@Component({
  selector: 'app-siq-radio-button',
  templateUrl: './siq-radio-button.component.html',
  styleUrls: ['./siq-radio-button.component.scss']
})
export class SiqRadioButtonComponent {
  @Input() selectedOption: number | null;
  @Input() direction = 'column';
  @Input() answer: RadioButton;
  @Output() selected = new EventEmitter<number>();

  isButtonSelected(id: number): boolean {
    return this.selectedOption === id;
  }

  get isColumn(): boolean {
    return this.direction === 'column';
  }

  selectOption(selectedId: number): void {
    this.selectedOption = selectedId;
    this.selected.emit(selectedId);
  }
}
