import { Routes } from "@angular/router";
import { AuthGuard } from "@guards/auth/auth.guard";
import { LandingGuard } from "@guards/auth/landing.guard";
import { runSerially } from "@guards/auth/sleep.guard";
import { TacoGuard } from "@guards/auth/taco.guard";
import { BedsGuard } from "@guards/pages/beds.guard";
import { DetailsGuard } from "@guards/pages/details.guard";
import { HealthIccGuard, WellnessReportGuard } from "@guards/pages/health.guard";
import { NetworksGuard } from "@guards/pages/networks.guard";
import { SelectBedsGuard } from "@guards/pages/select-bed.guard";
import { SessionsGuard } from "@guards/pages/sessions.guard";
import { SleepResearchGuard } from "@guards/pages/sleep-reserach.guard";
import { SleepersGuard } from "@guards/pages/sleepers.guard";
import { ToOnboardingGuard, ToSleepGuard } from "@guards/pages/to-onboarding.guard";
import { ToSelectDefaultSleeper } from "@guards/pages/to-select-default-sleeper.guard";
import { WellnessQuestionsGuard } from "@guards/pages/wellness-questions.guard";
import { PrivacyPolicyResolver } from "@resolvers/privacy-policy.resolver";
import { AuthComponent } from "src/app/main/auth/auth.component";
import { LoginComponent } from "src/app/main/auth/login/login.component";
import { OrderFoundComponent } from "src/app/main/auth/register/components/order-found/order-found.component";
import { RegisterFormComponent } from "src/app/main/auth/register/components/register-form/register-form.component";
import { SetupLoginComponent } from "src/app/main/auth/register/components/setup-login/setup-login.component";
import { RegisterComponent } from "src/app/main/auth/register/register.component";
import { PagesComponent } from "src/app/main/pages/pages.component";
import { SleepStudySuccessComponent } from "src/app/main/pages/profile/details/components/sleep-study/sleep-study-success/sleep-study-success.component";
import { SleepStudyComponent } from "src/app/main/pages/profile/details/components/sleep-study/sleep-study.component";
import { AnswerQuestionsComponent } from "src/app/main/pages/profile/details/components/wellness-profile/answer-questions/answer-questions.component";
import { WellnessProfileHomeComponent } from "src/app/main/pages/profile/details/components/wellness-profile/wellness-profile-home/wellness-profile-home.component";
import { WellnessProfileComponent } from "src/app/main/pages/profile/details/components/wellness-profile/wellness-profile.component";
import { CongratulationComponent } from "src/app/main/pages/setup/components/congratulation/congratulation.component";
import { CreateLoginComponent } from "src/app/main/pages/setup/components/create-login/create-login.component";
import { SelectBedComponent } from "src/app/main/pages/setup/components/select-bed/select-bed.component";
import { SetupBedComponent } from "src/app/main/pages/setup/components/setup-bed/setup-bed.component";
import { SetupPartnerComponent } from "src/app/main/pages/setup/components/setup-partner/setup-partner.component";
import { SetupSleeperComponent } from "src/app/main/pages/setup/components/setup-sleeper/setup-sleeper.component";
import { SleepResearchLearnMoreComponent } from "src/app/main/pages/setup/components/sleep-research-learn-more/sleep-research-learn-more.component";
import { SleepResearchComponent } from "src/app/main/pages/setup/components/sleep-research/sleep-research.component";
import { SetupComponent } from "src/app/main/pages/setup/setup.component";
import { BaselineBedHomeComponent } from "src/app/main/pages/smart-bed/details/components/baseline-bed/baseline-bed-home/baseline-bed-home.component";
import { BaselineBedProgressComponent } from "src/app/main/pages/smart-bed/details/components/baseline-bed/baseline-bed-progress/baseline-bed-progress.component";
import { BaselineBedComponent } from "src/app/main/pages/smart-bed/details/components/baseline-bed/baseline-bed.component";
import { ChangeBedNameComponent } from "src/app/main/pages/smart-bed/details/components/change-bed-name/change-bed-name.component";
import { AddNewNetworkComponent } from "src/app/main/pages/smart-bed/details/components/connectivity/add-new-network/add-new-network.component";
import { ConnectedNetworksHomeComponent } from "src/app/main/pages/smart-bed/details/components/connectivity/connected-networks-home/connected-networks-home.component";
import { ConnectedNetworksComponent } from "src/app/main/pages/smart-bed/details/components/connectivity/connected-networks/connected-networks.component";
import { ConnectivityHomeComponent } from "src/app/main/pages/smart-bed/details/components/connectivity/connectivity-home/connectivity-home.component";
import { ConnectivityComponent } from "src/app/main/pages/smart-bed/details/components/connectivity/connectivity.component";
import { TroubleshootingTipsComponent } from "src/app/main/pages/smart-bed/details/components/connectivity/troubleshooting-tips/troubleshooting-tips.component";
import { PrivacyModeComponent } from "src/app/main/pages/smart-bed/details/components/privacy-mode/privacy-mode.component";
import { SleeperSetupComponent } from "src/app/main/pages/smart-bed/details/components/sleeper-setup/sleeper-setup.component";
import { TimezoneComponent } from "src/app/main/pages/smart-bed/details/components/timezone/timezone.component";
import { DetailsComponent } from "src/app/main/pages/smart-bed/details/details.component";

export class RoutesHelper {

  static get appRoutes(): Routes {
    return [
      { path: 'change-server', loadChildren: () => import('src/app/change-server/change-server.module').then(m => m.ChangeServerModule) },
      { path: 'auth', loadChildren: () => import('src/app/main/auth/auth.module').then(m => m.AuthModule) },
      { path: 'pages', loadChildren: () => import('src/app/main/pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
      { path: 'validate-login/:token', loadChildren: () => import('src/app/validate-login/validate-login.module').then(m => m.ValidateLoginModule) },
      { path: 'password-recovery', loadChildren: () => import('src/app/password-recovery/password-recovery.module').then(m => m.PasswordRecoveryModule) },
      { path: 'forgot-password', loadChildren: () => import('src/app/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
      { path: '**', redirectTo: 'auth' },
    ];
  }

  static get authRoutes(): Routes {
    return [
      {
        path: '', component: AuthComponent, children: [
          { path: '', redirectTo: 'login', pathMatch: 'full' },
          { path: 'login', component: LoginComponent, canActivate: [LandingGuard] },
          { path: 'login', component: SetupBedComponent },
          {
            path: 'register',
            loadChildren: () => import('src/app/main/auth/register/register.module').then(m => m.RegisterModule)
          },
          {
            path: 'terms-of-use',
            resolve: [PrivacyPolicyResolver],
            canActivate: [TacoGuard],
            loadChildren: () => import('src/app/main/auth/terms-of-use/terms-of-use.module').then(m => m.TermsOfUseModule)
          },
          { path: 'password-update', loadChildren: () => import('src/app/main/auth/update-native-password/update-native-password.module').then(m => m.UpdateNativePasswordModule) },
        ]
      },
    ];
  }

  static get registerRoutes(): Routes {
    return [
      {
        path: '', component: RegisterComponent, children: [
          { path: '', redirectTo: '', component: RegisterFormComponent, pathMatch: 'full' },
          { path: 'order-found', component: OrderFoundComponent },
          { path: 'setup-login', component: SetupLoginComponent }
        ]
      },
    ];
  }


  static get setupRoutes(): Routes {
    return [
      {
        path: '', component: SetupComponent, children: [
          { path: 'select-bed', component: SelectBedComponent, canActivate: [SelectBedsGuard] },
          { path: 'setup-bed', component: SetupBedComponent },
          { path: 'setup-sleeper', component: SetupSleeperComponent },
          { path: 'setup-partner', component: SetupPartnerComponent },
          { path: 'create-login', component: CreateLoginComponent },
          { path: 'sleep-research', component: SleepResearchComponent, canActivate: [SleepResearchGuard] },
          { path: 'congratulation', component: CongratulationComponent },
          { path: 'learn-more', component: SleepResearchLearnMoreComponent }
        ]
      },
    ];
  }

  static get pagesRoutes(): Routes {
    return [
      {
        path: '',
        component: PagesComponent,
        runGuardsAndResolvers: 'always',
        canActivate: [runSerially([BedsGuard, SleepersGuard, SessionsGuard])],
        children: [
          {
            path: 'select-default-sleeper', loadChildren: () => import('src/app/main/pages/select-default-sleeper/select-default-sleeper.module').then(m => m.SelectDefaultSleeperModule)
          },
          {
            path: 'sleep', loadChildren: () => import('src/app/main/pages/sleep/sleep.module').then(m => m.SleepModule),
            runGuardsAndResolvers: 'always',
            canActivate: [runSerially([ToSelectDefaultSleeper, ToOnboardingGuard])]
          },
          {
            path: 'health', loadChildren: () => import('src/app/main/pages/health/health.module').then(m => m.HealthModule),
            canActivate: [HealthIccGuard, WellnessReportGuard]
          },
          {
            path: 'profile', loadChildren: () => import('src/app/main/pages/profile/profile.module').then(m => m.ProfileModule)
          },
          {
            path: 'smart-bed', loadChildren: () => import('src/app/main/pages/smart-bed/smart-bed.module').then(m => m.SmartBedModule)
          },
          {
            path: 'account-settings', loadChildren: () => import('src/app/main/pages/account-settings/account-settings.module').then(m => m.AccountSettingsModule)
          },
          {
            path: 'refresh',
            redirectTo: 'sleep'
          },
          {
            path: 'sleep/onboarding', loadChildren: () => import('src/app/main/pages/onboarding/onboarding.module').then(m => m.OnboardingModule),
            canActivate: [ToSleepGuard]
          },
          {
            path: 'setup',
            loadChildren: () => import('src/app/main/pages/setup/setup.module').then(m => m.SetupModule)
          },
          {
            path: 'why-is-this',
            canActivate: [DetailsGuard],
            loadChildren: () => import('src/app/main/pages/why-is-this/why-is-this.module').then(m => m.WhyIsThisModule)
          }
        ]
      },
    ];
  }

  static get smartBedRoutes(): Routes {
    return [
      {
        path: '', component: DetailsComponent, children: [
          {
            path: 'connectivity', component: ConnectivityComponent, children: [
              { path: '', component: ConnectivityHomeComponent },
              { path: 'troubleshooting-tips', component: TroubleshootingTipsComponent },
              {
                path: 'networks', component: ConnectedNetworksComponent, children: [
                  { path: '', component: ConnectedNetworksHomeComponent, canActivate: [NetworksGuard] },
                  { path: 'add-new-network', component: AddNewNetworkComponent },
                ]
              }
            ]
          },
          { path: 'change-bed-name', component: ChangeBedNameComponent },
          { path: 'sleeper-setup', component: SleeperSetupComponent },
          {
            path: 'baseline-bed', component: BaselineBedComponent, children: [
              { path: '', component: BaselineBedHomeComponent },
              { path: 'baseline-in-progress', component: BaselineBedProgressComponent }
            ]
          },
          { path: 'timezone', component: TimezoneComponent },
          { path: 'privacy-mode', component: PrivacyModeComponent },
        ]
      }
    ];
  }

  static get profileDetailsRoutes(): Routes {
    return [
      {
        path: '', component: DetailsComponent, children: [
          { path: '', redirectTo: 'sleep-study', pathMatch: 'full' },
          { path: 'sleep-study', component: SleepStudyComponent },
          { path: 'sleep-study/sleep-study-success', component: SleepStudySuccessComponent },
          {
            path: 'wellness', component: WellnessProfileComponent, children: [
              { path: '', redirectTo: 'home', pathMatch: 'full' },
              { path: 'home', component: WellnessProfileHomeComponent, canActivate: [WellnessQuestionsGuard] },
              { path: 'questions/:categoryId', component: AnswerQuestionsComponent, canActivate: [WellnessQuestionsGuard] }
            ]
          }
        ]
      }
    ];
  }
}