import { AsyncPipe, NgClass, NgForOf, NgIf, NgStyle, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { BedsState } from '@store/beds/beds.state';
import { EdpState } from '@store/edp/edp.state';
import { HealthState } from '@store/health/health.state';
import { SessionsState } from '@store/sessions/sessions.state';
import { SettingsState } from '@store/settings/settings.state';
import { SleepersState } from '@store/sleepers/sleepers.state';
import { TacoState } from '@store/taco/taco.state';
import { LoadingComponent } from './components/loading/loading.component';
import { SiqLottieModule } from './lottie.module';
import { ButtonStateDirective } from './utils/directives/button-state.directive';
import { GridDirective } from './utils/directives/grid.directive';
import { InputFormatDirective } from './utils/directives/input-mask.directive';
import { MomentPipe } from './utils/pipes/moment.pipe';
import { TimeConverterPipe } from './utils/pipes/time-converter.pipe';

import { WhyIsThisState } from '@store/why-is-this/why-is-this.state';
import * as CryptoJS from "crypto-js";
import { SetupState } from '@store/setup/setup.state';
import { ScrollableDirective } from './utils/directives/scrollable.directive';

const modules = [
  NgIf, NgTemplateOutlet, NgForOf, AsyncPipe,
  NgSwitch, NgSwitchCase, NgClass, RouterOutlet,
  ReactiveFormsModule, NgStyle, FormsModule,
];

const directives = [GridDirective, ButtonStateDirective, InputFormatDirective, ScrollableDirective];

const pipes = [TimeConverterPipe, MomentPipe];

const components = [LoadingComponent];

@NgModule({
  declarations: [
    ...directives,
    ...pipes,
    ...components
  ],
  imports: [
    ...modules,
    NgxsModule.forFeature([BedsState, SleepersState, SessionsState, EdpState, SettingsState, HealthState, TacoState, WhyIsThisState, SetupState]),
    SiqLottieModule
  ],
  exports: [
    ...directives,
    ...pipes,
    ...components,
    ...modules
  ],
  providers: [
    { provide: 'CryptoJS', useValue: CryptoJS }
  ]
})
export class SiqCommonModule { }