import { SessionModel, SleepSession } from "./session.model";

interface GoalEntry {
  goalId: string;
  status: number;
}

interface SmartAlarmStatus {
  smartAlarmSetTime: number;
  smartAlarmActivationTime: string;
  smartWakeUpPeriod: number;
}

interface DualTempData {
  targetTemperature: number;
  blowerSetPoint: number;
  dualTempStatus: number;
  acquisitionDate: string;
}

interface Tag {
  date: string;
  session: SleepSession;
}

export interface SleepDataStructure {
  sleeperId: string;
  date?: string;
  message: string;
  tip: string;
  fallAsleepPeriod: number;
  sleepData: Array<SleepDataDays>;
  smartAlarmStatus: Array<SmartAlarmStatus>;
  dualTempData: Array<DualTempData>;
  bedExitAvg: number;
  bedExitMax: number;
  inBedAvg: number;
  inBedMax: number;
  inBedTotal: number;
  outOfBedAvg: number;
  outOfBedMax: number;
  outOfBedTotal: number;
  respirationRateAvg: number;
  respirationRateMax: number;
  respirationRateMin: number;
  heartRateAvg: number;
  heartRateMin: number;
  heartRateMax: number;
  restfulAvg: number;
  restfulMax: number;
  restfulTotal: number;
  restlessAvg: number;
  restlessMax: number;
  restlessTotal: number;
  sleepIQAvg: number;
  sleepIQMax: number;
  hrvAvg: number | null;
  hrvMax: number | null;
  hrvMin: number | null;
  sleepSessionTotal: number;
  sleepSessionCount: number;
}

export interface SleepDataMetrics {
  sleepIQAvg: number;
  sleepIQMax: number;
  inBedAvg: number;
}

export interface YearHistoryModel {
  date: string;
  sleepers: Array<YearSleepDataEntityModel>;
}

export interface YearHistorySleepData {
  yearSleepData: YearHistoryModel;
}

export interface MonthSleepDataModel {
  date: string;
  heartRateAvg: number;
  inBedAvg: number;
  respirationRateAvg: number;
  sleepIQAvg: number;
}
export interface YearSleepDataEntityModel {
  sleeperId: string;
  date?: string;
  inBedAvg: number;
  monthSleepData: Array<MonthSleepDataModel>;
  sleepIQAvg: number;
  sleepIQMax: number;
  sleepSessionCount: number;
}

export interface HistoryMetrics {
  text: string;
  value: number | string;
}

export class SleepDataSessions {
  date: string;
  sessions: Array<SessionModel>;

  constructor(date: string, sessions: Array<SessionModel>) {
    this.date = date;
    this.sessions = sessions;
  }
}

export class SleepData {
  date: string;
  sleepData: Array<SleepDataSessions>;
  sleepIQAvg: number;
  sleepIQMax: number;
  inBedAvg: number;

  constructor(date: string, sleepData: Array<SleepDataSessions>, metrics: SleepDataMetrics) {
    this.date = date;
    this.sleepData = sleepData;
    this.sleepIQAvg = metrics.sleepIQAvg;
    this.sleepIQMax = metrics.sleepIQMax;
    this.inBedAvg = metrics.inBedAvg;
  }
}

export class SleepDataEntity {
  sleeperId: string;
  sleepData: Array<SleepData>;

  constructor(data: SleepDataEntity) {
    this.sleeperId = data.sleeperId;
    this.sleepData = data.sleepData;
  }
}
export class SleepDataDays {
  tip: string;
  message: string;
  date: string;
  sessions: Array<SessionModel>;
  goalEntry?: GoalEntry;
  tags: Array<Tag>;

  constructor(sleepData: SleepDataDays) {
    sleepData.sessions = sleepData.sessions.map((it) => new SessionModel(it));
    Object.assign(this, sleepData);
  }
}
export class SleepHistoryMonth {
  date: string;
  sleepData: Array<SleepDataSessions>;
  sleepIQAvg: number;
  sleepIQMax: number;
  inBedAvg: number;

  constructor(date: string, sessions: Array<SleepDataSessions>, sleepIQAvg: number, sleepIQMax: number, inBedAvg: number) {
    this.date = date;
    this.sleepData = sessions;
    this.sleepIQAvg = sleepIQAvg;
    this.sleepIQMax = sleepIQMax;
    this.inBedAvg = inBedAvg;
  }
}
