import { Injectable } from "@angular/core";
import { ActionStatus, Actions, Store, ofActionDispatched, ofActionSuccessful } from "@ngxs/store";
import { AcceptTaco } from "@store/taco/taco.actions";
import { BehaviorSubject, filter, switchMap, tap, withLatestFrom } from "rxjs";

/**
    * @description
    * The service handles 409 error occurrence
    * 409 indicates that the user has to accept TACO (reference error codes in ErrorCodeHelper)
    * The isProcessing409Error subject (BehaviorSubject since we need initial value) indicates if the error happened or not
    * initial value is false
    * This will not affect registration or login, since no request are expected to fail with 409
    * The service is subscribed to actions$ observable only when the 409 error occurs
    * Since inside the interceptor in case of 409 error all other request are stopped, the
    * service listens to the AcceptTaco dispatch which enables the request to be made
    * on AcceptTaco success the action that returned a 409 error will be retried so the app can continue to function as expected
  **/

@Injectable({
  providedIn: 'root'
})
export class Handle409Service {

  isProcessing409Error$ = new BehaviorSubject<boolean>(false);

  constructor(private actions$: Actions, private store: Store) {
    this.actions$
      .pipe(
        filter((action) => action.status === ActionStatus.Errored),
        withLatestFrom(this.isProcessing409Error$), // Combine with the actions$ observable
        filter(([, isProcessing]) => isProcessing), // Only proceed if isProcessing is true
        switchMap(([action,]) => {
          return this.actions$.pipe(
            ofActionSuccessful(AcceptTaco),
            switchMap(() => this.store.dispatch(action.action)) // Retry the action that returned 409
          );
        })
      )
      .subscribe();

    this.actions$
      .pipe(
        ofActionDispatched(AcceptTaco),
        withLatestFrom(this.isProcessing409Error$), // Combine with the actions$ observable
        filter(([, isProcessing]) => isProcessing), // Only proceed if isProcessing is true
        tap(() => this.setProcessing409Error(false)) // Enable request to be made
      )
      .subscribe();
  }

  setProcessing409Error(isProcessing: boolean): void {
    this.isProcessing409Error$.next(isProcessing);
  }
}