import { SiqError } from "@models/app/helpers.model";

export interface TacoStateModel {
  privacyPolicy: string;
  licenseAccepted: boolean;
  privacyAccepted: boolean;
  innerCircleAccepted: boolean;
  loading: boolean;
  error: SiqError | null
}

export const defaultTacoState = {
  privacyPolicy: '',
  licenseAccepted: true,
  privacyAccepted: true,
  innerCircleAccepted: true,
  loading: false,
  error: null
};