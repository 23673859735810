<app-top-bar-navigation [showBackButton]="true" title="Privacy Mode" (backBtnClicked)="back()" />
<div class="px-24 py-32">
  <div siqGrid fx-direction="column" class="w-100-p">
    <div siqGrid fx-direction="column" fx-layout-align="center center">
      <span [ngClass]="bedImage"></span>
      <span class="siq-text-400 fs-16 text-center white w-50-p mt-24">{{informationText}}</span>
    </div>

    <div class="privacy-mode-container my-48">
      <div siqGrid fx-direction="row">
        <div siqGrid fx-direction="column" class="flex-fill">
          <span class="siq-text-900 fs-16 white">Status</span>
          <span class="siq-text-400 fs-14 white mt-8">{{privacyStatus}}</span>
        </div>
        <div siqGrid fx-direction="row" fx-layout-align="end center">
          <span class="siq-text-400 fs-14 white mr-16">{{selectedBedPrivacyMode}}</span>
          <app-siq-slide-toggle [checked]="privacySetting" (toggleState)="updatePrivacy($event)"/>
        </div>
      </div>
    </div>

    <div siqGrid fx-direction="row" fx-layout-align="end center" fx-layout-align-sm="center center">
      <app-siq-button
        text="Done"
        btnType="primary-btn"
        width="208px"
        (buttonClicked)="back()" />
    </div>
  </div>
</div>
