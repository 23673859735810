import { FormControl } from "@angular/forms";

//#region Interfaces
export interface ListItem {
  icon: string;
  text: string;
  textClasses: string;
}

export interface SleeperRegistrationState {
  accountId: string;
  registrationState: string;
}

export interface SetSleepersPassword {
  login: string;
  password: string;
  token: string;
}

export interface SetSleepersPasswordResponse {
  email: string;
  passwordSetInCognito: boolean;
}

export interface SleepScienceLearnMore {
  icon: string;
  title: string;
  description?: string;
}

export interface Environment {
  name: string;
  apiEndpoint: string;
  mixpanelKey: string;
  edpEndpoint: string;
  edpKey: string;
  cognitoEndpoint: string;
  clientId: string;
  sleepNumberEndpoint: string;
  deeplinkHome: string;
  deeplinkWelcome: string;
  isTest: boolean;
  active?: boolean;
  plansEndpoint: string,
  biqEndpoint: string
}

export interface TacoElement {
  code: number;
  accept: boolean;
  text: string;
}

export interface SetupSleeperQuestionsData {
  birthMonthOptions: Array<string>,
  birthMonthValues: Array<number>,
  birthYearOptions: Array<string>,
  birthYearValues: Array<number>,
  heightValues: Array<number>,
  heightOptions: Array<string>,
  weightValues: Array<number>,
  weightOptions: Array<string>,
  genderOptions: Array<string>,
  genderValues: Array<number>,
  sleepGoalValues: Array<number>,
  sleepGoalOptions: Array<string>
}
export interface ValidateEmailToken {
  passwordToken: string
}

export interface SetupAddSleeperResponse {
  sleeperId: string;
}

export interface SetupSleeperForm {
  firstName: FormControl<string | null>;
  birthMonth: FormControl<number | null>;
  birthYear: FormControl<string | null>;
  height: FormControl<number | null>;
  weight: FormControl<number | null>;
  sleepGoal: FormControl<number | null>;
  gender: FormControl<number | null>;
  bedId: FormControl<string | null>;
  side: FormControl<number | null>;
  duration: FormControl<number | null>;
}
export interface RadioButton {
  id: number;
  text: string;
  subtext?: string;
  textWidth?: string;
}

export interface SetupSleeperIntroduction {
  title: string;
  introduction: string;
}

//#endregion

//#region Classes
export class BannerData {
  title: string;
  text: string;
  icon: string;
  type: string;
}

export class SiqNotification {
  text: string;
  isSuccess = true; // default value will be success, since this one is mostly used in the app
  mixpanelSourceScreen: string;
  mixpanelFlow: string;

  constructor(text: string, mixpanelSourceScreen: string, mixpanelFlow: string,  isSuccess?: boolean) {
    this.text = text;
    this.mixpanelSourceScreen = mixpanelSourceScreen;
    this.mixpanelFlow = mixpanelFlow;
    if (isSuccess) {
      this.isSuccess = isSuccess;
    }

  }
}

export class ChartDataObject {
  date: string;
  value: number;
  sleepNumber?: number;
}

export class NavigationItem {
  id = 0;
  type = '';
  clicked = false;

  constructor(id?: number, type?: string, clicked?: boolean) {
    if (id) this.id = id;
    if (type) this.type = type;
    if (clicked) this.clicked = clicked;
  }
}

export class SiqError {
  code!: number;
  message!: string;

  constructor(error: SiqError) {
    Object.assign(this, error);
  }
}

export class YourDay {
  yourDayPeriod: string;
  icon: string;
  idealTime: string;
}

export class InnerCircleLicenseState {
  accepted: boolean;
}

export class SetupBedData {
  name: string;
  timezone: string;
  side: number;

  constructor(data: SetupBedData) {
    Object.assign(this, data);
  }
}

export class SetupSleeperData {
  firstName?: string;
  birthYear?: string;
  birthMonth?: number;
  height?: number;
  weight?: number;
  sleepGoal?: number;
  gender?: number;
  bedId?: string;
  side?: number;
  isChild?: boolean;
  duration?: number;

  constructor(data: SetupSleeperData) {
    Object.assign(this, data);
  }
}

export class SetupSleeperValues {
  birthYear: number;
  height: number;
  weight: number;
  gender: number;
  sleepGoal: number;
}
//#endregion

//#region Types

export type SingleMultipleQuestions =  FormControl<Array<number> | Array<object> | null>;
//#endregion
