<div [style.width]="width" >
  <div siqGrid fx-direction="column" class="banner-container px-12 pt-12 pb-32 background-white br-top-8">
    <div siqGrid fx-layout-align="end center">
      <span class="close-primary-icon cursor-pointer" (click)="onCloseClick()"></span>
    </div>
    <div siqGrid fx-direction="row" class="mt-12">
      <div siqGrid fx-direction="row" fx-layout-align="center start">
        <div siqGrid fx-layout-align="center center" class="circle wh-48 br-30" [ngClass]="data.type">
          <span [ngClass]="data.icon"></span>
        </div>
      </div>
      <div siqGrid fx-direction="column" fx-layout-align="center start" class="ml-12">
        <span class="siq-text-900 fs-20 primary">{{ data.title }}</span>
        <span class="siq-text-400 fs-14 primary mt-12 text-left">{{ data.text }}</span>
      </div>
    </div>
  </div>
</div>
