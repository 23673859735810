<div siqGrid [class.cursor-pointer]="isCursorPointer">
  <div #button siqGrid [tabindex]="tabIndex" [attr.aria-label]="ariaLabel" role="button" [style.width]="width" [buttonState]="isDisabled" [isPressed]="isPressed" [btnType]="btnType" [ngClass]="btnClass" [isListItemBtn]="isListItemBtn" [isActiveServer]="isActiveServer" (click)="handleMouseDown()" (mousedown)="handleButtonClick()">
    <div aria-hidden="true" siqGrid fx-direction="row" fx-layout-align="start center">
      <div aria-hidden="true"  siqGrid fx-layout-align="center center" *ngIf="(icon && checkIconPosition('left')) || isPendingEmail" [class]="isPendingEmail ? 'pr-12' : 'pr-8'">
        <img aria-hidden="true" class="icon" [src]="iconPathLeft" alt="icon">
      </div>
      <div>
        <div aria-hidden="true" siqGrid fx-direction="row" fx-layout-align="start center">
          <span aria-hidden="true" siqGrid="block" [ngClass]="{'btn-text': subText}" [class.pb-24]="!subText && isChangeServer" [class.text-underline]="hasTextUnderline">{{ text }}</span>
          <span aria-hidden="true" *ngIf="isPendingEmail" class="siq-text-400 fs-14 primary-200 ml-8">(pending verification)</span>
        </div>
        <span aria-hidden="true" [class.btn-subtext]="subText" class="mt-8" [class.text-underline]="hasTextUnderline">{{ subText }}</span>
      </div>
    </div>
    <div aria-hidden="true" siqGrid fx-layout-align="center center" *ngIf="icon && checkIconPosition('right')" class="pl-8">
        <img aria-hidden="true" class="icon" [src]="iconPathRight" alt="icon">
    </div>
  </div>
</div>