<app-top-bar-navigation title="Join Study" />
<div siqGrid fx-direction="column" class="px-24 py-32">
  <span class="siq-text-900 fs-24 white text-center px-12">{{ sleepScienceResearchText }}</span>
  <span class="sleep-science-research wh-48 mt-48 w-100-p"></span>
  <span class="siq-text-400 fs-16 white text-center mt-48 px-12">{{ optInInstructions }}</span>
  <div siqGrid fx-direction="row" fx-direction-sm="row" fx-direction-xs="column" fx-layout-align="end center" fx-layout-align-sm="center center" class="mt-48 px-12">
    <app-siq-button
      text="Skip"
      btnType="secondary-btn"
      [width]="(breakpoints$ | async)?.isTablet || (breakpoints$ | async)?.isMobile  ? '158px': '208px'"
      (buttonClicked)="skip()" />
    <app-siq-button
      [class.ml-12]="!(breakpoints$ | async)?.isSmallScreen"
      [class.mt-12]="(breakpoints$ | async)?.isSmallScreen"
      text="Count Me In"
      btnType="primary-btn"
      [width]="(breakpoints$ | async)?.isTablet || (breakpoints$ | async)?.isMobile  ? '158px': '208px'"
      (buttonClicked)="optIn()" />
  </div>
  <app-siq-button
    class="mt-48 w-100-p"
    width="100%"
    text="Learn More"
    subText="Help us make new discoveries to improve sleep quality for the greater community.​"
    btnType="secondary-btn border-primary"
    icon="right-arrow-btn-icon" 
    [iconPosition]="iconPosition"
    [isListItemBtn]="true"
    (buttonClicked)="openLearnMore()" />
</div>
