
const ISO_8601 = 'YYYY-MM-DDTHH:mm:ss';
const DATE = 'YYYY-MM-DD';
const ABBREVIATED_DATE = 'ddd MMM D'; // Tue Apr 25
const ABBREVIATED_DATE_LEADING_ZERO = 'ddd MMM DD'; // Mon Aug 05 - has leading zero
const LONG_DATE = 'MMM Do, YYYY'; // Jan 1st, 2022
const TIME_LEADING_ZERO = 'hh:mm';
const TIME = 'h:mm';
const TIME_OF_DAY = 'A'
const MONTH_YEAR_DATE = 'MMM YYYY' // August 2023
const MONTH_YEAR_SHORT_DATE = 'MMMM YYYY'; // Aug 2023
const YEAR = 'YYYY' // 2023
const YEAR_MONTH = 'YYYY-MM' // 2023-11

export class SiqDateFormats {
  static get ISO8601(): string {
    return ISO_8601;
  }

  static get Date(): string {
    return DATE;
  }

  static get Abbreviated(): string {
    return ABBREVIATED_DATE;
  }

  static get Long(): string {
    return LONG_DATE;
  }

  static get TimeWithLeadingZero(): string {
    return TIME_LEADING_ZERO;
  }

  static get Time(): string {
    return TIME;
  }

  static get TimeOfDay(): string {
    return TIME_OF_DAY;
  }

  static get MonthYearDate(): string {
    return MONTH_YEAR_DATE;
  }

  static get MonthYearShortDate(): string {
    return MONTH_YEAR_SHORT_DATE;
  }

  static get WeekdayLeadingZero(): string {
    return ABBREVIATED_DATE_LEADING_ZERO
  }

  static get Year(): string {
    return YEAR;
  }

  static get YearMonth(): string {
    return YEAR_MONTH;
  }
}