import { RegisterTokenModel } from "@models/auth/register.model";
import { SiqError } from "@models/app/helpers.model";

export interface RegisterStateModel {
  registerToken: RegisterTokenModel | null;
  loading: boolean;
  error: SiqError | null;
}

export const defaultRegisterState = {
  registerToken: null,
  loading: false,
  error: null
};