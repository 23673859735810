import { Injectable } from "@angular/core";
import { EnvironmentsService } from "./environments.service";
import { environment } from '@environments/environment';
import { Sleeper } from "@models/sleeper/sleeper.model";
import { Bed } from "@models/bed/bed.model";
import { AccountEntity } from "@models/auth/account.model";

// @ts-expect-error this should work as expected
import * as mixpanel from 'mixpanel-browser';
import * as moment from "moment";
import { SiqDateFormats } from "@shared/utils/helpers/date-formats.helper";
import { IccMessage, SleepHealthIcc } from "@models/health/sleep-health-icc.model";
import { NoDataSession, SessionModel } from "@models/sessions/session.model";
import { Store } from "@ngxs/store";
import { BedsSelectors } from "@store/beds/beds.selectors";
import { BedSettingsResources } from "@shared/utils/helpers/bed-settings.helper";
import { BannerData } from "@models/app/helpers.model";

@Injectable()
export class MixpanelService {

  constructor(private environmentsService: EnvironmentsService, private store: Store) {
    mixpanel.init(this.environmentsService.MIXPANELKEY);
  }

  identify(username: string): void {
    mixpanel.identify(username);
  }

  alias(sleeperId: string): void {
    mixpanel.alias(sleeperId);
  }

  reset(): void {
    mixpanel.reset();
  }

  setProfileProperties(loggedInSleeper: Sleeper, selectedSleeper: Sleeper | null, bed: Bed | null, account: AccountEntity, username: string): void {
    const props = {
      account_id: loggedInSleeper.accountId,
      customer_number: account.Service.Response.account.name,
      registration_date: account.Service.Response.account.activationDate,
      age_range: loggedInSleeper.getMarketingAgeGroup(),
      app_user_id: loggedInSleeper.sleeperId,
      is_child: loggedInSleeper.isChild ? 'yes' : 'no',
      is_account_owner: loggedInSleeper.isAccountOwner ? 'yes' : 'no',
      gender: loggedInSleeper.getGender,
      last_app_build: environment.buildNumber,
      last_app_version: environment.version,
      last_os_platform: window.clientInformation.platform,
      last_mixpanel_library: 'web',
      web_app_version: environment.version,
      environment: this.environmentsService.ENV_NAME.toLowerCase(),
      'web_change_ts ': moment().format(SiqDateFormats.ISO8601),
      web_os_platform: window.clientInformation.platform, // TODO: check this
    };

    if (loggedInSleeper.profile) {
      props['first_night_date'] = loggedInSleeper.firstSessionRecorded;
    }

    if (bed) {
      props['bed_timezone'] = bed.timezone;
      props['bed_location'] = bed.zipcode;
      props['bed_id'] = bed.bedId;
      props['mac'] = bed.macAddress;
      props['bed_generation'] = bed.generation;
      props['purchase_date'] = moment(bed.mainMattress?.purchaseDate).format(SiqDateFormats.Long);
      props['install_date'] = bed.mainMattress?.installDate;
      props['privacy_mode'] = this.store.selectSnapshot(BedsSelectors.getPrivacyStatusForBed)(bed.bedId);
    }

    if (selectedSleeper) {
      props['bed_side'] = selectedSleeper.side;
    }
    if (mixpanel.cookie.props.session_id) {
      props['last_session_id'] = mixpanel.cookie.props.session_id;
    }

    mixpanel.people.set(props);
    mixpanel.identify(username);
  }

  setSuperProperties(loggedInSleeper: Sleeper, selectedSleeper: Sleeper, bed: Bed, username: string): void {
    let sessionId = '';
    if (mixpanel.cookie.props.session_id) {
      sessionId = mixpanel.cookie.props.session_id;
    } else {
      sessionId = `${username}&${moment().format(SiqDateFormats.ISO8601)}`;
    }

    mixpanel.register({
      offline: false,
      session_id: sessionId,
      sleeper_id: loggedInSleeper.sleeperId,
      v_sleeper_id: selectedSleeper.sleeperId,
      v_bed_side: selectedSleeper.side ? 'right' : 'left',
      v_bed_id: selectedSleeper.bedId,
      v_bed_mac: bed.macAddress,
      cognito_id: username ?? ''
    });
  }

  trackSessionStart(): void {
    const appClosedDate = localStorage.getItem('siq_app_closed');
    if (appClosedDate) {
      if (moment(moment().format(SiqDateFormats.ISO8601)).diff(moment(appClosedDate), 'minutes') > 30) {
        if (mixpanel.cookie.props.session_id) {
          mixpanel.register({
            session_id: `${mixpanel.cookie.props.session_id.split('&')[0]}&${moment().format(SiqDateFormats.ISO8601)}`
          });
        }
        mixpanel.track('session_start', {
          event_name: 'session_start'
        });
      }
    } else {
      if (mixpanel.cookie.props.session_id) {
        mixpanel.register({
          session_id: `${mixpanel.cookie.props.session_id.split('&')[0]}&${moment().format(SiqDateFormats.ISO8601)}`
        });
      }
      mixpanel.track('session_start', {
        event_name: 'session_start'
      });
    }
  }

  //#region Global Tracking
  trackAppOpen(): void {
    mixpanel.track('app_open', {
      event_name: 'app_open'
    });
  }

  trackAppClose(): void {
    localStorage.setItem('siq_app_closed', moment().format(SiqDateFormats.ISO8601));
    mixpanel.track('app_close', {
      event_name: 'app_close'
    });
  }

  trackPopupOpen(message: string, screen: string): void {
    mixpanel.track('popup_open', {
      event_name: 'popup_open',
      message,
      screen
    });
  }

  trackPopupClose(message: string, action: string): void {
    mixpanel.track('popup_close', {
      event_name: 'popup_close',
      message,
      action
    });
  }

  trackDykMessage(sessionStart: string, category: string): void {
    mixpanel.track('dyk_view', {
      event_name: 'dyk_view',
      category,
      sleep_session_start: sessionStart
    });
  }

  trackToastDisplay(sourceScreen: string, message: string, flow: string): void {
    mixpanel.track('toast_display', {
      event_name: 'toast_display',
      source_screen: sourceScreen,
      message,
      flow
    });
  }

  //#endregion

  //#region API 
  trackError(endpoint: string, error_code: number, description: string, http_code: number): void {
    endpoint = endpoint.split('/').slice(3).join('/');
    mixpanel.track('error', {
      event_name: 'error',
      type_of_error: 'api',
      endpoint,
      error_code,
      description,
      http_code
    });
  }
  //#endregion

  //#region Auth 
  trackLogin(clickedOnRememberMe: boolean): void {
    mixpanel.track('login', {
      event_name: 'login',
      remember_me: clickedOnRememberMe ? 'yes' : 'no'
    });
  }

  trackLoginError(error: string): void {
    mixpanel.track('login_error', {
      event_name: 'login_error',
      error
    });
  }

  trackLogout(): void {
    mixpanel.track('logout', {
      event_name: 'logout',
    });
    mixpanel.reset();
  }

  trackLoginForgotPassword(): void {
    mixpanel.track('login_forgot_password', {
      event_name: 'login_forgot_password',
    });
  }

  trackLoginValidationError(message: string): void {
    mixpanel.track('login_validation_error', {
      event_name: 'login_validation_error',
      message
    });
  }

  //#endregion

  //#region Health

  trackHealthHome(): void {
    mixpanel.track('health_home', {
      event_name: 'health_home',
    });
  }

  trackHealthDurationDetails(): void {
    mixpanel.track('health_duration_details', {
      event_name: 'health_duration_details',
      source_screen: 'health',
    });
  }

  trackHealthEfficiencyDetails(): void {
    mixpanel.track('health_efficiency_details', {
      event_name: 'health_efficiency_details',
      source_screen: 'health',
    });
  }

  trackHealthTimingDetails(): void {
    mixpanel.track('health_timing_details', {
      event_name: 'health_timing_details',
      source_screen: 'health',
    });
  }

  trackQuickTipOpen(sourceScreen: string, tipDetails: string, drawAction: string): void {
    mixpanel.track('quick_tip_open', {
      event_name: 'quick_tip_open',
      source_screen: sourceScreen,
      tip_details: tipDetails,
      draw_action: drawAction
    });
  }

  trackSleepGoalEdit(): void {
    mixpanel.track('sleep_goal_edit', {
      event_name: 'sleep_goal_edit',
      source_screen: 'duration',
    });
  }

  trackSleepGoalSet(): void {
    mixpanel.track('sleep_goal_set', {
      event_name: 'sleep_goal_set',
      source_screen: 'duration',
    });
  }

  trackOpenCircadianDetails(): void {
    mixpanel.track('sleep_circadian_details_open', {
      event_name: 'sleep_circadian_details_open',
      source_screen: 'timing',
    });
  }

  trackHealthSleepQualityLearnMore(sourceScreen: string): void {
    mixpanel.track('health_sleep_quality_learn_more', {
      event_name: 'health_sleep_quality_learn_more',
      source_screen: sourceScreen,
    });
  }

  trackWellnessReportOpen(): void {
    mixpanel.track('profile_wellness_report_open', {
      event_name: 'profile_wellness_report_open'
    });
  }

  trackWellnessReportDownload(): void {
    mixpanel.track('profile_wellness_report_download', {
      event_name: 'profile_wellness_report_download'
    });
  }

  trackWellnessReportDownloadError(error: string, month: string): void {
    mixpanel.track('profile_wellness_report_download_error', {
      event_name: 'profile_wellness_report_download_error',
      error,
      month
    });
  }

  trackSleepHealthMessages(icc: SleepHealthIcc): void {
    mixpanel.track('sleep_sleep_health_details_insight', {
      event_name: 'sleep_sleep_health_details_insight',
      rule_id: icc.ruleId,
      category: 'sleep_health',
      sleep_health_subcategory: icc.type.toLowerCase(),
      sleep_health_message: icc.message
    });
  }
  //#endregion

  //#region Sleep
  trackSleep(originalStartDate: string): void {
    mixpanel.track('sleep', {
      event_name: 'sleep',
      sleep_session_start: originalStartDate
    });
  }

  trackSleepHistoryOpen(session: SessionModel | NoDataSession | null, timeframe: string, date: string): void {
    if (session) {
      mixpanel.track('sleep_calendar_open', {
        event_name: 'sleep_calendar_open',
        sleep_session_start: session.originalStartDate,
        timeframe,
        date
      });
    }
  }

  trackIccMessage(iccMessage: IccMessage): void {
    mixpanel.track('icc_view', {
      event_name: 'icc_view',
      category: iccMessage.category.toLowerCase(),
      subcategory: iccMessage.originalSubcategory.toLowerCase(),
      message_id: iccMessage.ruleId
    });
  }

  trackSleepDetailsOpen(originalStartDate: string): void {
    mixpanel.track('sleep_details_open', {
      event_name: 'sleep_details_open',
      sleep_session_start: originalStartDate
    });
  }

  trackBiometricsDetails(originalStartDate: string, timeframe: string, property: string): void {
    mixpanel.track('sleep_biometrics_details', {
      event_name: 'sleep_biometrics_details',
      sleep_session_start: originalStartDate,
      timeframe,
      property
    });
  }

  trackBiosignalInfoOpen(originalStartDate: string, detail: string): void {
    mixpanel.track('sleep_info_open', {
      event_name: 'sleep_info_open',
      sleep_session_start: originalStartDate,
      detail
    });
  }

  trackCircadianDetailsOpen(hasIdealSchedule: string): void {
    mixpanel.track('sleep_circadian_details_open', {
      event_name: 'sleep_circadian_details_open',
      has_ideal_schedule: hasIdealSchedule
    });
  }

  trackCircadianInfoOpen(screen: string): void {
    mixpanel.track('circadian_info_open', {
      event_name: 'circadian_info_open',
      screen
    });
  }

  trackSleepInfoOpen(originalStartDate: string): void {
    mixpanel.track('sleep_info_open', {
      event_name: 'sleep_info_open',
      sleep_session_start: originalStartDate,
    });
  }

  trackSwitchSleeper(sleeperId: string): void {
    mixpanel.track('profile_switch_sleeper', {
      event_name: 'profile_switch_sleeper',
      selected_sleeper: sleeperId
    });
  }

  //#endregion

  //#region Onboarding

  trackOnboardingHomeOpen(registrationDate: string, bedSetup: string, orderNumber: string): void {
    mixpanel.track('onboarding_home_open', {
      event_name: 'onboarding_home_open',
      registration_date: registrationDate,
      bed_setup: bedSetup,
      order_number: orderNumber
    });
  }

  trackFullOrder(): void {
    mixpanel.track('welcome_track_full_order', {
      event_name: 'welcome_track_full_order'
    });
  }

  trackManageDelivery(): void {
    mixpanel.track('welcome_manage_delivery', {
      event_name: 'welcome_manage_delivery'
    });
  }

  trackSeeChecklist(): void {
    mixpanel.track('welcome_see_checklist', {
      event_name: 'welcome_see_checklist'
    });
  }

  trackSleepWorks(sourceScreen: string): void {
    mixpanel.track('how_sleep_works', {
      event_name: 'how_sleep_works',
      source_screen: sourceScreen
    });
  }

  //#endregion

  //#region Profile

  //#region Sleep Study
  trackSleepStudyDetailsOpen(status: string, isLoggedInSleeper: boolean): void {
    mixpanel.track('profile_sleep_study_details_open', {
      event_name: 'profile_sleep_study_details_open',
      is_logged_in_sleeper: isLoggedInSleeper ? 'yes' : 'no',
      status
    });
  }

  trackSleepStudyDetailsOpenSecondSleeper(status: string, isLoggedInSleeper: boolean, hasEmail: string): void {
    mixpanel.track('profile_sleep_study_details_open', {
      event_name: 'profile_sleep_study_details_open',
      is_logged_in_sleeper: isLoggedInSleeper ? 'yes' : 'no',
      status,
      has_email: hasEmail
    });
  }

  trackSleepStudyOptIn(successful: string): void {
    mixpanel.track('profile_sleep_study_optin', {
      event_name: 'profile_sleep_study_optin',
      successful
    });
  }

  trackSleepStudyOptInDone(action: string): void {
    mixpanel.track('profile_sleep_study_optin_done', {
      event_name: 'profile_sleep_study_optin_done',
      action
    });
  }

  trackSleepStudyOptOut(): void {
    mixpanel.track('profile_sleep_study_optout', {
      event_name: 'profile_sleep_study_optout'
    });
  }

  trackSleepStudyOptOutConfirm(action: string, successful: string): void {
    mixpanel.track('profile_sleep_study_optout_confirm', {
      event_name: 'profile_sleep_study_optout_confirm',
      action,
      successful
    });
  }

  trackSleepStudyOptOutDone(): void {
    mixpanel.track('profile_sleep_study_optout_done', {
      event_name: 'profile_sleep_study_optout_done'
    });
  }

  trackSleepStudyCreate(): void {
    mixpanel.track('profile_sleep_study_create', {
      event_name: 'profile_sleep_study_create'
    });
  }

  trackProfile(): void {
    mixpanel.track('profile_open', {
      event_name: 'profile_open'
    });
  }

  trackWellnessProfileOpen(): void {
    mixpanel.track('profile_wellness_open', {
      event_name: 'profile_wellness_open'
    });
  }

  trackWellnessProfileCategoryOpen(category: string): void {
    mixpanel.track('profile_wellness_section_open', {
      event_name: 'profile_wellness_section_open',
      wellness_section: category.toLowerCase()
    });
  }

  trackWellnessProfileCategoryCancelUpdate(): void {
    mixpanel.track('profile_wellness_section_cancel', {
      event_name: 'profile_wellness_section_cancel'
    });
  }

  trackWellnessProfileCategoryUpdate(category: string): void {
    mixpanel.track('profile_wellness_section_update', {
      event_name: 'profile_wellness_section_update',
      wellness_section: category.toLowerCase()
    });
  }

  trackSleepGoalChange(oldValue: number, newValue: number): void {
    mixpanel.track('sleep_goal_change', {
      event_name: 'sleep_goal_change',
      old_value: oldValue,
      new_value: newValue
    });
  }
  //#endregion

  //#region Account Settings

  trackAccountSettingsOpen(): void {
    mixpanel.track('profile_account_open', {
      event_name: 'profile_account_open'
    });
  }

  trackProfileAccountSettingsAddLogin(bedId: string, side: string, sleeperId: string, successful: string): void {
    mixpanel.track('profile_account_settings_add_login', {
      event_name: 'profile_account_settings_add_login',
      bed: bedId,
      side,
      sleeper_id: sleeperId,
      successful
    });
  }

  trackAccountEmailUpdate(successful: string): void {
    mixpanel.track('profile_account_email_update', {
      event_name: 'profile_account_email_update',
      successful
    });
  }

  trackAccountPasswordUpdate(passwordSetInCognito: string, successful: string): void {
    mixpanel.track('profile_account_password_update', {
      event_name: 'profile_account_password_update',
      user_migrated_to_cognito: passwordSetInCognito,
      successful
    });
  }

  trackAccountPasswordUpdateNoCognito(successful: string): void {
    mixpanel.track('profile_account_password_update', {
      event_name: 'profile_account_password_update',
      successful
    });
  }

  trackOrdersOpen(): void {
    mixpanel.track('profile_orders_open', {
      event_name: 'profile_orders_open'
    });
  }

  trackOrdersTrack(bedId: string): void {
    mixpanel.track('profile_orders_track', {
      event_name: 'profile_orders_track',
      bed: bedId
    });
  }

  trackContactUs(): void {
    mixpanel.track('profile_account_delete_account_link', {
      event_name: 'profile_account_delete_account_link'
    });
  }

  trackAccountSettingsCompleteRegistration(): void {
    mixpanel.track('account_settings_complete_registration', {
      event_name: 'account_settings_complete_registration'
    });
  }

  trackProfileAccountEmailView(pendingVerification: string): void {
    mixpanel.track('profile_account_email_view', {
      event_name: 'profile_account_email_view',
      pending_verification: pendingVerification
    });
  }

  trackProfileAccountEmailDetails(pendingVerification: string): void {
    mixpanel.track('profile_account_email_details', {
      event_name: 'profile_account_email_details',
      pending_verification: pendingVerification
    });
  }

  trackProfileAccountEmailInvitationAction(action: string): void {
    mixpanel.track('profile_account_email_invitation_action', {
      event_name: 'profile_account_email_invitation_action',
      action
    });
  }

  //#endregion

  //#region Bed Settings

  trackBedSettingsOpen(status: string): void {
    mixpanel.track('bed_settings_open', {
      event_name: 'bed_settings_open',
      bed_connectivity_status: status
    });
  }

  trackConnectivityOpen(): void {
    mixpanel.track('bed_settings_connectivity_open', {
      event_name: 'bed_settings_connectivity_open'
    });
  }

  trackSleeperSetupOpen(): void {
    mixpanel.track('bed_settings_sleeper_setup_open', {
      event_name: 'bed_settings_sleeper_setup_open'
    });
  }

  trackBedNameUpdate(bed_id: string): void {
    mixpanel.track('bed_settings_bed_name_update', {
      event_name: 'bed_settings_bed_name_update',
      bed_id
    });
  }

  trackBedSwitchSide(bed_id: string): void {
    mixpanel.track('bed_settings_switch_sleeper', {
      event_name: 'bed_settings_switch_sleeper',
      bed_id
    });
  }

  trackBedTimezoneUpdate(bed_id: string): void {
    mixpanel.track('bed_settings_timezone_update', {
      event_name: 'bed_settings_timezone_update',
      bed_id
    });
  }

  trackAddSleeperOnBed(bed_id: string): void {
    mixpanel.track('bed_settings_add_sleeper', {
      event_name: 'bed_settings_add_sleeper',
      bed_id
    });
  }

  trackUpdateWifiOpen(): void {
    mixpanel.track('bed_settings_update_wifi_open', {
      event_name: 'bed_settings_update_wifi_open'
    });
  }

  trackPrivacyModeOpen(sourceScreen: string): void {
    mixpanel.track('bed_pm_open', {
      event_name: 'bed_pm_open',
      source_screen: sourceScreen
    });
  }

  trackPrivacyModeToggle(status: string): void {
    mixpanel.track('bed_pm_toggle', {
      event_name: 'bed_pm_toggle',
      status
    });
  }

  trackPrivacyModeBannerDisplay(): void {
    const privacyModeBannerData = BedSettingsResources.privacyModeBannerData;
    mixpanel.track('banner_display', {
      event_name: 'banner_display',
      title: privacyModeBannerData['title'],
      message: privacyModeBannerData['message']
    });
  }

  trackPrivacyModeBannerAction(text: string): void {
    mixpanel.track('banner_display_action', {
      event_name: 'banner_display_action',
      text
    });
  }
  //#endregion

  //#region Registration

  trackRegistrationOpen(): void {
    mixpanel.track('registration_open', {
      event_name: 'registration_open'
    });
  }

  trackRegistrationValidationError(message: string): void {
    mixpanel.track('registration_validation_error', {
      event_name: 'registration_validation_error',
      message
    });
  }

  trackFindMyOrder(sourceScreen: string, orderNumber: string): void {
    mixpanel.track('registration_find_order', {
      event_name: 'registration_find_order',
      source_screen: sourceScreen,
      order_number: orderNumber
    });
  }

  trackOrderFoundSplash(): void {
    mixpanel.track('registration_order_found_splash', {
      event_name: 'registration_order_found_splash'
    });
  }

  trackRegistrationAccountCreation(userInCognito: string): void {
    mixpanel.track('registration_account_create', {
      event_name: 'registration_account_create',
      user_already_in_cognito: userInCognito
    });
  }

  trackRegistrationStart(bedCount: number, hasKidsBed: string): void {
    mixpanel.track('registration_start', {
      event_name: 'registration_start',
      bed_count: bedCount,
      has_kids_bed: hasKidsBed
    });
  }

  trackRegistrationBedSelect(bedId: string): void {
    mixpanel.track('registration_bed_select', {
      event_name: 'registration_bed_select',
      bed: bedId
    });
  }

  trackRegistrationBedSideSelect(bedId: string, side: string): void {
    mixpanel.track('registration_bed_side_select', {
      event_name: 'registration_bed_side_select',
      bed: bedId,
      side
    });
  }

  trackRegistrationProfileOtherSide(action: string): void {
    mixpanel.track('registration_profile_other_side', {
      event_name: 'registration_profile_other_side',
      action
    });
  }

  trackRegistrationBedSkip(bedsSetup: number, bedsLeft: number): void {
    mixpanel.track('registration_bed_skip', {
      event_name: 'registration_bed_skip',
      beds_setup: bedsSetup,
      beds_left: bedsLeft
    });
  }

  trackBannerDisplay(sourceScreen: string, bannerData: BannerData): void {
    mixpanel.track('banner_display', {
      event_name: 'banner_display',
      source_screen: sourceScreen,
      title: bannerData.title,
      message: bannerData.text
    });
  }

  trackSleeperEmailInvite(flow: string): void {
    mixpanel.track('sleeper_email_invite', {
      event_name: 'sleeper_email_invite',
      flow
    });
  }

  trackSmartBedSetupComplete(name: string, timezone: string): void {
    mixpanel.track('registration_smartbed_setup_complete', {
      event_name: 'registration_smartbed_setup_complete',
      name,
      timezone
    });
  }

  trackProceedNextAction(sourceScreen: string): void {
    mixpanel.track('proceed_next_action', {
      event_name: 'proceed_next_action',
      source_screen: sourceScreen
    });
  }

  trackProceedSkipAction(sourceScreen: string, flow: string): void {
    mixpanel.track('proceed_skip_action', {
      event_name: 'proceed_skip_action',
      source_screen: sourceScreen,
      flow
    });
  }

  trackRegistrationCompleteSleepStudy(action: string): void {
    mixpanel.track('registration_complete_sleep_study', {
      event_name: 'registration_complete_sleep_study',
      action
    });
  }

  trackRegistrationCompleteSleepStudyDetails(): void {
    mixpanel.track('registration_complete_sleep_study_details', {
      event_name: 'registration_complete_sleep_study_details'
    });
  }

  trackRegistrationComplete(): void {
    mixpanel.track('registration_complete', {
      event_name: 'registration_complete'
    });
  }

  //#endregion

  //#region Password Recovery
  trackSecondSleeperPasswordCreate(): void {
    mixpanel.track('second_sleeper_password_created_ok_action', {
      event_name: 'second_sleeper_password_created_ok_action',
    });
  }
  //#endregion

  //#region Native Users
  trackUpdateNativePasswordIntroOpen(): void {
    mixpanel.track('update_native_password_intro_open', {
      event_name: 'update_native_password_intro_open'
    });
  }

  trackUpdateNativePasswordOpen(): void {
    mixpanel.track('update_native_password_open', {
      event_name: 'update_native_password_open'
    });
  }


  trackUpdateNativePassword(successful: string): void {
    mixpanel.track('update_native_password', {
      event_name: 'update_native_password',
      successful
    });
  }
  //#endregion

  //#region TACO

  trackTacoOpen(flow: string): void {
    mixpanel.track('terms_and_conditions_open', {
      event_name: 'terms_and_conditions_open',
      flow
    });
  }

  trackTermsButtonAction(action: string, title: string): void {
    mixpanel.track('terms_and_conditions_action', {
      event_name: 'terms_and_conditions_action',
      action,
      title
    });
  }

  trackTermsPrivacy(action: string, title: string): void {
    mixpanel.track('terms_and_conditions_privacy', {
      event_name: 'terms_and_conditions_privacy',
      action,
      title
    });
  }

  trackTermsUserAgreement(action: string, title: string): void {
    mixpanel.track('terms_and_conditions_user_agreement', {
      event_name: 'terms_and_conditions_user_agreement',
      action,
      title
    });
  }


  trackTermsRewards(action: string, title: string): void {
    mixpanel.track('terms_and_conditions_rewards', {
      event_name: 'terms_and_conditions_rewards',
      action,
      title
    });
  }

  trackTermsPrivacyLink(title: string): void {
    mixpanel.track('terms_and_conditions_privacy_link', {
      event_name: 'terms_and_conditions_privacy_link',
      title
    });
  }

  trackTermsUserAgreementLink(title: string): void {
    mixpanel.track('terms_and_conditions_user_agreement_link', {
      event_name: 'terms_and_conditions_user_agreement_link',
      title
    });
  }

  trackTermsRewardsLink(title: string): void {
    mixpanel.track('terms_and_conditions_rewards_link', {
      event_name: 'terms_and_conditions_rewards_link',
      title
    });
  }

  //#endregion

  //#region Right Rail
  trackDownloadTheAppCard(store: string, sourceScreen: string): void {
    mixpanel.track('download_the_app_card', {
      event_name: 'download_the_app_card',
      store,
      source_screen: sourceScreen
    });
  }

  trackSupportSleepTeamOpen(): void {
    mixpanel.track('support_sleep_team_open', {
      event_name: 'support_sleep_team_open'
    });
  }

  trackSupportArticlesOpen(): void {
    mixpanel.track('support_articles_open', {
      event_name: 'support_articles_open'
    });
  }

  trackProfilePrivacyLegal(): void {
    mixpanel.track('profile_privacy_legal', {
      event_name: 'profile_privacy_legal'
    });
  }

  trackProfilePrivacyPolicy(): void {
    mixpanel.track('profile_privacy_policy', {
      event_name: 'profile_privacy_policy'
    });
  }

  trackProfilePrivacyLicense(): void {
    mixpanel.track('profile_privacy_license', {
      event_name: 'profile_privacy_license'
    });
  }

  //#endregion
}
