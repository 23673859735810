export class Regex {
  static get email(): RegExp {
    return RegExp('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}$');
  }

  static get phoneNumber(): RegExp {
    return RegExp('^[0-9]*$');
  }

  static get phoneNumberMask(): RegExp {
    return new RegExp(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  }

  static get number(): RegExp {
    return new RegExp(/\d/);
  }

  static get capitalCase(): RegExp {
    return new RegExp(/[A-Z]/);
  }

  static get specialCharacter(): RegExp {
    //eslint-disable-next-line no-useless-escape
    return new RegExp(/(?=.*[\^$*.\[\]{}()?\-"!@%#&\/\\,><':;|_~`])/);
  }

  static get whitespace(): RegExp {
    return new RegExp(/^\S*$/);
  }

  static get orderNumber(): RegExp {
    return RegExp('^[0-9]*$');
  }

  static get lowerCase(): RegExp {
    return new RegExp(/[a-z]/);
  }

}