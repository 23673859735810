<div siqGrid fx-direction="column" fx-layout-align="start start">
  <span class="siq-text-400 fs-16 white" [ngClass]="description ? 'mb-4' : 'mb-12'" [innerHTML]="label"></span>
  <span *ngIf="description" class="siq-text-400 fs-14 primary-200 mb-12" [innerHTML]="description"></span>
  <div class="input-container cursor-pointer w-100-p">
    <input 
      id="siq-input"
      readonly
      class="dropdown-input input-field-sn cursor-pointer background-transparent valid-bottom-border-input siq-text-400 fs-16 white w-100-p"
      type="text"
      [value]="inputValue"
      [placeholder]="placeholder"/>
    <div class="input-icon arrow-icon-down"></div>  
  </div>
</div>
