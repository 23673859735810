import { Selector } from "@ngxs/store";
import { RegisterStateModel } from "./register.model";
import { RegisterTokenModel } from "@models/auth/register.model";
import { RegisterState } from "./register.state";

export class RegisterSelectors {

  @Selector([RegisterState])
  static registerToken(state: RegisterStateModel): RegisterTokenModel | null {
    return state.registerToken;
  }
}