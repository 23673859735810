const MIN_WEIGHT_FOR_ADULT = 75;
const MAX_WEIGHT_FOR_ADULT = 349;
const MIN_WEIGHT_FOR_KID = 40;
const MAX_WEIGHT_FOR_KID = 349;

export class WeightHelper {

    static get minWeightKid(): number {
        return MIN_WEIGHT_FOR_KID;
    }

    static get minWeightAdult(): number {
        return MIN_WEIGHT_FOR_ADULT;
    }

    static get weightValuesArr(): Array<number> {
        return this.getValues(MIN_WEIGHT_FOR_ADULT, MAX_WEIGHT_FOR_ADULT) as Array<number>;
    }

    static get weightOptionsArr(): Array<string> {
        return this.getValues(MIN_WEIGHT_FOR_ADULT, MAX_WEIGHT_FOR_ADULT, 'lbs') as Array<string>;
    }

    static get weightValuesKidArr(): Array<number> {
        return this.getValues(MIN_WEIGHT_FOR_KID, MAX_WEIGHT_FOR_KID) as Array<number>;
    }

    static get weightOptionsKidArr(): Array<string> {
        return this.getValues(MIN_WEIGHT_FOR_KID, MAX_WEIGHT_FOR_KID, 'lbs') as Array<string>;
    }

    private static getValues(min: number, max: number, unit?: string): Array<string | number> {
        const arr = [];
        for (let i = min; i <= max; i++ ) {
            if(unit) {
                arr.push(`${i} ${unit}`);
            } else {
                arr.push(i);
            }
        }
        return arr;
    }
}
