<app-top-bar-navigation [showBackButton]="true" title="Sleeper Setup" (backBtnClicked)="back()"/>
<div siqGrid fx-direction="column" fx-layout-align="center center" class="py-32 px-24">
  <div siqGrid fx-direction="row" [class]="hasLeftSleeper ? 'layout-align end' : 'layout-align start'" class="w-256">
    <div *ngIf="rightSleeper" siqGrid fx-direction="column" fx-layout-align="center center" class="w-50-p">
      <span class="siq-text-900 fs-20 white mb-12">{{ getSleeperName(rightSleeper) }}</span>
      <span class="down-arrow-icon mb-12"></span>
    </div>
    <div *ngIf="leftSleeper" siqGrid fx-direction="column" fx-layout-align="center center" class="w-50-p">
      <span class="siq-text-900 fs-20 white mb-12">{{ getSleeperName(leftSleeper) }}</span>
      <span class="down-arrow-icon mb-12"></span>
    </div>
  </div>
  <div [class]="bedIcon"></div>
  <app-siq-button
    *ngIf="isDualBed"
    class="mt-32"
    text="Switch Sides"
    btnType="secondary-btn"
    (buttonClicked)="switchSleeperSides()" />
  <div *ngIf="canAddSleeper" siqGrid fx-direction="row" fx-layout-align="end center" fx-layout-align-lt-sm="center center" class="w-100-p mt-48">
    <app-siq-button 
      text="Add New Sleeper" 
      btnType="primary-btn" 
      width="208px"
      [iconPosition]="iconPosition"
      icon="plus-icon"
      (buttonClicked)="addNewSleeper()" />
  </div>
</div>
