import { Component, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { SetupSleepersFrom } from '@store/app/app.actions';
import { ResetSetupState } from '@store/setup/setup.actions';

@Component({
  selector: 'app-congratulation',
  templateUrl: './congratulation.component.html'
})
export class CongratulationComponent implements OnInit {

  constructor(private store: Store, private mixpanelService: MixpanelService) { }

  ngOnInit(): void {
    this.mixpanelService.trackRegistrationComplete();
    const redirectTo = localStorage.getItem('setupSleepersFrom');
    FunctionsHelper.scrollToTop();
    setTimeout(() => {
      this.store.dispatch(new ResetSetupState());
      if (redirectTo) {
        this.store.dispatch(new SetupSleepersFrom(null));
        this.store.dispatch(new Navigate([redirectTo]));
      } else {
        this.store.dispatch(new Navigate(['pages/sleep']));
      }
    }, 3000);
  }
}
