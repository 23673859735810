import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentsService } from './environments.service';
import { MixpanelService } from './mixpanel.service';
import { MatDialog } from '@angular/material/dialog';
import { SplashScreenService } from './splash-screen.service';
import { SiqApiService } from './siq-api.service';
import { Observable, catchError, map, throwError } from 'rxjs';
import { DYKTip } from '@models/edp/dyk-tips.model';

@Injectable({
  providedIn: 'root'
})
export class EdpService extends SiqApiService {

  constructor(
    httpClient: HttpClient,
    environmentService: EnvironmentsService,
    mixpanelService: MixpanelService,
    dialog: MatDialog,
    splashScreenService: SplashScreenService) {
    super(httpClient, environmentService, mixpanelService, dialog, splashScreenService);
  }

  getDYKTips(sleeperId: string): Observable<Array<DYKTip>> {
    return this.makeEdpRequest<Array<DYKTip>>(`tips/${sleeperId}`, {}, 'GET').pipe(
      map((response: Array<DYKTip> | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }
}
