import { AnimationOptions } from 'ngx-lottie';

export class LottieHelper {

  static createAnimationOptions(jsonFile: string, isOnLoop: boolean): AnimationOptions {
    return {
      path: `assets/json_animations/loader/${jsonFile}.json`,
      autoplay: true,
      loop: isOnLoop
    }
  }
}