import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BannerData, SetupSleeperIntroduction } from '@models/app/helpers.model';
import { Bed } from '@models/bed/bed.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { ResizeService } from '@services/resize.service';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { RegistrationStringResource } from '@shared/utils/helpers/registration.helper';
import { ResetBiqData, SetSettingFirstSleeper, SetupAccountOwner } from '@store/setup/setup.actions';
import { SetupSelectors } from '@store/setup/setup.selectors';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-setup-sleeper',
  templateUrl: './setup-sleeper.component.html'
})
export class SetupSleeperComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('container') container: ElementRef = new ElementRef('');
  bannerWidth = '';
  showBanner = false;
  isMobile = false;
  bannerData: BannerData;
  isAccountOwnerSet: boolean;
  isKidsBed: boolean;
  sleeperIntroduction: SetupSleeperIntroduction;

  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private resizeService: ResizeService, private mixpanelService: MixpanelService) { }

  ngOnInit(): void {
    FunctionsHelper.scrollToTop();
    this.store.dispatch(new SetSettingFirstSleeper(true));

    this.store.select(SetupSelectors.isBiqUser(false)).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(isBiqUser => {
      this.sleeperIntroduction = RegistrationStringResource.setupSleeperIntroduction(isBiqUser, false);
      this.bannerData =  RegistrationStringResource.bannerData(isBiqUser);
    });


    this.resizeService.isMobile.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((res) => {
      this.isMobile = res.isMobile;
      this.bannerWidth = `${this.container.nativeElement.offsetWidth}px`;
    });

    this.store.select(SetupSelectors.selectedBed).pipe(
      takeUntil(this._unsubscribeAll),
      filter((bed): bed is Bed => !!bed)
    ).subscribe((bed) => this.isKidsBed = bed.isKidsBed);

    this.store.select(SetupSelectors.isAccountOwnerSet).pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe(isAccountOwnerSet => this.isAccountOwnerSet = isAccountOwnerSet);
  }

  ngAfterViewInit(): void {
    this.bannerWidth = `${this.container.nativeElement.offsetWidth}px`;
  }

  back(): void {
    this.store.dispatch(new Navigate(['pages/setup/setup-bed']));
  }

  changeBannerState(bannerState: boolean): void {
    this.showBanner = bannerState;
    if (this.showBanner)
      this.mixpanelService.trackBannerDisplay('set up sleeper', this.bannerData);
  }

  next(): void {
    this.mixpanelService.trackProceedNextAction('set up sleeper');
    if (this.isAccountOwnerSet || this.isKidsBed) {
      // check if sending email property will cause email to be triggered
      this.store.dispatch(new ResetBiqData());
      this.store.dispatch(new Navigate(['pages/setup/create-login']));
    } else {
      this.store.dispatch(new SetSettingFirstSleeper(false));
      this.store.dispatch(new SetupAccountOwner());
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
