<div class="slider full-bleed" #fullBleed>
  <ng-container *ngFor="let item of carouselItems; let ind = index">
    <div class="slidewrapper" [id]="ind" #slideWrapper>
      <div class="slide px-12 py-48">
        <div siqGrid fx-direction="column" fx-layout-align="center center" class="carousal-item-content">
          <span [ngClass]="item.image"></span>
          <div siqGrid fx-direction="column" fx-layout-align="center start">
            <span class="siq-text-900 bold white fs-36 mt-24" [innerHTML]="item.title"></span>
            <span *ngIf="item.subtitle" class="siq-text-900 bold white fs-20 mt-8" [innerHTML]="item.subtitle"></span>
            <span class="siq-text-400 white fs-16 mt-12" [innerHTML]="item.text"></span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="carousel-arrows">
  <div siqGrid fx-direction="row" fx-layout-align="center center" class="mt-28">
    <div siqGrid fx-direction="row" [fx-layout-align]="controlAlignment" class="carousel-controls w-100-p mx-12">
      <ng-container *ngIf="!isLastElementActive else button">
        <ng-container *ngFor="let _ of carouselItems; let id = index">
          <span siqGrid fx-direction="row" class="rectangle background-white cursor-pointer mr-4 br-26"
            (click)="scrollOnDot(id)" [class.active]="isElementActive(id)"></span>
        </ng-container>
      </ng-container>
      <ng-template #button>
        <app-siq-button 
          class="px-12"
          text="Done"
          btnType="primary-btn"
          (buttonClicked)="done()" />
      </ng-template>
    </div>
  </div>
</div>