import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {
  bedsLoading = false;
  sessionsLoading = false;
  authLoading = false;
  shown = true;
  splashScreenSubject = new BehaviorSubject<boolean>(false);

  constructor(private router: Router) {}

  setBedsLoading(loading: boolean): void {
    this.bedsLoading = loading;
    this.checkLoading();
  }

  setSessionLoading(loading: boolean): void {
    this.sessionsLoading = loading;
    this.checkLoading();
  }

  setAuthLoading(loading: boolean): void {
    this.authLoading = loading;
    this.checkLoading();
  }

  setSplashScreen(loading: boolean): void {
    this.splashScreenSubject.next(loading);
  }

  checkLoading(): void {
    if ((this.bedsLoading || this.sessionsLoading || this.authLoading) &&
      (!this.router.url.includes('pages/setup') &&
        !this.router.url.includes('auth'))) {
      this.splashScreenSubject.next(true);
    } else {
      this.splashScreenSubject.next(false);
    }
  }

  unsubscribe(): void {
    this.splashScreenSubject.next(false);
    this.splashScreenSubject.complete();
  }
}