export class AppSetting {
  key: string;
  updatedOn: string;
  value: string;

  get isOptIn(): boolean {
    return this.value === 'optIn';
  }

  get isOptOut(): boolean {
    return this.value === 'optOut';
  }
}

export interface AppSettingEntityModel {
  appSettings: Array<AppSetting>;
}