export class LoginErrorHelper {
  static Errors = {
    forbidden: 'This account is not authorized for this application.',
    unauthorized: 'Email and/or password is incorrect.',
    default: `Cannot reach server - SleepIQ<sup>®</sup> is unavailable due to server maintenance; 
        please check back later.`,
    email: 'Please enter a valid email address',
    password: 'Please enter your password',
    reset: 'Please try entering your information again. If you are still having trouble, try resetting your password.'
  }    
}
export class ServerErrors {
  static ServerMaintenance = {
    title: 'We’re dreaming big',
    text: `The Sleep Number<sup>®</sup> App is currently down and working hard to improve your sleep experience. 
           You can still control your bed through Bluetooth.`
  };

  static ApiErrors = {
    error400: {
      title: 'Oops, something went wrong',
      text: 'An error has occurred, please try again.'
    },

    requestTimeout: {
      title: 'Oops, something went wrong',
      text: 'The request timed out, please try again.'
    },

    accountExists: {
      title: 'Account already exists',
      text: 'An account with that email already exists. Try adding a new email address.'
    },

    accountActivated: {
      title: 'Looks like we’ve met before.',
      text: 'A Sleep Number<sup>®</sup> account with this email already exists, you can use that email address and password.'
    },
    
    requestFailed: {
      title: 'Oops, something went wrong',
      text: 'Request failed, try again'
    },

    nonExistingAccount: {
      title: 'Oops, something went wrong',
      text: 'We are unable to find your account. Please validate your email address and try again.'
    },

    digitalUser: {
      title: 'Open BreatheIQ<sup>TM</sup>',
      text: 'We noticed you have a BreatheIQ account. Download or open the BreatheIQ app on your mobile device.'
    }
  };
}

// TODO: We might not need this for 5.1 registration updates
export class ErrorCodeHelper {
  static tacoCodes = {
    license: 409,
    privacyPolicy: 50011,
    innerCircle: -93, // this code is only used by web app, it is combination of numeric position of letters in alphabet i and c.
    licenseAndPrivacy: 50012
  };

  static baselineError = -404;
}

export class FindMyOrderErrors {
  static Errors = {
    orderNumber: 'Please enter an 11-digit order number',
    emailAddress: 'Please enter a valid email address',
    phoneNumber: 'Please enter a valid phone number'
  } 
}   
export interface PasswordError {
  required: boolean;
  hasNumber: boolean;
  hasCapitalCase: boolean;
  hasLowerCase: boolean;
  hasSpecialCharacters: boolean;
  hasWhitespace: boolean;
  minlength: object;
  maxlength: object;
}
