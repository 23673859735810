import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-siq-dropdown',
  templateUrl: './siq-dropdown.component.html',
  styleUrls: ['./siq-dropdown.component.scss']
})
export class SiqDropdownComponent implements OnInit {
  @ViewChild('dropdownBody') dropdownBody: ElementRef;
  @Input() dropdownValues: Array<number>; // values that will be used as a selected value
  @Input() dropdownOptions: Array<string>; // options that we can see in the dropdown
  @Input() value: number;
  @Input() paddingClass: string;
  @Input() dropdownInputLabel: string;
  @Input() dropdownInputPlaceholder: string;
  @Input() dropdownInputValue: string;
  @Input() description: string;
  @Output() selectedValue =  new EventEmitter<number>();

  showDropdownBody = false;

  constructor(private eRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent): void {
    if(this.dropdownInputLabel) {
      if (this.checkClass('.dropdown-input', event) || this.checkClass('.input-icon', event)) {
        // to change a state of the dropdown on clicking a dropdown input or arrow icon
        this.showDropdownBody = !this.showDropdownBody;
        setTimeout(() => {
          this.scrollSelectedIntoView();
        }, 10);
      } else if (!this.checkClass('.dropdown-container', event)) {
        // to close a dropdown on outside click
        this.showDropdownBody = false;
      }
    }
  }

  ngOnInit(): void {
    // to handle the case where we have only dropdown body, without input (e.g. edit sleep goal)
    if(!this.dropdownInputLabel) {
      this.showDropdownBody = true;
      setTimeout(() => {
        document.getElementById('selected')?.scrollIntoView();
      }, 10);
    }
  }

  isSelected(value: number): boolean {
    return value === this.value;
  }

  getTextColor(index: number): string {
    const selectedItemIndex = this.dropdownValues.indexOf(this.value);
    if(index === selectedItemIndex) return 'white';
    if(index === selectedItemIndex - 1 || index === selectedItemIndex + 1) return 'white';
    return 'primary-200';
  }

  selectValue(value: number): void {
    this.value = value;
    this.selectedValue.emit(this.value);
    if(this.dropdownInputLabel) {
      // the dropdown should close when we select a value in the case there is dropdown input field
      // in case when we have only dropdown body (e.g. edit sleep goal), it will be visible all the time
      this.showDropdownBody = false;
    }
  }

  getDropdownItemId(index: number): string {
    const selectedItemIndex = this.dropdownValues.indexOf(this.value);
    if(index === selectedItemIndex - 2 || index === selectedItemIndex - 1 || index === selectedItemIndex) return 'selected';
    return '';
  }

  private checkClass(element: string, event: MouseEvent): boolean {
    return this.eRef.nativeElement.querySelector(`.select-container ${element}`)?.contains(event.target);
  }

  private scrollSelectedIntoView(): void {
    const selectedItem = this.eRef.nativeElement.querySelector('.selected-item');

    if (selectedItem && this.dropdownBody) {
      this.dropdownBody.nativeElement.scrollTop = selectedItem.offsetTop - 3 * this.dropdownBody.nativeElement.offsetTop;
    }
  }
}