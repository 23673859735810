import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { Navigate } from "@ngxs/router-plugin";
import { Store } from "@ngxs/store";
import { BedsSelectors } from "@store/beds/beds.selectors";
import { filter, map } from "rxjs";

export const SelectBedsGuard: CanActivateFn = () => {
  const store = inject(Store);
  return store.select(BedsSelectors.loading).pipe(
    filter((loading) => !loading),
    map(() => {
     const bedsForSetup = store.selectSnapshot(BedsSelectors.bedsForSetup);
      if (bedsForSetup && bedsForSetup.length > 0) {
        return true;
      }
      store.dispatch(new Navigate(['pages/setup/sleep-research']));
      return false;
    })
  );
};