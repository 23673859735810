import { Component, OnInit } from '@angular/core';
import { PopupData } from '@models/app/modal-data.model';
import { Bed } from '@models/bed/bed.model';
import { WifiNetwork, WifiStatus } from '@models/wifi-network/wifi-network.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { BedSettingsResources } from '@shared/utils/helpers/bed-settings.helper';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { SiqPopupHelper } from '@shared/utils/helpers/siq-popup.helper';
import { CloseModal } from '@store/app/app.actions';
import { DeleteWifiNetwork } from '@store/settings/settings.actions';
import { SettingsSelectors } from '@store/settings/settings.selectors';
import { Observable, Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-connected-networks-home',
  templateUrl: './connected-networks-home.component.html',
})
export class ConnectedNetworksHomeComponent implements OnInit {

  connectedNetwork$: Observable<WifiNetwork | null>;
  filteredNetworks$: Observable<Array<WifiNetwork> | null>;
  selectedBedWifiStatus$: Observable<WifiStatus | null>;

  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private siqPopupHelper: SiqPopupHelper) {}
  
  ngOnInit(): void {
    FunctionsHelper.scrollToTop();
    this.connectedNetwork$ = this.store.select(SettingsSelectors.connectedNetwork);
    this.filteredNetworks$ = this.store.select(SettingsSelectors.filteredNetworks);
    this.store.select(SettingsSelectors.selectedBed).pipe(
      takeUntil(this._unsubscribeAll),
      filter((selectedBed): selectedBed is Bed => !!selectedBed)
    ).subscribe(selectedBed => {
      this.selectedBedWifiStatus$ = this.store.select(SettingsSelectors.wifiStatus(selectedBed.bedId));
    })
  }

  redirectToRoute(route: string): void {
    this.store.dispatch(new Navigate([`pages/smart-bed/details/connectivity/${route}`]));
  }

  removeNetwork(wifiId: string): void {
    const removeNetworkCTA = BedSettingsResources.removeNetworkPopupData;
    const popupData = new PopupData({
      title: removeNetworkCTA['title'],
      text: removeNetworkCTA['text'],
      icon: 'warning-icon',
      type: 'yellow',
      screen: 'Change WiFi',
      rightBtnTxt: 'Continue',
      leftBtnTxt: 'Cancel'
    });
    const modal = this.siqPopupHelper.createPopup(popupData);
    modal.componentInstance.onClose.subscribe(() => {
      this.store.dispatch(new CloseModal(modal));
    });
    modal.componentInstance.onLeftAction.subscribe(() => {
      this.store.dispatch(new CloseModal(modal));
    });
    modal.componentInstance.onRightAction.subscribe(() => {
      this.store.dispatch(new DeleteWifiNetwork(wifiId)).subscribe(() => {
        this.store.dispatch(new CloseModal(modal));
        this.store.select(SettingsSelectors.networks).pipe(
          takeUntil(this._unsubscribeAll)
        ).subscribe(networks => {
          if (networks && networks.length === 0) {
            // case: there is no networks
            // user will be redirected to add new network form
            this.store.dispatch(new Navigate(['pages/smart-bed/details/connectivity/networks/add-new-network']));
          }
        });
      });
    });
    modal.afterClosed().subscribe(() => {
      this.store.dispatch(new CloseModal(modal));
      modal.componentInstance.onLeftAction.unsubscribe();
      modal.componentInstance.onRightAction.unsubscribe();
      modal.componentInstance.onClose.unsubscribe();
    });
  }

}
