<ng-container *ngIf="selectedSleeper">
  <app-top-bar-navigation title="Sleep Science Research" />
  <div siqGrid fx-layout-align="center center" fx-direction="column">
    <div class="w-100-p">
      <app-no-data 
        noDataIcon="white-checkmark" 
        [noDataText]="noDataText"
        [noDataSubtext]="noDataSubtext">
      </app-no-data>
      <div *ngIf="!isOptInSuccess" siqGrid fx-direction="row" fx-layout-align="center center">
        <p class="mt-24 mb-32 w-90-p siq-text-400 fs-16 white text-center">{{ optOutSuccessMessage }}</p>
      </div>
    </div>
    <div *ngIf="isOptInSuccess" siqGrid fx-layout-align="center center" fx-direction="column" class="mt-48">
      <span class="siq-text-900 fs-16 white">Current Email</span>
      <span class="siq-text-400 fs-16 primary-200 mt-12">{{selectedSleeper.email}}</span>
    </div>
    <div siqGrid fx-direction="column" class="mt-48">
      <app-siq-button *ngIf="isOptInSuccess" text="Update Email" btnType="secondary-btn" width="178px" [iconPosition]="iconPosition" icon="edit" (buttonClicked)="updateEmail()" />
      <app-siq-button class="mt-12" text="Done" btnType="primary-btn" width="178px" (buttonClicked)="done()" />
    </div>
  </div>
</ng-container>