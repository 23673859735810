import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MixpanelService } from '@services/mixpanel.service';
import { SleepGoalHelper } from '@shared/utils/helpers/sleep-goal.helper';

@Component({
  selector: 'app-edit-sleep-goal-modal',
  templateUrl: './edit-sleep-goal-modal.component.html'
})
export class EditSleepGoalModalComponent implements OnInit {
  dropdownOptions: Array<string>;
  dropdownValues: Array<number>;
  value: number;
  onSave = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<EditSleepGoalModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number,
    private mixpanelService: MixpanelService) {
    dialogRef.disableClose = false;
  }

  ngOnInit(): void {
    this.mixpanelService.trackPopupOpen('', 'Edit Sleep Goal');
    this.value = this.data;
    this.dropdownOptions = SleepGoalHelper.sleepGoalOptionsAdultArr;
    this.dropdownValues = SleepGoalHelper.sleepGoalValuesAdultArr;
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  setNewSleepGoal(newGoal: number): void {
    this.value = newGoal;
  }

  setSleepGoal(): void {
    this.onSave.emit(this.value);
  }
}
