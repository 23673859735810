<div #privacyBanner siqGrid fx-direction="row" fx-layout-align="space-between center" class="background-white px-12 h-76 br-8 mx-24">
  <div siqGrid fx-direction="row" fx-layout-align="center center" fx-layout-align-sm="start center" [class.w-65-p]="(breakpoints$ | async)?.isMobile">
    <div siqGrid fx-direction="row" fx-layout-align="center center">
      <div siqGrid fx-layout-align="center center" class="circle wh-40 br-40 yellow">
        <span class="warning-icon wh-18"></span>
      </div>
    </div>
    <div siqGrid fx-direction="column" fx-layout-align="center start" class="ml-12">
      <span class="siq-text-900 fs-16 secondary">{{ privacyModeBannerData['title'] }}</span>
      <span class="siq-text-400 fs-14 primary-300" [class.w-80-p]="(breakpoints$ | async)?.isMobile">{{ privacyModeBannerData['message'] }}</span>
    </div>
  </div>
  <div siqGrid fx-direction="row" fx-layout-align="center center">
    <span class="siq-text-900 fs-14 secondary cursor-pointer" (click)="redirectToPrivacyMode()">Turn off</span>
    <div class="border-left-400 h-24 ml-16 mr-16"></div>
    <span class="siq-text-900 fs-14 secondary cursor-pointer" (click)="hidePrivacyBanner()">Later</span>
  </div>
</div>
