<div class="wrapper" [class.particle-background]="showLayoutWithContentInTheMiddle">
  <div class="container">
    <ng-container tabindex="-1" aria-hidden="true" *ngIf="!showLayoutWithContentInTheMiddle">
      <div class="left-column scroll">
        <div class="layout-column navigation-bar p-24">
          <div class="sn-logo mb-44"></div>
          <app-left-navigation-menu *ngIf="showLeftNavigationMenu" />
        </div>
      </div>
    </ng-container>
    
    <div siqGrid fx-direction="column" fx-layout-align="space-between" appScrollable class="middle-column" [ngClass]="{'border-left-400 border-right primary-400 scroll': !showLayoutWithContentInTheMiddle}">
      <div class="content-wrapper">
        <ng-content></ng-content>
        <ng-container *ngIf="(siqNotification$ | async) as siqNotification ">
          <app-siq-notification
            tabindex="-1"
            class="siq-notification"
            [isSuccess]="siqNotification.isSuccess" 
            [text]="siqNotification.text" />
        </ng-container> 
        <ng-container *ngIf="showPrivacyModeBanner">
          <app-siq-privacy-banner tabindex="-1"  class="siq-privacy-banner" />
        </ng-container>     
      </div>
      <app-info-bar tabindex="-1" *ngIf="!showLayoutWithContentInTheMiddle" class="info-bar-mobile-screen"/>
      <app-left-navigation-menu tabindex="-1" *ngIf="showLeftNavigationMenu" class="navigation-bar-mobile-screen" />
    </div>

    <ng-container *ngIf="!showLayoutWithContentInTheMiddle">
      <div tabindex="-1" aria-hidden="true" class="right-column scroll">
        <app-info-bar />
      </div>
    </ng-container>
  </div>

  <div *ngIf="showFooter" tabindex="-1" aria-hidden="true" class="py-24">
    <app-siq-footer></app-siq-footer>
  </div>
</div>
