import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UpdateSleeperProperty } from '@models/sleeper/sleeper.model';
import { SleeperWellnessQuestion } from '@models/sleeper/wellness-profile.model';

interface SleeperQuestionForm {
  [key: string]: FormControl<string | number | null>;
}

@Component({
  selector: 'app-sleeper-questions',
  templateUrl: './sleeper-questions.component.html',
})
export class SleeperQuestionsComponent implements OnInit {

  @Input() questions: Array<SleeperWellnessQuestion>;
  @Output() sleeperUpdatedAnswers = new EventEmitter<UpdateSleeperProperty>();
  @Output() errorSleeperName = new EventEmitter<boolean>();
  wellnessQuestionsForm: FormGroup<SleeperQuestionForm>;
  controlFocusState = {};
  notAnsweredQuestion = 'Please select...';

  ngOnInit(): void {
    this.createForm();
  }

  getSleeperProfileAnswer(question: SleeperWellnessQuestion): string {
    const controlValue = this.wellnessQuestionsForm.controls[question.property].value;
    const optionIndex = question.dropdownValues.findIndex(val => val.toString() === controlValue?.toString());
    return question.dropdownOptions[optionIndex];
  }

  handleDropdownSelect(selectedItem: number | string, question: string): void {
    this.wellnessQuestionsForm.controls[question].setValue(selectedItem);
    this.sleeperUpdatedAnswers.emit(this.wellnessQuestionsForm.getRawValue());
  }

  setControlFocusState(focusState: object): void {
    this.controlFocusState = focusState;
    if(!this.controlFocusState['firstName']) {
      this.sleeperUpdatedAnswers.emit(this.wellnessQuestionsForm.getRawValue());
    }
  }

  get hasErrors(): boolean {
    if (!this.controlFocusState['firstName']) {
      if(this.wellnessQuestionsForm.controls['firstName'].touched && !this.wellnessQuestionsForm.controls['firstName'].valid) {
        this.errorSleeperName.emit(true);
        return true
      } 
    }
    this.errorSleeperName.emit(false);
    return false;
  }

  private createForm(): void {
    const controls = {};
    this.questions.forEach((question) => {
      if(!question.isDropdownQuestion) {
        // this is the input question - first name
        controls[question.property] = new FormControl(question.value ?? null, [Validators.required]);
      } else {
        controls[question.property] = new FormControl(question.value ?? null);
      }
    });
    this.wellnessQuestionsForm = new FormGroup<SleeperQuestionForm>(controls);
  }
}
