import { SwitchElement, SwitchElementAction, SwitchModel } from "@models/app/switch.model";
import { UserInfo } from "@models/auth/cognito.model";
import { Bed } from "@models/bed/bed.model";
import { SleeperStudyState } from "@models/sleeper/sleeper-study-state";
import { Sleeper } from "@models/sleeper/sleeper.model";
import { Category, QuestionProperties, RadioButtonQuestion, WellnessCategory, WellnessProfile } from "@models/sleeper/wellness-profile.model";
import { Selector, createSelector } from "@ngxs/store";
import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import { ProfileStringResource } from "@shared/utils/helpers/profile.helper";
import { AuthStateModel } from "@store/auth/auth.model";
import { AuthState } from "@store/auth/auth.state";
import { BedsStateModel } from "@store/beds/beds.model";
import { BedsState } from "@store/beds/beds.state";
import { SleepersStateModel } from "./sleepers.model";
import { SleepersState } from "./sleepers.state";

enum BedType {
  'adult',
  'kid'
}
export class SleepersSelectors {

  @Selector([SleepersState])
  static loading(state: SleepersStateModel): boolean {
    return state.loading;
  }

  @Selector([SleepersState])
  static sleepers(state: SleepersStateModel): Array<Sleeper> | null {
    return state.sleepers;
  }

  @Selector([SleepersState])
  static selectedSleeper(state: SleepersStateModel): Sleeper | null {
    return state.selectedSleeper;
  }

  @Selector([SleepersState])
  static defaultSleeper(state: SleepersStateModel): Sleeper | null {
    const defaultSleeper = state.sleepers.find(sleeper => sleeper.sleeperId === state.defaultSleeperId);
    return defaultSleeper ?? null;
  }

  @Selector([SleepersState])
  static getSleeperById(state: SleepersStateModel): (id: string) => Sleeper | null {
    return (id: string) => {
      const sleeper = state.sleepers.find(s => s.sleeperId === id);
      return sleeper ? sleeper : null;
    };
  }

  @Selector([SleepersState])
  static getSleepersFromBed(state: SleepersStateModel): (id: string) => Array<Sleeper> | null {
    return (id: string) => {
      const sleepers = state.sleepers.filter(s => s.bedId === id);
      return sleepers ? sleepers : null;
    };
  }

  @Selector([SleepersState, AuthState])
  static loggedInSleeper(state: SleepersStateModel, authSate: AuthStateModel): Sleeper {
    if (authSate.userInfo) {
      // @ts-expect-error this should work as expected since logged in sleeper should never be null or undefined
      return state.sleepers.find(s => s.sleeperId === FunctionsHelper.decrypt<UserInfo>(authSate.userInfo).sleeperId);
    }
    return new Sleeper();
  }

  @Selector([SleepersState])
  static accountOwner(state: SleepersStateModel): Sleeper | null {
    return state.sleepers.find(sleeper => sleeper.isAccountOwner) ?? null;
  }

  @Selector([SleepersState, BedsState])
  static sortedSleepers(state: SleepersStateModel, bedState: BedsStateModel): Array<Sleeper> | null {
    return this.sortSleepers(state, bedState);
  }

  static switchSleepersElements(isDefault: boolean): (state: SleepersStateModel, bedState: BedsStateModel, authState: AuthStateModel) => SwitchModel<string> {
    return createSelector([SleepersState, BedsState, AuthState], (state: SleepersStateModel, bedState: BedsStateModel, authState: AuthStateModel): SwitchModel<string> => {
      const loggedInSleeper = this.loggedInSleeper(state, authState);
      if (isDefault) {
        //@ts-expect-error won't be null;
        return new SwitchModel({ selectedElement: new SwitchElement<string>(state.selectedSleeper?.sleeperId, state.selectedSleeper?.firstName), switcherElements: [] });
      }
      if (loggedInSleeper.isChild) {
        return new SwitchModel({ selectedElement: new SwitchElement<string>(loggedInSleeper.sleeperId, loggedInSleeper.firstName), switcherElements: [] });
      }
      const switcherElements = state.sleepers ? this.sortSleepers(state, bedState).map((s) => new SwitchElement<string>(s.sleeperId, s.firstName)) : [];
      let selectedElement: SwitchElement<string>;
      const selectedSleeperBed = bedState.beds.find((bed) => bed.bedId === state.selectedSleeper?.bedId);
      // check if there is available space on the selected sleepers bed
      if (state.selectedSleeper && selectedSleeperBed && selectedSleeperBed.dualSleep && (selectedSleeperBed.sleeperLeftId === '0' || selectedSleeperBed.sleeperRightId === '0')) {
        selectedElement = new SwitchElement<string>(state.selectedSleeper.sleeperId, state.selectedSleeper.firstName, 'plus-blue-icon', 'right-arrow-icon' , SwitchElementAction.AddNewSleeper);
      } else {
        if (state.selectedSleeper) {
          selectedElement = new SwitchElement<string>(state.selectedSleeper.sleeperId, state.selectedSleeper.firstName);
        }
      }
      //@ts-expect-error won't be null;
      return new SwitchModel({ selectedElement, switcherElements });
    });
  }

  @Selector([SleepersState, AuthState])
  static sleepStudyState(state: SleepersStateModel, authState: AuthStateModel): SleeperStudyState {
    const isOptIn = state.selectedSleeper?.sleepStudy?.isOptIn;
    const selectedSleeper = state.selectedSleeper;
    const loggedInSleeper = this.loggedInSleeper(state, authState);

    if (selectedSleeper?.sleeperId === loggedInSleeper.sleeperId) {
      if (!isOptIn) {
        return new SleeperStudyState(ProfileStringResource.beAPartOf(), '', '', 'primary-btn', 'Count Me In', 'optIn');
      } else {
        return new SleeperStudyState(ProfileStringResource.loggedInSleeperIsEnrolled(), ProfileStringResource.thankYouForEnrolling(), '', 'secondary-btn', 'Opt Out', 'optOut', 'white-checkmark');
      }
    } else {
      if (isOptIn) {
        return new SleeperStudyState(ProfileStringResource.sleeperIsEnrolled(selectedSleeper?.firstName), ProfileStringResource.logInAsSleeper(selectedSleeper?.firstName), '', '', '', '', 'white-checkmark');
      }
      // This is because API returns this filed as 'null' (string)
      if (selectedSleeper?.email === 'null') {
        return new SleeperStudyState(ProfileStringResource.beAPartOf(), ProfileStringResource.selectedSleeperDoesNotHaveEmail(selectedSleeper?.firstName), '', 'primary-btn', 'Create Login', 'createLogin');
      } else {
        return new SleeperStudyState(ProfileStringResource.beAPartOf(), ProfileStringResource.selectedSleeperHasEmail(selectedSleeper?.firstName), selectedSleeper?.email);
      }
    }
  }

  @Selector([SleepersState])
  static getNumberOfQuestionsForCategory(state: SleepersStateModel): (category: WellnessCategory) => number {
    return (category: WellnessCategory) => {
      if (category.isCategory(Category.Sleeper)) {
        if (state.selectedSleeper?.isChild) {
          // 5 as questions from the sleeper API (name, birth, height, weight, sleep goal)
          return 5;
        }
        // 6 as questions from the sleeper API (name, birth, gender, height, weight, sleep goal)
        return Object.keys(category.questions).length + 6;
      }
      return Object.keys(category.questions).length;
    };
  }

  @Selector([SleepersState])
  static getAnsweredQuestionsForCategory(state: SleepersStateModel): (category: WellnessCategory) => number {
    return (category: WellnessCategory) => {
      let answeredSleeperQuestions = 0;
      let countAnsweredQuestions = 0;
      const selectedSleeper = state.selectedSleeper;
      if (category.isCategory(Category.Sleeper)) {
        // these questions are prefilled during registration
        if (selectedSleeper?.isChild) {
          answeredSleeperQuestions = 5; // kid does not have a gender question
        } else {
          answeredSleeperQuestions = 6;
        }
      }
      if (!selectedSleeper?.wellnessProfile) {
        return answeredSleeperQuestions;
      } else {
        category.questions.forEach((question) => {
          if (!category.isCategory(Category.SleepHealth)) {
            if (selectedSleeper.wellnessProfile[question.name] !== null) countAnsweredQuestions++;
          } else {
            countAnsweredQuestions += this.countWPSleepHealthAnswers(question, selectedSleeper.wellnessProfile);
          }
        });
      }
      return answeredSleeperQuestions + countAnsweredQuestions;
    };
  }

  @Selector([SleepersState])
  static getWellnessQuestionsCategory(state: SleepersStateModel): (categoryId: number) => WellnessCategory | null {
    return (categoryId: number): WellnessCategory | null => {
      const category = state.selectedSleeper?.wellnessQuestions.find(category => category.id === categoryId);
      return category ?? null;
    };
  }

  private static sortSleepers(state: SleepersStateModel, bedState: BedsStateModel): Array<Sleeper> {
    const unsortedSleepers = state.sleepers.filter((s) => s.hasBed && s.sleeperId !== state.selectedSleeper?.sleeperId);

    const sortedSleepers: Array<Sleeper> = [];

    // @ts-expect-error bed won't be null
    sortedSleepers.push(state.selectedSleeper);

    // first sleeper in array is from the active bed 
    // active bed - selected sleeper bed
    const activeBed = bedState.beds.find((bed) => bed.bedId === state.selectedSleeper?.bedId);
    const sleeperFromTheSameBed = unsortedSleepers.find((s) => s.bedId == activeBed?.bedId);

    if (sleeperFromTheSameBed) {
      sortedSleepers.push(sleeperFromTheSameBed);
    }

    // all other adult beds alphabetically, left sleeper, then right
    // @ts-expect-error bed won't be null
    const adultSleepersArr = sortedSleepers.concat(this.sortSleepersByBed(BedType.adult, bedState, activeBed, unsortedSleepers));

    // all other kid beds alphabetically
    // there can only be one sleeper on the kids bed (left or right)
    // @ts-expect-error bed won't be null
    const adultAndKidSleepersArr = adultSleepersArr.concat(this.sortSleepersByBed(BedType.kid, bedState, activeBed, unsortedSleepers));

    return adultAndKidSleepersArr;
  }

  private static sortSleepersByBed(type: BedType, bedState: BedsStateModel, activeBed: Bed, unsortedSleepers: Array<Sleeper>): Array<Sleeper> {
    const sleepersArr: Array<Sleeper> = [];
    let allBeds: Array<Bed> = [];

    // get all adult or kid beds
    switch (type) {
      case BedType.adult:
        allBeds = bedState.beds.filter((bed) => !bed.isKidsBed && bed.bedId !== activeBed.bedId);
        break;
      case BedType.kid:
        allBeds = bedState.beds.filter((bed) => bed.isKidsBed && bed.bedId !== activeBed.bedId);
        break;
    }

    // sort bed alphabetically
    allBeds = FunctionsHelper.sortBy(allBeds, (obj: Bed) => obj.name);

    // first left sleeper, then right sleeper
    allBeds.forEach((bed) => {
      const leftSleeper = unsortedSleepers.find((s) => s.sleeperId === bed.sleeperLeftId);
      if (leftSleeper && leftSleeper.hasBed) sleepersArr.push(leftSleeper);
      const rightSleeper = unsortedSleepers.find((s) => s.sleeperId === bed.sleeperRightId);
      if (rightSleeper && rightSleeper.hasBed) sleepersArr.push(rightSleeper);
    });

    return sleepersArr;
  }

  private static countWPSleepHealthAnswers(questionProperties: QuestionProperties, wellnessProfile: WellnessProfile): number {
    let countAnsweredQuestions = 0;
    const question = questionProperties.name;
    if (questionProperties.type === 0) { // dropdown sleep health question
      if (wellnessProfile[question] !== null) countAnsweredQuestions++;
    }
    if (questionProperties.type === 1 && wellnessProfile[question].length > 0) { // checkbox sleep health questions
      countAnsweredQuestions++;
    }
    if (questionProperties.type === 2 && questionProperties.subquestions) { // radio button sleep health questions
      let hasAnswer = true;
      wellnessProfile[question].forEach((subQuestion: RadioButtonQuestion) => {
        if (Object.values(subQuestion)[0] === null) {
          hasAnswer = false;
          return;
        }
      });
      if (hasAnswer) {
        countAnsweredQuestions++;
      }
    }
    return countAnsweredQuestions;
  }
}