import { CognitoLoginData, CognitoLoginModel, CognitoQuery, CognitoRefreshToken, UserInfo } from "@models/auth/cognito.model";
import { LoginResponse } from "@models/auth/login.model";
import { SiqError } from "@models/app/helpers.model";
import { Selector } from "@ngxs/store";
import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import { AuthStateModel } from "./auth.model";
import { AuthState } from "./auth.state";
import { SleeperInfo } from "@models/auth/sleeper-type.model";

const REGISTRATION_COMPLETE = 13;
export class AuthSelectors {

  @Selector([AuthState])
  static loading(state: AuthStateModel): boolean {
    return state.loading;
  }

  @Selector([AuthState])
  static login(): CognitoLoginModel | null {
    const userCredentials = localStorage.getItem('login');
    return userCredentials ? FunctionsHelper.decrypt<CognitoLoginModel>(userCredentials) : null;
  }

  @Selector([AuthState])
  static cognitoAuth(state: AuthStateModel): CognitoQuery<CognitoLoginData | CognitoRefreshToken> | null {
    return state.cognitoAuth ? FunctionsHelper.decrypt<CognitoQuery<CognitoLoginData | CognitoRefreshToken>>(state.cognitoAuth) : null;
  }

  @Selector([AuthState])
  static siqAuth(state: AuthStateModel): LoginResponse | null {
    return state.siqAuth ? FunctionsHelper.decrypt<LoginResponse>(state.siqAuth) : null;
  }

  @Selector([AuthState])
  static userInfo(state: AuthStateModel): UserInfo | null {
    return state.userInfo ? FunctionsHelper.decrypt<UserInfo>(state.userInfo) : null;
  }

  @Selector([AuthState])
  static error(state: AuthStateModel): SiqError | null {
    return state.error;
  }

  @Selector([AuthState])
  static loginError(state: AuthStateModel): SiqError | null {
    return state.loginError;
  }

  @Selector([AuthState])
  static loggedInSleeperId(state: AuthStateModel): string | null {
    return (FunctionsHelper.decrypt<UserInfo>(state.userInfo)).sleeperId;
  }

  @Selector([AuthState])
  static isRegistrationComplete(state: AuthStateModel): boolean {
    const cognitoAuth = (FunctionsHelper.decrypt<UserInfo>(state.userInfo));
    const siqAuth = state.siqAuth ? FunctionsHelper.decrypt<LoginResponse>(state.siqAuth) : null;
    if (cognitoAuth) {
      return cognitoAuth.registrationState === REGISTRATION_COMPLETE;
    } else if (siqAuth) {
      return siqAuth.registrationState === REGISTRATION_COMPLETE;
    } else {
      return false;
    }
  }

  @Selector([AuthState])
  static sleeperInfo(state: AuthStateModel): SleeperInfo | null {
    return state.sleeperInfo ? new SleeperInfo(state.sleeperInfo.sleeperType, state.sleeperInfo.features) : null;
  }

  @Selector([AuthState])
  static username(state: AuthStateModel): string {
    return FunctionsHelper.decrypt<string>(state.username);
  }
}
