import { SetupBedData } from "@models/app/helpers.model";
import { Bed } from "@models/bed/bed.model";

export class SelectSetupBed {
  static readonly type = '[Setup] SelectSetupBed';
  constructor(public bed: Bed) { }
}

export class SetBedData {
  static readonly type = '[Setup] SetBedData';
  constructor(public data: SetupBedData) { }
}

export class SetSleeperDataProperty {
  static readonly type = '[Setup] SetSleeperDataProperty';
  constructor(public property: string, public value: number | string) { }
}

export class SetAddSecondSleeper {
  static readonly type = '[Setup] SetAddSecondSleeper';
  constructor(public shouldAddSecondSleeper: number | null) { }
}
// account owner or primary sleeper
export class SetupAccountOwner {
  static readonly type = '[Setup] SetupAccountOwner';
}

export class SetupAddSleeper {
  static readonly type = '[Setup] SetupAddSleeper';
}

export class ResetSetupState {
  static readonly type = '[Setup] ResetSetupState';
}

export class ResetBedData {
  static readonly type = '[Setup] ResetBedData';
}

export class ResetSleeperData {
  static readonly type = '[Setup] ResetSleeperData';
}

export class SetSettingFirstSleeper {
  static readonly type = '[Setup] SetSettingFirstSleeper';
  constructor(public isSettingFirstSleeper: boolean) { }
}

export class GetBiqUserDetails {
  static readonly type = '[Setup] GetBiqUserDetails';
}

export class CheckForBiqDetails {
  static readonly type = '[Setup] CheckForBiqDetails';
  constructor(public isPartnerSetup: boolean) { }
}

export class ResetBiqData {
  static readonly type = '[Setup] ResetBiqData';
}
