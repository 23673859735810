import { ApplicationConfig } from "@models/app/application-config.model";
import { SiqNotification } from "@models/app/helpers.model";
import { SiqError } from "@models/app/helpers.model";
import { AppConfig } from "@shared/utils/helpers/app-config.helper";
import { PrivacyMode } from "@shared/utils/helpers/enum.helper";

export interface AppStateModel {
    passwordUpdateShown: boolean;
    selectDefaultSleeperShown: boolean;
    tacoFlowShown: boolean;
    appConfig: ApplicationConfig;
    notification: SiqNotification | null;
    showPrivacyModeBanner: PrivacyMode | null;
    isPrivacyBannerDismissedWithLater: boolean;
    hasECIMIdentity: boolean;
    error: SiqError | null;
}

export const defaultAppState = {
    passwordUpdateShown: false,
    selectDefaultSleeperShown: false,
    tacoFlowShown: false,
    appConfig: new ApplicationConfig(AppConfig.configFallback),
    notification: null,
    showPrivacyModeBanner: null,
    isPrivacyBannerDismissedWithLater: false,
    hasECIMIdentity: false,
    error: null,
};
