import { SetupBedData } from "@models/app/helpers.model";
import { UpdateBedProperty } from "@models/bed/bed.model";

export class LoadBeds {
  static readonly type = '[Beds] LoadBeds'
}

export class RefreshBeds {
  static readonly type = '[Beds] RefreshBeds'
}

export class LoadBedStatus {
  static readonly type = '[Beds] LoadBedStatus'
}

export class UnsubscribeFromBedStatus {
  static readonly type = '[Beds] UnsubscribeFromBedStatus'
}

export class UpdateBed {
  static readonly type = '[Beds] UpdateBed';
  constructor(public bedId: string, public payload: UpdateBedProperty | SetupBedData) {}
}

export class GetBedPrivacyMode {
  static readonly type = '[Beds] GetBedPrivacyMode';
  constructor(public bedId: string) {}
}

export class SetBedPrivacyMode {
  static readonly type = '[Beds] SetBedPrivacyMode';
  constructor(public bedId: string, public mode: string) {}
}
export class ResetBedState {
  static readonly type = '[Beds] ResetBedState';
}
