import { ListItem } from "@models/app/helpers.model";
import { BedSettingsActionEnum } from "./enum.helper";

export class BedSettingAction {
  id: BedSettingsActionEnum;
  title: string;

  constructor(id: BedSettingsActionEnum, title: string) {
    this.id = id;
    this.title = title;
  }
}

export class BedSettingsResources {
  static bedSettingActions(dualBed: boolean, currentTimezone: string): Array<BedSettingAction> {
    const bedSettingsActionsArr = [
      new BedSettingAction(BedSettingsActionEnum.Connectivity, 'Connectivity'),
      new BedSettingAction(BedSettingsActionEnum.Baseline, 'Baseline Bed'),
      new BedSettingAction(BedSettingsActionEnum.Timezone, `Change Timezone (${currentTimezone})`),
      new BedSettingAction(BedSettingsActionEnum.Privacy, 'Privacy Mode'),
    ];
    if (dualBed) {
      bedSettingsActionsArr.splice(2, 0, new BedSettingAction(BedSettingsActionEnum.Setup, 'Sleeper Setup'));
    }
    return bedSettingsActionsArr;
  }

  static TroubleshootingTips: Array<ListItem> = [
    { icon: 'lock-key-icon', text: 'Make sure your network password is correct. Remember passwords are case sensitive. The process can take up to four minutes. ', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
    { icon: 'signal-icon', text: 'Make sure the security setting on your Wifi network is WPA2 (recommended), WPA, or WEP (not recommended).', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
  ];

  static TroubleshootingCTAs = {
    havingIssues: 'If you have issues setting up the network on your bed, here are some of the common issues.',
    contactUs: 'If you are still having issues, call customer support at 1-888-484-9263 during the hours Mon. – Fri. 8:00 am – 8:00 pm, Sat. 8.30 am – 5:00 pm.'
  };

  static NetworkTypes = {
    options: ['WPA', 'WPA2 Personal', 'WPA2 Enterprise', 'WEP'],
    values: [0, 1, 2, 3]
  }

  static AuthenticationProtocol = {
    options: ['PEAP', 'LEAP'],
    values: [0, 1]
  }

  static Errors = {
    bedName: 'Bed name must contain at least one character. Please add your bed name and continue.'
  }

  static StepsBeforeBaseline: Array<ListItem> = [
    { icon: 'check-icon', text: 'No people are on the bed', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
    { icon: 'check-icon', text: 'No pets are on the bed', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
    { icon: 'check-icon', text: 'Bedding can be kept on the bed', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
  ];

  static PrivacyMode = {
    informationText: 'No sleep data will be collected or displayed when privacy mode is turned on.',
    active: 'Your bed is no longer collecting any sleep data.',
    paused: 'Recommended'
  }

  static get bedSettingsKid(): string {
    return 'If you are having problems with the bed, talk to your parents.';
  }

  static get removeNetworkPopupData(): object {
    return {
      title: 'Remove Network',
      text: 'Are you sure you want to continue?'
    };
  }

  static get unconfiguredBedCTA(): object {
    return {
      text: 'Settings & Support',
      subtext: 'Once you finish setting up your smart bed, you will be able to access your smart bed settings and support here.'
    };
  }

  static get connectivityErrorStates(): object {
    return {
      networkName: 'Network name is required',
      password: 'Your password must be between 8 and 64 characters in length',
      passwordsDoNotMatch: 'The passwords you entered do not match',
      username: 'WPA2 Enterprise requires a username'
    }
  }

  static get privacyModeBannerData(): object {
    return {
      title: 'Privacy Mode is on',
      message: 'Your bed is not collecting data'
    }
  }
}