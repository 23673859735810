import { Component, Input } from '@angular/core';
import { SiqCommonModule } from '@shared/siq-common.module';

@Component({
  selector: 'app-siq-metrics-preview',
  templateUrl: './siq-metrics-preview.component.html',
  standalone: true,
  imports: [SiqCommonModule]

})
export class SiqMetricsPreviewComponent {
  @Input() icon: string;
  @Input() value: string | number | null;
  @Input() mainDirection: string;
  @Input() direction: string;
  @Input() text: string;
  @Input() subText: number | string | null;
  @Input() textClass: string;
  @Input() subTextClass: string;
  @Input() valueClass: string;
  @Input() sleepMetricsType: string;
  @Input() averageValue30Days: string;

  get rectangleClass(): string {
    return `square details-page ${this.sleepMetricsType}`;
  }

  get textLayoutAlign(): string {
    return this.mainDirection ? 'top left' : 'top center';
  }

  getComponentDirection(isMainDirection: boolean): string {
    if(isMainDirection) return this.mainDirection ? this.mainDirection : 'column';
    return 'column';
  }
}
