<ng-container *ngIf="optInState">
  <div siqGrid fx-layout-align="center center" fx-direction="column">
    <div class="w-100-p">
      <app-no-data [noDataIcon]="optInState.icon" [noDataText]="optInState.text" [noDataSubtext]="optInState.subText">
      </app-no-data>
    </div>
    <ng-container *ngIf=""></ng-container>
    <ng-container *ngIf="optInState.email else button">
      <div siqGrid fx-layout-align="center center" fx-direction="column" class="mt-48">
        <span class="siq-text-900 fs-16 white"> Current Email </span>
        <span class="siq-text-400 fs-16 primary-200 mt-12"> {{optInState.email}} </span>
      </div>
    </ng-container>
    <ng-template #button>
      <div class="mt-24">
        <app-siq-button [text]="optInState.btnText" [btnType]="optInState.btnType" width="178px" (buttonClicked)="click()" />
      </div>
    </ng-template>
  </div>
</ng-container>
