import { SiqError } from "@models/app/helpers.model";
import { SleeperInfo } from "@models/auth/sleeper-type.model";

export interface AuthStateModel {
  siqAuth: string | null;
  cognitoAuth: string | null;
  refreshToken: string;
  sleeperInfo: SleeperInfo | null;
  username: string;
  userInfo: string;
  loading: boolean;
  loginError: SiqError | null;
  error: SiqError | null;
}

export const defaultAuthState = {
  siqAuth: null,
  cognitoAuth: null,
  refreshToken: '',
  sleeperInfo: null,
  username: '',
  userInfo: '',
  loading: false,
  loginError: null,
  error: null
};
