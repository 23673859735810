import { Component, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';

@Component({
  selector: 'app-sleep-research-learn-more',
  templateUrl: './sleep-research-learn-more.component.html'
})
export class SleepResearchLearnMoreComponent implements OnInit {

  constructor(private store: Store) {}

  ngOnInit(): void {
    FunctionsHelper.scrollToTop();
  }

  back(): void {
    this.store.dispatch(new Navigate(['pages/setup/sleep-research']));
  }
}
