import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import * as moment from "moment";

export class WellnessReportParams {
  endDate: string;
  period = 'monthly';

  constructor(date: string) {
    this.endDate = date;
  }
}

export class WellnessReportEntityModel {
  sleepData: Array<WellnessReport | NotAvailableReport | PreviousMonthReport>;
  sleeperId: string;

  constructor(report: WellnessReportEntityModel) {
    report.sleepData = report.sleepData.map((wellnessReport) => {
      if (Object.prototype.hasOwnProperty.call(wellnessReport, 'error')) {
        return new NotAvailableReport(wellnessReport as NotAvailableReport);
      } else if (Object.prototype.hasOwnProperty.call(wellnessReport, 'icc')) {
        return new PreviousMonthReport(wellnessReport as PreviousMonthReport);
      }
      return new WellnessReport(wellnessReport as WellnessReport);
    }).sort((e1, e2) => FunctionsHelper.compareByDate(e1, e2, 'date'));
    Object.assign(this, report);
  }
}


export class Report {
  date: string;
  constructor(date: string) {
    this.date = date;
  }

  get monthName(): string {
    return moment(this.date).format('MMMM');
  }

  get year(): string {
    return moment(this.date).format('YYYY');
  }
}

export class WellnessReport extends Report {
  outOfBed: number;
  restful: number;
  restless: number;
  sleepIQ: number;
  isEdited: boolean;
  sleepQuotient: number;

  constructor(wellnessReport: WellnessReport) {
    super(wellnessReport.date);
    wellnessReport.sleepQuotient = wellnessReport.sleepIQ; // for gauge chart
    Object.assign(this, wellnessReport);
  }
}

export class PreviousMonthReport extends WellnessReport {
  icc: ReportIcc;

  constructor(previousMonthReport: PreviousMonthReport) {
    super(previousMonthReport);
    this.icc = new ReportIcc(previousMonthReport.icc);
  }
}

export class WellnessReportsByYear {
  year: string;
  yearReports: Array<WellnessReport | NotAvailableReport>;

  constructor(year: string, yearReports: Array<WellnessReport | NotAvailableReport>) {
    this.year = year;
    this.yearReports = yearReports;
  }
}

export class ReportIcc {
  message: string;
  type: string;

  constructor(icc: ReportIcc) {
    Object.assign(this, icc);
  }
}

export class NotAvailableReport extends Report {
  error: string;

  constructor(notAvailableReport: NotAvailableReport) {
    super(notAvailableReport.date);
    this.error = notAvailableReport.error;
  }
}
export class ReportContent {
  report: string;
  date: string;

  constructor(content: string, date: string) {
    this.report = JSON.stringify(content);
    this.date = date;
  }
}

export class SleeperDownloadedReport {
  sleeperId: string;
  reports: Array<ReportContent>;

  constructor(sleeperId: string, reports: Array<ReportContent>) {
    this.sleeperId = sleeperId;
    this.reports = reports;
  }
}
