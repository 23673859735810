const MIN_SLEEP_GOAL_HRS_ADULT = 6;
const MAX_SLEEP_GOAL_HRS_ADULT = 9;
const MIN_SLEEP_GOAL_HRS_KID = 7;
const MAX_SLEEP_GOAL_HRS_KID = 14;

export class SleepGoalHelper {
  
  static get sleepGoalValuesAdultArr(): Array<number> {
    return this.getSleepGoalData('values', false) as Array<number>;
  }

  static get sleepGoalOptionsAdultArr(): Array<string> {
    return this.getSleepGoalData('options', false) as Array<string>;
  }

  private static getSleepGoalData(type: string, isKid: boolean): Array<string | number> {
    const arr: (string | number)[] = [];
    const minSleepGoalHrs = isKid ? MIN_SLEEP_GOAL_HRS_KID : MIN_SLEEP_GOAL_HRS_ADULT;
    const maxSleepGoalHrs = isKid ? MAX_SLEEP_GOAL_HRS_KID : MAX_SLEEP_GOAL_HRS_ADULT;
    for (let i = minSleepGoalHrs; i <= maxSleepGoalHrs; i++) {
      let minMins = 0;
      let maxMins = 55;
      if (i === minSleepGoalHrs && !isKid) {
        minMins = 30;
      }
      if (i === maxSleepGoalHrs) {
        maxMins = 0;
      }
      for (let j = minMins; j <= maxMins; j += 5) {
        if (type === 'values') {
          const sleepGoalValue = i * 60 + j;
          arr.push(sleepGoalValue);
        } else {
          const sleepGoalOption = `${i}h ${j}m`;
          arr.push(sleepGoalOption);
        }
      }
    }
    return arr;
  }
}