// Wrapper class that will support usage of ideal-timing component between timing and CR
// Timing and CR are same features, but with different response structure :(

export class IdealTimes {
  bedTime: IdealTimesValues;
  wakeTime: IdealTimesValues;

  constructor(times: IdealTimes) {
    this.bedTime = times.bedTime;
    this.wakeTime = times.wakeTime;
  }
}

// Sleep Health response 
// goal.bedTime.endDate and goal.bedTime.startDate (same is for the wake time) // used on the timing details
// value.bedTime.endDate and value.bedTime.startDate // session times

// CR response
// idealBedTime.start and idealBedTime.end (same is for the wake time) // used on the CR details
// originalSleepSession.start and originalSleepSession.end // used on the CR card and details

// To be able to differentiate between CR response and Sleep Health response
// if the end is set to null, that will indicate that the values are CR values
export class IdealTimesValues {
  start: string;
  end: string | null = null;

  constructor(start: string, end?: string) {
    this.start = start;
    if (end) {
      this.end = end;
    }
  }
}