<div class="background-transparent">
  <div siqGrid fx-direction="row" class="card br-8" [ngClass]="theme" [class.border-primary-400]="hasBorder">
    <span [ngClass]="cardIcon"></span>
    <ng-container *ngIf="infoBoxIcon">
      <div siqGrid fx-direction="column" fx-layout-align="center center" class="info-box my-12 ml-12 br-8" [ngClass]="infoBoxType">
        <img class="image" [src]="image" alt="IMAGE">
      </div>
    </ng-container>
    <div siqGrid fx-direction="column" class="w-100-p p-12">
      <ng-container *ngIf="headerText">
        <span class="siq-text-900" [class]="headerTextSize" [innerHTML]="headerText"></span>
      </ng-container>
      <span 
        *ngIf="text"
        class="siq-text-400"
        [ngClass]="textClasses"
        [innerHTML]="text">
      </span>

      <div siqGrid fx-direction="row" class="w-100-p">
          <ng-content select="div.body"></ng-content>
      </div>

      <ng-container *ngIf="btnText">
        <div siqGrid fx-layout-align="end center" [ngClass]="btnMargin">
          <app-siq-button
            [text]="btnText"
            [btnType]="btnType"
            (buttonClicked)="onClick()"
            />
        </div>
      </ng-container>
    </div>
  </div>
</div>