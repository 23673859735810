import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationItem } from '@models/app/helpers.model';
import { SleeperInfo } from '@models/auth/sleeper-type.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '@store/auth/auth.selectors';
import { Subject, filter, takeUntil } from 'rxjs';

const NAVIGATION_LIST = ['sleep', 'health', 'profile', 'smart-bed'];

@Component({
  selector: 'app-left-navigation-menu',
  templateUrl: './left-navigation-menu.component.html',
  styleUrls: ['./left-navigation-menu.component.scss']
})
export class LeftNavigationMenuComponent implements OnInit, OnDestroy {
  sleeperInfo: SleeperInfo | null;
  // account setting menu item is created with the clicked: window.history.state.redirectedFrom === 'account-settings'
  // to handle the case when we go to the reset-password module from account settings and have to keep appropriate item clicked
  navigationItems: Array<NavigationItem> = [];

  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private router: Router) { }

  ngOnInit(): void {
    this.store.select(AuthSelectors.sleeperInfo).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(sleeperInfo => this.sleeperInfo = sleeperInfo);
    this.navigationItems = this.createNavigationItemsList();

    // to keep the selected item after browser refresh
    const activeRoute = this.router.url.split('/')[2];
    this.navigationItems.forEach(item => {
      if (item.type.includes(activeRoute)) item.clicked = true;
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      const activeRoute = (event as NavigationEnd).urlAfterRedirects.split('/')[2];
      this.navigationItems.forEach(item => {
        if (item.type.includes(activeRoute)) {
          item.clicked = true;
        } else {
          item.clicked = false;
        }
      });
    })
  }

  isProfile(item: NavigationItem): boolean {
    return item.type === 'profile';
  }

  handleItemClick(clickedItem: NavigationItem): void {
    this.store.dispatch(new Navigate([`/pages/${clickedItem.type}`]));
  }

  private createNavigationItemsList(): Array<NavigationItem> {

    const navigationItems: Array<NavigationItem> = [];
    NAVIGATION_LIST.forEach((item, index) => {
      navigationItems.push(new NavigationItem(index + 1, item))
    });

    const accSettingsItem = new NavigationItem(navigationItems.length + 1, 'account-settings', window.history.state.redirectedFrom === 'account-settings');
    
    return this.sleeperInfo?.isDigitalSleeper ? [accSettingsItem] : [...navigationItems, accSettingsItem];

  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
