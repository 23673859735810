/* eslint-disable */
import { Injectable } from '@angular/core';
import { IntegrationEndpoint } from '@models/app/application-config.model';
import { Environment } from '@models/app/helpers.model';

@Injectable()
export class EnvironmentsService {
  environments: Array<Environment> = [];
  public ENV_NAME = 'Test';
  public APIURL = 'https://test-api.test.siq.sleepnumber.com';
  public EDPURL = 'https://sleepiq.azure-api.net/test';
  public EDPSUBSRCIPTION = 'b26ec87efcd44ebf98802d9565dcf723';
  public MIXPANELKEY = '7216f13b9c0bdc7c595d746ce3dfcfdd';
  public COGNITOURL = 'https://ecim-qa.sleepnumber.com';
  public CLIENTID = '3or2r1bhbi0sqqhq87774oijb5';
  public SLEEPNUMBERURL = 'https://staging.sleepnumber.com';
  public DEEPLINKHOME = 'https://test-ui.test.siq.sleepnumber.com/deeplink/home';
  public DEEPLINKWELCOME = 'https://test-ui.test.siq.sleepnumber.com/deeplink/v1/welcome';
  public PLANSURL = '';
  public BIQURL = '';

   constructor() {
    this.environments.push({
      name: 'Production',
      apiEndpoint: 'https://prod-api.sleepiq.sleepnumber.com',
      mixpanelKey: '2e5e95142188884cc10846b68e708063',
      edpEndpoint: 'https://sleepiqapi.azure-api.net/prod',
      edpKey: 'a6b6cfd7079b4cfd9edf9fee927de59b',
      cognitoEndpoint: 'https://ecim.sleepnumber.com',
      clientId: '2oa5825venq9kek1dnrhfp7rdh',
      sleepNumberEndpoint: 'https://www.sleepnumber.com',
      deeplinkHome: 'https://sleepiq.sleepnumber.com/deeplink/home',
      deeplinkWelcome: 'https://sleepiq.sleepnumber.com/deeplink/v1/welcome',
      plansEndpoint: '',
      biqEndpoint: '',
      isTest: false
    } as Environment);
    this.environments.push({
      name: 'Stage',
      apiEndpoint: 'https://stage-api.stage.siq.sleepnumber.com',
      mixpanelKey: '45f2c5328d5e07437617d2d69f4d07fe',
      edpEndpoint: 'https://sleepiqapi.azure-api.net/stage',
      edpKey: 'a6b6cfd7079b4cfd9edf9fee927de59b',
      cognitoEndpoint: 'https://ecim-stage.sleepnumber.com',
      clientId: '36nqosmruu4i0672cqprnh18gs',
      sleepNumberEndpoint: '',
      deeplinkHome: 'https://stage-ui.stage.siq.sleepnumber.com/deeplink/home',
      deeplinkWelcome: 'https://stage-ui.stage.siq.sleepnumber.com/deeplink/v1/welcome',
      plansEndpoint: '',
      biqEndpoint: '',
      isTest: false
    } as Environment);
    this.environments.push({
      name: 'Test',
      apiEndpoint: 'https://test-api.test.siq.sleepnumber.com',
      mixpanelKey: '7216f13b9c0bdc7c595d746ce3dfcfdd',
      edpEndpoint: 'https://sleepiq.azure-api.net/test',
      edpKey: 'b26ec87efcd44ebf98802d9565dcf723',
      cognitoEndpoint: 'https://ecim-qa.sleepnumber.com',
      clientId: '3or2r1bhbi0sqqhq87774oijb5',
      sleepNumberEndpoint: 'https://staging.sleepnumber.com',
      deeplinkHome: 'https://test-ui.test.siq.sleepnumber.com/deeplink/home',
      deeplinkWelcome: 'https://test-ui.test.siq.sleepnumber.com/deeplink/v1/welcome',
      plansEndpoint: '',
      biqEndpoint: '',
      isTest: true
    } as Environment);
    this.environments.push({
      name: 'Circle1',
      apiEndpoint: 'https://circle1-api.circle.siq.sleepnumber.com',
      mixpanelKey: '7216f13b9c0bdc7c595d746ce3dfcfdd',
      edpEndpoint: 'https://sleepiq.azure-api.net/circle1',
      edpKey: 'b26ec87efcd44ebf98802d9565dcf723',
      cognitoEndpoint: 'https://ecim-circle1.sleepnumber.com',
      clientId: '1su2j5nrmc694ntsk8tu952seu',
      sleepNumberEndpoint: 'https://qa.sleepnumber.com',
      deeplinkHome: 'https://circle1-ui.circle.siq.sleepnumber.com/deeplink/home',
      deeplinkWelcome: 'https://circle1-ui.circle.siq.sleepnumber.com/deeplink/v1/welcome',
      plansEndpoint: '',
      biqEndpoint: '',
      isTest: true
    } as Environment);
    this.environments.push({
      name: 'Qa22',
      apiEndpoint: 'https://qa22-api.dev.siq.sleepnumber.com',
      mixpanelKey: '7216f13b9c0bdc7c595d746ce3dfcfdd',
      edpEndpoint: 'https://sleepiq.azure-api.net/qa22',
      edpKey: 'b26ec87efcd44ebf98802d9565dcf723',
      cognitoEndpoint: 'https://ecim-qa22.sleepnumber.com',
      clientId: '74gen1l4g6mudtefqjre0bgems',
      sleepNumberEndpoint: 'https://pauu96skhb.execute-api.us-east-1.amazonaws.com',
      deeplinkHome: 'https://qa22-ui.dev.siq.sleepnumber.com/deeplink/home',
      deeplinkWelcome: 'https://qa22-ui.dev.siq.sleepnumber.com/deeplink/v1/welcome',
      plansEndpoint: '',
      biqEndpoint: '',
      isTest: true
    } as Environment);
    this.environments.push({
      name: 'Qa23',
      apiEndpoint: 'https://qa23-api.dev.siq.sleepnumber.com',
      mixpanelKey: '7216f13b9c0bdc7c595d746ce3dfcfdd',
      edpEndpoint: 'https://sleepiq.azure-api.net/qa23',
      edpKey: 'b26ec87efcd44ebf98802d9565dcf723',
      cognitoEndpoint: 'https://ecim-qa23.sleepnumber.com',
      clientId: '6mo8q6si5ti2hagiu00an7trlb',
      sleepNumberEndpoint: 'https://pauu96skhb.execute-api.us-east-1.amazonaws.com',
      deeplinkHome: 'https://qa23-ui.dev.siq.sleepnumber.com/deeplink/home',
      deeplinkWelcome: 'https://qa23-ui.dev.siq.sleepnumber.com/deeplink/v1/welcome',
      plansEndpoint: '',
      biqEndpoint: '',
      isTest: true
    } as Environment);
    this.environments.push({
      name: 'Dev21',
      apiEndpoint: 'https://dev21-api.dev.siq.sleepnumber.com',
      mixpanelKey: '7216f13b9c0bdc7c595d746ce3dfcfdd',
      edpEndpoint: 'https://sleepiq.azure-api.net/dev21',
      edpKey: 'b26ec87efcd44ebf98802d9565dcf723',
      cognitoEndpoint: 'https://ecim-dev21.sleepnumber.com',
      clientId: 'q6ptc55u7fo47rafecq4u4iqr',
      sleepNumberEndpoint: 'https://pauu96skhb.execute-api.us-east-1.amazonaws.com',
      deeplinkHome: 'https://dev21-ui.dev.siq.sleepnumber.com/deeplink/home',
      deeplinkWelcome: 'https://dev21-ui.dev.siq.sleepnumber.com/deeplink/v1/welcome',
      plansEndpoint: '',
      biqEndpoint: '',
      isTest: true
    } as Environment);
    this.environments.push({
      name: 'Dev22',
      apiEndpoint: 'https://dev22-api.dev.siq.sleepnumber.com',
      mixpanelKey: '7216f13b9c0bdc7c595d746ce3dfcfdd',
      edpEndpoint: 'https://sleepiq.azure-api.net/dev22',
      edpKey: 'b26ec87efcd44ebf98802d9565dcf723',
      cognitoEndpoint: 'https://ecim-dev22.sleepnumber.com',
      clientId: '1qlgg40vfuhc7b93j79dqvm5sr',
      sleepNumberEndpoint: 'https://pauu96skhb.execute-api.us-east-1.amazonaws.com',
      deeplinkHome: 'https://dev22-ui.dev.siq.sleepnumber.com/deeplink/home',
      deeplinkWelcome: 'https://dev22-ui.dev.siq.sleepnumber.com/deeplink/v1/welcome',
      plansEndpoint: '',
      biqEndpoint: '',
      isTest: true
    } as Environment);
    this.environments.push({
      name: 'Dev23',
      apiEndpoint: 'https://dev23-api.dev.siq.sleepnumber.com',
      mixpanelKey: '7216f13b9c0bdc7c595d746ce3dfcfdd',
      edpEndpoint: 'https://sleepiq.azure-api.net/dev23',
      edpKey: 'b26ec87efcd44ebf98802d9565dcf723',
      cognitoEndpoint: 'https://ecim-dev23.sleepnumber.com',
      clientId: '2kcsdigte8kqtgoo8692dbghis',
      sleepNumberEndpoint: 'https://pauu96skhb.execute-api.us-east-1.amazonaws.com',
      deeplinkHome: 'https://dev23-ui.dev.siq.sleepnumber.com/deeplink/home',
      deeplinkWelcome: 'https://dev23-ui.dev.siq.sleepnumber.com/deeplink/v1/welcome',
      plansEndpoint: '',
      biqEndpoint: '',
      isTest: true
    } as Environment);
    this.environments.push({
      name: 'Dev24',
      apiEndpoint: 'https://dev24-api.dev.siq.sleepnumber.com',
      mixpanelKey: '7216f13b9c0bdc7c595d746ce3dfcfdd',
      edpEndpoint: 'https://sleepiq.azure-api.net/dev24',
      edpKey: 'b26ec87efcd44ebf98802d9565dcf723',
      cognitoEndpoint: 'https://ecim-dev24.sleepnumber.com',
      clientId: '7iigj7917elnuplj72pasqdo2d',
      sleepNumberEndpoint: 'https://pauu96skhb.execute-api.us-east-1.amazonaws.com',
      deeplinkHome: 'https://dev24-ui.dev.siq.sleepnumber.com/deeplink/home',
      deeplinkWelcome: 'https://dev24-ui.dev.siq.sleepnumber.com/deeplink/v1/welcome',
      plansEndpoint: '',
      biqEndpoint: '',
      isTest: true
    } as Environment);
    this.environments.push({
      name: 'Dev26',
      apiEndpoint: 'https://dev26-api.dev.siq.sleepnumber.com',
      mixpanelKey: '7216f13b9c0bdc7c595d746ce3dfcfdd',
      edpEndpoint: 'https://qa-api-dev26.azurewebsites.net',
      edpKey: 'b26ec87efcd44ebf98802d9565dcf723',
      cognitoEndpoint: 'https://jep2yppivi.execute-api.us-east-1.amazonaws.com/Dev26',
      clientId: '15gn0ta05dnd8e0canus5m97nu',
      sleepNumberEndpoint: 'https://pauu96skhb.execute-api.us-east-1.amazonaws.com',
      deeplinkHome: 'https://dev26-ui.dev.siq.sleepnumber.com/deeplink/home',
      deeplinkWelcome: 'https://dev26-ui.dev.siq.sleepnumber.com/deeplink/v1/welcome',
      plansEndpoint: '',
      biqEndpoint: '',
      isTest: true
    });
    this.environments.push({
      name: 'default',
      apiEndpoint: 'DEFAULT_API_URL',
      mixpanelKey: 'DEFAULT_MIXPANEL_KEY',
      edpEndpoint: 'DEFAULT_EDP_URL',
      edpKey: 'DEFAULT_EDP_KEY',
      cognitoEndpoint: 'DEFAULT_COGNITO_URL',
      clientId: 'DEFAULT_CLIENT_ID_KEY',
      sleepNumberEndpoint: 'DEFAULT_SLEEP_NUMBER_URL',
      deeplinkHome: 'DEFAULT_DEEPLINK_HOME_URL',
      deeplinkWelcome: 'DEFAULT_DEEPLINK_WELCOME_URL',
      plansEndpoint: 'DEFAULT_PLANS_URL',
      biqEndpoint: 'DEFAULT_BIQ_URL',
      isTest: true
    } as Environment);
  }

  get isProduction(): boolean {
    return this.ENV_NAME.toLowerCase() === 'production'
  }

  changeActiveServer(serverName: string): void {
    const self = this;
    this.environments.map((environment) => {
      if (environment.name === serverName) {
        try {
          localStorage.setItem('activeServer', environment.name);
          self.APIURL = environment.apiEndpoint;
          self.EDPURL = environment.edpEndpoint;
          self.EDPSUBSRCIPTION = environment.edpKey;
          self.MIXPANELKEY = environment.mixpanelKey;
          self.ENV_NAME = environment.name;
          self.COGNITOURL = environment.cognitoEndpoint;
          self.CLIENTID = environment.clientId;
          self.SLEEPNUMBERURL = environment.sleepNumberEndpoint;
          self.DEEPLINKHOME = environment.deeplinkHome;
          self.DEEPLINKWELCOME = environment.deeplinkWelcome;
        } catch (exception) {}
        environment.active = true;
      } else {
        environment.active = false;
      }
      return environment;
    });
  }

  addCustomServer(server: Environment): void {
    server.active = true;
    this.environments.push(server);
  }

  setDefaultServer(): void {
    let activeServer = null;
    try {
      activeServer = localStorage.getItem('activeServer');
      if (activeServer && activeServer !== this.ENV_NAME) {
        this.changeActiveServer(activeServer);
      } else {
        localStorage.setItem('activeServer', this.ENV_NAME);
      }
    } catch (exception) {}
  }

  setDigitalEndpoints(plansUrl: string, biqUrl: string): void {
    const self = this;
    self.PLANSURL = `https://${plansUrl}`;
    self.BIQURL = `https://${biqUrl}`;
  }
}