export enum Status {
  Installed = 'INSTALLED',
  NotInstalled = 'NOT_INSTALLED',
  InstalledByExchange = 'INSTALLED_BY_EXCHANGE',
  Removed = 'REMOVED',
  RemovedByExchange = 'REMOVED_BY_EXCHANGE',
  PendingExchange = 'PENDING_EXCHANGE'
}

export enum ComponentSize {
  KingFlex = 'KING-FLEX',
  SplitKing = 'KING-SPLIT',
  TwinXl = 'TWINXL'
}

export enum ComponentSizeCustomerFriendly {
  KingFlex = 'FlexTop King',
  SplitKing = 'Split King',
  TwinXl = 'TwinXL'
}

export enum ProductClassificationExcluded {
  FlexFitC = 'FLEX FIT C',
  Undefined = 'UNDEFINED',
  FlexFit = 'FLEX FIT'
}

export class Component {
  componentId!: string;
  parentId: string | null;
  type!: string;
  model!: string;
  dualSleep!: boolean;
  version!: string;
  serial!: string;
  sku!: string;
  size: string | null;
  base: string | null;
  reference!: string;
  purchaseDate!: string;
  installDate: string | null;
  installer: string | null;
  orderNumberToExchange: string | null;
  status!: string;
  hasPendingReturn!: boolean;
  mattressModel: string;
  subgeneration: string | null;
  productclassification: string | null;

  constructor(component: Component) {
    Object.assign(this, component);
  }
}