import { HttpErrorResponse } from "@angular/common/http";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ChartDataObject, SetupSleeperData, SetupSleeperForm, SiqError, SiqNotification } from "@models/app/helpers.model";
import { PopupBtnIcon } from "@models/app/modal-data.model";
import { CognitoLoginData, CognitoQuery, CognitoRefreshToken, UserInfo } from "@models/auth/cognito.model";
import { Bed } from "@models/bed/bed.model";
import { IccMessage } from "@models/health/sleep-health-icc.model";
import { NoDataSession, SessionModel } from "@models/sessions/session.model";
import { SleepData, SleepHistoryMonth } from "@models/sessions/sleep-data.model";
import { Sleeper } from "@models/sleeper/sleeper.model";
import { Navigate } from "@ngxs/router-plugin";
import { Store } from "@ngxs/store";
import { PopupComponent } from "@shared/components/popup/popup.component";
import { SetSiqNotification } from "@store/app/app.actions";
import { SetRegistrationState } from "@store/auth/auth.actions";
import { BedsSelectors } from "@store/beds/beds.selectors";
import { SetupSelectors } from "@store/setup/setup.selectors";
import { ResetTacoState } from "@store/taco/taco.actions";
import { Observable, throwError, timer } from "rxjs";
import { CustomValidators } from "./custom-validators.helper";
import { SiqDateFormats } from "./date-formats.helper";
import { BedSide, ShouldAddPartnerSleeper } from "./enum.helper";
import { Regex } from "./regex.helper";

import * as CryptoJS from "crypto-js";
import * as exprEval from 'expr-eval';
import * as moment from "moment";

export class FunctionsHelper {

  static isSameArray(arr1: Array<unknown>, arr2: Array<unknown>): boolean {
    if (!arr1 || !arr2) {
      return false;
    }
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }

  static camelize(str: string): string {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  static booleanify(value: string): boolean {
    const truthy: string[] = [
      'true',
      'True',
      '1'
    ];

    return truthy.includes(value);
  }

  static compareSessions(s1: SessionModel, s2: SessionModel): number {
    const d1 = moment(s1.originalEndDate).valueOf();
    const d2 = moment(s2.originalEndDate).valueOf();
    if (d1 < d2) {
      return 1;
    } else if (d1 === d2) {
      return 0;
    } else {
      return -1;
    }
  }

  static compareByDate<T>(element1: T, element2: T, prop: string): number {
    if (moment(element2[prop]).isBefore(element1[prop])) {
      return -1;
    }
    if (moment(element1[prop]).isBefore(element2[prop])) {
      return 1;
    }
    return 0;
  }

  static sortBy<T>(col: Array<T>, iterator: (obj: T) => Array<any> | boolean | string): Array<T> {
    const isString = typeof iterator === 'string';
    const len = col.length;
    let temp;
    let lowest;

    const it = JSON.parse(JSON.stringify(col));
    // @ts-expect-error expect error since this is generic function and prop is not used
    it.forEach((_, index: number) => {
      lowest = index;
      if (isString) {
        for (let i = index + 1; i < len; i++) {
          if (it[i][iterator] < it[lowest][iterator]) {
            lowest = i;
          }
        }
      } else {
        for (let i = index + 1; i < len; i++) {
          if (iterator(it[i]) < iterator(it[lowest]) || it[lowest] === undefined) {
            lowest = i;
          }
        }
      }
      if (index !== lowest) {
        temp = it[index];
        it[index] = it[lowest];
        it[lowest] = temp;
      }
    });
    col = it;
    return col;
  }

  static groupBy<T>(arr: Array<T>, key: string): object {
    const initialValue = {};
    return arr.reduce((acc, cval) => {
      const myAttribute = cval[key];
      acc[myAttribute] = [...(acc[myAttribute] || []), cval];
      return acc;
    }, initialValue);
  }

  static getSessions(sleepData: Array<SleepData>): Array<SessionModel> {
    let mergedSleepSessions: Array<SessionModel> = []; // only data sessions
    sleepData.forEach((ss) => {
      ss.sleepData.forEach((it) => {
        mergedSleepSessions = mergedSleepSessions.concat(it.sessions);
      });
    });
    return mergedSleepSessions;

  }

  static createGenericError(statusCode: number, message?: string): HttpErrorResponse {
    const errorMsg = message ? message : 'An error has occurred';
    return new HttpErrorResponse({ error: { Error: { Code: statusCode, Message: errorMsg } }, status: statusCode, statusText: 'An error has occurred' });
  }

  static createPopup(dialog: MatDialog, title: string, text: string, screen: string, icon: string, type: string, rightBtnTxt: string, leftBtnTxt?: string | null, leftBtnIcon?: PopupBtnIcon | null, rightBtnIcon?: PopupBtnIcon | null): MatDialogRef<PopupComponent> {
    return dialog.open(PopupComponent, {
      width: '327px',
      height: 'auto',
      disableClose: false,
      data: {
        screen,
        icon,
        title,
        text,
        hideLeftButton: leftBtnTxt ? false : true,
        rightBtnTxt,
        leftBtnTxt,
        leftBtnIcon,
        rightBtnIcon,
        type
      }
    });
  }

  // should be called on 400 error (TooManyRequests) and 500 errors
  // should not apply to DELETE (SignOut)
  static genericRetryStrategy(scalingDuration = 1000): (error: HttpErrorResponse) => Observable<HttpErrorResponse | number> {
    return (error: HttpErrorResponse) => {
      if (!this.shouldRetry(error)) {
        return throwError(() => error);
      }
      return timer(scalingDuration);
    };
  }

  static decrypt<T>(payload: string): T {
    if (typeof payload !== 'string') {
      return payload;
    }
    try {
      return JSON.parse(CryptoJS.AES.decrypt(payload, 'selectcomfort').toString(CryptoJS.enc.Utf8));
    } catch (e) {
      return payload as T;
    }
  }

  static encrypt(payload: unknown): string {
    return CryptoJS.AES.encrypt(JSON.stringify(payload), 'selectcomfort').toString();
  }

  static compareByProp(element1: Sleeper, element2: Sleeper, prop: string): number {
    if (element1[prop] < element2[prop]) {
      return 1;
    } else if (element2[prop] < element1[prop]) {
      return -1;
    }
    return 0;
  }

  static createSiqError(code: number, message: string): SiqError {
    return new SiqError({ code, message });
  }

  static addTrademarkToText(text: string): string {
    let tip = text;
    if (tip.includes('®')) {
      tip = tip.replace('®', '');
    }
    if (text.includes('SleepIQ')) {
      tip = tip.replace(/SleepIQ/g, 'SleepIQ<sup>\u00AE</sup>');
    }
    if (text.includes('Sleep Number')) {
      tip = tip.replace(/Sleep Number/g, 'Sleep Number<sup>\u00AE</sup>');
    }
    return tip;
  }

  static scrollToTop(): void {
    if (self.innerWidth < 600) {
      window.scrollTo(0, 0);
    } else {
      const middleColumn = document.getElementsByClassName('middle-column')[0];
      middleColumn.scrollTop = 0;
    }

  }

  static getRandomCategory(isEdp: boolean): string {
    const categories: string[] = ['restful', 'restless'];
    if (isEdp) {
      categories.push('bed_exits');
    } else {
      categories.push('bed-exits');
    }
    return categories[Math.floor(Math.random() * categories.length)];
  }

  // used on circadian rhythm and ideal-timing
  static formatCrTimeValues(time1: string, time2: string): string {
    return `${moment(time1).format(SiqDateFormats.Time)} - ${moment(time2).format(SiqDateFormats.Time)}  ${moment(time2).format(SiqDateFormats.TimeOfDay)}`;
  }

  static calculateChartDataObject(data: Array<SessionModel | NoDataSession>, prop: string): ChartDataObject[] {
    const transformedData: ChartDataObject[] = [];
    data?.forEach(item => {
      transformedData.push(Object.assign(new ChartDataObject(), {
        date: item.date,
        value: this.getSessionValue(item, prop)
      }));
    });
    return transformedData;
  }

  static formatBedtimeWakeTimeValues(time: string): string {
    return `${moment(time).format(SiqDateFormats.Time)} <span class='siq-text-400 fs-14'>${moment(time).format(SiqDateFormats.TimeOfDay)}</span>`;
  }

  static indexBy<T>(arr: Array<T>, key: string): any {
    const grouped = this.groupBy(arr, key);
    const indexed = { ...grouped };
    for (const [k, value] of Object.entries(indexed)) {
      if (value && value[0]) {
        indexed[k] = value[0];
      } else {
        delete indexed[k];
      }
    }
    return indexed;
  }

  static biosignalIcon(session: SessionModel, metric: string, isBiosignalCard: boolean): string {
    switch (metric) {
      case 'avgHeartRate':
        return this.hasBiosignalMetric(session, metric) ? 'heart-soft-icon' : this.createNotAvailableClass('heart-soft-not-available-icon', isBiosignalCard);
      case 'avgRespirationRate':
        return this.hasBiosignalMetric(session, metric) ? 'breath-rate-soft-icon' : this.createNotAvailableClass('breath-rate-soft-not-available-icon', isBiosignalCard);
      case 'hrv':
        return this.hasBiosignalMetric(session, metric) ? 'hrv-soft-icon' : this.createNotAvailableClass('hrv-soft-not-available-icon', isBiosignalCard);
      default:
        return '';
    }
  }

  static hasBiosignalMetric(session: SessionModel, metric: string): boolean {
    return metric === 'hrv' ? this.hasHrv(session) : session && session[metric] && session[metric] >= 0;
  }

  static hasHrv(session: SessionModel): boolean {
    return session && !session.hrvActionCode && session.hrv > 0;
  }

  static biosignalMetric(metric: string): string {
    switch (metric) {
      case 'avgHeartRate':
        return 'heartRate';
      case 'avgRespirationRate':
        return 'respirationRate';
      case 'hrv':
        return 'hrv';
      default:
        return '';
    }
  }

  static getDataForHistoryChart(sessions: Array<SessionModel>, property: string): number {
    if (sessions.length === 0) {
      return 0;
    }
    const longestSession = sessions.find(session => session.longest);
    if (longestSession) {
      if (longestSession[property] < 0 || longestSession.isHidden) {
        return 0;
      }
      return longestSession[property];
    }
    return 0;
  }

  static showIccMessage(icc: IccMessage | null, selectedSession: SessionModel | NoDataSession): boolean {
    return !!icc && (selectedSession instanceof SessionModel) && !selectedSession.isEdited && !selectedSession.isLessThanFourHours;
  }

  static evaluateExpression(expression: string, x: string): number {
    const parser = new exprEval.Parser();
    const expr = parser.parse(expression);
    const scope = { x };
    return expr.evaluate(scope);
  }

  static addMissingSleepDataDays(selectedData: SleepHistoryMonth): SleepHistoryMonth {
    const tempDate = moment(selectedData.date).startOf('month');
    while (tempDate.isSame(moment(selectedData.date), 'month')) {
      selectedData.sleepData.push({
        date: tempDate.format(SiqDateFormats.Date),
        sessions: []
      });
      tempDate.add(1, 'days');
    }
    return selectedData;
  }

  // transform string WORD-TWO to Word-Two
  // example: KING-SPLIT -> King-Split
  static transformBedSizeString(input: string): string {
    // Split the input string by '-' character
    const parts = input.split('-');

    // Capitalize the first letter of each part and convert the rest to lowercase
    const transformedParts = parts.map(part => this.capitalizeFirstLetter(part));

    // Join the parts back together with '-' in between
    const transformedString = transformedParts.join('-');

    return transformedString;
  }

  static capitalizeFirstLetter(input: string): string {
    return `${input.charAt(0).toUpperCase()}${input.slice(1).toLowerCase()}`;
  }

  static transformArrayToString(input: Array<string>, isT360: boolean): string {
    const [firstString, ...restStrings] = input;
    let resultString = '';
    if (isT360) {
      resultString = input.join(' ');
    } else {
      if (restStrings.length > 0) {
        resultString = `${firstString}, ${restStrings.join(' ')}`;
      } else {
        resultString = firstString;
      }
    }
    return resultString;
  }

  static get passwordFormControl(): FormControl {
    return new FormControl('', Validators.compose([
      // 1. Required
      Validators.required,
      // 2. check whether the entered password has a number
      CustomValidators.patternValidator(Regex.number, { hasNumber: true }),
      // 3. check whether the entered password has upper case letter
      CustomValidators.patternValidator(Regex.capitalCase, { hasCapitalCase: true }),
      // 4. check whether the entered password has upper case letter
      CustomValidators.patternValidator(Regex.lowerCase, { hasLowerCase: true }),
      // 5. check whether the entered password has a special character
      CustomValidators.patternValidator(Regex.specialCharacter, { hasSpecialCharacters: true }),
      // 6. check whether the entered password has a white space
      CustomValidators.patternValidator(Regex.whitespace, { hasWhitespace: true }),
      // 7. Has a minimum length of 8 characters
      Validators.minLength(8),
      // 8. Has maximum length of 99 characters
      Validators.maxLength(99),
    ]));
  }

  static handleUserNavigation(store: Store, userInfo: UserInfo): void {
    if (userInfo.registrationState === 0) {
      store.dispatch(new SetRegistrationState(10));
      store.dispatch(new Navigate(['pages/setup/select-bed']));
    } else if (userInfo.registrationState === 10) {
      store.dispatch(new Navigate(['pages/setup/select-bed']));
    } else {
      store.dispatch(new Navigate(['pages/sleep']));
    }
    store.dispatch(new ResetTacoState());
  }

  static checkRoutesToHideLayoutParts(isUserLoggedIn: CognitoQuery<CognitoLoginData | CognitoRefreshToken> | null, router: Router): boolean {
    // this function will be used to hide left navigation menu or download apps and DYK sections in the layout
    if (isUserLoggedIn) {
      return !router.url.includes('select-default-sleeper') &&
        !router.url.includes('terms-of-use') &&
        !router.url.includes('register') &&
        !router.url.includes('password-update') &&
        !router.url.includes('pages/setup');
      // since after user sets up password she is logged in into the app
      // by design the registration is handled the same way select-default-sleeper is (no left navigation)
      // same approach should be used for EULA and Privacy
    }
    return false;
  }

  static createSetupSleeperForm(bed: Bed, side: number | null, sleeperData: SetupSleeperData | null): FormGroup<SetupSleeperForm> {
    return new FormGroup<SetupSleeperForm>({
      firstName: new FormControl(sleeperData?.firstName ? sleeperData.firstName : null, [Validators.required]),
      birthMonth: new FormControl(sleeperData?.birthMonth ? sleeperData.birthMonth : null),
      birthYear: new FormControl(sleeperData?.birthYear ? sleeperData.birthYear : null, [Validators.required]),
      height: new FormControl(sleeperData?.height ? sleeperData.height : null, [Validators.required]),
      weight: new FormControl(sleeperData?.weight ? sleeperData.weight : null, [Validators.required]),
      sleepGoal: new FormControl(sleeperData?.sleepGoal ? sleeperData.sleepGoal : null, [Validators.required]),
      gender: new FormControl(sleeperData?.gender !== null ? sleeperData?.gender ?? null : null),
      bedId: new FormControl(bed.bedId, [Validators.required]),
      side: new FormControl(bed.isKidsBed ? BedSide.Left : side ?? null, [Validators.required]),
      duration: new FormControl(bed.isKidsBed ? 30 : 0)
    });
  }

  static redirectToTheNextStepInRegistration(store: Store, showToast = false): void {
    const bedsWithoutSleepers = store.selectSnapshot(BedsSelectors.bedsForSetup);
    const shouldRedirectToSelectBed = bedsWithoutSleepers && bedsWithoutSleepers.length > 0;
    const shouldAddSecondSleeper = store.selectSnapshot(SetupSelectors.shouldAddSecondSleeper);
    const setupSleepersFrom = localStorage.getItem('setupSleepersFrom');
    if (shouldAddSecondSleeper === ShouldAddPartnerSleeper.Yes) {
      store.dispatch(new Navigate(['pages/setup/setup-partner']));
      this.setSiqNotification(store, showToast, 'set up sleep partner');
    } else if (shouldRedirectToSelectBed && !setupSleepersFrom) {
      store.dispatch(new Navigate(['pages/setup/select-bed']));
      this.setSiqNotification(store, showToast, 'select bed');
    } else {
      if (setupSleepersFrom) {
        store.dispatch(new Navigate(['pages/setup/congratulation']));
        this.setSiqNotification(store, showToast, 'congratulation');
      } else {
        store.dispatch(new Navigate(['pages/setup/sleep-research']));
        this.setSiqNotification(store, showToast, 'sleep research');
      }
    }
  }

  static isUnconfiguredBed(unconfiguredBeds: Array<Bed>, selectedBed: Bed): boolean {
    return !!unconfiguredBeds.find(bed => bed.bedId === selectedBed.bedId);
  }

  static isDateBefore(date1: string, date2: string): boolean {
    return moment(date1).isBefore(moment(date2));
  }

  static getProceedSkipActionFlow(): string {
    const addSleepersFrom = localStorage.getItem('setupSleepersFrom');
    switch (addSleepersFrom) {
      case 'pages/smart-bed/details/sleeper-setup':
        return 'bed_settings';
      case 'pages/account-settings/details/orders':
        return 'account_settings';
      case 'pages/sleep':
        return 'sleeper_switcher';
      default:
        return 'registration';
    }
  }

  static getBiosignalProperty(selectedTab: string): string {
    switch (selectedTab) {
      case 'Heart Rate':
        return 'heart';
      case 'Breath Rate':
        return 'breath';
      case 'Heart Rate Variability':
        return 'hrv';
      default:
        return '';
    }
  }

  static decodeJWT(token: string): Record<string, any> {
    const payload = token.split('.')[1];
    const wordArray = CryptoJS.enc.Base64.parse(payload);
    const str = CryptoJS.enc.Utf8.stringify(wordArray);
    return JSON.parse(str);
  }

  //#region Private helper functions

  private static createNotAvailableClass(iconClass: string, isBiosignalCard: boolean): string {
    return `${iconClass} ${isBiosignalCard ? 'pb-16' : 'wh-48'}`;
  }

  private static getSessionValue(session: SessionModel | NoDataSession, prop: string): number {
    if (session.isSession) {
      const tmp = session as SessionModel;
      if (prop === 'hrv') {
        const hrv = tmp.hrv;
        if (tmp.hrvActionCode !== null || hrv < 0) {
          return 0;
        }
        return hrv;
      } else {
        return tmp[prop] < 0 ? 0 : tmp[prop];
      }
    }
    return 0;
  }

  private static shouldRetry(error: HttpErrorResponse): boolean {
    return (error.status === 400 && error.error.data.code === 'TooManyRequestsException') || (error.status >= 500) || !navigator.onLine;
  }

  private static setSiqNotification(store: Store, showToast: boolean, sourceScreen: string): void {
    if (showToast)
      store.dispatch(new SetSiqNotification(new SiqNotification('Invitation sent!', sourceScreen, 'registration')));
  }

  //#endregion
}