import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { environment } from '@environments/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { TokenInterceptor } from '@services/token.interceptor';
import { LoadAppConfig } from '@store/app/app.actions';
import { AppState } from '@store/app/app.state';
import { AuthState } from '@store/auth/auth.state';
import { firstValueFrom } from 'rxjs';
import { TestComponent } from './services/tests/components/test/test.component';

export const appState = [
  AuthState,
  AppState
];

export function initializeApp(store: Store) {
  return (): Promise<any> => {
    return firstValueFrom(store.dispatch(new LoadAppConfig()))
      .catch(() => Promise.resolve());
  };
}

@NgModule({
  declarations: [
    TestComponent
  ],
  imports: [
    NgxsModule.forRoot(appState, {
      compatibility: {
        strictContentSecurityPolicy: true
      }
    }),
    NgxsStoragePluginModule.forRoot({
      key: ['app', 'auth', 'register', 'taco', 'setup'],
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    MatDialogModule
  ],
  exports: [
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [Store],
      multi: true
    }
  ]
})
export class AppCoreModule { }