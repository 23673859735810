import { Component } from '@angular/core';
import { SiqCommonModule } from '@shared/siq-common.module';
import { LearnMoreContentComponent } from '../learn-more-content/learn-more-content.component';
import { NavigationModule } from '@shared/navigation.module';
import { ProfileStringResource } from '@shared/utils/helpers/profile.helper';
import { LinksHelper } from '@shared/utils/helpers/links-helper';

@Component({
  selector: 'app-learn-more',
  templateUrl: './learn-more.component.html',
  styleUrls: ['./learn-more.component.scss'],
  standalone: true,
  imports: [SiqCommonModule, LearnMoreContentComponent, NavigationModule]
})
export class LearnMoreComponent {
  tabs: Array<string> = ['Sleep Research', 'How it Works', 'Your Privacy'];
  selectedTab = 0;
  sleepStudyInfo = ProfileStringResource.sleepStudyLearnMoreInfo();
  sleepStudyLearnMoreListItems = ProfileStringResource.sleepStudyLearnMoreListItems();
  privacyLink = LinksHelper.privacyPolicy;

  get listItemsTitle(): string | null {
    return this.selectedTab === 0 ? 'Why It Matters' : null;
  }

  get showPrivacyLink(): boolean {
    return this.selectedTab === 2;
  }
  
  selectTab(selectedTab: number): void {
    this.selectedTab = selectedTab;
  }
}
