const MIN_AGE_FOR_ADULT = 14;

export class YearHelper {
  static monthOptionsArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  static monthValuesArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  static get currentYear(): number {
    return (new Date()).getFullYear();
  }

  static get minBirthYearKid(): number {
    return this.currentYear - MIN_AGE_FOR_ADULT;
  }

  static get birthYearArr(): Array<string> {
    return this.getValues(this.currentYear - MIN_AGE_FOR_ADULT - 100, this.currentYear - MIN_AGE_FOR_ADULT, 'options') as Array<string>;
  }

  static get birthYearValuesArr(): Array<number> {
    return this.getValues(this.currentYear - MIN_AGE_FOR_ADULT - 100, this.currentYear - MIN_AGE_FOR_ADULT, 'value') as Array<number>;
  }

  static get birthYearKidArr(): Array<string> {
    return this.getValues(this.currentYear - MIN_AGE_FOR_ADULT, this.currentYear - 4, 'options') as Array<string>;
  }

  static get birthYearKidValuesArr(): Array<number> {
    return this.getValues(this.currentYear - MIN_AGE_FOR_ADULT, this.currentYear - 4, 'value') as Array<number>;
  }

  static checkKidYears(years: string): boolean {
    return this.currentYear - parseInt(years) <= 14;
  }

  private static getValues(min: number, max: number, type?: string): Array<string | number> {
    const arr = [];
    for (let i = min; i <= max; i++) {
      if(type === 'options')
        arr.push(i.toString());
      else 
        arr.push(i);
    }
    return arr;
  }
}
