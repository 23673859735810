import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { WhyIsThisImportant } from '@models/app/why-is-this-important.model';
import { CarouselService } from '@services/carousel.service';
import { ResizeService } from '@services/resize.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-siq-carousel',
  templateUrl: './siq-carousel.component.html',
  styleUrls: ['./siq-carousel.component.scss'],
})
export class SiqCarouselComponent implements AfterViewInit {

  @Input() carouselItems: Array<WhyIsThisImportant>;
  @Output() closeCarousel = new EventEmitter<void>();

  @ViewChild('fullBleed') fullBleed: ElementRef;
  @ViewChild('slideWrapper') slideWrapper: ElementRef;

  private _unsubscribeAll = new Subject<void>();

  constructor(private renderer2: Renderer2, private resizeService: ResizeService, private carouselService: CarouselService) { }

  ngAfterViewInit(): void {
    this.resizeService.isMobile.pipe((
      takeUntil(this._unsubscribeAll)
    )).subscribe((breakpointState) => {
      // makes carousel responsive
      const middleColumnWidth = document.getElementsByClassName('middle-column')[0].clientWidth;
      // convert pixels to vw
      const fullBleedWidthToVW = (middleColumnWidth / breakpointState.windowWidth) * 100;
      const fullBleedHTMLElement = this.fullBleed.nativeElement as HTMLElement;
      this.renderer2.setStyle(fullBleedHTMLElement, 'width', `${fullBleedWidthToVW}vw`);
    });

    const slideItems = this.fullBleed.nativeElement.querySelectorAll('.slidewrapper');
    this.carouselService.observeItems(slideItems);
  }

  get elementInTheViewId(): number {
    return this.carouselService.elementInTheViewId;
  }

  get isLastElementActive(): boolean {
    return this.elementInTheViewId === this.carouselItems.length - 1;
  }
  
  get controlAlignment(): string {
    return this.isLastElementActive ? 'end center': 'center center';
  }

  scroll(direction: number): void {
    this.carouselService.scroll(direction, this.carouselItems.length);
  }

  isElementActive(id: number): boolean {
    return this.carouselService.isElementActive(id);
  }

  scrollTo(id: number): void {
    this.carouselService.scrollTo(id);
  }

  // When user clicks on the dot the requirement is that the user should move -/+ one place, not on the selected dot
  scrollOnDot(id: number): void {
    this.carouselService.scrollOnDot(id, this.carouselItems.length);
  }

  done(): void {
    this.closeCarousel.emit();
  }
}
