import { Component, OnDestroy, OnInit } from '@angular/core';
import { Bed } from '@models/bed/bed.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { BedSettingsResources } from '@shared/utils/helpers/bed-settings.helper';
import { PrivacyMode } from '@shared/utils/helpers/enum.helper';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { SetPrivacyModeBannerState } from '@store/app/app.actions';
import { SetBedPrivacyMode } from '@store/beds/beds.actions';
import { BedsSelectors } from '@store/beds/beds.selectors';
import { SettingsSelectors } from '@store/settings/settings.selectors';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-privacy-mode',
  templateUrl: './privacy-mode.component.html',
  styleUrls: ['./privacy-mode.component.scss']
})
export class PrivacyModeComponent implements OnInit, OnDestroy {

  selectedBed: Bed;
  informationText = BedSettingsResources.PrivacyMode.informationText;
  privacySetting = false;
  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private mixpanelService: MixpanelService) { }

  ngOnInit(): void {
    this.mixpanelService.trackPrivacyModeOpen(window.history.state.sourceScreen);
    this.store.select(SettingsSelectors.selectedBed).pipe(
      takeUntil(this._unsubscribeAll),
      filter((bed): bed is Bed => !!bed)
    ).subscribe((bed) => {
      this.selectedBed = bed;
      this.privacySetting = this.isPrivacyOn;
    });
  }

  get bedImage(): string {
    if(this.selectedBed.dualSleep) {
      return this.isPrivacyOn ? 'dual-bed-gray-icon big': 'dual-bed-icon big';
    }
    return this.isPrivacyOn ? 'single-bed-gray-icon big': 'single-bed-icon big';
  }

  get privacyStatus(): string {
    return this.isPrivacyOn ? BedSettingsResources.PrivacyMode.active: BedSettingsResources.PrivacyMode.paused;
  }

  get selectedBedPrivacyMode(): string {
    const privacyModeStatus = this.privacyModeStatusForSelectedBed;

    // Off would show in case API fails or something happens that pauseMode is not set
    return privacyModeStatus ? FunctionsHelper.capitalizeFirstLetter(privacyModeStatus) : 'Off';
  }

  private get isPrivacyOn(): boolean {
    return this.privacyModeStatusForSelectedBed === PrivacyMode.On;
  }

  private get privacyModeStatusForSelectedBed(): PrivacyMode | null {
    return this.store.selectSnapshot(BedsSelectors.getPrivacyStatusForBed)(this.selectedBed.bedId);
  }

  back(): void {
    this.store.dispatch(new Navigate(['pages/smart-bed/bed-view']));
  }

  updatePrivacy(privacy: boolean): void {
    this.privacySetting = privacy;
    const setting = privacy ? PrivacyMode.On: PrivacyMode.Off;
    this.store.dispatch(new SetBedPrivacyMode(this.selectedBed.bedId, setting)).subscribe({
      next: () => {
        this.store.dispatch(new SetPrivacyModeBannerState(setting));
        this.mixpanelService.trackPrivacyModeToggle(setting);
      },
      error: () => {
        // Since nothing is defined for the error state, since fuzion bed's do not work if there are offline
        // proposal is to set it to false
        this.privacySetting = false;
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
