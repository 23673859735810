export class WifiNetworkModel {
  ssid: string;
  password: string;
  protocol?: number;
  eap?: number;
  identity?: string;
}

export class WifiNetwork {
  accountId: string;
  eap: number;
  identity: string;
  name?: string;
  password: string;
  protocol: number;
  ssid: string;
  wifiId: string;
}

export class WifiNetworks {
  networks: Array<WifiNetwork>;
}

export class WifiNetworkResponse {
  name: string;
  wifiId: string;
}

export class DeletedNetworkResponse {
  accountId: string;
  wifiId: string;
}

export class WifiStatus {
  bedId?: string;
  bssid: string;
  ssid: string;
  security: string;
  quality: number;
  signal: number;
  state: number;
}

