<div siqGrid fx-direction="column">
  <div siqGrid fx-direction="column" fx-layout-align="center center">
    <span class="siq-text-900 fs-24 white text-center mt-24">{{ title }}</span>
    <span class="siq-text-400 fs-16 white text-center mt-12">{{ subtitle }}</span>
  </div>

  <form class="mt-48 w-100-p" siqGrid fx-direction="column" [formGroup]="inviteUserForm">
    <app-siq-input
      class="px-12"
      formControlName="email"  
      name="email"
      type="email" 
      label="Email Address"
      placeholder="Enter their email address"
      [isInvalid]="hasErrors"
      (controlFocus)="setControlFocusState($event)"/>
    <span *ngIf="hasErrors" class="siq-text-400 fs-14 red mt-12 px-12">Please enter a valid email address.</span>
  </form>

  <div siqGrid fx-direction="column" class="py-48">
    <span siqGrid fx-layout-align="center center" class="siq-text-900 fs-24 white">Additional Benefits</span>
    <div siqGrid>
      <div siqGrid fx-layout-align="center start">
        <app-siq-list-items [items]="additionalBenefits" itemsLayoutAlign="center start" textMargin="ml-12"/>
      </div>
    </div>
  </div>

  <div siqGrid fx-direction="row" fx-layout-align="end center" fx-layout-align-sm="center center" class="px-12">
    <app-siq-button
      text="Send Invitation"
      btnType="primary-btn"
      [width]="'178px'"
      [isDisabled]="!inviteUserForm.valid"
      (buttonClicked)="onSendInvitation()" />
  </div>
</div>
