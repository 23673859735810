<div siqGrid fx-direction="column" fx-layout-align="start start">
  <span aria-hidden="true" class="mb-12 siq-text-400 fs-16 white"> {{label}} </span>
  <div role="region" class="input-container w-100-p">
    <ng-container *ngIf="isMaskedInput else otherInputs">
      <input 
        id="siq-input"
        appInputFormat
        maxlength="12"
        class="input-field-sn background-transparent siq-text-400 fs-16 white w-100-p" 
        [attr.aria-label]="ariaLabel"
        [tabIndex]="tabIndex"
        [ngClass]="borderClass" 
        type="text" 
        (focus)="onFocus()"
        (blur)="onBlur()"
        [placeholder]="placeholder" 
        [(ngModel)]="control"/>
    </ng-container>
    <ng-template #otherInputs>
      <input 
        id="siq-input"
        class="input-field-sn background-transparent siq-text-400 fs-16 white w-100-p" 
        [tabIndex]="tabIndex"
        [attr.aria-label]="ariaLabel"
        [ngClass]="borderClass" 
        [type]="type" 
        (focus)="onFocus()"
        (blur)="onBlur()"
        [placeholder]="placeholder" 
        [attr.maxlength]="maxLength"
        [(ngModel)]="control"/>
    </ng-template>
    <ng-container *ngIf="isPasswordField else resetIcon">
      <div aria-hidden="true" tabindex="-1"  *ngIf="type === 'text' && isPasswordField" (click)="setPasswordType('password')"
      class="input-icon show-password-icon cursor-pointer"></div>
      <div aria-hidden="true" tabindex="-1"  *ngIf="type === 'password'" (click)="setPasswordType('text')"
      class="input-icon hide-password-icon cursor-pointer"></div>
    </ng-container>
    <ng-template #resetIcon>
      <div aria-hidden="true" tabindex="-1" *ngIf="showResetInput" class="input-icon cursor-pointer" (mousedown)="resetField($event)">
        <span class="close-white-icon"></span>
      </div>
    </ng-template>
  </div>
</div>