import { ListItem, SleepScienceLearnMore } from "@models/app/helpers.model";
import { Sleeper } from "@models/sleeper/sleeper.model";
import { SleeperWellnessQuestion } from "@models/sleeper/wellness-profile.model";
import { HeightHelper } from "./height.helper";
import { SleepGoalHelper } from "./sleep-goal.helper";
import { WeightHelper } from "./weight.helper";
import { YearHelper } from "./year.helper";

export class ProfileStringResource {

  static profileIcons(): string[] {
    return ['sleeper', 'home', 'habits', 'heart'];
  }

  static profileHomeText(): string {
    return 'We personalize insights based on your wellness profile questions.';
  }

  static sleepScienceResearchText(isProfile = false): string {
    return `Participate in research studies to advance the science of sleep and health${isProfile ? '.' : ''}`;
  }

  static beAPartOf(): string {
    return 'Be part of something bigger to advance the science of sleep.';
  }

  static loggedInSleeperIsEnrolled(): string {
    return 'You are currently enrolled.';
  }

  static thankYouForEnrolling(): string {
    return 'Thank you for advancing scientific discoveries for the greater community.';
  }

  static sleeperIsEnrolled(sleeperName: string | undefined): string {
    return `${sleeperName} is currently enrolled`;
  }

  static logInAsSleeper(sleeperName: string | undefined): string {
    return `Log in as ${sleeperName} to change enrollment status.`;
  }

  static selectedSleeperDoesNotHaveEmail(sleeperName: string | undefined): string {
    return `To participate in sleep research, you will need to create a Sleep Number<sup>®</sup> login for ${sleeperName}.`;
  }

  static selectedSleeperHasEmail(sleeperName: string | undefined): string {
    return `To participate in research, you need to log into Sleep Number<sup>®</sup> with ${sleeperName}'s email and password.`;
  }

  static sleepStudyLearnMoreInfo(): Array<SleepScienceLearnMore> {
    return [
      { icon: 'sleep-research', title: 'About Sleep Research', description: 'Research completed by Sleep Number<sup>®</sup> and research in collaboration with Mayo Clinic is intended to advance the science of sleep—and health—by making new discoveries to improve sleep quality for the greater community.' } as SleepScienceLearnMore,
      { icon: 'how-it-works', title: 'How It Works' } as SleepScienceLearnMore,
      { icon: 'your-privacy', title: 'How Your Data Is Secure' } as SleepScienceLearnMore
    ];
  }

  static sleepStudyLearnMoreListItems(): Array<Array<ListItem>> {
    return [
      [
        { icon: 'check-icon wh-14 px-4 mb-4', text: 'Insufficient sleep has been linked to heart disease, obesity, type 2 diabetes and depression.', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
        { icon: 'check-icon wh-14 px-4 mb-4', text: 'One-in-three people do not get enough quality sleep.', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
        { icon: 'check-icon wh-14 px-4 mb-4', text: 'Quality sleep is vital to our physical, mental and emotional health.', textClasses: 'siq-text-400 fs-16 white' } as ListItem
      ],
      [
        { icon: 'sleep-science-bed', text: 'Simply sleep in your Sleep Number<sup>®</sup> bed as you normally do.', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
        { icon: 'sleep-science-edit', text: 'We will occasionally send you short email surveys. You can opt-out at any time.', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
        { icon: 'sleep-science-hrv', text: 'With your consent, sleep researchers will analyze sleep data and trends.', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
        { icon: 'sleep-science-bulb', text: 'Your survey answers and sleep data will advance the science of sleep and health.', textClasses: 'siq-text-400 fs-16 white' } as ListItem
      ],
      [
        { icon: 'check-icon wh-14 px-4 mb-4', text: 'Researchers will not receive names, emails or other personal identifying information.', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
        { icon: 'check-icon wh-14 px-4 mb-4', text: 'Your data will not be sold.', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
        { icon: 'check-icon wh-14 px-4 mb-4', text: 'You can opt-out of the research at any time.', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
        { icon: 'check-icon wh-14 px-4 mb-4', text: 'If you opt-out, any data collected while opted in will be maintained and secured.', textClasses: 'siq-text-400 fs-16 white' } as ListItem
      ]
    ];
  }

  static changeSleepStudySuccessText(isOptIn: boolean): string {
    return isOptIn ? 'Your participation makes a difference.' : 'Your are currently not enrolled.';
  }

  static changeSleepStudySuccessSubtext(isOptIn: boolean): string {
    return isOptIn ?
      'Look for a confirmation email from Research@sleepnumber.com to your email below.' :
      'Thank you for your participation.';
  }

  static optOutSuccessMessage(): string {
    return 'Please allow 48 hours for unenrollment to take effect. Any data collected while opted in will be maintained and secured.';
  }

  static getSleeperWellnessProfileQuestions(sleeper: Sleeper): Array<SleeperWellnessQuestion> {
    const sleeperQuestions = [];
    const birthYearOptions = sleeper.isChild ? YearHelper.birthYearKidArr: YearHelper.birthYearArr;
    const birthYearValues = sleeper.isChild ? YearHelper.birthYearKidValuesArr: YearHelper.birthYearValuesArr;
    const weightOptions = sleeper.isChild ? WeightHelper.weightOptionsKidArr : WeightHelper.weightOptionsArr;
    const weightValues = sleeper.isChild ? WeightHelper.weightValuesKidArr : WeightHelper.weightValuesArr;

    sleeperQuestions.push(new SleeperWellnessQuestion('firstName', [], [], 'What’s your name?', '', sleeper.firstName, 2));
    sleeperQuestions.push(new SleeperWellnessQuestion('birthYear', birthYearOptions, birthYearValues, 'What year were you born?', '', sleeper.birthYear));
    if (sleeper.isChild) {
      sleeperQuestions.push(new SleeperWellnessQuestion('birthMonth', YearHelper.monthOptionsArr, YearHelper.monthValuesArr, 'What month were you born?', '', sleeper.birthMonth));
    }
    sleeperQuestions.push(new SleeperWellnessQuestion('height', HeightHelper.heightOptionsArr, HeightHelper.heightValuesArr, 'How tall are you?' ,'',  sleeper.height));
    sleeperQuestions.push(new SleeperWellnessQuestion('weight', weightOptions, weightValues, 'How much do you weigh?', '', sleeper.weight));
    if (!sleeper.isChild) {
      sleeperQuestions.push(new SleeperWellnessQuestion('gender', ['Male', 'Female', 'Non-Binary', 'Prefer not to say'], [1, 0, 2, 9], 'What gender do you identify as?', '(Optional)', sleeper.gender));
      sleeperQuestions.push(new SleeperWellnessQuestion('sleepGoal', SleepGoalHelper.sleepGoalOptionsAdultArr, SleepGoalHelper.sleepGoalValuesAdultArr, 'What is your sleep goal?', 'A good place to start is how long you typically sleep each night.', sleeper.sleepGoal));
    }
    return sleeperQuestions;
  }
}