import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BedStatusEntity } from '@models/bed/bed-status.model';
import { BedEntity, BedPrivacyMode, UpdateBedProperty } from '@models/bed/bed.model';
import { catchError, map, Observable, throwError } from 'rxjs';
import { EnvironmentsService } from './environments.service';
import { MixpanelService } from './mixpanel.service';
import { SiqApiService } from './siq-api.service';
import { SplashScreenService } from './splash-screen.service';
import { SetupBedData } from '@models/app/helpers.model';

@Injectable({
  providedIn: 'root'
})
export class BedService extends SiqApiService {

  constructor(
    httpClient: HttpClient,
    environmentService: EnvironmentsService,
    mixpanelService: MixpanelService,
    dialog: MatDialog,
    splashScreenService: SplashScreenService) {
    super(httpClient, environmentService, mixpanelService, dialog, splashScreenService);
  }

  getBeds(): Observable<BedEntity> {
    return this.makeSiqRequest<BedEntity>('bed', 'siq', {}, 'GET', false).pipe(
      map((response: BedEntity | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  getBedStatus(): Observable<BedStatusEntity> {
    return this.makeSiqRequest<BedStatusEntity>('bed/familyStatus/', 'siq', {}, 'GET', false).pipe(
      map((response: BedStatusEntity | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  updateBed(bedId: string, data: UpdateBedProperty | SetupBedData): Observable<object> {
    return this.makeSiqRequest<object>(`bed/${bedId}`, 'siq', data, 'PUT', false).pipe(
      map((response: object | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  baselineBed(sleeperId: string): Observable<object> {
    return this.makeSiqRequest<object>(`sleeper/${sleeperId}/calibrate`, 'siq', {}, 'PUT', false).pipe(
      map((response: object | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  getBedPauseMode(bedId: string): Observable<BedPrivacyMode> {
    return this.makeSiqRequest<BedPrivacyMode>(`bed/${bedId}/pauseMode`, 'siq', {}, 'GET', false).pipe(
      map((response: BedPrivacyMode | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  setBedPauseMode(bedId: string, pauseMode: string): Observable<BedPrivacyMode> {
    return this.makeSiqRequest<BedPrivacyMode>(`bed/${bedId}/pauseMode?mode=${pauseMode}`, 'siq', {}, 'PUT', false).pipe(
      map((response: BedPrivacyMode | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }
}
