<div class="cursor-pointer background-primary p-12 br-8" (click)="changeDrawerState()">
  <div siqGrid fx-direction="row" fx-layout-align="space-between center" class="w-100-p"> 
    <span class="siq-text-900 fs-16 white">{{ drawerTitle }}</span>
    <img [src]="arrowIcon" alt="icon" class="arrow-icon" />
  </div>
  <div *ngIf="isExpanded" [ngSwitch]="drawerType">
    <div *ngSwitchCase="'whyIsImportant'" class="pt-24">
      <span class="siq-text-400 fs-16 white" [innerHtml]="whyIsImportantText"></span>
    </div>

    <div *ngSwitchCase="'waysToImprove'" class="pt-12">
      <div *ngFor="let wayToImprove of waysToImproveContent; index as i">
        <div class="py-12" [ngClass]="{'border-bottom-400': i < waysToImproveContent.length - 1 }">
          <div siqGrid fx-direction="row" fx-layout-align="start center">
            <span *ngIf="showSleepNumberSetting(i); else improveIcon" class="siq-text-800 fs-26 white">{{ latestSleepNumberSetting$ | async }}</span>
            <ng-template #improveIcon>
              <img [src]="getWayToImproveIcon(wayToImprove.iconTitle)" alt="icon" class="way-to-improve-icon"/>
            </ng-template>
            <span class="siq-text-400 fs-16 white ml-16" [innerHtml]="wayToImprove.text"></span>
          </div>
          <app-siq-button *ngIf="i === 0 && checkTrendsType('Duration')" 
            siqGrid fx-direction="row" fx-layout-align="end center"
            class="mt-8"
            text="Edit Sleep Goal"
            btnType="tertiary-btn"
            (buttonClicked)="editSleepGoal()"/>
        </div>
      </div>
    </div>
  
    <div *ngSwitchCase="'howWeCalculate'" class="pt-12">
      <div siqGrid fx-direction="column">
        <span class="siq-text-400 white" [ngClass]="checkTrendsType('Duration') ? 'fs-16' : 'fs-14'">{{ howWeCalculateText.description }}</span>
        <span class="siq-text-900 white fs-16 mt-24 mb-12">{{ howWeCalculateText.subtitle }}</span>
        <div *ngIf="!checkTrendsType('Timing')" siqGrid fx-direction="row" fx-layout-align="space-between center">
          <div siqGrid fx-direction="row" fx-layout-align="center center">
            <div [ngClass]="checkTrendsType('Duration') ? 'duration-goal-blue-icon' : 'green-rectangle background-electric br-2'"></div>
            <span class="siq-text-900 fs-24 white ml-8">{{ trendsData | timeConverter: timeConverterFormat }}</span>
          </div>
          <app-siq-button *ngIf="checkTrendsType('Duration')" 
            siqGrid fx-direction="row" fx-layout-align="end center"
            class="mt-8"
            text="Edit Sleep Goal"
            btnType="tertiary-btn"
            [isPressed]="isButtonClicked"
            (buttonClicked)="editSleepGoal()"/>
        </div>
        <div *ngIf="checkTrendsType('Timing')">
          <div *ngIf="idealTimes else noIdealSchedule" siqGrid fx-direction="row" fx-layout-align="center center" class="mt-12">
            <app-ideal-timing 
              [data]="idealTimes"
              rightText="Ideal bedtime"
              leftText="Ideal wake time"
              bedtimeIcon="bedtime-blue-icon"
              wakeTimeIcon="wake-time-blue-icon"
              textClass="siq-text-900 fs-16 white mt-12"
              subTextBedtimeClass="siq-text-900 fs-16 rain-blue mt-4"
             />
          </div>
          <ng-template #noIdealSchedule>
            <span class="siq-text-400 fs-14 white" [innerHtml]="noIdealScheduleMessage"></span>
          </ng-template>
          <app-siq-button
            siqGrid fx-direction="row" fx-layout-align="end center"
            class="mt-44"
            text="See My Circadian Rhythm"
            btnType="tertiary-btn"
            [isPressed]="isButtonClicked"
            (buttonClicked)="seeCircadianRhythm()"/>
        </div>
      </div>
    </div>
  </div>
</div>
