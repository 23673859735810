export class BiqUser {
  sleeperInfo: BiqDetails;
  sleepPartner?: BiqDetails;

  constructor(user: BiqUser) {
    this.sleeperInfo = new BiqDetails(user.sleeperInfo);

    if (user.sleepPartner) {
      this.sleepPartner = new BiqDetails(user.sleepPartner);
    }
  }
}

export class BiqDetails {
  gender: number;
  height: number;
  weight: number;
  birthYear: string;
  firstName: string;
  email?: string;

  constructor(details: BiqDetails) {
    this.gender = details.gender;
    this.height = details.height;
    this.weight = details.weight;
    this.firstName = details.firstName;
    this.birthYear = details.birthYear;

    if (details.email) {
      this.email = details.email;
    }
  }
}