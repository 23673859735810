import { NgModule } from "@angular/core";
import { ButtonsModule } from "./buttons.module";
import { AppDownloadCardComponent } from "./components/app-download-card/app-download-card.component";
import { SiqCarouselComponent } from "./components/siq-carousel/siq-carousel.component";
import { SiqDrawerComponent } from "./components/siq-drawer/siq-drawer.component";
import { ModalModule } from "./modal.module";
import { SiqCommonModule } from "./siq-common.module";
import { IdealTimingComponent } from "./standalone-components/ideal-timing/ideal-timing.component";

const components = [AppDownloadCardComponent, SiqDrawerComponent, SiqCarouselComponent]

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    SiqCommonModule,
    ButtonsModule,
    ModalModule,
    IdealTimingComponent
  ],
  exports: [
    ...components
  ]
})
export class StaticContentModule { }