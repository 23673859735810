import { NgModule } from "@angular/core";
import { SiqCommonModule } from "./siq-common.module";
import { PopupComponent } from "./components/popup/popup.component";
import { EditSleepGoalModalComponent } from "./components/edit-sleep-goal-modal/edit-sleep-goal-modal.component";
import { ButtonsModule } from "./buttons.module";
import { FormsModule } from "./forms.module";

const components = [PopupComponent, EditSleepGoalModalComponent]

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    SiqCommonModule,
    FormsModule,
    ButtonsModule
  ],
  exports: [
    ...components
  ]
})
export class ModalModule { }