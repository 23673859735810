import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-wellness-profile-category',
  templateUrl: './wellness-profile-category.component.html',
  styleUrls: ['./wellness-profile-category.component.scss'],
})
export class WellnessProfileCategoryComponent {
  @Input() category: string; // sleeper, home, habits, sleep-health
  @Input() allQuestions: number;
  @Input() answeredQuestions: number;
  @Output() categoryClicked = new EventEmitter<string>();

  get categoryText(): string {
    return this.category.split('-').join(' ');
  }

  get isFinished(): boolean {
    return this.answeredQuestions === this.allQuestions;
  }

  get progressWidth(): object {
    return { width: `${(this.answeredQuestions / this.allQuestions) * 100}%` };
  }

  getIcon(isCheckIcon?: boolean): string {
    return `assets/icons/${this.category}${isCheckIcon ? '-check-icon' : ''}.svg`;
  }

  selectCategory(): void {
    this.categoryClicked.emit(this.category);
  }
}
