<div siqGrid fx-direction="row" fx-layout-align="space-between end" class="background-primary br-8" [class.pr-48]="!(breakpoints$ | async)?.isSmallScreen">
  <div siqGrid fx-direction="column" class="pt-12 px-12">
    <span class="siq-text-900 fs-18 white">Download the App</span>
    <span class="siq-text-400 fs-14 white mt-8">{{ text }}</span>
    <div siqGrid fx-direction="row" fx-direction-xs="column" fx-layout-align="space-between end" fx-layout-align-xs="center center">
      <div class="mt-24 pb-12"> 
        <app-store-btns direction="row" smallScreen="column" mediumScreen="column" [isAppDownload]="true" sourceScreen="login" />
      </div>
      <img *ngIf="showPhoneSmallScreen" [src]="icon" alt="icon"/>
    </div>
  </div>
  <img *ngIf="showPhoneBigScreen" [src]="icon" alt="icon"/>
</div>
