import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BedStatus, BedStatusEntity } from "@models/bed/bed-status.model";
import { Bed, BedEntity, BedPrivacyMode } from "@models/bed/bed.model";
import { Action, State, StateContext } from "@ngxs/store";
import { insertItem, patch, updateItem } from "@ngxs/store/operators";
import { BedService } from "@services/bed.service";
import { FuzionService } from "@services/fuzion.service";
import { SplashScreenService } from "@services/splash-screen.service";
import { FuzionPrivacyMode, PrivacyMode } from "@shared/utils/helpers/enum.helper";
import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import { SiqPopupHelper } from "@shared/utils/helpers/siq-popup.helper";
import { Observable, Subject, catchError, from, map, switchMap, takeUntil, tap, throwError, timer } from "rxjs";
import { SleepiqDataState } from "src/app/core/fuzion/enums";
import { GetSleepIQPrivacyStateResponse, SetSleepIQPrivacyStateResponse } from "src/app/core/fuzion/model";
import { GetBedPrivacyMode, LoadBedStatus, LoadBeds, RefreshBeds, ResetBedState, SetBedPrivacyMode, UnsubscribeFromBedStatus, UpdateBed } from "./beds.actions";
import { BedsStateModel, defaultBedState } from "./beds.model";
import { PrivacyModeStatus } from "@models/bed/privacy-status.model";

@State<BedsStateModel>({
  name: 'beds',
  defaults: defaultBedState
})
@Injectable()
export class BedsState {
  private _unsubscribeAll = new Subject<void>();

  constructor(
    private bedService: BedService,
    private splashScreenService: SplashScreenService,
    private fuzionService: FuzionService,
    private siqPopupHelper: SiqPopupHelper) { }

  @Action(LoadBeds)
  loadBeds(ctx: StateContext<BedsStateModel>): Observable<BedEntity | HttpErrorResponse> {
    ctx.patchState({ loading: true });
    this.splashScreenService.setBedsLoading(true);
    this.splashScreenService.setSessionLoading(true);
    this.splashScreenService.setAuthLoading(false);
    return this.getBeds(ctx, true);
  }

  @Action(RefreshBeds)
  refreshBeds(ctx: StateContext<BedsStateModel>): Observable<BedEntity | HttpErrorResponse> {
    ctx.patchState({ loading: true });
    this.splashScreenService.setBedsLoading(false);
    this.splashScreenService.setSessionLoading(false);
    this.splashScreenService.setAuthLoading(false);
    return this.getBeds(ctx, false);
  }

  @Action(LoadBedStatus)
  loadBedStatus(ctx: StateContext<BedsStateModel>): Observable<BedStatusEntity> {
    ctx.patchState({ loading: true });
    return timer(0, 30000).pipe(
      takeUntil(this._unsubscribeAll),
      switchMap(() => this.bedService.getBedStatus().pipe(
        tap({
          next: (response: BedStatusEntity) => {
            ctx.patchState({ bedsStatus: response.beds.map((bed: BedStatus) => new BedStatus(bed)), loading: false });
          },
          error: error => this.handleError(ctx, error)
        })
      )
      )
    );
  }

  @Action(UpdateBed)
  updateBed(ctx: StateContext<BedsStateModel>, action: UpdateBed): Observable<object> {
    return this.bedService.updateBed(action.bedId, action.payload).pipe(
      tap({
        next: () => {
          let updatedBed = ctx.getState().beds.find((bed) => bed.bedId === action.bedId);
          if (updatedBed) {
            Object.keys(action.payload).forEach((key) => {
              updatedBed = Object.assign(new Bed(), { ...updatedBed, [key]: action.payload[key] });
            });
            ctx.setState(
              patch({
                beds: updateItem((data: Bed) => data.bedId === action.bedId, updatedBed)
              })
            );
          }
        },
        error: error => this.handleError(ctx, error)
      })
    );
  }

  @Action(UnsubscribeFromBedStatus)
  unsubscribeFromBedStatus(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  @Action(GetBedPrivacyMode)
  getBedPrivacyMode(ctx: StateContext<BedsStateModel>, action: GetBedPrivacyMode): Observable<BedPrivacyMode | GetSleepIQPrivacyStateResponse> | void {
    const selectedBed = ctx.getState().beds.find((bed) => bed.bedId === action.bedId);
    if (selectedBed) {
      const pauseMode = selectedBed.isFuzion ? this.fuzionService.getPauseMode(selectedBed.accountId, selectedBed.bedId) : this.bedService.getBedPauseMode(selectedBed.bedId);
      if (selectedBed?.isFuzion && !selectedBed.isOnline) {
        return;
      }
      return from(pauseMode).pipe(
        tap({
          next: (response: BedPrivacyMode | GetSleepIQPrivacyStateResponse) => {
            const setting = selectedBed.isFuzion ?
              this.mapFuzionPrivacyToNonFuzion((response as GetSleepIQPrivacyStateResponse).sleepiqPrivacyState) :
              (response as BedPrivacyMode).pauseMode;
            const bedWithPrivacyModeStatus = ctx.getState().privacyModeStatus?.find(privacyModeStatus => privacyModeStatus.bedId === action.bedId);
            const privacyStatus = new PrivacyModeStatus({ bedId: action.bedId, pauseMode: setting });
            if (bedWithPrivacyModeStatus) {
              // update existing privacy status
              ctx.setState(
                patch({
                  privacyModeStatus: updateItem((privacyModeStatus) => privacyModeStatus.bedId === action.bedId, privacyStatus)
                })
              );
            } else {
              // add new privacy status
              ctx.setState(
                patch({
                  privacyModeStatus: insertItem(privacyStatus)
                })
              );
            }
          },
          error: (err) => this.handleError(ctx, err)
        }),
      );
    }
  }

  @Action(SetBedPrivacyMode)
  setBedPrivacyMode(ctx: StateContext<BedsStateModel>, action: SetBedPrivacyMode): Observable<BedPrivacyMode | SetSleepIQPrivacyStateResponse> {
    const bed = ctx.getState().beds.find((bed) => bed.bedId === action.bedId);
    // @ts-expect-error bed won't be null here
    const pauseMode = bed.isFuzion ? this.fuzionService.setPauseMode(bed.accountId, bed.bedId, FuzionPrivacyMode[action.mode]) : this.bedService.setBedPauseMode(bed.bedId, action.mode);
    return from(pauseMode).pipe(
      tap({
        next: () => ctx.dispatch(new GetBedPrivacyMode(action.bedId)),
        error: (err) => this.handleError(ctx, err)
      }),
    );
  }

  @Action(ResetBedState)
  resetBedState(ctx: StateContext<BedsStateModel>): void {
    ctx.setState({ ...defaultBedState });
  }

  private handleError(ctx: StateContext<BedsStateModel>, error: HttpErrorResponse): Observable<HttpErrorResponse> {
    if (error.status === -400) {
      this.siqPopupHelper.showAlert('Bed', '', error.error.Error.Message);
    } else if (error.status >= 400 && error.status < 500 && error.status !== 401) {
      this.siqPopupHelper.showAlert('Bed');
    }
    ctx.patchState({ error: FunctionsHelper.createSiqError(error.error.Error.Code, error.error.Error.Message), loading: false });
    return throwError(() => error);
  }

  private mapFuzionPrivacyToNonFuzion(setting: string): PrivacyMode {
    return setting === SleepiqDataState.ActiveData ? PrivacyMode.Off : PrivacyMode.On;
  }

  private getBeds(ctx: StateContext<BedsStateModel>, shouldCallStatus: boolean): Observable<BedEntity | HttpErrorResponse> {
    return this.bedService.getBeds().pipe(
      map((response: BedEntity) => {
        this.splashScreenService.setBedsLoading(false);
        if (response.beds.length > 0) {
          ctx.patchState({ beds: response.beds.map((bed: Bed) => new Bed(bed)), loading: false });
          if (shouldCallStatus) {
            ctx.dispatch(new LoadBedStatus());
            response.beds.forEach((bed) => {
              ctx.dispatch(new GetBedPrivacyMode(bed.bedId));
            });
          }
          return response;
        } else {
          // handle this error
          throw FunctionsHelper.createGenericError(-400, 'No bed connected to this account. Please try logging into a different account.');
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.splashScreenService.setBedsLoading(false);
        this.splashScreenService.setSessionLoading(false);
        return this.handleError(ctx, error);
      })
    );
  }
}