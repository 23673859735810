import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { Store } from "@ngxs/store";
import { Logout } from "@store/auth/auth.actions";
import { LoadSleepers } from "@store/sleepers/sleepers.actions";
import { SleepersSelectors } from "@store/sleepers/sleepers.selectors";
import { catchError, map, of } from "rxjs";

export const SleepersGuard: CanActivateFn = () => {
  const store = inject(Store);
  if (store.selectSnapshot(SleepersSelectors.sleepers)?.length === 0) {
    return store.dispatch(new LoadSleepers()).pipe(
      map(() => true),
      catchError(() => store.dispatch(new Logout()))
    );
  } else {
    return of(true);
  }
};