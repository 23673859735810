import { Selector, createSelector } from "@ngxs/store";
import { Bed } from "@models/bed/bed.model";
import { BedsStateModel } from "@store/beds/beds.model";
import { BedsState } from "@store/beds/beds.state";
import { SettingsStateModel } from "./settings.model";
import { SettingsState } from "./settings.state";
import { BedStatus } from "@models/bed/bed-status.model";
import { WifiNetwork, WifiStatus } from "@models/wifi-network/wifi-network.model";
import { SleepersState } from "@store/sleepers/sleepers.state";
import { SleepersStateModel } from "@store/sleepers/sleepers.model";
import { SiqError } from "@models/app/helpers.model";

export class SettingsSelectors {

  @Selector([SettingsState, BedsState])
  static selectedBed(state: SettingsStateModel, bedsState: BedsStateModel): Bed | null {
    return bedsState.beds.find(bed => bed.bedId === state.selectedBedId) ?? null;
  }

  @Selector([SettingsState, BedsState])
  static selectedBedStatus(state: SettingsStateModel, bedsState: BedsStateModel): BedStatus | null {
    return bedsState.bedsStatus.find(bed => bed.bedId === state.selectedBedId) ?? null;
  }

  @Selector([SettingsState])
  static networks(state: SettingsStateModel): Array<WifiNetwork> | null {
    return state.wifiNetworks;
  }

  @Selector([SettingsState, BedsState, SleepersState])
  static connectedNetwork(state: SettingsStateModel, bedState: BedsStateModel, sleeperState: SleepersStateModel): WifiNetwork | null {
    const selectedBed = bedState.beds.find(bed => bed.bedId === sleeperState.selectedSleeper?.bedId);
    const selectedBedWifiStatus = state.wifiStatus.find(wifiStatus => wifiStatus.bedId === selectedBed?.bedId);
    if (selectedBedWifiStatus) {
      return state.wifiNetworks.find(network => network.ssid === selectedBedWifiStatus.ssid) ?? null;
    }

    return null;
  }

  @Selector([SettingsState, BedsState, SleepersState])
  static filteredNetworks(state: SettingsStateModel, bedState: BedsStateModel, sleeperState: SleepersStateModel): Array<WifiNetwork> | null {
    const selectedBed = bedState.beds.find(bed => bed.bedId === sleeperState.selectedSleeper?.bedId);
    const selectedBedWifiStatus = state.wifiStatus.find(wifiStatus => wifiStatus.bedId === selectedBed?.bedId);
    if (!selectedBedWifiStatus) {
      return state.wifiNetworks;
    }
    return state.wifiNetworks.filter(network => network.ssid !== selectedBedWifiStatus.ssid);
  }

  @Selector([SettingsState])
  static loading(state: SettingsStateModel): boolean {
    return state.loading;
  }

  static wifiStatus(bedId: string): (state: SettingsStateModel) => WifiStatus | null {
    return createSelector([SettingsState], (state: SettingsStateModel): WifiStatus | null => {
      return state.wifiStatus.find(wifiStatus => wifiStatus.bedId === bedId) ?? null;
    })
  } 

  @Selector([SettingsState])
  static baselineInProgress(state: SettingsStateModel): boolean {
    return state.baselineInProgress
  }

  @Selector([SettingsState])
  static error(state: SettingsStateModel): SiqError | null {
    return state.error
  }
}