import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationItem } from '@models/app/helpers.model';
import { SleeperInfo } from '@models/auth/sleeper-type.model';
import { Bed } from '@models/bed/bed.model';
import { Sleeper } from '@models/sleeper/sleeper.model';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '@store/auth/auth.selectors';
import { BedsSelectors } from '@store/beds/beds.selectors';
import { SleepersSelectors } from '@store/sleepers/sleepers.selectors';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-left-navigation-item',
  templateUrl: './left-navigation-item.component.html',
  styleUrls: ['./left-navigation-item.component.scss']
})
export class LeftNavigationItemComponent implements OnInit, OnDestroy {
  @Input() item: NavigationItem = new NavigationItem();
  @Output() clickedItem = new EventEmitter<NavigationItem>();
  private _unsubscribeAll = new Subject<void>();
  bed: Bed | null;
  selectedSleeper: Sleeper | null;
  sleeperInfo$: Observable<SleeperInfo | null>;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.sleeperInfo$ = this.store.select(AuthSelectors.sleeperInfo);
    this.store.select(BedsSelectors.selectedSleeperBed).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(bed => this.bed = bed);

    this.store.select(SleepersSelectors.selectedSleeper).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(selectedSleeper => this.selectedSleeper = selectedSleeper);
  }

  get isSmartBed(): boolean {
    return this.checkItemType('smart-bed');
  }

  get itemTitle(): string {
    return this.isSmartBed ? 'Smart Bed' :
      this.checkItemType('account-settings') ?
      this.item.type.replace(new RegExp(/-+/g), ' ') :
      this.item.type;
  }

  get itemSubtitle(): string {
    return this.isSmartBed ? 'Settings & Support' : '';
  }

  get isHealthDisabled(): boolean {
    return !!this.selectedSleeper && this.selectedSleeper.isChild && this.checkItemType('health');
  }

  get iconPath(): string {
    if (this.isHealthDisabled) return 'assets/icons/health-menu-item-disabled.svg';

    let itemTitle = this.item.type;
    if (this.checkItemType('smart-bed')) {
      itemTitle += `-${this.bed?.isOnline ? 'online' : 'offline'}`;
    }
    return `assets/icons/${itemTitle}-menu-item${this.item.clicked ? '-active' : ''}.svg`;
  }

  handleItemClick(): void {
    this.clickedItem.emit(this.item);
  }

  checkItemType(type: string): boolean {
    return this.item.type === type;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
