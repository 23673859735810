import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { Store } from "@ngxs/store";
import { SiqDateFormats } from "@shared/utils/helpers/date-formats.helper";
import { Logout } from "@store/auth/auth.actions";
import { InitSessionsForCurrentMonth } from "@store/sessions/sessions.actions";
import { SessionsSelectors } from "@store/sessions/sessions.selectors";
import * as moment from "moment";
import { catchError, map, of } from "rxjs";

export const SessionsGuard: CanActivateFn = () => {
  const store = inject(Store);
  if (!store.selectSnapshot(SessionsSelectors.lastSessionFetched)) {
    return store.dispatch(new InitSessionsForCurrentMonth(moment().startOf('month').format(SiqDateFormats.Date))).pipe(
      map(() => true),
      catchError(() => store.dispatch(new Logout()))
    );
  } else {
    return of(true);
  }
};