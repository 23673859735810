<app-top-bar-navigation [showBackButton]="true" [title]="sleeperIntroduction.title" (backBtnClicked)="back()"/>
<div #container>
  <div class="px-24 py-32" siqGrid fx-direction="column" fx-layout-align="center center">
    <span class="siq-text-900 fs-24 white px-12 text-center">{{sleeperIntroduction.introduction}}</span>
    <span class="siq-text-900 fs-16 rain-blue px-12 mt-12 cursor-pointer" (click)="changeBannerState(true)">Why do we ask this?</span>
    <app-setup-sleeper-form 
      class="w-100-p"
      [isPartnerSetup]="false"
      (redirectToTheNextScreen)="next()" />
  </div>
  <div *ngIf="showBanner" class="position-fixed align-bottom">
    <app-siq-banner (closeBannerClicked)="changeBannerState(!$event)" [data]="bannerData" [width]="bannerWidth"/>
  </div>
</div>