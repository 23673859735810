import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { Navigate } from "@ngxs/router-plugin";
import { Store } from "@ngxs/store";
import { CognitoLogin } from "@store/auth/auth.actions";
import { AuthSelectors } from "@store/auth/auth.selectors";
import { catchError, map, of } from "rxjs";

export const AuthGuard: CanActivateFn = () => {
  const store = inject(Store);
  const auth = store.selectSnapshot(AuthSelectors.cognitoAuth);
  if (auth) {
    return of(true);
  } else {
    const login = store.selectSnapshot(AuthSelectors.login);
    if (login) {
      return store.dispatch(new CognitoLogin(login)).pipe(
        map(() => true),
        catchError(() => {
          store.dispatch(new Navigate(['auth/login']));
          return of(false);
        })
      );
    } else {
      store.dispatch(new Navigate(['auth/login']));
      return of(false);
    }
  }
};