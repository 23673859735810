import { EnvironmentInjector, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, concatMap, first, from, last, of, takeWhile } from "rxjs";

export function runSerially(guards: CanActivateFn[]): CanActivateFn {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const injector = inject(EnvironmentInjector);
    return from(guards).pipe(
      concatMap((guard) => {
        const guardResult = injector.runInContext(() => guard(route, state));
        return wrapIntoObservable(guardResult).pipe(first());
      }),
      takeWhile((v) => v === true || v instanceof UrlTree),
      last()
    )
  };
}

function wrapIntoObservable<T>(guardResult: T | Promise<T> | Observable<T>): Observable<T> {
  if (guardResult instanceof Observable) {
    return guardResult;
  } else if (guardResult instanceof Promise) {
    return from(Promise.resolve(guardResult));
  } else {
    return of(guardResult);
  }
}