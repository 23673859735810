import { Injectable } from "@angular/core";

enum ScrollDirection {
  left,
  right
}


@Injectable({
  providedIn: 'root'
})
export class CarouselService {
  elementInTheViewId = 0;

  observeItems(slideItems:  NodeList): void {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // trigger the callback function when the element is at least 50% visible in the viewport
    };

    // IntersectionObserver - API to check when an element is intersecting with the viewport.
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.elementInTheViewId = parseInt(entry.target.id, 10); // gets the element that is in the view
        }
      });
    }, options);

    // observe each item element in the array
    slideItems.forEach((item: Node) => {
      observer.observe(item as Element);
    });
  }

  scroll(direction: number, numberOfSlides: number): void {
    switch (direction) {
      case ScrollDirection.left:
        this.elementInTheViewId = Math.max(0, this.elementInTheViewId - 1);
        break;
      case ScrollDirection.right:
        this.elementInTheViewId = Math.min(numberOfSlides - 1, this.elementInTheViewId + 1);
        break;
      default:
        this.elementInTheViewId = 0;
    }
    this.scrollTo(this.elementInTheViewId);
  }

  // When user clicks on the dot the requirement is that the user should move -/+ one place, not on the selected dot
  scrollOnDot(id: number, numberOfSlides: number): void {
    if (this.elementInTheViewId != id) {
      if (this.elementInTheViewId < id) {
        this.elementInTheViewId += 1;
      } else {
        this.elementInTheViewId -= 1;
      }
      if (this.elementInTheViewId < 0) {
        this.elementInTheViewId = 0;
      } else if (this.elementInTheViewId > numberOfSlides) {
        this.elementInTheViewId = numberOfSlides - 1;
      }
      this.scrollTo(this.elementInTheViewId);
    }
  }

  scrollTo(id: number): void {
    document.getElementById(id.toString())?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
  }

  isElementActive(id: number): boolean {
    return id === this.elementInTheViewId;
  }
}