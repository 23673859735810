import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PopupData } from "@models/app/modal-data.model";
import { Store } from "@ngxs/store";
import { PopupComponent } from "@shared/components/popup/popup.component";
import { CloseModal, OpenLinkInNewTab } from "@store/app/app.actions";
import { LinksHelper } from "./links-helper";
import { ServerErrors } from "./siq-errors.helper";
import { SleepStringResource } from "./sleep.helper";

@Injectable({
  providedIn: 'root'
})
export class SiqPopupHelper {

  constructor(private store: Store, private dialog: MatDialog) { }

  createPopup(popupData: PopupData): MatDialogRef<PopupComponent> {
    return this.dialog.open(PopupComponent, {
      width: '327px',
      height: 'auto',
      disableClose: false,
      data: {
        ...popupData,
        hideLeftButton: popupData.leftBtnTxt ? false : true
      }
    });
  }

  showAlert(screen: string, text = ServerErrors.ApiErrors.error400.text, title = ServerErrors.ApiErrors.error400.title): void {
    const popupData = new PopupData({
      title,
      text,
      screen,
      icon: 'warning-icon',
      type: 'yellow',
      rightBtnTxt: 'OK',
      leftBtnTxt: ''
    });
    const modal = this.createPopup(popupData);
    modal.componentInstance.onClose.subscribe(() => {
      modal.close();
    });
    modal.componentInstance.onRightAction.subscribe(() => {
      modal.close();
    });
    modal.afterClosed().subscribe(() => {
      this.store.dispatch(new CloseModal(modal));
      modal.componentInstance.onClose.unsubscribe();
      modal.componentInstance.onRightAction.unsubscribe();
    });
  }

  openTrackMyDeliveryPopup(orderNumber: string): void {
    const trackMyDeliveryCTA = SleepStringResource.getTrackMyDeliveryCTA(orderNumber);
    const modal = this.createPopup(trackMyDeliveryCTA);
    modal.componentInstance.onClose.subscribe(() => {
      modal.close();
    });
    modal.componentInstance.onLeftAction.subscribe(() => {
      modal.close();
    });
    modal.componentInstance.onRightAction.subscribe(() => {
      this.store.dispatch(new OpenLinkInNewTab(LinksHelper.orderStatus));
      modal.close();
    });
    modal.afterClosed().subscribe(() => {
      this.store.dispatch(new CloseModal(modal));
      modal.componentInstance.onClose.unsubscribe();
      modal.componentInstance.onRightAction.unsubscribe();
      modal.componentInstance.onLeftAction.unsubscribe();
    });
  }
}