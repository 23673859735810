<div *ngIf="!isHealthOnboarding else healthOnboarding" siqGrid fx-direction="row" fx-direction-sm="column" fx-layout-align="start center" fx-layout-align-sm="center start" class="content background-secondary border-bottom-400 px-24">
  <div siqGrid fx-direction="row" fx-layout-align="space-between center" class="w-100-p">
    <!-- DETAILS TOP NAVIGATION -->
    <div siqGrid fx-direction="row" fx-layout-align="start center">
      <div *ngIf="showBackButton" class="back-arrow cursor-pointer mr-16" (click)="handleBackClick()"></div>
      <div siqGrid fx-direction="row" fx-layout-align="start center" fx-layout-align-sm="center start" fx-direction-sm="column">
        <div *ngIf="title" class="siq-text-900 fs-20 white" [innerHtml]="title"></div>
        <ng-container *ngIf="!(breakpoints$ | async)?.isMobile">
          <div *ngIf="title && subtitle" class="border-left-400 h-40 ml-16 mr-16"></div>
        </ng-container>
        <div siqGrid fx-layout-align="start center" *ngIf="subtitle && !showSleepHistory && !isOnboarding" class="siq-text-400 fs-14 white" [innerHtml]="subtitle"></div>
      </div>
    </div>
    <!-- DETAILS TOP NAVIGATION -->

    <!-- TAB HOME -->
    <ng-container *ngIf="switchElements">
      <div siqGrid fx-direction="row" [fx-direction-sm]="isOnboarding ? 'row': 'column'" class="w-100-p">
        <div siqGrid fx-direction="row" fx-layout-align="start center">
          <app-siq-switcher 
          [selectedElement]="switchElements.selectedElement"  
          [switcherElements]="switchElements.switcherElements" class="mr-16"/>
        </div>
        <div *ngIf="showSleepHistory" siqGrid fx-direction="row" [fx-direction-xs]="isOnboarding ? 'row': 'column'" class="flex-fill" [fx-layout-align]="isOnboarding ? 'end center': 'space-between center'" [fx-direction-lt-sm]="isOnboarding ? 'center end': 'space-between-center'" [class.mt-12]="(breakpoints$ | async)?.isMobile && !isOnboarding">
          <div *ngIf="showSleepHistory && !isOnboarding" class="siq-text-400 fs-14 white" [innerHtml]="subtitle"></div>
          <app-siq-button
            [text]="'Sleep History'"
            [btnType]="'tertiary-btn'"
            [icon]="'sleep-history'" 
            [iconPosition]="iconPosition"
            (buttonClicked)="openSleepHistory()" />
        </div>
      </div>
    </ng-container>
    <!-- TAB HOME -->

    <span *ngIf="showSkipLabel" class="siq-text-900 fs-14 white p-12 cursor-pointer" (click)="handleSkipBtn()">SKIP</span>

  </div>
</div>
<ng-template #healthOnboarding>
  <div siqGrid fx-direction="row" fx-layout-align="center end" class="content background-secondary pb-4">
    <span class="siq-text-900 fs-24 white">Sleep Health</span>
  </div>
</ng-template>
