import { Component, Input, OnInit } from '@angular/core';
import { IdealTimes } from '@models/app/ideal-times.model';
import { SiqCommonModule } from '@shared/siq-common.module';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { SiqMetricsPreviewComponent } from '../siq-metrics-preview/siq-metrics-preview.component';
import { Observable } from 'rxjs';
import { BreakpointState, ResizeService } from '@services/resize.service';

@Component({
  selector: 'app-ideal-timing',
  templateUrl: './ideal-timing.component.html',
  standalone: true,
  imports: [SiqCommonModule, SiqMetricsPreviewComponent],
})
export class IdealTimingComponent implements OnInit {
  @Input() data: IdealTimes | null
  @Input() rightText: string;
  @Input() leftText: string;
  @Input() bedtimeIcon: string;
  @Input() wakeTimeIcon: string;
  @Input() textClass: string;
  @Input() subTextBedtimeClass: string;
  @Input() subTextWakeTimeClass: string;
  @Input() isStretched = false

  breakpoints$: Observable<BreakpointState>;

  constructor(private resizeService: ResizeService) {}

  ngOnInit(): void {
    this.subTextWakeTimeClass = !this.subTextWakeTimeClass ? this.subTextBedtimeClass: this.subTextWakeTimeClass
    this.breakpoints$ = this.resizeService.isMobile;
  }

  idealTimes(type: string): string {
    if(this.data && !this.data[type].end) {
      return FunctionsHelper.formatBedtimeWakeTimeValues(this.data[type].start);
    }
    return FunctionsHelper.formatCrTimeValues(this.getIdealTime(type, 'start'), this.getIdealTime(type, 'end'));
  }

  getIdealTime(type: string, property: string): string {
    if(this.data) return this.data[type][property];
    return '';
  }
}
