<app-top-bar-navigation [showBackButton]="true" title="Connectivity" (backBtnClicked)="back()"/>
<div siqGrid fx-direction="column" fx-layout-align="center center" class="py-32 px-24">
  <span class="siq-text-900 fs-14 white mb-8">WiFi</span>
  <span [ngClass]="(selectedBedsStatus$ | async)?.isOnline ? 'wifi-online' : 'wifi-offline'"></span>
  <span class="siq-text-400 fs-14 primary-200 my-8 mb-48">{{ (selectedBedsStatus$ | async)?.isOnline ? 'Connected' : 'Not connected' }}</span>
  <div class="w-100-p">
    <app-siq-button
      width="100%"
      text="Change WiFi"
      subText=" "
      btnType="secondary-btn dark no-border"
      icon="right-arrow-btn-icon" 
      [iconPosition]="iconPosition"
      [isListItemBtn]="true"
      (buttonClicked)="changeWiFi()" />
  </div>
  <div siqGrid fx-direction="row" fx-layout-align="end center" fx-layout-align-sm="center center" class="w-100-p mt-32">
    <app-siq-button
        width="208px"
        text="Having Trouble?"
        btnType="secondary-btn"
        (buttonClicked)="openHavingTrouble()" />
  </div>
</div>
