import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupBtnIcon } from '@models/app/modal-data.model';
import { Sleeper } from '@models/sleeper/sleeper.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { SiqIconPosition } from '@shared/utils/helpers/enum.helper';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { ProfileStringResource } from '@shared/utils/helpers/profile.helper';
import { SleepersSelectors } from '@store/sleepers/sleepers.selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sleep-study-success',
  templateUrl: './sleep-study-success.component.html'
})
export class SleepStudySuccessComponent implements OnInit {
  selectedSleeper: Sleeper | null;
  iconPosition = SiqIconPosition.right;
  noDataText: string;
  noDataSubtext: string;
  optOutSuccessMessage = ProfileStringResource.optOutSuccessMessage();
  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private mixpanelService: MixpanelService, private dialog: MatDialog) { }

  get isOptInSuccess(): boolean {
    return this.selectedSleeper?.sleepStudy?.isOptIn ?? false;
  }

  ngOnInit(): void {
    this.store.select(SleepersSelectors.selectedSleeper).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((sleeper) => {
      this.selectedSleeper = sleeper;
      this.noDataText = ProfileStringResource.changeSleepStudySuccessText(this.isOptInSuccess);
      this.noDataSubtext = ProfileStringResource.changeSleepStudySuccessSubtext(this.isOptInSuccess);
    });
  }

  done(): void {
    this.mixpanelService.trackSleepStudyOptInDone('done');
    this.store.dispatch(new Navigate(['pages/profile/details/sleep-study']));
  }

  updateEmail(): void {
    const sleeperInfo = { email: this.selectedSleeper?.email, sleeperId: this.selectedSleeper?.sleeperId, redirectTo: 'pages/profile/details/sleep-study/sleep-study-success' };
    this.mixpanelService.trackSleepStudyOptInDone('edit');
    const modal = FunctionsHelper.createPopup(
      this.dialog,
      'This will also change your login credentials.',
      'We appreciate your participation to advance the science of sleep and health.',
      'Sleep science',
      'warning-icon',
      'yellow',
      'Update Email',
      'Cancel',
      null,
      new PopupBtnIcon(SiqIconPosition.right, 'edit-dark')
    );
    modal.componentInstance.onLeftAction.subscribe(() => {
      modal.close();
    });
    modal.componentInstance.onRightAction.subscribe(() => {
      modal.close();
      this.store.dispatch(new Navigate(['pages/account-settings/details/update-email'], undefined, { state: sleeperInfo }));
    });
    modal.afterClosed().subscribe(() => {
      modal.componentInstance.onRightAction.unsubscribe();
      modal.componentInstance.onLeftAction.unsubscribe();
    });
  }
}
