import { NgModule } from "@angular/core";
import { SiqDropdownComponent } from "./components/siq-dropdown/siq-dropdown.component";
import { SiqInputComponent } from "./components/siq-input/siq-input.component";
import { SiqCommonModule } from "./siq-common.module";
import { SiqDropdownInputComponent } from "./components/siq-dropdown-input/siq-dropdown-input.component";

const components = [SiqInputComponent, SiqDropdownComponent, SiqDropdownInputComponent]

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    SiqCommonModule
  ],
  exports: [
    ...components
  ]
})
export class FormsModule { }