import { inject } from '@angular/core';
import type { ResolveFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadPrivacyPolicy } from '@store/taco/taco.actions';
import { TacoStateModel } from '@store/taco/taco.model';
import { of } from 'rxjs';

export const PrivacyPolicyResolver: ResolveFn<boolean> = () => {
  const store = inject(Store);
  const taco = localStorage.getItem('taco');
  if (taco) {
    const isPrivacyAccepted = (JSON.parse(taco) as TacoStateModel).privacyAccepted;
    if (!isPrivacyAccepted) {
      return store.dispatch(new LoadPrivacyPolicy());
    }
  }
  return of(true);
};
