/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SetupAddSleeperResponse, SetupSleeperData } from '@models/app/helpers.model';
import { AccountEntity } from '@models/auth/account.model';
import { LoginResponse } from '@models/auth/login.model';
import { AppSetting, AppSettingEntityModel } from '@models/sleeper/application-settings.model';
import { BiqUser } from '@models/sleeper/biq-user.model';
import { SleeperProfile } from '@models/sleeper/sleeper-profile.model';
import { Sleeper, SleeperEntity, UpdateSleeperEmailProp, UpdateSleeperPasswordProp, UpdateSleeperPasswordResponse, UpdateSleeperProperty } from '@models/sleeper/sleeper.model';
import { WellnessProfileResponse } from '@models/sleeper/wellness-profile.model';
import { Observable, catchError, map, throwError } from 'rxjs';
import { EnvironmentsService } from './environments.service';
import { MixpanelService } from './mixpanel.service';
import { SiqApiService } from './siq-api.service';
import { SplashScreenService } from './splash-screen.service';

@Injectable({
  providedIn: 'root'
})
export class SleeperService extends SiqApiService {

  constructor(
    httpClient: HttpClient,
    environmentService: EnvironmentsService,
    mixpanelService: MixpanelService,
    dialog: MatDialog,
    splashScreenService: SplashScreenService) {
    super(httpClient, environmentService, mixpanelService, dialog, splashScreenService);
  }

  getSleepers(): Observable<SleeperEntity> {
    return this.makeSiqRequest<SleeperEntity>('sleeper', 'siq', {}, 'GET', false).pipe(
      map((response: SleeperEntity | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  getAccountDetails(): Observable<AccountEntity> {
    return this.makeSiqRequest<AccountEntity>('account', 'siq', {}, 'GET', false).pipe(
      map((response: AccountEntity | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  updateSleeper(sleeperId: string, data: UpdateSleeperProperty | SetupSleeperData): Observable<Sleeper | HttpErrorResponse> {
    return this.makeSiqRequest<Sleeper>(`sleeper/${sleeperId}`, 'siq', data, 'PUT', false).pipe(
      map((response: Sleeper | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  addSleeper(data: SetupSleeperData): Observable<SetupAddSleeperResponse> {
    return this.makeSiqRequest<SetupAddSleeperResponse>('sleeper', 'siq', data, 'POST', false).pipe(
      map((response: SetupAddSleeperResponse | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  getAllTimeAverages(sleeperId: string | undefined): Observable<SleeperProfile> {
    return this.makeSiqRequest<SleeperProfile>(`sleeper/${sleeperId}/profile`, 'siq', {}, 'GET', false).pipe(
      map((response: SleeperProfile | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  getSleeperSleepStudy(accountId: string, sleeperId: string | undefined): Observable<AppSettingEntityModel> {
    return this.makeSiqRequest<AppSettingEntityModel>(`sn/v1/accounts/${accountId}/sleepers/${sleeperId}/application-settings`, 'siq', {}, 'GET', false).pipe(
      map((response: AppSettingEntityModel | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  changeSleeperSleepStudyState(accountId: string, sleeperId: string, value: string): Observable<AppSetting> {
    return this.makeSiqRequest<AppSetting>(
      `sn/v1/accounts/${accountId}/sleepers/${sleeperId}/application-settings/sleep-study-survey-preference`, 'siq',
      { value },
      'PUT',
      false
    ).pipe(
      map((response: AppSetting | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  updateSleeperEmail(sleeperId: string | undefined, data: UpdateSleeperEmailProp): Observable<LoginResponse> {
    return this.makeSiqRequest<LoginResponse>(`sleeper/${sleeperId}/emailAddress`, 'siq', data, 'PUT', false).pipe(
      map((response: LoginResponse | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  updateSleeperPassword(sleeperId: string | undefined, data: UpdateSleeperPasswordProp): Observable<UpdateSleeperPasswordResponse> {
    return this.makeSiqRequest<UpdateSleeperPasswordResponse>(`sleeper/${sleeperId}/password`, 'siq', data, 'PUT', false).pipe(
      map((response: UpdateSleeperPasswordResponse | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  updateNativePassword(sleeperId: string | undefined, data: UpdateSleeperPasswordProp): Observable<UpdateSleeperPasswordResponse> {
    return this.makeSiqRequest<UpdateSleeperPasswordResponse>(`sleeper/${sleeperId}/password`, 'nsiq', data, 'PUT', false).pipe(
      map((response: UpdateSleeperPasswordResponse | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  getSleeperWellnessProfile(sleeperId: string): Observable<WellnessProfileResponse> {
    return this.makeSiqRequest<WellnessProfileResponse>(`sleeper/${sleeperId}/wellnessProfile`, 'siq', {}, 'GET', false).pipe(
      map((response: WellnessProfileResponse | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  addSleeperWellnessProfile(sleeperId: string, data: UpdateSleeperProperty): Observable<WellnessProfileResponse> {
    return this.makeSiqRequest<WellnessProfileResponse>(`sleeper/${sleeperId}/wellnessProfile`, 'siq', data, 'POST', false).pipe(
      map((response: WellnessProfileResponse | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  updateSleeperWellnessProfile(sleeperId: string, data: UpdateSleeperProperty): Observable<WellnessProfileResponse> {
    return this.makeSiqRequest<WellnessProfileResponse>(`sleeper/${sleeperId}/wellnessProfile`, 'siq', data, 'PUT', false).pipe(
      map((response: WellnessProfileResponse | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }


  cancelInviteSleeperEmail(accountId: string, sleeperId: string): Observable<object> {
    return this.makeSiqRequest<object>(`sn/v1/accounts/${accountId}/sleepers/${sleeperId}/invitationEmail`, 'siq', {}, 'DELETE', false).pipe(
      map((response: object | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }
  
  resendInvitation(accountId: string, sleeperId: string): Observable<object> {
    return this.makeSiqRequest<object>(`sn/v1/accounts/${accountId}/sleepers/${sleeperId}/invitationEmail`, 'siq', {}, 'PUT', false).pipe(
      map((response: object | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  biqUserDetails(username: string): Observable<BiqUser> {
    return this.makePlansRequest<BiqUser>(`sn/v1/users/${username}/biq-sleeper-detail`, {}, 'GET', true).pipe(
      map((response: BiqUser | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }
}
