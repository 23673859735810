<div class="w-100-p">
  <div siqGrid fx-direction="column" fx-layout-align="center center" class="category-container background-primary cursor-pointer py-24 br-8" (click)="selectCategory()">
    <img class="category-icon" [src]="getIcon()" alt="icon">
    <span class="siq-text-900 fs-18 white text-capitalize mt-12">{{ categoryText }}</span>
    <span class="siq-text-400 fs-14 white mt-8">{{ answeredQuestions }} / {{ allQuestions }}</span>
    <span class="siq-text-400 fs-14 white mt-8">Complete</span>
    <div siqGrid fx-direction="row" fx-layout-align="center center" class="progress-container mt-8">
      <ng-container *ngIf="isFinished else inProgress">
        <img class="finished-category-icon" [src]="getIcon(true)" alt="check-icon">
      </ng-container>
      <ng-template #inProgress>
          <div class="progress-bar background-secondary br-8">
              <div [ngStyle]="progressWidth" class="progress-bar-color br-8" [ngClass]="category"></div>
          </div>
      </ng-template>
    </div>
  </div>
</div>
