import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";

export class SleepHealthIccParams {
  sessionStartDate: string;
  isEdited: boolean;
}

export class SleepHealthIccEntity {
  messages: Array<SleepHealthIcc>;
  sessionEndDate: string;
  sessionStartDate: string;
  sleeperId: string;

  constructor(sleepHealthIcc: SleepHealthIccEntity) {
    sleepHealthIcc.messages = sleepHealthIcc.messages.map((shIcc) => new SleepHealthIcc(shIcc));
    Object.assign(this, sleepHealthIcc);
  }
}

export class SleepHealthIcc {
  image: string;
  message: string;
  ruleId: number;
  type: string;

  constructor(icc: SleepHealthIcc) {
    Object.assign(this, icc);
  }

  get tip(): string {
    return FunctionsHelper.addTrademarkToText(this.message);
  }
}

export class OptionsIcc {
  msgHeader?: string;
  ctaText?: string;
  ctaDeepLink?: string;
}

export class IccMessage {
  category: string;
  date: string;
  image: string;
  messageId: string;
  options: OptionsIcc;
  originalEndDate: string;
  originalStartDate: string;
  originalSubcategory: string;
  ruleId: string;
  subcategory: string;
  text: string;
  type: string
}

export class IccMessages {
  selectedSleeperId: string;
  startDate: string;
  endDate: string;
  messages: Array<IccMessage>;
}

export class SleepersIccMessagesModel {
  messages: Array<IccMessage>;
  sleeperId: string;
}

export class SleepersIccMessages {
  sleepers: Array<SleepersIccMessagesModel>;
}
