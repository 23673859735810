<app-top-bar-navigation [showBackButton]="true" title="WiFi Network" (backBtnClicked)="redirectToRoute('')"/>
<div siqGrid fx-direction="column" fx-layout-align="center start" class="py-32 px-24">
  <!-- CONNECTED NETWORK -->
  <ng-container *ngIf="(selectedBedWifiStatus$ | async)">
    <div *ngIf="connectedNetwork$ | async as connectedNetwork" class="mb-48 w-100-p">
      <span class="siq-text-900 fs-18 white">Connected</span>
      <ng-container 
        [ngTemplateOutlet]="networkPreview" 
        [ngTemplateOutletContext]="{network: connectedNetwork}">
      </ng-container>
    </div>
  </ng-container>
  <!-- CONNECTED NETWORK -->

  <!-- ADDITIONAL NETWORKS -->
  <ng-container *ngIf="(filteredNetworks$ | async)?.length">
    <span class="siq-text-900 fs-18 white">Additional Networks</span>
    <div *ngFor="let filteredNetwork of filteredNetworks$ | async" class="w-100-p">
      <ng-container
        [ngTemplateOutlet]="networkPreview" 
        [ngTemplateOutletContext]="{network: filteredNetwork}">
      </ng-container>
    </div>
  </ng-container>
  <!-- ADDITIONAL NETWORKS -->

  <div siqGrid fx-direction="column" fx-layout-align="center end" fx-layout-align-sm="center center" class="w-100-p mt-48">
    <app-siq-button
      width="208px"
      text="Add New Network"
      btnType="secondary-btn"
      (buttonClicked)="redirectToRoute('networks/add-new-network')" />
  </div>
</div>

<ng-template #networkPreview let-network='network'>
  <div siqGrid fx-direction="row" fx-layout-align="space-between center" class="p-12 mt-24 border-bottom-400">
    <span class="siq-text-900 fs-16 white">{{ network.ssid }}</span>
    <app-siq-button
      text="REMOVE"
      btnType="tertiary-btn"
      (buttonClicked)="removeNetwork(network.wifiId)" />
  </div>
</ng-template>