import { NgModule } from "@angular/core";
import { ButtonsModule } from "./buttons.module";
import { LayoutComponent } from "./components/layout/layout.component";
import { LeftNavigationItemComponent } from "./components/navigation/left-navigation-menu/left-navigation-item/left-navigation-item.component";
import { LeftNavigationMenuComponent } from "./components/navigation/left-navigation-menu/left-navigation-menu.component";
import { TopBarNavigationComponent } from "./components/navigation/top-bar-navigation/top-bar-navigation.component";
import { SiqFooterComponent } from "./components/siq-footer/siq-footer.component";
import { SiqNotificationComponent } from "./components/siq-notification/siq-notification.component";
import { SiqTabsComponent } from "./components/siq-tabs/siq-tabs.component";
import { SiqCommonModule } from "./siq-common.module";
import { InfoBarComponent } from "./standalone-components/info-bar/info-bar.component";
import { SiqPrivacyBannerComponent } from "./standalone-components/siq-privacy-banner/siq-privacy-banner.component";
import { StaticContentModule } from "./static-content.module";

const components = [LayoutComponent, LeftNavigationItemComponent, LeftNavigationMenuComponent, TopBarNavigationComponent, SiqTabsComponent, SiqFooterComponent]

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    SiqCommonModule,
    ButtonsModule,
    StaticContentModule,
    SiqNotificationComponent,
    InfoBarComponent,
    SiqPrivacyBannerComponent,
  ],
  exports: [
    ...components
  ]
})
export class NavigationModule { }