import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { Store } from "@ngxs/store";
import { Logout } from "@store/auth/auth.actions";
import { LoadBeds } from "@store/beds/beds.actions";
import { BedsSelectors } from "@store/beds/beds.selectors";
import { catchError, map, of } from "rxjs";

export const BedsGuard: CanActivateFn = () => {
  const store = inject(Store);
  const loading = store.selectSnapshot(BedsSelectors.loading);
  if (store.selectSnapshot(BedsSelectors.beds)?.length === 0 && !loading) {
    return store.dispatch(new LoadBeds()).pipe(
      map(() => true),
      catchError(() => store.dispatch(new Logout()))
    );
  } else {
    return of(true);
  }
};

