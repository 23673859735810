import { Component, OnDestroy, OnInit } from '@angular/core';
import { Bed, UpdateBedProperty } from '@models/bed/bed.model';
import { Sleeper } from '@models/sleeper/sleeper.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { SiqIconPosition } from '@shared/utils/helpers/enum.helper';
import { AddNewSleeper } from '@store/app/app.actions';
import { UpdateBed } from '@store/beds/beds.actions';
import { SettingsSelectors } from '@store/settings/settings.selectors';
import { UpdateSleepersSide } from '@store/sleepers/sleepers.actions';
import { SleepersSelectors } from '@store/sleepers/sleepers.selectors';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sleeper-setup',
  templateUrl: './sleeper-setup.component.html',
  styleUrls: ['./sleeper-setup.component.scss']
})
export class SleeperSetupComponent implements OnInit, OnDestroy {
  iconPosition = SiqIconPosition.right;
  selectedBed: Bed;
  loggedInSleeper: Sleeper;
  selectedSleeper: Sleeper;
  leftSleeper: Sleeper | null;
  rightSleeper: Sleeper | null;

  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private mixpanelService: MixpanelService) { }

  get bedIcon(): string {
    if (this.leftSleeper) {
      if (this.rightSleeper && this.rightSleeper.sleeperId === this.loggedInSleeper.sleeperId) return 'dual-bed-left-side-selected';
      return 'dual-bed-right-side-selected';
    }
    return 'dual-bed-left-side-selected';
  }

  get hasLeftSleeper(): boolean | null {
    return this.leftSleeper && !this.rightSleeper;
  }

  get canAddSleeper(): boolean {
    return (this.isDualBed && (!this.leftSleeper || !this.rightSleeper)) || (!this.isDualBed && (!this.leftSleeper && !this.rightSleeper));
  }

  get isDualBed(): boolean {
    return this.selectedBed.dualSleep;
  }

  ngOnInit(): void {
    this.mixpanelService.trackSleeperSetupOpen();
    this.store.select(SettingsSelectors.selectedBed).pipe(
      takeUntil(this._unsubscribeAll),
      filter((selectedBed): selectedBed is Bed => !!selectedBed)
    ).subscribe(selectedBed => {
      this.selectedBed = selectedBed;
    });

    this.store.select(SleepersSelectors.loggedInSleeper).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(sleeper => this.loggedInSleeper = sleeper);

    this.store.select(SleepersSelectors.selectedSleeper).pipe(
      takeUntil(this._unsubscribeAll),
      filter((selectedSleeper): selectedSleeper is Sleeper => !!selectedSleeper)
    ).subscribe(sleeper => this.selectedSleeper = sleeper);

    this.store.select(SleepersSelectors.sleepers).pipe(
      takeUntil(this._unsubscribeAll),
      filter((sleepers): sleepers is Array<Sleeper> => !!sleepers)
    ).subscribe(sleepers => {
      this.rightSleeper = this.findSleeper(sleepers, 'sleeperRightId');
      this.leftSleeper = this.findSleeper(sleepers, 'sleeperLeftId');
    });
  }

  getSleeperName(sleeper: Sleeper): string {
    return sleeper && sleeper.sleeperId === this.selectedSleeper.sleeperId ? 'You' : sleeper.firstName;
  }

  back(): void {
    this.store.dispatch(new Navigate(['pages/smart-bed/bed-view']));
  }

  switchSleeperSides(): void {
    const sleeperLeftId = this.rightSleeper ? this.rightSleeper.sleeperId : '0';
    const sleeperRightId = this.leftSleeper ? this.leftSleeper.sleeperId : '0';
    this.store.dispatch(new UpdateBed(this.selectedBed.bedId, new UpdateBedProperty({ sleeperLeftId, sleeperRightId })));
    this.mixpanelService.trackBedSwitchSide(this.selectedBed.bedId);
    this.selectedBed.sleeperLeftId = this.rightSleeper ? this.rightSleeper.sleeperId : '0';
    this.selectedBed.sleeperRightId = this.leftSleeper ? this.leftSleeper.sleeperId : '0';
    this.store.dispatch(new UpdateSleepersSide(this.selectedBed.bedId));
  }

  addNewSleeper(): void {
    this.mixpanelService.trackAddSleeperOnBed(this.selectedBed.bedId);
    this.store.dispatch(new AddNewSleeper(this.selectedBed, 'pages/smart-bed/details/sleeper-setup'));
  }

  private findSleeper(sleepers: Array<Sleeper>, sleeperSideProp: string): Sleeper | null {
    return sleepers.find((sleeper) => sleeper.sleeperId === this.selectedBed[sleeperSideProp]) ?? null;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
