import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-siq-dropdown-input',
  templateUrl: './siq-dropdown-input.component.html'
})
export class SiqDropdownInputComponent {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() value = '';
  @Input() description: string;

  get inputValue(): string {
    return this.value ? this.value : '';
  }
}
