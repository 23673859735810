export const environment = {
  production: true,
  buildNumber: '20250217.1',
  version: '5.3.0',
  firebase: {
    apiKey: "AIzaSyAJyv9K4HISqBgzWHqPqJ-0STR_NTftQSc",
    authDomain: "sleepiq-production-2a519.firebaseapp.com",
    projectId: "sleepiq-production-2a519",
    storageBucket: "sleepiq-production-2a519.appspot.com",
    messagingSenderId: "268662094243",
    appId: "1:268662094243:web:5bbb6c456ca990810cc216",
    measurementId: "G-FJSVPZCXC7",
  },
};
