import { BedStatus } from "@models/bed/bed-status.model";
import { Bed } from "@models/bed/bed.model";
import { SiqError } from "@models/app/helpers.model";
import { PrivacyModeStatus } from "@models/bed/privacy-status.model";

export interface BedsStateModel {
  beds: Array<Bed> | [];
  bedsStatus: Array<BedStatus> | [];
  privacyModeStatus: Array<PrivacyModeStatus> | [];
  loading: boolean;
  error: SiqError | null;
}

export const defaultBedState = {
  beds: [],
  bedsStatus: [],
  privacyModeStatus: [],
  loading: false,
  error: null
};
