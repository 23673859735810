import { Component } from '@angular/core';
import { SiqDateFormats } from '@shared/utils/helpers/date-formats.helper';
import { LinksHelper } from '@shared/utils/helpers/links-helper';
import * as moment from 'moment';

@Component({
  selector: 'app-siq-footer',
  templateUrl: './siq-footer.component.html'
})
export class SiqFooterComponent {
  year =  moment().format(SiqDateFormats.Year)

  getLink(link: string): string {
     return LinksHelper[link];
  }
}