const MIN_HEIGHT_FT_FOR_ADULT = 2;
const MAX_HEIGHT_FT_FOR_ADULT = 8;

export class HeightHelper {
    static get heightValuesArr(): Array<number> {
      return this.getHeightData('values') as Array<number>;
    }

    static get heightOptionsArr(): Array<string> {
      return this.getHeightData('options') as Array<string>;
    }

    private static getHeightData(type: string): Array<number | string> {
      const arr = [];
      for (let i = MIN_HEIGHT_FT_FOR_ADULT; i <= MAX_HEIGHT_FT_FOR_ADULT; i++ ) {
        let maxIn = 11;
        if (i === MAX_HEIGHT_FT_FOR_ADULT) {
            maxIn = 0;
        }
        for (let j = 0; j <= maxIn; j++) {
          if (type === 'values') {
            const heightValue = i * 12 + j;
            arr.push(heightValue);
          } else {
            const heightOption = `${i}' ${j}''`;
            arr.push(heightOption);
          }
        }
      }
      return arr;
    }
}
