import { Component, OnDestroy, OnInit } from '@angular/core';
import { SleeperStudyState } from '@models/sleeper/sleeper-study-state';
import { Sleeper } from '@models/sleeper/sleeper.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { SleepersSelectors } from '@store/sleepers/sleepers.selectors';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sleep-study',
  templateUrl: './sleep-study.component.html'
})
export class SleepStudyComponent implements OnInit, OnDestroy {

  sleeperStudyState$: Observable<SleeperStudyState>;
  selectedSleeper: Sleeper | null;

  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private mixpanelService: MixpanelService) {}

  ngOnInit(): void {
    FunctionsHelper.scrollToTop();
    this.sleeperStudyState$ = this.store.select(SleepersSelectors.sleepStudyState);
    this.store.select(SleepersSelectors.selectedSleeper).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(selectedSleeper => {
      this.selectedSleeper = selectedSleeper;
    });
    this.trackMixpanelEvent();
  }

  back(): void {
    this.store.dispatch(new Navigate(['pages/profile']));
  }

  private getSleepStudyStatus(sleeper: Sleeper): string {
    return sleeper.sleepStudy?.isOptIn ? 'optIn' : 'optOut';
  }

  private trackMixpanelEvent(): void {
    const loggedInSleeper = this.store.selectSnapshot(SleepersSelectors.loggedInSleeper);
    const isLoggedInSleeperSelectedSleeper = loggedInSleeper.sleeperId === this.selectedSleeper?.sleeperId;
    if(isLoggedInSleeperSelectedSleeper) {
      if(this.selectedSleeper)
        this.mixpanelService.trackSleepStudyDetailsOpen(this.getSleepStudyStatus(this.selectedSleeper), isLoggedInSleeperSelectedSleeper);
    } else {
      if(this.selectedSleeper)
        this.mixpanelService.trackSleepStudyDetailsOpenSecondSleeper(
          this.getSleepStudyStatus(loggedInSleeper),
          isLoggedInSleeperSelectedSleeper,
          this.selectedSleeper.email !== 'null' ? 'yes' : 'no'
        );
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
