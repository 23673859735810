import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SleepHealthIccEntity, SleepHealthIccParams } from '@models/health/sleep-health-icc.model';
import { SleepHealthEntity, SleepHealthParams } from '@models/health/sleep-health.model';
import { WellnessReportEntityModel, WellnessReportParams } from '@models/health/wellness-report.model';
import { Observable, catchError, map, throwError } from 'rxjs';
import { EnvironmentsService } from './environments.service';
import { MixpanelService } from './mixpanel.service';
import { SiqApiService } from './siq-api.service';
import { SplashScreenService } from './splash-screen.service';

@Injectable({
  providedIn: 'root'
})
export class HealthService extends SiqApiService {

  constructor(
    httpClient: HttpClient,
    environmentService: EnvironmentsService,
    mixpanelService: MixpanelService,
    dialog: MatDialog,
    splashScreenService: SplashScreenService) {
    super(httpClient, environmentService, mixpanelService, dialog, splashScreenService);
  }

  getSleepHealth(accountId: string, sleeperId: string, data: SleepHealthParams): Observable<SleepHealthEntity> {
    return this.makeSiqRequest<SleepHealthEntity>(
      `sn/v1/accounts/${accountId}/sleepers/${sleeperId}/mySleepHealth/metrics`,
      'siq', data, 'GET', false).pipe(
        map((response: SleepHealthEntity | HttpErrorResponse) => {
          if (response instanceof HttpErrorResponse) {
            throw response;
          }
          return response;
        }),
        catchError((error: HttpErrorResponse) => throwError(() => error))
      );
  }

  getSleepHealthIcc(accountId: string, sleeperId: string, data: SleepHealthIccParams): Observable<SleepHealthIccEntity> {
    return this.makeSiqRequest<SleepHealthIccEntity>(
      `sn/v1/accounts/${accountId}/sleepers/${sleeperId}/mySleepHealth/messages?sessionStartDate=${data.sessionStartDate}&isEdited=${data.isEdited}`,
      'siq', {}, 'PUT', false).pipe(
        map((response: SleepHealthIccEntity | HttpErrorResponse) => {
          if (response instanceof HttpErrorResponse) {
            throw response;
          }
          return response;
        }),
        catchError((error: HttpErrorResponse) => throwError(() => error))
      );
  }

  getWellnessReport(accountId: string, sleeperId: string, data: WellnessReportParams): Observable<WellnessReportEntityModel> {
    return this.makeSiqRequest<WellnessReportEntityModel>(
      `sn/v1/accounts/${accountId}/sleepers/${sleeperId}/reports/wellness`,
      'siq', data, 'GET', false).pipe(
        map((response: WellnessReportEntityModel | HttpErrorResponse) => {
          if (response instanceof HttpErrorResponse) {
            throw response;
          }
          return response;
        }),
        catchError((error: HttpErrorResponse) => throwError(() => error))
      );
  }

  downloadWellnessReport(accountId: string, sleeperId: string, date: string): any {
    return this.makeSiqRequest<string>(
      `sn/v1/accounts/${accountId}/sleepers/${sleeperId}/download/reports/wellness`,
      'siq', { date }, 'GET', false, 'text').pipe(
        map((response: string | HttpErrorResponse) => {
          if (response instanceof HttpErrorResponse) {
            throw response;
          }
          return response;
        }),
        catchError((error: HttpErrorResponse) => throwError(() => error))
      );
  }
}
