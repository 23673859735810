<div siqGrid fx-direction="column" [fx-layout-align]="itemsLayoutAlign" fx-layout-align-lt-sm="center start">
  <div *ngFor="let item of items" class="mt-24" [ngClass]="itemsPadding">
    <div siqGrid fx-direction="row" fx-layout-align="start center">
      <span [class]="item.icon" ngClass="flex-auto"></span>
      <div [class]="textMargin">
        <span [class]="item.textClasses" [innerHTML]="item.text"></span>
      </div>
    </div>  
  </div>
</div>
