export class CircadianRhythmParams {
  accountId: string;
  sleeperId: string;
  date: string;
}

export interface CircadianRhythmResponse {
  circadianRhythm: Array<CircadianRhythm>;
  sleeperId: string;
}

export class CircadianRhythmEntity {
  circadianRhythm: CircadianRhythm
  sleeperId: string;

  constructor(cr: CircadianRhythmResponse) {
    this.sleeperId = cr.sleeperId;
    // since the app is fetching only one date, there will always be only one element in the app
    this.circadianRhythm = new CircadianRhythm(cr.circadianRhythm[0])
  }
}

export class CircadianRhythm {
  date: string;
  idealBedTime?: Schedule;
  idealWakeTime?: Schedule;
  optimalTimeActivities: OptimalTimeActivities;
  originalSleepSession: Schedule;
  sleepStartDate: string;
  reason?: Reason;

  constructor(cr: CircadianRhythm) {
    this.date = cr.date;
    this.originalSleepSession = new Schedule(cr.originalSleepSession);
    this.optimalTimeActivities = new OptimalTimeActivities(cr.optimalTimeActivities);
    this.sleepStartDate = cr.sleepStartDate;

    if (cr.reason) {
      this.reason = new Reason(cr.reason);
    } else {
      if (cr.idealBedTime) {
        this.idealBedTime = new Schedule(cr.idealBedTime);
      }
      if (cr.idealWakeTime) {
        this.idealWakeTime = new Schedule(cr.idealWakeTime);
      }
    }
  }

  get hasIdealSchedule(): boolean {
    return this.reason ? false: true;
  }
}

export class Schedule {
  end: string;
  start: string;

  constructor(schedule: Schedule) {
    Object.assign(this, schedule);
  }
}

export class OptimalTimeActivities {
  dinner?: Schedule;
  mostAlert: Schedule;
  windDown?: Schedule;
  workOut: Schedule;

  constructor(activities: OptimalTimeActivities) {
    this.mostAlert = new Schedule(activities.mostAlert);
    this.workOut = new Schedule(activities.workOut);
    if (activities.dinner) {
      this.dinner = new Schedule(activities.dinner);
    }
    if (activities.windDown) {
      this.windDown = new Schedule(activities.windDown);
    }
  }
}

export class Reason {
  code: number;
  text: string;

  constructor(reason: Reason) {
    Object.assign(this, reason);
  }
}