import { MatDialogRef } from "@angular/material/dialog";
import { SiqNotification } from "@models/app/helpers.model";
import { Bed } from "@models/bed/bed.model";
import { EditSleepGoalModalComponent } from "@shared/components/edit-sleep-goal-modal/edit-sleep-goal-modal.component";
import { PopupComponent } from "@shared/components/popup/popup.component";
import { PrivacyMode } from "@shared/utils/helpers/enum.helper";

export class SetSelectDefaultSleeperShown {
  static readonly type = '[App] SetSelectDefaultSleeperShown';
  constructor(public payload: boolean) {}
}
export class ChangeServer {
  static readonly type = '[App] ChangeServer';
  constructor(public serverName: string) {}
}
export class ResetAppState {
  static readonly type = '[App] ResetAppState';
}
export class LoadAccountDetails {
  static readonly type = '[App] LoadAccountDetails';
}
export class LoadAppConfig {
  static readonly type = '[App] LoadAppConfig';
}

export class OpenLinkInNewTab {
  static readonly type = '[App] OpenLinkInNewTab';
  constructor(public link: string) {}
}

export class SetSiqNotification {
  static readonly type = '[App] SetSiqNotification';
  constructor(public payload: SiqNotification | null) {}
}

export class CloseModal {
  static readonly type = '[App] CloseModal';
  constructor(public modal: MatDialogRef<PopupComponent> | MatDialogRef<EditSleepGoalModalComponent>) {}
}

export class SetupSleepersFrom {
  static readonly type = '[App] SetupSleepersFrom';
  constructor(public setupSleepersFrom: string | null) {}
}

// This action is used across the application
// in sleeper dropdown or on smart bed(sleeper setup)
export class AddNewSleeper {
  static readonly type = '[App] AddNewSleeper';
  constructor(public bed: Bed, public addSleepersFrom: string) {}
}

export class SetPrivacyModeBannerState {
  static readonly type = '[App] SetPrivacyModeBannerState';
  constructor(public bannerState: PrivacyMode) {}
}

export class SetPrivacyModeDismissedWithLater {
  static readonly type = '[App] SetPrivacyModeDismissedWithLater';
  constructor(public isLaterClicked: boolean) {}
}

export class GeneratePdf {
  static readonly type = '[App] GeneratePdf';
  constructor(public report: string, public date: string) {}
}
export class HasECIMIdentity {
  static readonly type = '[App] HasECIMIdentity';
  constructor(public hasECIMIdentity: boolean) {}
}
