<div siqGrid fx-direction="column" fx-layout-align="center center" class="p-24">
  <div siqGrid fx-direction="column" class="w-100-p">
    <div siqGrid fx-direction="row" fx-layout-align="space-between start" [class]="(breakpoints$ | async)?.isTablet ? 'w-60-p': (breakpoints$ | async)?.isMobile ? 'w-100-p' : 'w-90-p'">
      <div siqGrid fx-direction="column" fx-layout-align="center start">
        <a class="siq-text-400 fs-16 rain-blue no-outline" [href]="contactUsLink" target="_blank" (click)="trackMixpanelEvent('contactUs')">Contact us</a>
        <a class="siq-text-400 fs-16 rain-blue no-outline mt-8" [href]="legalLink" target="_blank" (click)="trackMixpanelEvent('legal')">Legal</a>
      </div>
  
      <div siqGrid fx-direction="column" fx-layout-align="center start">
        <a class="siq-text-400 fs-16 rain-blue no-outline" [href]="searchSupportArticlesLink" target="_blank" (click)="trackMixpanelEvent('supportArticles')">Search Support Articles</a>
        <a class="siq-text-400 fs-16 rain-blue no-outline mt-8 text-left" [href]="privacyPolicyLink" target="_blank" (click)="trackMixpanelEvent('privacyPolicy')">Privacy Policy</a>
      </div>
    </div>
    <a class="siq-text-400 fs-16 rain-blue no-outline mt-8 text-left" [href]="openSourceLink" target="_blank" (click)="trackMixpanelEvent('license')">Open Source Information</a>
    <span class="siq-text-400 fs-16 primary-200 my-12">© <span>{{ currentYear }}</span> Sleep Number<sup>®</sup> Corporation</span>
  </div>
</div>

