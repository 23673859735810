import { BedsState } from "@store/beds/beds.state";
import { SetupState } from "./setup.state";
import { createSelector, Selector } from "@ngxs/store";
import { BedsStateModel } from "@store/beds/beds.model";
import { SetupStateModel } from "./setup.model";
import { Bed } from "@models/bed/bed.model";
import { SetupBedData, SetupSleeperData } from "@models/app/helpers.model";
import { BiqUser } from "@models/sleeper/biq-user.model";

export class SetupSelectors {

  @Selector([BedsState, SetupState])
  static selectedBed(bedState: BedsStateModel, setupState: SetupStateModel): Bed | null {
    return bedState.beds.find((bed) => bed.bedId === setupState.selectedBedId) ?? null;
  }

  @Selector([SetupState])
  static shouldAddSecondSleeper(setupState: SetupStateModel): number | null {
    return setupState.shouldAddSecondSleeper;
  }

  @Selector([SetupState, BedsState])
  static hasSleeperOnSelectedBed(state: SetupStateModel, bedsState: BedsStateModel): boolean | null {
    return this.selectedBed(bedsState, state)?.hasSleepersOnBed ?? null;
  }

  @Selector([SetupState])
  static bedSetupData(state: SetupStateModel): SetupBedData | null {
    return state.bedData;
  }

  @Selector([SetupState])
  static sleeperData(state: SetupStateModel): SetupSleeperData | null {
    return state.sleeperData;
  }

  @Selector([SetupState])
  static selectedSleeperId(setupState: SetupStateModel): string | null {
    return setupState.selectedSleeperId;
  }

  @Selector([BedsState])
  static isAccountOwnerSet(bedState: BedsStateModel): boolean {
    // if no beds are set, it means that account owner is not set
    return bedState.beds.length !== bedState.beds.filter((bed) => bed.sleeperLeftId === '0' && bed.sleeperRightId === '0').length;
  }

  @Selector([SetupState])
  static isSettingFirstSleeper(state: SetupStateModel): boolean {
    return state.isSettingFirstSleeper;
  }

  @Selector([SetupState])
  static biqUser(state: SetupStateModel): BiqUser | null {
    return state.biqUser;
  }

  static isBiqUser(isPartnerSetup: boolean): (state: SetupStateModel) => boolean {
    return createSelector([SetupState], (state: SetupStateModel): boolean => {
      if (isPartnerSetup) {
        return !!state.biqUser?.sleepPartner;
      }
      return !!state.biqUser?.sleeperInfo;
    })
  } 
}