import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ListItem, SetupSleeperData } from '@models/app/helpers.model';
import { Sleeper, UpdateSleeperProperty } from '@models/sleeper/sleeper.model';
import { ButtonsModule } from '@shared/buttons.module';
import { FormsModule } from '@shared/forms.module';
import { SiqCommonModule } from '@shared/siq-common.module';
import { AccountSettingsStringResource } from '@shared/utils/helpers/account-settings.helper';
import { Regex } from '@shared/utils/helpers/regex.helper';
import { SiqListItemsComponent } from '../siq-list-items/siq-list-items.component';

interface InviteUserForm {
  email: FormControl<string | null>;
}

@Component({
  selector: 'app-invite-sleeper',
  standalone: true,
  imports: [SiqCommonModule, ButtonsModule, FormsModule, SiqListItemsComponent],
  templateUrl: './invite-sleeper.component.html',
})
export class InviteSleeperComponent implements OnInit {
  @Input() sleeper: Sleeper | SetupSleeperData | null;
  @Output() sendInvitation = new EventEmitter<UpdateSleeperProperty>();
  inviteUserForm: FormGroup<InviteUserForm>;
  additionalBenefits: Array<ListItem>;
  controlFocusState = {};

  ngOnInit(): void {
    this.additionalBenefits = AccountSettingsStringResource.AdditionalBenefits;
    this.inviteUserForm = new FormGroup<InviteUserForm>({
      email: new FormControl('', [Validators.required, Validators.pattern(Regex.email)]),
    });
  }

  get hasErrors(): boolean {
    if (!this.controlFocusState['email']) {
      return this.inviteUserForm.controls.email.touched && !this.inviteUserForm.controls.email.valid;
    }
    return false;
  }

  get title(): string {
    return AccountSettingsStringResource.inviteSleeperTitle(this.sleeper?.firstName ?? '');
  }

  get subtitle(): string {
    return AccountSettingsStringResource.inviteSleeperSubtitle(this.sleeper?.gender ?? 9);
  }

  setControlFocusState(focusState: object): void {
    this.controlFocusState = focusState;
  }

  onSendInvitation(): void {
    this.sendInvitation.emit(this.inviteUserForm.value as UpdateSleeperProperty);
  }
}
