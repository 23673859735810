import { Injectable } from "@angular/core";
import { EdpStateModel, defaultEdpState } from "./edp.model";
import { Action, State, StateContext } from "@ngxs/store";
import { GetDYKTips, ResetEdpState } from "./edp.actions";
import { Observable, tap, throwError } from "rxjs";
import { DYKTip, DYKTipEntity } from "@models/edp/dyk-tips.model";
import { EdpService } from "@services/edp.service";
import { HttpErrorResponse } from "@angular/common/http";
import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import { insertItem, patch, updateItem } from "@ngxs/store/operators";

@State<EdpStateModel>({
  name: 'edp',
  defaults: defaultEdpState
})
@Injectable()
export class EdpState {

  constructor(private edpService: EdpService) { }

  @Action(GetDYKTips)
  getDykTips(ctx: StateContext<EdpStateModel>, action: GetDYKTips): Observable<Array<DYKTip>> {
    return this.edpService.getDYKTips(action.sleeper.sleeperId).pipe(
      tap({
        next: (response: Array<DYKTip>) => {
          const dykTips = new DYKTipEntity({ sleeperId: action.sleeper.sleeperId, tips: response });
          const sleeperDykTips = ctx.getState().dykTips.find((it) => it.sleeperId === action.sleeper.sleeperId);
          if (sleeperDykTips) {
            ctx.setState(
              patch({
                dykTips: updateItem((tip) => tip.sleeperId === action.sleeper.sleeperId, dykTips)
              })
            );
          } else {
            ctx.setState(
              patch({
                dykTips: insertItem(dykTips)
              })
            );
          }
        },
        error: err => this.handleError(ctx, err)
      })
    );
  }

  @Action(ResetEdpState)
  resetHealthState(ctx: StateContext<EdpStateModel>): void {
    ctx.setState({ ...defaultEdpState });
  }

  private handleError(ctx: StateContext<EdpStateModel>, err: HttpErrorResponse): Observable<HttpErrorResponse> {
    ctx.patchState({ error: FunctionsHelper.createSiqError(err.error.code, err.error.message), loading: false });
    return throwError(() => err);
  }


}