import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SiqError } from '@models/app/helpers.model';
import { CognitoLoginModel } from '@models/auth/cognito.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { RememberMe, SiqErrorCodes, SiqIconPosition } from '@shared/utils/helpers/enum.helper';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { Regex } from '@shared/utils/helpers/regex.helper';
import { LoginErrorHelper } from '@shared/utils/helpers/siq-errors.helper';
import { CloseModal } from '@store/app/app.actions';
import { AppSelectors } from '@store/app/app.selectors';
import { CognitoLogin, ResetAuthState, SetRememberMe } from '@store/auth/auth.actions';
import { AuthSelectors } from '@store/auth/auth.selectors';
import { Subject, delay, filter, takeUntil } from 'rxjs';

interface LoginForm {
  Email: FormControl<string | null>;
  Password: FormControl<string | null>;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  errorMsgAPI = '';
  loginForm!: FormGroup<LoginForm>;
  rememberMe = false;
  submitted = false;
  hasErrorLoginAPI = false;
  iconPosition = SiqIconPosition.right;
  loginText = 'Login';
  hasECIMIdentity = false;

  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private mixpanelService: MixpanelService, public dialog: MatDialog) { }

  get rememberMeLogin(): CognitoLoginModel | null {
    const login = localStorage.getItem('login');
    return login ? FunctionsHelper.decrypt<CognitoLoginModel>(login) : null;
  }

  get emailAfterRegistrationError400(): string | null {
    const email = localStorage.getItem('email');
    return email ? FunctionsHelper.decrypt(email) as string : null;
  }

  private get emailValue(): string {
    const email = this.emailAfterRegistrationError400;
    return this.rememberMeLogin ? this.rememberMeLogin.Email : email ? email : '';
  }

  private get passwordValue(): string {
    return this.rememberMeLogin ? this.rememberMeLogin.Password : '';
  }

  ngOnInit(): void {
    FunctionsHelper.scrollToTop();
    this.loginForm = new FormGroup<LoginForm>({
      Email: new FormControl(this.emailValue, [Validators.required, Validators.pattern(Regex.email)]),
      Password: new FormControl(this.passwordValue, [Validators.required])
    });
    this.rememberMe = this.rememberMeLogin ? true : false;
    this.store.dispatch(new ResetAuthState());

    this.store.select(AppSelectors.hasECIMIdentity).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(hasECIMIdentity => {
      this.hasECIMIdentity = hasECIMIdentity;
      if (hasECIMIdentity) {
        this.loginText = 'Congratulations on your new smart bed!';
      }
    });

    this.store.select(AuthSelectors.loginError).pipe(
      takeUntil(this._unsubscribeAll),
      delay(500),
      filter((error): error is SiqError => !!error)
    ).subscribe((error: SiqError) => {
      this.hasErrorLoginAPI = true;
      if (error.code >= SiqErrorCodes.Server) {
        this.openErrorModal(LoginErrorHelper.Errors.default);
      } else {
        if (error.code === SiqErrorCodes.Forbidden) {
          this.openErrorModal(LoginErrorHelper.Errors.forbidden);
        } else if (error.code === SiqErrorCodes.Unauthorized) {
          this.openErrorModal(LoginErrorHelper.Errors.unauthorized);
        } else if (error.code !== SiqErrorCodes.Eula && error.code !== SiqErrorCodes.PrivacyPolicy) {
          this.openErrorModal(LoginErrorHelper.Errors.default);
        }
      }
    });

    this.emailAfterRegistrationError400;
  }

  invalidValueErrorMsg(field: string): string {
    if (field === 'Email' && this.isInvalidField('Email')) {
      return LoginErrorHelper.Errors.email;
    }
    if (field === 'Password' && this.isInvalidField('Password')) {
      return LoginErrorHelper.Errors.password;
    }
    return '';
  }

  isInvalidField(field: string): boolean {
    return this.loginForm && this.submitted
      ? this.checkIsInputValid(this.loginForm.controls[field])
      : false;
  }

  checkIsInputValid(item: FormControl): boolean {
    if (this.hasErrorLoginAPI) {
      return true;
    }
    if (item) {
      return !item.valid;
    }
    return false;
  }

  hasErrors(field: string): boolean {
    return this.isInvalidField(field) && !this.hasErrorLoginAPI;
  }

  setRememberMeClicked(isRememberMeClicked: boolean): void {
    this.rememberMe = isRememberMeClicked;
  }

  login(): void {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.store.dispatch(new SetRememberMe(this.rememberMe ? RememberMe.yes : RememberMe.no));
      this.store.dispatch(new CognitoLogin(this.loginForm.value as CognitoLoginModel));
      this.mixpanelService.trackLogin(this.rememberMe);
    } else {
      if (this.isInvalidField('Email')) {
        this.mixpanelService.trackLoginValidationError(this.invalidValueErrorMsg('Email'));
      }
      if (this.isInvalidField('Password')) {
        this.mixpanelService.trackLoginValidationError(this.invalidValueErrorMsg('Password'));
      }
    }
    localStorage.removeItem('email');
  }

  forgotPassword(): void {
    this.store.dispatch(new Navigate(['forgot-password'], undefined, { state: { redirectTo: 'auth/login' } }));
  }

  register(): void {
    this.mixpanelService.trackRegistrationOpen();
    this.store.dispatch(new Navigate(['auth/register']));
  }

  openErrorModal(errorMsg: string): void {
    const modal = FunctionsHelper.createPopup(
      this.dialog,
      errorMsg,
      LoginErrorHelper.Errors.reset,
      'Login',
      'warning-icon',
      'yellow',
      'OK'
    );
    modal.componentInstance.onRightAction.subscribe(() => {
      this.store.dispatch(new CloseModal(modal));
      this.hasErrorLoginAPI = false;
    });
    modal.afterClosed().subscribe(() => {
      modal.componentInstance.onRightAction.unsubscribe();
      this.hasErrorLoginAPI = false;
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
