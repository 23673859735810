import { Component, OnDestroy, OnInit } from '@angular/core';
import { Bed, UpdateBedProperty } from '@models/bed/bed.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { Timezones } from '@shared/utils/helpers/timezones.helper';
import { UpdateBed } from '@store/beds/beds.actions';
import { SettingsSelectors } from '@store/settings/settings.selectors';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-timezone',
  templateUrl: './timezone.component.html',
})
export class TimezoneComponent implements OnInit, OnDestroy {

  selectedBed: Bed;
  timezonesValues = Timezones.values;
  timezonesOptions = Timezones.options;
  timezone = '';
  selectedUserTimezone = 0;
  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private mixpanelService: MixpanelService) { }

  ngOnInit(): void {
    this.store.select(SettingsSelectors.selectedBed).pipe(
      takeUntil(this._unsubscribeAll),
      filter((bed): bed is Bed => !!bed)
    ).subscribe((bed) => {
      this.selectedBed = bed;
      this.timezone = this.selectedBed.timezone;
      this.selectedUserTimezone = this.timezonesOptions.findIndex((timezone) => this.timezone === timezone);
    });
  }

  handleDropdownSelect(selectedIndex: number): void {
    this.timezone = this.timezonesOptions[selectedIndex];
    if (this.timezone !== this.selectedBed.timezone) {
      this.store.dispatch(new UpdateBed(this.selectedBed.bedId, new UpdateBedProperty({ timezone: this.timezone })));
      this.mixpanelService.trackBedTimezoneUpdate(this.selectedBed.bedId);
    }
  }

  back(): void {
    this.store.dispatch(new Navigate(['pages/smart-bed/bed-view']));
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
