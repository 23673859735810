import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SleeperStudyState } from '@models/sleeper/sleeper-study-state';
import { Sleeper } from '@models/sleeper/sleeper.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { CloseModal } from '@store/app/app.actions';
import { OptInSleeper, OptOutSleeper } from '@store/sleepers/sleepers.actions';

@Component({
  selector: 'app-opt-info',
  templateUrl: './opt-info.component.html'
})
export class OptInfoComponent {

  @Input() optInState: SleeperStudyState | null;
  @Input() selectedSleeper: Sleeper | null;

  constructor(private store: Store, private dialog: MatDialog, private mixpanelService: MixpanelService) { }

  click(): void {
    switch (this.optInState?.btnAction) {
      case 'optIn':
        if (this.selectedSleeper) {
          this.store.dispatch(new OptInSleeper(this.selectedSleeper)).subscribe(() => {
            this.store.dispatch(new Navigate(['pages/profile/details/sleep-study/sleep-study-success']));
          });
        }
        break;
      case 'optOut':
        this.openOptOutPopup();
        break;
      case 'createLogin':
        this.store.dispatch(new Navigate(['pages/account-settings/details/create-login'], undefined, { state: { redirectTo: 'pages/profile/details/sleep-study' } }));
        this.mixpanelService.trackSleepStudyCreate();
        break;
      default:
        break;
    }
  }

  private openOptOutPopup(): void {
    this.mixpanelService.trackSleepStudyOptOut();
    const modal = FunctionsHelper.createPopup(
      this.dialog, 
      'Are you sure?',
      'We appreciate your participation to advance the science of sleep and health.', 
      'Sleep science',
      'warning-icon',
      'yellow',
      'Keep Me In',
      'Opt Out');
    modal.componentInstance.onRightAction.subscribe(() => {
      this.store.dispatch(new CloseModal(modal));
    });
    modal.componentInstance.onLeftAction.subscribe(() => {
      if (this.selectedSleeper) { 
        this.store.dispatch(new OptOutSleeper(this.selectedSleeper));
        this.store.dispatch(new CloseModal(modal));
        this.store.dispatch(new Navigate(['pages/profile/details/sleep-study/sleep-study-success'], undefined, { state: { type: 'optOut' } }));
      }
    });
    modal.afterClosed().subscribe(() => {
      this.store.dispatch(new CloseModal(modal));
      modal.componentInstance.onRightAction.unsubscribe();
      modal.componentInstance.onLeftAction.unsubscribe();
    });
  }
}
