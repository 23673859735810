export class SleeperStudyState {
  icon: string;
  text: string;
  subText: string;
  btnType: string;
  btnText: string;
  btnAction: string;
  email: string;

  constructor(text: string, subText = '', email = '', btnType = '', btnText = '', btnAction = '', icon = 'sleep-science-research',) {
    this.icon = icon;
    this.text = text;
    this.subText = subText;
    this.btnType = btnType;
    this.btnText = btnText;
    this.btnAction = btnAction;
    this.email = email;
  }
}