export class Timezones {
  static options = [
      'US/Hawaii',
      'US/Alaska',
      'US/Pacific',
      'US/Arizona',
      'US/Mountain',
      'US/Central',
      'US/Eastern',
      'US/East-Indiana',
      'America/Mexico_City',
      'America/Tijuana',
      'America/Chihuahua',
      'America/Guatemala',
      'America/Bogota',
      'America/Caracas',
      'America/Manaus',
      'America/Santiago',
      'Canada/Eastern',
      'Canada/Saskatchewan',
      'Canada/Newfoundland',
      'Brazil/East',
      'America/Buenos_Aires',
      'America/Godthab',
      'America/Montevideo',
      'Atlantic/South_Georgia',
      'Atlantic/Azores',
      'Atlantic/Cape_Verde',
      'Africa/Casablanca',
      'Europe/London',
      'Europe/Berlin',
      'Europe/Belgrade',
      'Europe/Brussels',
      'Europe/Warsaw',
      'Africa/Algiers',
      'Asia/Amman',
      'Europe/Athens',
      'Asia/Beirut',
      'Africa/Cairo',
      'Africa/Harare',
      'Europe/Helsinki',
      'Asia/Jerusalem',
      'Europe/Minsk',
      'Africa/Windhoek',
      'Asia/Baghdad',
      'Asia/Kuwait',
      'Europe/Moscow',
      'Africa/Nairobi',
      'Asia/Tbilisi',
      'Asia/Tehran',
      'Asia/Muscat',
      'Asia/Baku',
      'Asia/Yerevan',
      'Asia/Kabul',
      'Asia/Yekaterinburg',
      'Asia/Karachi',
      'Asia/Calcutta',
      'Asia/Colombo',
      'Asia/Katmandu',
      'Asia/Novosibirsk',
      'Asia/Dhaka',
      'Asia/Rangoon',
      'Asia/Bangkok',
      'Asia/Krasnoyarsk',
      'Asia/Hong_Kong',
      'Asia/Irkutsk',
      'Asia/Kuala_Lumpur',
      'Australia/Perth',
      'Asia/Taipei',
      'Asia/Tokyo',
      'Asia/Seoul',
      'Asia/Yakutsk',
      'Australia/Adelaide',
      'Australia/Darwin',
      'Australia/Brisbane',
      'Australia/Sydney',
      'Pacific/Guam',
      'Australia/Hobart',
      'Asia/Vladivostok',
      'Asia/Magadan',
      'Pacific/Auckland',
      'Pacific/Fiji',
      'Pacific/Tongatapu',
      'Pacific/Midway'
  ];
  static values = Array.from({ length: this.options.length }, (_, index) => index);
}
