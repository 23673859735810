import { IccMessages } from "@models/health/sleep-health-icc.model";
import { CircadianRhythmEntity } from "@models/sessions/circadian-rhythm.model";
import { Rolling30DaysSleepData, RollingDataAggregates } from "@models/sessions/rolling-data-aggregates";
import { NoDataSession, SessionModel } from "@models/sessions/session.model";
import { SleepDataEntity, SleepDataStructure, YearSleepDataEntityModel } from "@models/sessions/sleep-data.model";
import { SiqError } from "@models/app/helpers.model";

export interface SessionsStateModel {
  selectedSession: SessionModel | NoDataSession | null;
  sleepData: Array<SleepDataEntity>;
  sleepDataWeek: Array<SleepDataStructure>;
  sleepDataYear: Array<YearSleepDataEntityModel>;
  iccMessages: Array<IccMessages>;
  rolling30Days: Array<Rolling30DaysSleepData>;
  rollingDataAggregates: Array<RollingDataAggregates>;
  circadianRhythm: Array<CircadianRhythmEntity>;
  loading: boolean;
  error: SiqError | null;
}

export const defaultSessionsState = {
  selectedSession: null,
  sleepData: [],
  sleepDataWeek: [],
  sleepDataYear: [],
  iccMessages: [],
  rolling30Days: [],
  rollingDataAggregates: [],
  circadianRhythm: [],
  loading: false,
  error: null
};
