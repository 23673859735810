import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SetupAddSleeperResponse, SetupSleeperData } from "@models/app/helpers.model";
import { Bed } from "@models/bed/bed.model";
import { BiqUser } from "@models/sleeper/biq-user.model";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { SleeperService } from "@services/sleeper.service";
import { BedSide } from "@shared/utils/helpers/enum.helper";
import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import { SiqPopupHelper } from "@shared/utils/helpers/siq-popup.helper";
import { SetSelectDefaultSleeperShown } from "@store/app/app.actions";
import { RefreshSleeperType, SetRegistrationState } from "@store/auth/auth.actions";
import { AuthSelectors } from "@store/auth/auth.selectors";
import { RefreshBeds } from "@store/beds/beds.actions";
import { BedsSelectors } from "@store/beds/beds.selectors";
import { LoadSleepers, UpdateSleeper } from "@store/sleepers/sleepers.actions";
import { SleepersSelectors } from "@store/sleepers/sleepers.selectors";
import { EMPTY, Observable, tap, throwError } from "rxjs";
import { CheckForBiqDetails, GetBiqUserDetails, ResetBedData, ResetBiqData, ResetSetupState, ResetSleeperData, SelectSetupBed, SetAddSecondSleeper, SetBedData, SetSettingFirstSleeper, SetSleeperDataProperty, SetupAccountOwner, SetupAddSleeper } from "./setup.actions";
import { SetupStateModel, defaultSetupState } from "./setup.model";

@State<SetupStateModel>({
  name: 'setup',
  defaults: defaultSetupState
})
@Injectable()
export class SetupState {

  constructor(
    private store: Store,
    private sleeperService: SleeperService,
    private siqPopupHelper: SiqPopupHelper
  ) { }

  // Actions
  @Action(SelectSetupBed)
  selectSetupBed(ctx: StateContext<SetupStateModel>, action: SelectSetupBed): void {
    ctx.patchState({
      selectedBedId: action.bed.bedId,
      selectedSide: this.getSelectedSide(action.bed),
      dualBed: action.bed.dualSleep
    });
  }

  @Action(SetBedData)
  setBedData(ctx: StateContext<SetupStateModel>, action: SetBedData): void {
    ctx.patchState({ bedData: action.data });
  }

  @Action(SetSleeperDataProperty)
  setSleeperDataProperty(ctx: StateContext<SetupStateModel>, action: SetSleeperDataProperty): void {
    ctx.patchState({
      sleeperData: {
        ...ctx.getState().sleeperData,
        [action.property]: action.value
      }
    });
  }

  @Action(SetAddSecondSleeper)
  setAddSecondSleeper(ctx: StateContext<SetupStateModel>, action: SetAddSecondSleeper): void {
    ctx.patchState({ shouldAddSecondSleeper: action.shouldAddSecondSleeper });
  }

  @Action(SetupAccountOwner)
  setupAccountOwner(ctx: StateContext<SetupStateModel>): Observable<void> {
    const sleeperData = ctx.getState().sleeperData;
    const accountOwner = this.store.selectSnapshot(SleepersSelectors.accountOwner);
    if (accountOwner && sleeperData) {
      return ctx.dispatch(new UpdateSleeper(accountOwner.sleeperId, sleeperData)).pipe(
        tap({
          next: () => {
            ctx.dispatch(new SetRegistrationState(13)).subscribe({
              next: () => {
                ctx.dispatch(new ResetSleeperData());
                ctx.dispatch(new SetSelectDefaultSleeperShown(true));
                ctx.dispatch(new RefreshBeds());
                ctx.dispatch(new LoadSleepers());
                ctx.dispatch(new RefreshSleeperType());
                FunctionsHelper.redirectToTheNextStepInRegistration(this.store);
              }
            });
          }
        })
      );
    }
    return EMPTY;
  }

  @Action(SetupAddSleeper)
  setupAddSleeper(ctx: StateContext<SetupStateModel>): Observable<SetupAddSleeperResponse | void> {
    const isChildBed = this.store.selectSnapshot(BedsSelectors.beds)?.find((bed) => bed.bedId === ctx.getState().selectedBedId)?.isKidsBed ?? false;
    const sleeperData = { ...ctx.getState().sleeperData, isChild: isChildBed } as SetupSleeperData;
    if (sleeperData) {
      return this.sleeperService.addSleeper(sleeperData).pipe(
        tap({
          next: (response) => {
            ctx.patchState({ selectedSleeperId: response.sleeperId });
            ctx.dispatch(new SetSelectDefaultSleeperShown(true));
            ctx.dispatch(new RefreshBeds());
            ctx.dispatch(new LoadSleepers());
          },
          error: (error) => this.handleError(ctx, error)
        })
      );
    }
    return EMPTY;
  }

  @Action(ResetSetupState)
  resetSetupState(ctx: StateContext<SetupStateModel>): void {
    ctx.setState({ ...defaultSetupState });
  }

  @Action(ResetBedData)
  resetBedData(ctx: StateContext<SetupStateModel>): void {
    ctx.patchState({ bedData: null });
  }

  @Action(ResetSleeperData)
  resetSleeperData(ctx: StateContext<SetupStateModel>): void {
    ctx.patchState({ sleeperData: null, selectedSleeperId: null });
  }

  @Action(SetSettingFirstSleeper)
  setSettingFirstSleeper(ctx: StateContext<SetupStateModel>, action: SetSettingFirstSleeper): void {
    ctx.patchState({ isSettingFirstSleeper: action.isSettingFirstSleeper });
  }

  @Action(GetBiqUserDetails)
  getBiqUserDetails(ctx: StateContext<SetupStateModel>): Observable<BiqUser> {
    const username = this.store.selectSnapshot(AuthSelectors.username);
    return this.sleeperService.biqUserDetails(username).pipe(
      tap({
        next: (response: BiqUser) => {
          ctx.patchState({ biqUser: new BiqUser(response) });
        },
        error: (err) => ctx.patchState({ error: FunctionsHelper.createSiqError(err.error.Error.Code, err.error.Error.Message), loading: false })
      })
    );
  }

  @Action(CheckForBiqDetails)
  checkForBiqDetails(ctx: StateContext<SetupStateModel>, action: CheckForBiqDetails): void {
    const biqUser = ctx.getState().biqUser;
    if (biqUser) {
      if (action.isPartnerSetup) {
        ctx.patchState({
          sleeperData: {
            ...ctx.getState().sleeperData,
            ...biqUser?.sleepPartner
          }
        });
      } else {
        ctx.patchState({
          sleeperData: {
            ...ctx.getState().sleeperData,
            ...biqUser?.sleeperInfo
          }
        });
      }
    }
  }

  @Action(ResetBiqData)
  resetBiqData(ctx: StateContext<SetupStateModel>): void {
    ctx.patchState({ biqUser: null });
  }

  // Helper methods
  private getSelectedSide(bed: Bed): number | null {
    if (bed.dualSleep) {
      if (bed.sleeperLeftId === '0' && bed.sleeperRightId === '0') {
        return null;
      } else if (bed.sleeperRightId === '0') {
        return BedSide.Right;
      }
    }
    return BedSide.Left;
  }

  private handleError(ctx: StateContext<SetupStateModel>, err: HttpErrorResponse): Observable<HttpErrorResponse> {
    ctx.patchState({ error: FunctionsHelper.createSiqError(err.error.Error.Code, err.error.Error.Message), loading: false });
    if (err.status >= 400 && err.status < 500 && err.status !== 401) {
      this.siqPopupHelper.showAlert('Setup');
    }
    return throwError(() => err);
  }
}
