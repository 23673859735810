import { Component } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { BedSettingsResources } from '@shared/utils/helpers/bed-settings.helper';

@Component({
  selector: 'app-troubleshooting-tips',
  templateUrl: './troubleshooting-tips.component.html',
})
export class TroubleshootingTipsComponent {

  troubleshootingTips = BedSettingsResources.TroubleshootingTips;
  havingIssuesCTA = BedSettingsResources.TroubleshootingCTAs.havingIssues;
  contactUs = BedSettingsResources.TroubleshootingCTAs.contactUs;

  constructor(private store: Store) {}

  back(): void {
    this.store.dispatch(new Navigate(['pages/smart-bed/details/connectivity']));
  }
}
