import { Injectable } from '@angular/core';
import { SiqApiService } from './siq-api.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EnvironmentsService } from './environments.service';
import { MixpanelService } from './mixpanel.service';
import { MatDialog } from '@angular/material/dialog';
import { SplashScreenService } from './splash-screen.service';
import { Observable, catchError, map, throwError } from 'rxjs';
import { InnerCircleLicenseState } from '@models/app/helpers.model';

@Injectable({
  providedIn: 'root'
})
export class TacoService extends SiqApiService {

  constructor(
    httpClient: HttpClient,
    environmentService: EnvironmentsService,
    mixpanelService: MixpanelService,
    dialog: MatDialog,
    splashScreenService: SplashScreenService) {
    super(httpClient, environmentService, mixpanelService, dialog, splashScreenService);
  }

  getPrivacyPolicy(): Observable<string> {
    return this.makeTextRequest('privacy-policy').pipe(
      map((response : string | HttpErrorResponse) => {
        if(response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    )
  }

  getInnerCircleLicenseState(): Observable<InnerCircleLicenseState> {
    return this.makeSleepNumberRequest<InnerCircleLicenseState>('terms', {}, 'GET').pipe(
      map((response : InnerCircleLicenseState | HttpErrorResponse) => {
        if(response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    )
  }

  acceptEulaAndPrivacy(): Observable<object> {
    return this.makeSiqRequest<object>('privacy-policy-license', 'siq', {}, 'PUT', false).pipe(
      map((response : object | HttpErrorResponse) => {
        if(response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    )
  }

  acceptEula(): Observable<object> {
    return this.makeSiqRequest<object>('license', 'siq', {}, 'PUT', false).pipe(
      map((response : object | HttpErrorResponse) => {
        if(response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    )
  }

  acceptPrivacyPolicy(): Observable<object> {
    return this.makeSiqRequest<object>('privacy-policy/jwt', 'siq', {}, 'PUT', false).pipe(
      map((response : object | HttpErrorResponse) => {
        if(response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    )
  }

  acceptInnerCircle(): Observable<object> {
    return this.makeSleepNumberRequest<object>('terms', { accepted: true }, 'PATCH').pipe(
      map((response : object | HttpErrorResponse) => {
        if(response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    )
  }
}
