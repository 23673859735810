<app-top-bar-navigation title="Register Smart Bed"/>
<div siqGrid fx-direction="column" class="px-24 py-32">
  <div siqGrid fx-direction="column" fx-layout-align="center center">
    <span class="siq-text-900 fs-20 white text-center">Create Password</span>
    <span class="siq-text-400 fs-16 white text-center mt-12">You will use this email and password across all Sleep Number<sup>®</sup> accounts.</span>
  </div>

  <form siqGrid fx-direction="column" class="my-48" [formGroup]="setupPasswordForm">

    <ng-container *ngIf="registerToken.disableEmailUpdate else inputEmail">
      <div siqGrid fx-direction="column" class="px-12">
        <span class="siq-text-400 fs-16 white">Email</span>
        <span class="siq-text-400 fs-16 white py-12">{{registerToken.email}}</span>
      </div>
    </ng-container>
    <ng-template #inputEmail>
      <app-siq-input
         class="px-12"
         formControlName="login"  
         name="login"
         type="email" 
         placeholder="Enter your email address"
         label="Email"
         [isInvalid]="hasErrors('login')"
         (controlFocus)="setControlFocusState($event)"
        />
      <span *ngIf="hasErrors('login')" class="siq-text-400 fs-14 red mt-12 px-12">Please enter a valid email address.</span>
    </ng-template>
   
    <app-siq-input
      formControlName="password"
      name="password"
      class="mt-24 px-12" 
      type="password" 
      [isPasswordField]="true"
      placeholder="Enter your password" 
      label="Create a password for your account"
      [isInvalid]="hasErrors('password')"
      (controlFocus)="setControlFocusState($event)"/>
    <span class="siq-text-400 fs-14 primary-200 mt-12 px-12" [class.red]="hasErrors('password')">{{passwordText}}</span>
  </form>

  <div siqGrid fx-direction="row" fx-layout-align="end center" fx-layout-align-sm="center center" class="px-12">
    <app-siq-button
    text="Create Account"
    btnType="primary-btn"
    width="178px"
    [isDisabled]="!isDisabled"
    (buttonClicked)="createAccount()" />
  </div>
</div>
