import { AnimationBuilder, AnimationPlayer, animate, style } from '@angular/animations';
import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SplashScreenService } from '@services/splash-screen.service';
import { SiqCommonModule } from '@shared/siq-common.module';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  imports: [SiqCommonModule, BrowserAnimationsModule],
  standalone: true
})
export class SplashScreenComponent implements AfterViewInit {

  shown = true;
  player!: AnimationPlayer;

  constructor(private splashScreenService: SplashScreenService, private animationBuilder: AnimationBuilder, private elRef: ElementRef) { }

  ngAfterViewInit(): void {
    this.splashScreenService.splashScreenSubject.subscribe({
      next: (showSplash: boolean) => {
        if (showSplash) {
          this.show();
        } else {
          this.hide();
        }
      }
    });
  }

  private show(): void {
    if (this.shown) {
      return;
    }
    this.shown = true;
    this.player =
      this.animationBuilder
        .build([
          style({
            opacity: '0',
            zIndex: '99999'
          }),
          animate('40ms ease', style({ opacity: '1' }))
        ]).create(this.elRef.nativeElement);
    this.player.play();
  }

  private hide(): void {
    if (!this.shown) {
      return;
    }
    this.shown = false;
    this.player =
      this.animationBuilder
        .build([
          style({ opacity: '1' }),
          animate('400ms ease', style({
            opacity: '0',
            zIndex: '-10'
          }))
        ]).create(this.elRef.nativeElement);
    this.player.play();
  }
}
