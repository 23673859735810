import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { Navigate } from "@ngxs/router-plugin";
import { Store } from "@ngxs/store";
import { SleepersSelectors } from "@store/sleepers/sleepers.selectors";
import { of } from "rxjs";

export const ToOnboardingGuard: CanActivateFn = () => {
  const store = inject(Store);
  const selectedSleeper = store.selectSnapshot(SleepersSelectors.selectedSleeper);
  if (selectedSleeper) {
    if (!selectedSleeper.firstSessionRecorded) {
      store.dispatch(new Navigate(['pages/sleep/onboarding']));
      return of(false);
    }
    return of(true);
  }
  return of(false);
};

// This is used so when the user refreshes page on the onboarding screen
// she will be redirected to sleep
// example
// if the logged in sleeper has data (she is on the sleep), and switches to sleeper who has onboarding
// if she refreshes the page the selected sleeper will be logged in, but the page would be onboarding
// this guard prevents the behavior above
export const ToSleepGuard: CanActivateFn = () => {
  const store = inject(Store);
  const selectedSleeper = store.selectSnapshot(SleepersSelectors.selectedSleeper);
  if (selectedSleeper) {
    if (selectedSleeper.firstSessionRecorded) {
      store.dispatch(new Navigate(['pages/sleep']));
      return of(false);
    }
    return of(true);
  }
  return of(false);
};
