import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { BreakpointState, ResizeService } from '@services/resize.service';
import { LinksHelper } from '@shared/utils/helpers/links-helper';
import { OpenLinkInNewTab } from '@store/app/app.actions';
import { Subject, takeUntil } from 'rxjs';

enum StoreLink {
  AppleStore, 
  GooglePlay
}
@Component({
  selector: 'app-store-btns',
  templateUrl: './app-store-btns.component.html',
})
export class AppStoreBtnsComponent implements OnInit, OnDestroy {
  @Input() direction: string;
  @Input() smallScreen = 'row';
  @Input() mediumScreen = 'row';
  @Input() isAppDownload = false;
  @Input() sourceScreen: string;
  @Input() openBreatheIq = false;
  btnsDirection: string;
  breakpoint: BreakpointState;

  private _unsubscribeAll = new Subject<void>();

  constructor(private resizeService: ResizeService, private store: Store, private mixpanelService: MixpanelService) { }

  ngOnInit(): void {
    this.resizeService.isMobile.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((res) => {
      this.breakpoint = res;
    });
    this.btnsDirection = this.isAppDownload && this.isRightRail ? 'column' : this.direction;
  }

  get marginClass(): string {
    if(this.isAppDownload && this.isRightRail) {
      return 'ml-0 mt-16';
    }
    if(this.breakpoint.isTablet) {
      return 'ml-0 mt-16';
    }
    if (this.breakpoint.isMobile) {
      if (this.smallScreen === 'row') {
        return 'ml-16 mt-0';
      } else {
        return 'mt-16 ml-0';
      }
    } else {
      if (this.direction === 'column') {
        return 'mt-16 ml-0';
      } else {
        return 'ml-16 mt-0';
      }
    }
  }

  private get isRightRail(): boolean {
    return this.sourceScreen === 'right_rail';
  }

  openStoreLink(store: StoreLink): void {
    if(this.openBreatheIq) {
      this.store.dispatch(new OpenLinkInNewTab(LinksHelper.breatheIqApp));
    } else {
      switch(store) {
        case StoreLink.AppleStore:
          this.store.dispatch(new OpenLinkInNewTab(LinksHelper.appStore));
          this.mixpanelService.trackDownloadTheAppCard('ios', this.sourceScreen);
          break;
        case StoreLink.GooglePlay:
          this.store.dispatch(new OpenLinkInNewTab(LinksHelper.googlePlay));
          this.mixpanelService.trackDownloadTheAppCard('android', this.sourceScreen);
          break;
      }
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
