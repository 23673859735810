import { Component } from '@angular/core';
import { SiqLottieModule } from '@shared/lottie.module';
import { SiqCommonModule } from '@shared/siq-common.module';
import { LottieHelper } from '@shared/utils/helpers/lottie-helper';

@Component({
  selector: 'app-success-with-particles',
  templateUrl: './success-with-particles.component.html',
  standalone: true,
  imports: [SiqCommonModule, SiqLottieModule]
})
export class SuccessWithParticlesComponent {
  animation = LottieHelper.createAnimationOptions('success', false);
}
