<div siqGrid fx-direction="column" class="select-container">
  <app-siq-dropdown-input *ngIf="dropdownInputLabel" [label]="dropdownInputLabel" [value]="dropdownInputValue" [placeholder]="dropdownInputPlaceholder" [description]="description" class="px-12"/>
  <div [class.position-relative]="dropdownInputLabel" class="dropdown-container">
    <div *ngIf="showDropdownBody" siqGrid fx-direction="column" class="dropdown-body background-primary br-8 mt-24" [class.input-dropdown]="dropdownInputLabel" #dropdownBody>
      <div [ngClass]="paddingClass">
        <div siqGrid fx-direction="row" fx-layout-align="center center"
          *ngFor="let value of dropdownValues; index as i" 
          class="dropdown-item cursor-pointer" 
          [id]="(getDropdownItemId(i))"
          [class.mb-12]="i < dropdownValues.length - 1"
          [class.selected-item]="isSelected(value)"
          (click)="selectValue(value)">
          <span class="siq-text-900 fs-18 text-center" [ngClass]="getTextColor(i)">{{ dropdownOptions[i] }}</span>
        </div>
     </div>
    </div>
  </div>
</div>