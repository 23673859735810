import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { SiqNotification } from "@models/app/helpers.model";
import { PopupData } from "@models/app/modal-data.model";
import { CognitoLoginModel } from "@models/auth/cognito.model";
import { LoginResponse } from "@models/auth/login.model";
import { AppSetting, AppSettingEntityModel } from "@models/sleeper/application-settings.model";
import { SleeperProfile } from "@models/sleeper/sleeper-profile.model";
import { Sleeper, SleeperEntity, UpdateSleeperPasswordResponse } from "@models/sleeper/sleeper.model";
import { Question, QuestionProperties, WellnessCategory, WellnessProfile, WellnessProfileResponse } from "@models/sleeper/wellness-profile.model";
import { Navigate } from "@ngxs/router-plugin";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { patch, updateItem } from "@ngxs/store/operators";
import { MixpanelService } from "@services/mixpanel.service";
import { SleeperService } from "@services/sleeper.service";
import { PopupComponent } from "@shared/components/popup/popup.component";
import { AccountSettingsStringResource } from "@shared/utils/helpers/account-settings.helper";
import { WellnessProfileAction } from "@shared/utils/helpers/enum.helper";
import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import { ServerErrors } from "@shared/utils/helpers/siq-errors.helper";
import { SiqPopupHelper } from "@shared/utils/helpers/siq-popup.helper";
import { LoadAccountDetails, SetSiqNotification } from "@store/app/app.actions";
import { CognitoLogin, SetLoginData } from "@store/auth/auth.actions";
import { AuthSelectors } from "@store/auth/auth.selectors";
import { SelectDefaultSession } from "@store/sessions/sessions.actions";
import { Observable, tap, throwError } from "rxjs";
import WELLNESS_QUESTIONS from 'src/assets/json_data/wellnessQuestions.json';
import { CancelInviteSleeperEmail, LoadAllTimeAverages, LoadSleepStudy, LoadSleeperWellnessProfile, LoadSleeperWellnessQuestions, LoadSleepers, OptInSleeper, OptOutSleeper, RefreshSleepers, ResendInvitation, ResetSleeperState, SelectDefaultSleeper, SelectSleeper, SetDefaultSelectedSleeper, SetSleeperWellnessProfile, UpdateNativePassword, UpdateSleeper, UpdateSleeperEmail, UpdateSleeperPassword, UpdateSleepersSide } from "./sleepers.actions";
import { SleepersStateModel, defaultSleeperState } from "./sleepers.model";

@State<SleepersStateModel>({
  name: 'sleepers',
  defaults: defaultSleeperState
})
@Injectable()
export class SleepersState {

  constructor(private store: Store, private sleeperService: SleeperService, private siqPopupHelper: SiqPopupHelper, private mixpanelService: MixpanelService) { }

  @Action(LoadSleepers)
  loadSleepers(ctx: StateContext<SleepersStateModel>): Observable<SleeperEntity> {
    ctx.patchState({ loading: true });
    return this.getSleepers(ctx, true);
  }

  @Action(RefreshSleepers)
  refreshSleepers(ctx: StateContext<SleepersStateModel>): Observable<SleeperEntity> {
    ctx.patchState({ loading: true });
    return this.getSleepers(ctx, false);
  }

  @Action(SetDefaultSelectedSleeper)
  setDefaultSelectedSleeper(ctx: StateContext<SleepersStateModel>): void {
    const loggedInSleeperId = this.store.selectSnapshot(AuthSelectors.loggedInSleeperId);
    const defaultSleeperId = localStorage.getItem(`defaultSleeper${loggedInSleeperId}`);
    ctx.patchState({ defaultSleeperId });
    const sleeper = ctx.getState().sleepers.find(c => c.sleeperId === defaultSleeperId);
    if (defaultSleeperId && sleeper && sleeper.bedId !== '0') {
      ctx.patchState({ selectedSleeper: sleeper });
    } else {
      const defaultSleeper = this.getDefaultSelectedSleepers(ctx);
      if (defaultSleeper) {
        ctx.dispatch(new SelectDefaultSleeper(defaultSleeper.sleeperId));
        ctx.patchState({ selectedSleeper: defaultSleeper });
      }
    }
    ctx.dispatch(new LoadAccountDetails());
  }

  @Action(SelectDefaultSleeper)
  selectDefaultSleeper(ctx: StateContext<SleepersStateModel>, action: SelectDefaultSleeper): void {
    localStorage.setItem('defaultSleeper' + this.store.selectSnapshot(AuthSelectors.loggedInSleeperId), action.sleeperId);
    ctx.patchState({ defaultSleeperId: action.sleeperId });
    ctx.patchState({ selectedSleeper: ctx.getState().sleepers.find(s => s.sleeperId === action.sleeperId) });
  }

  @Action(SelectSleeper)
  selectSleeper(ctx: StateContext<SleepersStateModel>, action: SelectSleeper): void {
    ctx.patchState({ selectedSleeper: ctx.getState().sleepers.find(s => s.sleeperId === action.sleeperId) });
    ctx.dispatch(new SelectDefaultSession());
  }

  @Action(ResetSleeperState)
  resetSleeperState(ctx: StateContext<SleepersStateModel>): void {
    ctx.setState({ ...defaultSleeperState });
  }

  @Action(UpdateSleeper)
  updateSleeper(ctx: StateContext<SleepersStateModel>, action: UpdateSleeper): Observable<Sleeper | HttpErrorResponse> {
    ctx.patchState({ loading: true });
    return this.sleeperService.updateSleeper(action.sleeperId, action.payload).pipe(
      tap({
        next: () => {
          ctx.patchState({ loading: false });
          const selectedSleeper = ctx.getState().selectedSleeper;
          let sleeper = ctx.getState().sleepers.find((sleeper) => sleeper.sleeperId === action.sleeperId);
          if (sleeper) {
            Object.keys(action.payload).forEach((key) => {
              sleeper = Object.assign(new Sleeper(), { ...sleeper, [key]: action.payload[key] });
            });
            ctx.setState(
              patch({
                sleepers: updateItem<Sleeper>(it => it.sleeperId === action.sleeperId,
                  sleeper),
              })
            );
            if (selectedSleeper?.sleeperId === action.sleeperId) {
              ctx.patchState({ selectedSleeper: sleeper });
            }
          }
        },
        error: error => this.showOopsScreen(ctx, error)
      })
    );
  }

  @Action(UpdateSleepersSide)
  updateSleepersSide(ctx: StateContext<SleepersStateModel>, action: UpdateSleepersSide): void {
    const sleepers = ctx.getState().sleepers.map((sleeper: Sleeper) => {
      if (sleeper.bedId === action.bedId) return Object.assign(new Sleeper(), { ...sleeper, side: sleeper.side ? 0 : 1 });
      return sleeper;
    });

    ctx.setState(
      patch({
        sleepers
      })
    );
  }

  @Action(LoadAllTimeAverages)
  loadAllTimeAverages(ctx: StateContext<SleepersStateModel>, action: LoadAllTimeAverages): Observable<SleeperProfile | HttpErrorResponse> {
    return this.sleeperService.getAllTimeAverages(action.sleeperId).pipe(
      tap({
        next: (response: SleeperProfile) => {
          const sleepers = ctx.getState().sleepers.map(sleeper => {
            if (sleeper.sleeperId === action.sleeperId) {
              sleeper = Object.assign(new Sleeper(sleeper), { ...sleeper, profile: new SleeperProfile(response) });
              ctx.patchState({ selectedSleeper: sleeper });
            }
            return sleeper;
          });
          ctx.patchState({ sleepers });
        },
        error: error => this.handleError(ctx, error)
      })
    );
  }

  @Action(LoadSleepStudy)
  loadSleepStudy(ctx: StateContext<SleepersStateModel>, action: LoadSleepStudy): Observable<AppSettingEntityModel> {
    ctx.patchState({ loading: true });
    return this.sleeperService.getSleeperSleepStudy(action.sleeper.accountId, action.sleeper.sleeperId).pipe(
      tap({
        next: response => {
          this.mapSleepStudyToSleeper(ctx, action.sleeper, response);
        },
        error: (err: HttpErrorResponse) => {
          this.mapSleepStudyToSleeper(ctx, action.sleeper, { appSettings: [] });
          return this.handleError(ctx, err);
        }
      })
    );
  }

  @Action(OptInSleeper)
  optInSleeper(ctx: StateContext<SleepersStateModel>, action: OptInSleeper): Observable<AppSetting> {
    ctx.patchState({ loading: true });
    return this.sleeperService.changeSleeperSleepStudyState(action.sleeper.accountId, action.sleeper.sleeperId, 'optIn').pipe(
      tap({
        next: (response: AppSetting) => {
          this.mapSleepStudyToSleeper(ctx, action.sleeper, { appSettings: [response] });
          this.mixpanelService.trackSleepStudyOptIn('yes');
        },
        error: (err: HttpErrorResponse) => {
          this.mapSleepStudyToSleeper(ctx, action.sleeper, { appSettings: [] });
          this.mixpanelService.trackSleepStudyOptIn('no');
          return this.handleError(ctx, err);
        }
      })
    );
  }

  @Action(OptOutSleeper)
  optOutSleeper(ctx: StateContext<SleepersStateModel>, action: OptOutSleeper): Observable<AppSetting> {
    return this.sleeperService.changeSleeperSleepStudyState(action.sleeper.accountId, action.sleeper.sleeperId, 'optOut').pipe(
      tap({
        next: (response: AppSetting) => {
          this.mixpanelService.trackSleepStudyOptOutConfirm('opt_out', 'yes');
          this.mixpanelService.trackSleepStudyOptOutDone();
          this.mapSleepStudyToSleeper(ctx, action.sleeper, { appSettings: [response] });
        },
        error: error => {
          this.mixpanelService.trackSleepStudyOptOutConfirm('opt_out', 'no');
          return this.handleError(ctx, error);
        }
      })
    );
  }

  @Action(UpdateSleeperEmail)
  updateSleeperEmail(ctx: StateContext<SleepersStateModel>, action: UpdateSleeperEmail): Observable<LoginResponse | HttpErrorResponse> {
    return this.sleeperService.updateSleeperEmail(action.sleeper?.sleeperId, action.data).pipe(
      tap({
        next: () => {
          const selectedSleeper = ctx.getState().selectedSleeper;
          const updatedSleeper = Object.assign(new Sleeper(), { ...action.sleeper, emailValidated: false, email: action.data.login });
          ctx.setState(
            patch({
              sleepers: updateItem<Sleeper>(it => it.sleeperId === action.sleeper?.sleeperId,
                updatedSleeper),
            })
          );
          if (selectedSleeper?.sleeperId === action.sleeper?.sleeperId) {
            ctx.patchState({ selectedSleeper: updatedSleeper });
          }
          this.mixpanelService.trackAccountEmailUpdate('yes');
        },
        error: error => {
          return this.showOopsScreen(ctx, error);
        }
      })
    );
  }

  @Action(UpdateSleeperPassword)
  updateSleeperPassword(ctx: StateContext<SleepersStateModel>, action: UpdateSleeperPassword): Observable<UpdateSleeperPasswordResponse> {
    return this.sleeperService.updateSleeperPassword(action.sleeper?.sleeperId, action.data).pipe(
      tap({
        next: (response: UpdateSleeperPasswordResponse) => {
          const passwordUpdateCTA = AccountSettingsStringResource.passwordUpdateSuccess(response.login);
          const popupData = new PopupData({
            title: passwordUpdateCTA.title,
            text: passwordUpdateCTA.text,
            icon: 'dark-checkmark',
            type: 'green',
            screen: 'Account Settings - Edit Password',
            rightBtnTxt: 'OK',
            leftBtnTxt: ''
          });
          const modal = this.handlePasswordUpdateSuccess(popupData);
          modal.afterClosed().subscribe(() => {
            modal.componentInstance.onRightAction.unsubscribe();
            ctx.dispatch(new Navigate(['pages/account-settings']));
          });

          this.mixpanelService.trackAccountPasswordUpdate(response.passwordSetInCognito ? 'yes' : 'no', 'yes');
          const loggedInSleeper = this.store.selectSnapshot(AuthSelectors.userInfo)?.sleeperId;
          const userCredentials = localStorage.getItem('login');
          const storedLogin = userCredentials ? FunctionsHelper.decrypt<CognitoLoginModel>(userCredentials) : null;
          if (storedLogin && loggedInSleeper === action.sleeper?.sleeperId) {
            ctx.dispatch(new SetLoginData({ Email: storedLogin.Email, Password: action.data.newPassword } as CognitoLoginModel));
          }
        },
        error: error => {
          this.mixpanelService.trackAccountPasswordUpdateNoCognito('no');
          return this.showOopsScreen(ctx, error);
        }
      })
    );
  }

  @Action(UpdateNativePassword)
  updateNativePassword(ctx: StateContext<SleepersStateModel>, action: UpdateNativePassword): Observable<UpdateSleeperPasswordResponse> {
    return this.sleeperService.updateNativePassword(action.sleeperId, action.data).pipe(
      tap({
        next: (response: UpdateSleeperPasswordResponse) => {
          const passwordUpdateCTA = AccountSettingsStringResource.passwordUpdateSuccess(response.login);
          const cognitoLogin = { Email: response.login, Password: action.data.newPassword };
          this.mixpanelService.trackUpdateNativePassword('yes');
          const popupData = new PopupData({
            title: passwordUpdateCTA.title,
            text: passwordUpdateCTA.text,
            icon: 'dark-checkmark',
            type: 'green',
            screen: 'Update Native Password',
            rightBtnTxt: 'OK',
            leftBtnTxt: ''
          });

          const modal = this.handlePasswordUpdateSuccess(popupData);
          modal.afterClosed().subscribe(() => {
            ctx.dispatch(new CognitoLogin(cognitoLogin as CognitoLoginModel));
            const loggedInSleeper = this.store.selectSnapshot(AuthSelectors.siqAuth)?.userId;
            const userCredentials = localStorage.getItem('login');
            const storedLogin = userCredentials ? FunctionsHelper.decrypt<CognitoLoginModel>(userCredentials) : null;
            if (storedLogin && loggedInSleeper === action.sleeperId) {
              ctx.dispatch(new SetLoginData(cognitoLogin as CognitoLoginModel));
            }
            modal.componentInstance.onLeftAction.unsubscribe();
          });
        },
        error: error => {
          this.mixpanelService.trackUpdateNativePassword('no');
          return this.showOopsScreen(ctx, error);
        }
      })
    );
  }

  @Action(LoadSleeperWellnessProfile)
  loadSleeperWellnessProfile(ctx: StateContext<SleepersStateModel>, action: LoadSleeperWellnessProfile): Observable<WellnessProfileResponse> {
    return this.sleeperService.getSleeperWellnessProfile(action.sleeperId).pipe((
      tap({
        next: (response: WellnessProfileResponse) => {
          const selectedSleeper = ctx.getState().selectedSleeper;
          const sleeper = ctx.getState().sleepers.find((sleeper) => sleeper.sleeperId === action.sleeperId);
          const wellnessProfile = response ? new WellnessProfile(response.wellnessProfile) : new WellnessProfile();
          const sleeperWithWellnessProfile = new Sleeper({ ...sleeper, wellnessProfile } as Sleeper);
          ctx.setState(
            patch({
              sleepers: updateItem<Sleeper>(it => it.sleeperId === action.sleeperId,
                sleeperWithWellnessProfile),
              selectedSleeper: selectedSleeper?.sleeperId === action.sleeperId ? sleeperWithWellnessProfile : selectedSleeper
            })
          );
        },
        error: error => {
          if (error.status === 404) {
            ctx.dispatch(new SetSleeperWellnessProfile(action.sleeperId, {}, WellnessProfileAction.Add));
          }
          return this.handleError(ctx, error);
        }
      })
    ));
  }

  @Action(LoadSleeperWellnessQuestions)
  loadSleeperWellnessQuestions(ctx: StateContext<SleepersStateModel>, action: LoadSleeperWellnessQuestions): void {
    const wellnessQuestionsArr: Array<WellnessCategory> = [];
    const categories = WELLNESS_QUESTIONS["categories"];
    const selectedSleeper = ctx.getState().selectedSleeper;
    categories.forEach((it) => {
      const { id, name, description, questions } = it;
      const keys = Object.keys(questions);
      const wellnessQuestion = {};
      keys.forEach((key) => {
        wellnessQuestion[key] = new QuestionProperties(questions[key]);
      });
      wellnessQuestionsArr.push(new WellnessCategory(id, name, description, new Question(wellnessQuestion)));
    });
    const sleeper = ctx.getState().sleepers.find((sleeper) => sleeper.sleeperId === action.sleeperId);
    if (sleeper) {
      const sleeperWithWellnessQuestions = new Sleeper({ ...sleeper, wellnessQuestions: wellnessQuestionsArr } as Sleeper);
      ctx.setState(
        patch({
          sleepers: updateItem<Sleeper>(it => it.sleeperId === action.sleeperId,
            sleeperWithWellnessQuestions),
          selectedSleeper: selectedSleeper?.sleeperId === action.sleeperId ? sleeperWithWellnessQuestions : selectedSleeper
        })
      );
    }
  }

  @Action(SetSleeperWellnessProfile)
  setSleeperWellnessProfile(ctx: StateContext<SleepersStateModel>, action: SetSleeperWellnessProfile): Observable<WellnessProfileResponse> {
    const wellnessProfileAction = action.actionType === WellnessProfileAction.Add ?
      this.sleeperService.addSleeperWellnessProfile(action.sleeperId, action.data) :
      this.sleeperService.updateSleeperWellnessProfile(action.sleeperId, action.data);
    return wellnessProfileAction.pipe(
      tap({
        next: () => ctx.dispatch(new LoadSleeperWellnessProfile(action.sleeperId)),
        error: (err) => this.handleError(ctx, err)
      })
    );
  }

  @Action(CancelInviteSleeperEmail)
  cancelInviteSleeperEmail(ctx: StateContext<SleepersStateModel>, action: CancelInviteSleeperEmail): Observable<object> {
    return this.sleeperService.cancelInviteSleeperEmail(action.accountId, action.sleeperId).pipe(
      tap({
        next: () => {
          ctx.dispatch(new Navigate(['pages/account-settings']));
        },
        error: (err) => this.showOopsScreen(ctx, err)
      })
    );
  }

  @Action(ResendInvitation)
  resendInvitation(ctx: StateContext<SleepersStateModel>, action: ResendInvitation): Observable<object> {
    ctx.patchState({ loading: true });
    return this.sleeperService.resendInvitation(action.accountId, action.sleeperId).pipe(
      tap({
        next: () => {
          ctx.dispatch(new Navigate(['pages/account-settings']));
          ctx.dispatch(new SetSiqNotification(new SiqNotification('Invitation sent!', 'account settings', 'other')));
        },
        error: (err: HttpErrorResponse) => this.showOopsScreen(ctx, err)
      })
    );
  }

  private formatSleepersResponse(sleepers: Array<Sleeper>): Array<Sleeper> {
    const adultSleepers = sleepers.filter(s => !s.isChild && !s.isAccountOwner).sort((s1, s2) => FunctionsHelper.compareByProp(s1, s2, 'bedId'));
    const kidSleepers = sleepers.filter(s => s.isChild).sort((s1, s2) => FunctionsHelper.compareByProp(s1, s2, 'bedId'));
    const accountOwner = sleepers.filter(s => s.isAccountOwner);
    return accountOwner.concat(adultSleepers).concat(kidSleepers).map((sleeper: Sleeper) => new Sleeper(sleeper));
  }

  private getDefaultSelectedSleepers(ctx: StateContext<SleepersStateModel>): Sleeper | null {
    const loggedInSleeper = ctx.getState().sleepers.find(s => s.sleeperId === this.store.selectSnapshot(AuthSelectors.loggedInSleeperId));
    if (loggedInSleeper && loggedInSleeper.bedId !== '0') {
      return loggedInSleeper;
    } else {
      const sleeper = ctx.getState().sleepers.find(s => s.bedId !== '0');
      return sleeper ? sleeper : null;
    }
  }

  private handleError(ctx: StateContext<SleepersStateModel>, err: HttpErrorResponse): Observable<HttpErrorResponse> {
    ctx.patchState({ error: FunctionsHelper.createSiqError(err.error.Error.Code, err.error.Error.Message), loading: false });
    return throwError(() => err);
  }

  private showOopsScreen(ctx: StateContext<SleepersStateModel>, initialError: HttpErrorResponse): Observable<HttpErrorResponse> {
    ctx.patchState({ error: FunctionsHelper.createSiqError(initialError.error.Error.Code, initialError.error.Error.Message), loading: false });
    if (initialError.status >= 400 && initialError.status < 500 && initialError.status !== 401) {
      if (initialError.status === 400) {
        if (initialError.error.Error.Message.includes(AccountSettingsStringResource.InviteSleeperError.message)) {
          this.handleAccountAlreadyExistsError();
        } else {
          this.siqPopupHelper.showAlert('Sleeper', initialError.error.Error.Message);
        }
      } else {
        this.siqPopupHelper.showAlert('Sleeper');
      }
    }
    return throwError(() => initialError);
  }

  private handleAccountAlreadyExistsError(): void {
    const popupData = new PopupData({
      title: ServerErrors.ApiErrors.error400.title,
      text: ServerErrors.ApiErrors.accountExists.text,
      icon: 'warning-icon',
      type: 'yellow',
      screen: 'Invite Sleeper',
      rightBtnTxt: 'Try Different Email',
      leftBtnTxt: 'Cancel'
    });
    const modal = this.siqPopupHelper.createPopup(popupData);
    modal.componentInstance.onClose.subscribe(() => {
      modal.close();
    });
    modal.componentInstance.onLeftAction.subscribe(() => {
      modal.close();
    });
    modal.componentInstance.onRightAction.subscribe(() => {
      modal.close();
    });
    modal.afterClosed().subscribe(() => {
      modal.close();
      modal.componentInstance.onRightAction.unsubscribe();
      modal.componentInstance.onLeftAction.unsubscribe();
      modal.componentInstance.onClose.unsubscribe();
    });
  }

  private mapSleepStudyToSleeper(ctx: StateContext<SleepersStateModel>, selectedSleeper: Sleeper, sleepStudy: AppSettingEntityModel): void {
    const sleepers = ctx.getState().sleepers.map(sleeper => {
      if (sleeper.sleeperId === selectedSleeper.sleeperId) {
        if (sleepStudy.appSettings.length === 0) {
          sleeper = Object.assign(new Sleeper(sleeper), { ...sleeper, appSettings: [] });
        } else {
          sleeper = Object.assign(new Sleeper(sleeper), { ...sleeper, appSettings: sleepStudy.appSettings.map(setting => Object.assign(new AppSetting(), setting)) });
        }
        ctx.patchState({ selectedSleeper: sleeper });
      }
      return sleeper;
    });
    ctx.patchState({ sleepers });
  }

  private getSleepers(ctx: StateContext<SleepersStateModel>, setDefaultSleeper: boolean): Observable<SleeperEntity> {
    return this.sleeperService.getSleepers().pipe(
      tap({
        next: (result: SleeperEntity) => {
          ctx.patchState({ sleepers: this.formatSleepersResponse(result.sleepers), loading: false });
          if (setDefaultSleeper) {
            ctx.dispatch(new SetDefaultSelectedSleeper());
          } else {
            // to refresh selected sleeper
            const selectedSleeper = ctx.getState().selectedSleeper;
            if(selectedSleeper) {
              const sleeper = ctx.getState().sleepers.find(c => c.sleeperId === selectedSleeper.sleeperId);
              ctx.patchState({selectedSleeper: sleeper});
            }
          }
        },
        error: error => this.handleError(ctx, error)
      })
    );
  }

  private handlePasswordUpdateSuccess(popupData: PopupData): MatDialogRef<PopupComponent> {
    const modal = this.siqPopupHelper.createPopup(popupData);
    modal.componentInstance.onRightAction.subscribe(() => {
      modal.close();
    });
    return modal;
  }
}
