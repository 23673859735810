import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { UserInfo } from "@models/auth/cognito.model";
import { Bed } from "@models/bed/bed.model";
import { Sleeper } from "@models/sleeper/sleeper.model";
import { Navigate } from "@ngxs/router-plugin";
import { Store } from "@ngxs/store";
import { AppSelectors } from "@store/app/app.selectors";
import { SetRegistrationState } from "@store/auth/auth.actions";
import { AuthSelectors } from "@store/auth/auth.selectors";
import { BedsSelectors } from "@store/beds/beds.selectors";
import { SleepersSelectors } from "@store/sleepers/sleepers.selectors";
import { Observable, combineLatest, filter, map, take } from "rxjs";

export const ToSelectDefaultSleeper: CanActivateFn = () => {
  const store = inject(Store);
  const loggedInSleeper$: Observable<Sleeper> = store.select(SleepersSelectors.loggedInSleeper);
  const beds$: Observable<Array<Bed>> = store.select(BedsSelectors.bedsWithSleepers);
  const userInfo$: Observable<UserInfo | null> = store.select(AuthSelectors.userInfo);
  return combineLatest([loggedInSleeper$, beds$, userInfo$]).pipe(
    filter(([loggedInSleeper, beds, userInfo]) => !!loggedInSleeper && !!beds && !!userInfo),
    map(([loggedInSleeper, beds, userInfo]) => {
      if(userInfo?.registrationState !== 13) {
        store.dispatch(new SetRegistrationState(10));
        store.dispatch(new Navigate(['pages/setup/select-bed']));
        return false;
      }
      if (!store.selectSnapshot(AppSelectors.selectDefaultSleeperShown)) {

        if (oneBedOneSleeper(beds) || loggedInSleeper.isChild) {
          return true;
        }
        store.dispatch(new Navigate(['pages/select-default-sleeper']));
        return false;
      }
      return true;
    }),
    take(1)
  );
};

// check if there is only  one bed and one sleeper setup up
// in this case select default sleeper will not be shown
function oneBedOneSleeper(beds: Array<Bed>): boolean {
  return beds.length === 1 && beds.find(bed => (bed.sleeperLeftId !== '0' && bed.sleeperRightId === '0') || (bed.sleeperLeftId === '0' && bed.sleeperRightId !== '0')) ? true : false;
}
