/* eslint-disable */
import { EnvironmentsService } from '@services/environments.service';

export const EnvServiceFactory = (): EnvironmentsService => {
  // Create env
  const env = new EnvironmentsService();

  // Read environment variables from browser window
  const browserWindow = window || {};
  const browserWindowEnv = browserWindow['__env'] || {};

  // // Assign environment variables from browser window to env
  // // In the current implementation, properties from env.js overwrite defaults from the EnvService.
  // // If needed, a deep merge can be performed here to merge properties instead of overwriting them.
  for (const key in browserWindowEnv) {
    //eslint-disable-next-line no-prototype-builtins
    if (browserWindowEnv.hasOwnProperty(key)) {
      env[key] = window['__env'][key];
    }
  }

  env.setDefaultServer();
  return env;
};

export const EnvServiceProvider = {
  provide: EnvironmentsService,
  useFactory: EnvServiceFactory,
  deps: [],
};