import { Component, OnInit } from '@angular/core';
import { Sleeper } from '@models/sleeper/sleeper.model';
import { WellnessCategory } from '@models/sleeper/wellness-profile.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { WellnessCategoryEnum } from '@shared/utils/helpers/enum.helper';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { SleepersSelectors } from '@store/sleepers/sleepers.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-wellness-profile-home',
  templateUrl: './wellness-profile-home.component.html',
})
export class WellnessProfileHomeComponent implements OnInit {

  selectedSleeper$: Observable<Sleeper | null>;

  constructor(private store: Store, private mixpanelService: MixpanelService) {}

  ngOnInit(): void {
    FunctionsHelper.scrollToTop();
    this.mixpanelService.trackWellnessProfileOpen();
    this.selectedSleeper$ = this.store.select(SleepersSelectors.selectedSleeper);
  }

  getAnsweredQuestionsForCategory(category: WellnessCategory): number {
    return this.store.selectSnapshot(SleepersSelectors.getAnsweredQuestionsForCategory)(category);
  }

  getNumberOfQuestionsForCategory(category: WellnessCategory): number {
    return this.store.selectSnapshot(SleepersSelectors.getNumberOfQuestionsForCategory)(category);
  }

  back(): void {
    this.store.dispatch(new Navigate(['pages/profile']));
  }

  openWellnessCategory(category: string): void {
    const categoryId= WellnessCategoryEnum[category] + 1;
    this.mixpanelService.trackWellnessProfileCategoryOpen(category);
    this.store.dispatch(new Navigate([`pages/profile/details/wellness/questions/${categoryId}`]))
  }
}
