import { Component, Input } from '@angular/core';
import { ListItem } from '@models/app/helpers.model';
import { SiqCommonModule } from '@shared/siq-common.module';

@Component({
  selector: 'app-siq-list-items',
  templateUrl: './siq-list-items.component.html',
  standalone: true,
  imports: [SiqCommonModule]
})
export class SiqListItemsComponent {
  @Input() items: Array<ListItem>;
  @Input() itemsLayoutAlign: string;
  @Input() itemsPadding: string;
  @Input() textMargin: string;
}
