import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-siq-tabs',
  templateUrl: './siq-tabs.component.html',
  styleUrls: ['./siq-tabs.component.scss']
})
export class SiqTabsComponent {
  @Input() tabs: string[] = [];
  @Input() selectedTab = 0;
  @Input() height = ''; // 60px or 42px
  @Output() tabSelect = new EventEmitter();

  selectTab(index: number): void {
    this.selectedTab = index;
    this.tabSelect.emit(this.selectedTab);
  }

  isTabSelected(tab: number): boolean {
    return tab === this.selectedTab;
  }
}
