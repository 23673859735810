import { Component, OnDestroy, OnInit } from '@angular/core';
import { Bed } from '@models/bed/bed.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { BedsSelectors } from '@store/beds/beds.selectors';
import { GetBiqUserDetails, ResetSetupState, SelectSetupBed } from '@store/setup/setup.actions';
import { SetupSelectors } from '@store/setup/setup.selectors';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-select-bed',
  templateUrl: './select-bed.component.html'
})
export class SelectBedComponent implements OnInit, OnDestroy {
  bedsForSetup$: Observable<Array<Bed> | null>;
  bedsWithSleepers: Array<Bed>;

  private _unsubscribeAll = new Subject<void>();

  constructor(
    private store: Store,
    private mixpanelService: MixpanelService
  ) { }

  get hasSetupBeds(): boolean {
    return this.bedsWithSleepers.length > 0;
  }

  ngOnInit(): void {
    FunctionsHelper.scrollToTop();
    this.store.dispatch(new ResetSetupState());
    // check if account owner is already set, if yes then call the api, otherwise don't call it
    const beds = this.store.selectSnapshot(BedsSelectors.beds);
    this.bedsForSetup$ = this.store.select(BedsSelectors.bedsForSetup);
    this.store.select(BedsSelectors.bedsWithSleepers).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(bedsWithSleepers => this.bedsWithSleepers = bedsWithSleepers);

    this.bedsForSetup$.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(bedsForSetup => {
      if (beds && bedsForSetup?.length === beds?.length) {
        const hasKidsBed = beds?.find(bed => bed.isKidsBed);
        this.mixpanelService.trackRegistrationStart(beds?.length, hasKidsBed ? 'yes' : 'no');
      }
    });

    this.store.select(SetupSelectors.isAccountOwnerSet).pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe(isAccountOwnerSet => {
      if (!isAccountOwnerSet) {
        this.store.dispatch(new GetBiqUserDetails());
      }
    });
  }

  selectBed(bed: Bed): void {
    this.mixpanelService.trackRegistrationBedSelect(bed.bedId);
    this.store.dispatch(new SelectSetupBed(bed));
    this.store.dispatch(new Navigate(['pages/setup/setup-bed']));
  }

  skip(): void {
    this.store.dispatch(new Navigate(['pages/setup/sleep-research']));
    const bedsSetup = this.store.selectSnapshot(BedsSelectors.bedsWithSleepers).length;
    const beds = this.store.selectSnapshot(BedsSelectors.beds);
    if (beds) {
      this.mixpanelService.trackRegistrationBedSkip(
        bedsSetup,
        beds.length - bedsSetup
      );
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
