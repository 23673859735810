import { NgModule } from "@angular/core";
import { AppStoreBtnsComponent } from "./components/app-store-btns/app-store-btns.component";
import { SiqButtonComponent } from "./components/siq-button/siq-button.component";
import { SiqCheckboxComponent } from "./components/siq-checkbox/siq-checkbox.component";
import { SiqRadioButtonComponent } from "./components/siq-radio-button/siq-radio-button.component";
import { SiqSwitcherComponent } from "./components/siq-switcher/siq-switcher.component";
import { WellnessProfileCategoryComponent } from "./components/wellness-profile-category/wellness-profile-category.component";
import { SiqCommonModule } from "./siq-common.module";

const components = [SiqRadioButtonComponent, SiqButtonComponent, SiqCheckboxComponent, WellnessProfileCategoryComponent, AppStoreBtnsComponent, SiqSwitcherComponent]

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    SiqCommonModule
  ],
  exports: [
    ...components
  ]
})
export class ButtonsModule { }