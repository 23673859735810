import { Component, Input } from '@angular/core';
import { LottieHelper } from '@shared/utils/helpers/lottie-helper';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() width: string;
  @Input() height: string;
  loadingConfig = LottieHelper.createAnimationOptions('loader_particle', true);
}
