export class WayToImprove {
  iconTitle: string;
  text: string;

  constructor(iconTitle: string, text: string) {
    this.iconTitle = iconTitle;
    this.text = text;
  }
}

export class HowWeCalculate {
  description: string;
  subtitle: string;

  constructor(description: string, subtitle: string) {
    this.description = description;
    this.subtitle = subtitle;
  }
}
