import { NgModule } from '@angular/core';
import { LottieCacheModule, LottieModule, provideLottieOptions } from 'ngx-lottie';

export function playerFactory(): any {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [],
  imports: [
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot()
  ],
  exports: [
    LottieModule,
    LottieCacheModule
  ],
  providers: [
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
  ]
})
export class SiqLottieModule { }