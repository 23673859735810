export enum SleeperType {
  Digital = 'digital_only',
  SmartSleeper = 'smart_sleeper',
}

export class SleeperInfo {
  sleeperType: SleeperType;
  features: Array<string>;

  constructor(sleeperType: SleeperType, features: Array<string>) {
    this.sleeperType = sleeperType;
    this.features = features;
  }

  get isDigitalSleeper(): boolean {
    return this.sleeperType === SleeperType.Digital && this.features.length > 0;
  }

  get isUnknownSleeper(): boolean {
    return this.sleeperType === SleeperType.Digital && this.features.length === 0;
  }

  get isSmartSleeper(): boolean {
    return this.sleeperType === SleeperType.SmartSleeper;
  }
}