import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { Navigate } from "@ngxs/router-plugin";
import { Store } from "@ngxs/store";
import { AuthSelectors } from "@store/auth/auth.selectors";
import { of } from "rxjs";

export const LandingGuard: CanActivateFn = () => {
  const store = inject(Store);

  const login = store.selectSnapshot(AuthSelectors.login);
  const auth = store.selectSnapshot(AuthSelectors.cognitoAuth);
  if (login && auth) {
    store.dispatch(new Navigate(['pages/sleep']));
    return of(false);
  }
  return of(true);
};