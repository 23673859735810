import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IccMessage, IccMessages, SleepersIccMessages } from "@models/health/sleep-health-icc.model";
import { CircadianRhythmEntity, CircadianRhythmResponse } from "@models/sessions/circadian-rhythm.model";
import { Rolling30DaysSleepData, RollingDataAggregates } from "@models/sessions/rolling-data-aggregates";
import { NoDataSession, SessionModel } from "@models/sessions/session.model";
import { SleepData, SleepDataEntity, SleepDataMetrics, SleepDataSessions, SleepDataStructure, YearHistorySleepData, YearSleepDataEntityModel } from "@models/sessions/sleep-data.model";
import { Sleeper } from "@models/sleeper/sleeper.model";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { append, insertItem, patch, updateItem } from '@ngxs/store/operators';
import { SessionsService } from "@services/sessions.service";
import { SplashScreenService } from "@services/splash-screen.service";
import { SiqDateFormats } from "@shared/utils/helpers/date-formats.helper";
import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import { BedsSelectors } from "@store/beds/beds.selectors";
import { GetDYKTips } from "@store/edp/edp.actions";
import { GetSleepHealth } from "@store/health/health.actions";
import { LoadAllTimeAverages } from "@store/sleepers/sleepers.actions";
import { SleepersSelectors } from "@store/sleepers/sleepers.selectors";
import { Observable, tap, throwError } from "rxjs";
import { Get30DaysRolling, GetCircadianRhythm, GetRollingDataAggregates, GetSessionsForTwoPreviousMonths, GetSleepDataForMonth, GetSleepDataForWeek, GetSleepDataForYear, InitSessionsForCurrentMonth, LoadSelectedSessionIccMessages, ResetSessionsState, SelectDefaultSession } from "./sessions.actions";
import { SessionsStateModel, defaultSessionsState } from "./sessions.model";

import { SiqPopupHelper } from "@shared/utils/helpers/siq-popup.helper";
import * as moment from "moment";
import * as momentTimezone from "moment-timezone";
@State<SessionsStateModel>({
  name: 'sessions',
  defaults: defaultSessionsState
})
@Injectable()
export class SessionsState {

  constructor(private store: Store, private sessionsService: SessionsService, private siqPopupHelper: SiqPopupHelper, private splashScreenService: SplashScreenService) { }

  // Fetch data and init sessions for the current month
  @Action(InitSessionsForCurrentMonth)
  initSessionsForCurrentMonth(ctx: StateContext<SessionsStateModel>, action: InitSessionsForCurrentMonth): Observable<SessionModel> {
    this.splashScreenService.setSessionLoading(true);
    ctx.patchState({ loading: true });
    const sleeperId = this.store.selectSnapshot(SleepersSelectors.selectedSleeper)?.sleeperId;
    // @ts-expect-error sleeperId won't be null
    return this.sessionsService.getSleepData(action.month, 'M1', sleeperId, true).pipe(
      tap({
        next: (result: SleepDataStructure) => {
          this.createSessionsList(ctx, result, action.month);
          // @ts-expect-error won't be null
          ctx.dispatch(new GetSessionsForTwoPreviousMonths(moment(action.month).add(-1, 'month').format(SiqDateFormats.Date), sleeperId));
        },
        error: error => {
          ctx.patchState({ loading: false });
          this.splashScreenService.setSessionLoading(false);
          return this.handleError(ctx, error);
        }
      })
    );
  }

  // Fetch data and init sessions for the previous two months
  @Action(GetSessionsForTwoPreviousMonths)
  getSessionsForTwoPreviousMonths(ctx: StateContext<SessionsStateModel>, action: GetSessionsForTwoPreviousMonths): Observable<SleepDataStructure> | void {
    if (moment(action.month) > moment().startOf('month').add(-3, 'month')) {
      return this.sessionsService.getSleepData(action.month, 'M1', action.sleeperId, true).pipe(
        tap({
          next: (result: SleepDataStructure) => {
            this.createSessionsList(ctx, result, action.month);
            ctx.dispatch(new GetSessionsForTwoPreviousMonths(moment(action.month).add(-1, 'month').format(SiqDateFormats.Date), action.sleeperId));
          },
          error: error => this.handleError(ctx, error)
        })
      );
    } else {
      ctx.dispatch(new SelectDefaultSession());
    }
  }

  @Action(GetSleepDataForWeek)
  getSleepDataForWeek(ctx: StateContext<SessionsStateModel>, action: GetSleepDataForWeek): Observable<SleepDataStructure> | void {
    const weekSleepData = ctx.getState().sleepDataWeek?.find(data => data.date === action.week && data.sleeperId === action.sleeperId);
    if (!weekSleepData) {
      ctx.patchState({ loading: true });
      if (action.sleeperId) {
        return this.sessionsService.getSleepData(action.week, 'W1', action.sleeperId, true).pipe(
          tap({
            next: (result: SleepDataStructure) => {
              ctx.setState(patch(
                { sleepDataWeek: insertItem({ date: action.week, ...result }), loading: false }));
            },
            error: error => this.handleError(ctx, error)
          })
        );
      }
    }
  }

  @Action(GetSleepDataForMonth)
  getSleepDataForMonth(ctx: StateContext<SessionsStateModel>, action: GetSleepDataForMonth): Observable<SleepDataStructure> | void {
    // check if month sleep data was already added
    const sleeperSleepData = ctx.getState().sleepData.find(data => data.sleeperId === action.sleeperId);
    const monthSleepData = sleeperSleepData?.sleepData.filter((data) => moment(data.date).isSame(action.month, 'month'));
    if (!monthSleepData?.length) {
      ctx.patchState({ loading: true });
      // @ts-expect-error won't be null
      return this.sessionsService.getSleepData(action.month, 'M1', action.sleeperId, true).pipe(
        tap({
          next: (result: SleepDataStructure) => {
            // this will add new sessions to the sleepData list
            this.createSessionsList(ctx, result, action.month);
          },
          error: error => this.handleError(ctx, error)
        })
      );
    }
  }

  @Action(GetSleepDataForYear)
  getSleepDataForYear(ctx: StateContext<SessionsStateModel>, action: GetSleepDataForYear): Observable<YearHistorySleepData> | void {
    const yearSleepData = ctx.getState().sleepDataYear?.find(data => data.date === action.year && data.sleeperId === action.sleeperId);
    if (!yearSleepData) {
      ctx.patchState({ loading: true });
      return this.sessionsService.getSleepDataByYear(action.year, action.sleeperId).pipe(
        tap({
          next: (result: YearHistorySleepData) => {
            result.yearSleepData.sleepers.forEach((data: YearSleepDataEntityModel) => {
              const yearData = { ...data, date: result.yearSleepData.date };
              ctx.setState(patch({ sleepDataYear: insertItem(yearData as YearSleepDataEntityModel), loading: false }));
            });
          },
          error: error => this.handleError(ctx, error)
        }
        ));
    }
  }

  @Action(SelectDefaultSession)
  selectDefaultSession(ctx: StateContext<SessionsStateModel>): void {
    const lastSession = this.getLastSessionForSelectedSleeper(ctx);
    const selectedSleeper = this.store.selectSnapshot(SleepersSelectors.selectedSleeper);
    const selectedSleeperSessions = this.getSelectedSleeperSessions(ctx);

    // TODO: fetch metrics - providers
    if(selectedSleeper) {
      this.store.dispatch(new GetDYKTips(selectedSleeper));
    }

    // once sleeper had sessions, but not in last three months -> not to be confused with the new customer
    if (selectedSleeperSessions?.length === 0 && selectedSleeper?.firstSessionRecorded) {
      // taking time in sleeper timezone
      ctx.patchState({
        selectedSession: new NoDataSession(
          momentTimezone().tz(selectedSleeper?.timezone as string).add(-92, 'days').format(SiqDateFormats.ISO8601),
          momentTimezone().tz(selectedSleeper?.timezone as string).format(SiqDateFormats.ISO8601))
      });
      // @ts-expect-error won't be null
      this.store.dispatch(new GetSleepHealth(selectedSleeper, ctx.getState().selectedSession?.date));
    }

    if (!lastSession) {
      this.splashScreenService.setSessionLoading(false);
      return;
    }

    let duration;
    // if the sleeper has sessions
    if (selectedSleeperSessions) {
      const bedStatus = this.store.selectSnapshot(BedsSelectors.selectedSleeperBedStatus);
      const isInBed = selectedSleeper?.side === 0 ? bedStatus?.isLeftSideInBed : bedStatus?.isRightSideInBed;
      // take time in sleeper timezone convert it to utc (without zone) 
      // do the same to lastSession.endDate
      // subtract two dates to see if the session is longer than 24h
      duration = moment.duration(moment.utc().diff(momentTimezone.tz(lastSession.endDate, SiqDateFormats.ISO8601, selectedSleeper?.timezone as string).utc()));
      if (bedStatus && isInBed) {
        if (duration.asHours() < 24) {
          ctx.patchState({
            selectedSession: new SessionModel(lastSession)
          });
          //@ts-expect-error won't be null
          this.fetchSelectedSessionMetrics(ctx, ctx.getState().selectedSession, selectedSleeper);
        } else {
          ctx.patchState({
            selectedSession: new NoDataSession(lastSession.endDate, momentTimezone().tz(selectedSleeper?.timezone as string).format(SiqDateFormats.ISO8601)),
          });
        }
      } else if (duration.asHours() >= 24) {
        ctx.patchState({
          selectedSession: new NoDataSession(lastSession.endDate, momentTimezone().tz(selectedSleeper?.timezone as string).format(SiqDateFormats.ISO8601)),
        });
      } else {
        ctx.patchState({
          selectedSession: new SessionModel(lastSession)
        });
        //@ts-expect-error won't be null
        this.fetchSelectedSessionMetrics(ctx, ctx.getState().selectedSession, selectedSleeper);
      }
      //#region fetch sleep data metrics - sleep health
      // @ts-expect-error won't be null
      this.store.dispatch(new GetSleepHealth(selectedSleeper, ctx.getState().selectedSession?.date));
      //#endregion
    }
    this.splashScreenService.setSessionLoading(false);
  }

  @Action(LoadSelectedSessionIccMessages)
  loadSelectedSessionIccMessages(ctx: StateContext<SessionsStateModel>, action: LoadSelectedSessionIccMessages): Observable<SleepersIccMessages> | void {
    const selectedSession = action.sleepSession;
    const selectedSleeper = this.store.selectSnapshot(SleepersSelectors.selectedSleeper);
    if (!selectedSession.isHidden && selectedSession.isSession && selectedSleeper) {
      const startDate = momentTimezone.tz(selectedSession.originalStartDate, selectedSleeper.timezone)
        .utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
      const endDate = momentTimezone.tz(selectedSession.originalEndDate, selectedSleeper.timezone)
        .utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
      const sleeperId = selectedSleeper.sleeperId;
      return this.sessionsService.getSleepCategoryIccMessages(sleeperId, startDate, endDate).pipe(
        tap({
          next: response => {
            const iccMessages = response['sleepers'][0].messages.filter((iccMessage: IccMessage) =>
              iccMessage.originalStartDate === selectedSession.originalStartDate &&
              iccMessage.originalEndDate === selectedSession.originalEndDate);
            iccMessages.forEach((message: IccMessage) => {
              message.text = FunctionsHelper.addTrademarkToText(message.text);
              message.originalSubcategory = message.subcategory;
              message.subcategory = message.subcategory.toLowerCase().split('_').join('-').split(' ').join('-');
            });
            if (iccMessages.length > 0) {
              const iccMessagesGrouped = FunctionsHelper.indexBy<IccMessage>(iccMessages, 'subcategory');
              ctx.setState(
                patch({
                  iccMessages: append([
                    Object.assign(
                      new IccMessages(),
                      {
                        selectedSleeperId: selectedSleeper.sleeperId,
                        startDate: selectedSession.originalStartDate,
                        endDate: selectedSession.originalEndDate,
                        messages: iccMessagesGrouped
                      })
                  ])
                })
              );
            }
          },
          error: error => this.handleError(ctx, error)
        })
      );
    }
  }

  @Action(Get30DaysRolling)
  get30DaysRolling(ctx: StateContext<SessionsStateModel>, action: Get30DaysRolling): Observable<Rolling30DaysSleepData> {
    const selectedSleeper = this.store.selectSnapshot(SleepersSelectors.selectedSleeper);
    const selectedSession = ctx.getState().selectedSession;
    return this.sessionsService.get30DaysRolling(selectedSleeper?.sleeperId, selectedSession?.date, action.interval).pipe(
      tap({
        next: response => {
          ctx.setState(
            patch({
              rolling30Days: append([
                Object.assign(new Rolling30DaysSleepData(), { sleeperId: selectedSleeper?.sleeperId, ...response })
              ])
            })
          );
        },
        error: error => this.handleError(ctx, error)
      })
    );
  }

  @Action(GetRollingDataAggregates)
  getRollingDataAggregates(ctx: StateContext<SessionsStateModel>): Observable<RollingDataAggregates> {
    const selectedSleeper = this.store.selectSnapshot(SleepersSelectors.selectedSleeper);
    const selectedSession = ctx.getState().selectedSession;
    return this.sessionsService.getRollingDataAggregates(selectedSleeper?.accountId, selectedSleeper?.sleeperId, selectedSession?.date).pipe(
      tap({
        next: response => {
          ctx.setState(
            patch({
              rollingDataAggregates: append([
                Object.assign(new RollingDataAggregates(), response)
              ])
            })
          );
        },
        error: error => this.handleError(ctx, error)
      })
    );
  }

  @Action(GetCircadianRhythm)
  getCircadianRhythm(ctx: StateContext<SessionsStateModel>, action: GetCircadianRhythm): Observable<CircadianRhythmResponse> {
    return this.sessionsService.getCircadianRhythm(action.crParams.accountId, action.crParams.sleeperId, action.crParams.date).pipe(
      tap({
        next: (response: CircadianRhythmResponse) => {
          ctx.setState(
            patch({
              circadianRhythm: insertItem(new CircadianRhythmEntity(response)),
            })
          );
        },
        error: (err: HttpErrorResponse) => this.handleError(ctx, err)
      })
    );
  }

  @Action(ResetSessionsState)
  resetSessionsState(ctx: StateContext<SessionsStateModel>): void {
    ctx.setState({ ...defaultSessionsState });
  }

  private createSessionsList(ctx: StateContext<SessionsStateModel>, sleepDataStructure: SleepDataStructure, month: string): void {
    const sleeperId = this.store.selectSnapshot(SleepersSelectors.selectedSleeper)?.sleeperId;
    const sleeperSleepData = ctx.getState().sleepData.find((sd) => sd.sleeperId === sleeperId);
    const sleepDataMonthMetrics: SleepDataMetrics = {
      sleepIQAvg: sleepDataStructure.sleepIQAvg,
      sleepIQMax: sleepDataStructure.sleepIQMax,
      inBedAvg: sleepDataStructure.inBedAvg
    };
    const monthSleepData = sleepDataStructure?.sleepData.map((sd) => {
      return new SleepDataSessions(sd.date, sd.sessions);
    }).reverse();

    const sleepData = new SleepData(month, monthSleepData, sleepDataMonthMetrics);

    if (sleeperSleepData && sleeperId) {
      ctx.setState(
        patch({
          sleepData: updateItem<SleepDataEntity>(se => se.sleeperId === sleeperId,
            new SleepDataEntity({
              sleeperId,
              sleepData: [...sleeperSleepData.sleepData, sleepData]
            })
          )
        })
      );
    } else {
      ctx.setState(
        patch({
          sleepData: insertItem(new SleepDataEntity({
            // @ts-expect-error won't be null
            sleeperId,
            sleepData: [sleepData]
          }))
        })
      );
    }
    ctx.patchState({ loading: false });
  }

  private getLastSessionForSelectedSleeper(ctx: StateContext<SessionsStateModel>): SessionModel | null {
    const selectedSleeperId = this.store.selectSnapshot(SleepersSelectors.selectedSleeper)?.sleeperId;
    const sleepDataEntity = ctx.getState().sleepData.find(s => s.sleeperId === selectedSleeperId);
    return sleepDataEntity && sleepDataEntity.sleepData.length > 0 ? this.getLongestSleepSession(sleepDataEntity.sleepData) : null;
  }

  private getLongestSleepSession(sleepData: Array<SleepData>): SessionModel | null {
    // get the last sessions that is available
    const lastMonthAvailableData = sleepData.find((data) => data.sleepData.length > 0);
    const lastSessions = lastMonthAvailableData ? lastMonthAvailableData.sleepData.find((ss) => ss.sessions.length > 0) : null;
    // check if there are multiple sessions, if yes return the longest, otherwise there is only one session and she is the longest
    const longestSession = lastSessions && lastSessions.sessions.length > 1 ? lastSessions?.sessions.find((ss) => ss.longest) : lastSessions?.sessions[0];
    return longestSession ? longestSession : null;
  }

  private getSelectedSleeperSessions(ctx: StateContext<SessionsStateModel>): Array<SleepDataSessions> | [] {
    const sleeperId = this.store.selectSnapshot(SleepersSelectors.selectedSleeper)?.sleeperId;
    const sleepData = ctx.getState().sleepData.find((ss) => ss.sleeperId === sleeperId);
    const sleeperSessions = sleepData?.sleepData.find((it) => it.sleepData.length > 0);
    return sleeperSessions ? sleeperSessions.sleepData : [];
  }

  private fetchSelectedSessionMetrics(ctx: StateContext<SessionsStateModel>, lastSession: SessionModel, selectedSleeper: Sleeper): void {
    const hasIccMessagesFetched = ctx.getState().iccMessages?.find(icc => icc.selectedSleeperId === selectedSleeper?.sleeperId && lastSession.originalStartDate === icc.startDate && lastSession.originalEndDate === icc.endDate);
    const hasSleeperCr = ctx.getState().circadianRhythm.find((cr) => cr.sleeperId == selectedSleeper?.sleeperId);
    const has30DaysRollingFetched = ctx.getState().rolling30Days?.find(rolling30Days => rolling30Days.sleeperId === selectedSleeper?.sleeperId);
    const hasRollingAggregatesFetched = ctx.getState().rollingDataAggregates?.find(rollingData => rollingData.sleeperId === selectedSleeper?.sleeperId && rollingData.date === lastSession.date);

    //#region fetch selected session metrics - icc
    if (!hasIccMessagesFetched) {
      ctx.dispatch(new LoadSelectedSessionIccMessages(lastSession));
    }
    //#endregion

    //#region fetch selected session metrics - CR
    if (!hasSleeperCr) {
      const date = moment(lastSession.date).format(SiqDateFormats.Date);
      this.store.dispatch(new GetCircadianRhythm({ accountId: selectedSleeper.accountId, sleeperId: selectedSleeper.sleeperId, date }));
    }
    //#endregion

    //#region fetch sleep data metrics - all-time averages
    if (!selectedSleeper?.profile) {
      ctx.dispatch(new LoadAllTimeAverages(selectedSleeper?.sleeperId));
    }
    //#endregion

    //#region fetch selected session metrics - rolling 30 days 
    if (!has30DaysRollingFetched) {
      ctx.dispatch(new Get30DaysRolling(ctx.getState().selectedSession?.date));
    }
    if (!hasRollingAggregatesFetched) {
      ctx.dispatch(new GetRollingDataAggregates());
    }
    //#endregion
  }

  private handleError(ctx: StateContext<SessionsStateModel>, error: HttpErrorResponse): Observable<HttpErrorResponse> {
    ctx.patchState({ error: FunctionsHelper.createSiqError(error.error.Error.Code, error.error.Error.Message), loading: false });
    if (error.status > 400 && error.status < 500 && error.status !== 401) {
      this.siqPopupHelper.showAlert('Sleep Session');
    }
    return throwError(() => error);
  }
}