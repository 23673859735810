import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { SiqError, SleeperRegistrationState } from "@models/app/helpers.model";
import { CognitoLoginData, CognitoLoginModel, CognitoQuery, CognitoRefreshToken, CognitoRefreshTokenModel, UserInfo } from "@models/auth/cognito.model";
import { LoginModel, LoginResponse } from "@models/auth/login.model";
import { SleeperInfo } from "@models/auth/sleeper-type.model";
import { Navigate } from "@ngxs/router-plugin";
import { Action, State, StateContext } from "@ngxs/store";
import { AuthService } from "@services/auth.service";
import { MixpanelService } from "@services/mixpanel.service";
import { SplashScreenService } from "@services/splash-screen.service";
import { CognitoErrorCodes, RememberMe, SiqErrorCodes } from "@shared/utils/helpers/enum.helper";
import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import { ErrorCodeHelper, ServerErrors } from "@shared/utils/helpers/siq-errors.helper";
import { SiqPopupHelper } from "@shared/utils/helpers/siq-popup.helper";
import { CloseModal, ResetAppState } from "@store/app/app.actions";
import { ResetBedState, UnsubscribeFromBedStatus } from "@store/beds/beds.actions";
import { ResetEdpState } from "@store/edp/edp.actions";
import { ResetForgotPasswordState } from "@store/forgot-password/forgot-password.actions";
import { ResetHealthState } from "@store/health/health.actions";
import { ResetPasswordRecoveryState } from "@store/password-recovery/password-recovery.actions";
import { ResetRegisterState } from "@store/register/register.actions";
import { ResetSessionsState } from "@store/sessions/sessions.actions";
import { ResetSettingsState } from "@store/settings/settings.actions";
import { ResetSetupState } from "@store/setup/setup.actions";
import { ResetSleeperState } from "@store/sleepers/sleepers.actions";
import { ResetTacoState, SetTacoState } from "@store/taco/taco.actions";
import { ResetValidateLoginState } from "@store/validate-login/validate-login.actions";
import { ResetWhyIsThisState } from "@store/why-is-this/why-is-this.actions";
import { Observable, catchError, forkJoin, map, of, tap, throwError } from "rxjs";
import { BootController } from 'src/boot-control';
import { CognitoLogin, GetUserInformation, GoToLogin, Logout, NativeLogin, ReLogin, ReSiqLogin, RefreshSleeperType, ResetAuthState, ResetStates, SetCognitoAuth, SetLoginData, SetRegistrationState, SetRememberMe, UnpackToken } from "./auth.actions";
import { AuthStateModel, defaultAuthState } from "./auth.model";
import { PopupData } from "@models/app/modal-data.model";

@State<AuthStateModel>({
  name: 'auth',
  defaults: defaultAuthState
})
@Injectable()
export class AuthState {

  constructor(
    private authService: AuthService,
    private ngZone: NgZone,
    private mixpanelService: MixpanelService,
    private splashScreenService: SplashScreenService,
    private siqPopupHelper: SiqPopupHelper) { }

  @Action(CognitoLogin)
  cognitoLogin(ctx: StateContext<AuthStateModel>, action: CognitoLogin): Observable<CognitoQuery<CognitoLoginData>> {
    ctx.dispatch(new SetLoginData(action.payload));
    ctx.patchState({ loading: true });
    this.splashScreenService.setAuthLoading(true);
    return this.authService.cognitoLogin(action.payload).pipe(
      tap({
        next: (result: CognitoQuery<CognitoLoginData>) => {
          ctx.patchState({
            loading: false,
            cognitoAuth: FunctionsHelper.encrypt(result),
            refreshToken: FunctionsHelper.encrypt(result.data.RefreshToken),
            error: null,
            siqAuth: null
          });
          ctx.dispatch(new UnpackToken());
        },
        error: error => this.handleCognitoLoginError(ctx, error)
      })
    );
  }

  @Action(SetLoginData)
  setLoginData(ctx: StateContext<AuthStateModel>, action: SetLoginData): void {
    ctx.patchState({ loading: false });
    localStorage.setItem('login', FunctionsHelper.encrypt(action.payload));
  }

  @Action(SetCognitoAuth)
  setCognitoAuth(ctx: StateContext<AuthStateModel>, action: SetCognitoAuth): void {
    ctx.patchState({ cognitoAuth: FunctionsHelper.encrypt(action.payload) });
  }

  @Action(ReLogin)
  reLogin(ctx: StateContext<AuthStateModel>): Observable<CognitoQuery<CognitoRefreshToken>> {
    const reLoginData = Object.assign(new CognitoRefreshTokenModel({ RefreshToken: FunctionsHelper.decrypt<string>(ctx.getState().refreshToken) }));
    return this.authService.cognitoRefreshToken(reLoginData).pipe(
      tap({
        next: (result: CognitoQuery<CognitoRefreshToken>) => {
          ctx.patchState({ cognitoAuth: FunctionsHelper.encrypt(result) });
          const username = FunctionsHelper.decodeJWT(result.data.AccessToken)['username'];
          ctx.patchState({ username: FunctionsHelper.encrypt(username) });
        }
      }),
      catchError((error) => throwError(() => error))
    );
  }

  @Action(NativeLogin)
  nativeLogin(ctx: StateContext<AuthStateModel>, action: NativeLogin): Observable<LoginResponse> {
    const siqLogin = {
      login: action.payload.Email,
      password: action.payload.Password
    } as LoginModel;
    return this.authService.nativeLogin(siqLogin).pipe(
      tap({
        next: (result: LoginResponse) => {
          this.splashScreenService.setAuthLoading(false);
          ctx.patchState({ siqAuth: FunctionsHelper.encrypt(new LoginResponse(result)) });
          ctx.dispatch(new Navigate(['auth/password-update/update-your-password']));
        },
        error: error => {
          this.mixpanelService.trackLoginError(error.error);
          ctx.patchState({ loginError: FunctionsHelper.createSiqError(error.status, error.error.Error.Message) });
          return this.handleError(ctx, error, false);
        }
      })
    );
  }

  @Action(ReSiqLogin)
  reSiqLogin(ctx: StateContext<AuthStateModel>): Observable<LoginResponse> {
    const login = localStorage.getItem('login') ?? '';
    const loginDecrypted = FunctionsHelper.decrypt<CognitoLoginModel>(login);
    const siqLogin = {
      login: loginDecrypted.Email,
      password: loginDecrypted.Password
    };
    return this.authService.nativeLogin(siqLogin).pipe(
      tap({
        next: (result: LoginResponse) => ctx.patchState({ siqAuth: FunctionsHelper.encrypt(result) }),
        error: error => this.handleError(ctx, error, false)
      })
    );
  }

  @Action(UnpackToken)
  unpackToken(ctx: StateContext<AuthStateModel>): void {
    const cognitoAuth = ctx.getState().cognitoAuth;
    const auth = cognitoAuth ? FunctionsHelper.decrypt<CognitoQuery<CognitoLoginData | CognitoRefreshToken>>(cognitoAuth) : null;
    if (auth) {
      const username = FunctionsHelper.decodeJWT(auth.data.AccessToken)['username'];
      ctx.patchState({ username: FunctionsHelper.encrypt(username) });
      ctx.dispatch(new GetUserInformation());
    } else {
      this.splashScreenService.setAuthLoading(false);
    }
  }

  @Action(GetUserInformation)
  getUserInformation(ctx: StateContext<AuthStateModel>): Observable<{ sleeperType: SleeperInfo | SiqError, userInfo: UserInfo | SiqError; }> {
    const username = FunctionsHelper.decrypt<string>(ctx.getState().username);
    // In order for the observables not to be stopped
    // the catchError will catch and return correct response, so the observable chain will not break
    // this is done since the flow requires combinations between
    // one request passes and one fails
    const sleeperType$ = this.authService.getSleeperDetails(username).pipe(
      map((data) => new SleeperInfo(data.sleeperType, data.features)),
      catchError((initialError: HttpErrorResponse) => of(FunctionsHelper.createSiqError(initialError.error.Error.Code, initialError.error.Error.Message)))
    );

    const userInfo$ = this.authService.getUserInfo().pipe(
      map((data) => new UserInfo(data)),
      catchError((initialError: HttpErrorResponse) => of(FunctionsHelper.createSiqError(initialError.error.Error.Code, initialError.error.Error.Message)))
    );

    return forkJoin({
      sleeperType: sleeperType$,
      userInfo: userInfo$
    }).pipe(
      tap({
        next: (response) => {
          const isSleeperTypeSuccessful = !(response.sleeperType instanceof SiqError);
          const isUserInfoSuccessful = !(response.userInfo instanceof SiqError);

          // Case 1: Sleeper Details fails with any error
          if (!isSleeperTypeSuccessful) {
            this.splashScreenService.setAuthLoading(false);
            // Case 1.1: user/jwt successful
            if(isUserInfoSuccessful) {
              const userInfo = response.userInfo as UserInfo;
              ctx.patchState({ userInfo: FunctionsHelper.encrypt(userInfo) });
              // Case 1.1.1: user is already registered
              if(userInfo.registrationState === 13) {
                ctx.dispatch(new Navigate(['pages/select-default-sleeper']));
              } else { // Case 1.1.2: user has to go through registration
                // set TACO codes and redirect to terms-of-use
                if (!userInfo.licenseAccepted || !userInfo.privacyPolicyAccepted) {
                  this.setTacoCodes(ctx, userInfo);
                }
                ctx.dispatch(new Navigate(['auth/terms-of-use']));
                this.splashScreenService.setAuthLoading(false);
              }
            } else { // Case: 1.2: user/jwt failed
              this.siqPopupHelper.showAlert('Auth');
              this.resetApp(ctx);
            }
          } else {
            const sleeperInfo = response.sleeperType as SleeperInfo;
            ctx.patchState({
              sleeperInfo
            });
            // Case 2: Sleeper Details passed
            // Case 2.1 user/jwt successful
            if (isUserInfoSuccessful) {
              const userInfo = response.userInfo as UserInfo;
              ctx.patchState({ userInfo: FunctionsHelper.encrypt(userInfo) });
              // set TACO codes and redirect to terms-of-use
              // this also covers the case if digital_only user bought a bed
              // user/jwt will return TACO flags and registration state will not be 13 -> this will prompt digital_only user to register
              if (!userInfo.licenseAccepted || !userInfo.privacyPolicyAccepted) {
                this.setTacoCodes(ctx, userInfo);
              }
              ctx.dispatch(new Navigate(['auth/terms-of-use']));
              this.splashScreenService.setAuthLoading(false);
            } else {
              const userInfoError = response.userInfo as SiqError;
              // Case 2.2 user/jwt failed with a 404 error
              if (userInfoError.code === SiqErrorCodes.NotFound) {
                if (sleeperInfo.isUnknownSleeper) {
                  // Case 2.2.1 sn.com users should be logged out
                  this.siqPopupHelper.showAlert('Auth');
                  ctx.dispatch(new Logout());
                  this.splashScreenService.setAuthLoading(false);
                } else if (sleeperInfo.isDigitalSleeper) {
                  // Case 2.2.2 digital only users should be logged out
                  this.openDigitalPopup(ctx);
                  ctx.dispatch(new Logout());
                  this.splashScreenService.setAuthLoading(false);
                } else {
                  // All others will be logged out
                  this.splashScreenService.setAuthLoading(false);
                  ctx.dispatch(new Logout());
                }
              } else {
                this.splashScreenService.setAuthLoading(false);
                ctx.dispatch(new Logout());
              }
            }
          }
        },
        error: () => this.resetApp(ctx)
      })
    );
  }

  @Action(GoToLogin)
  goToLogin(ctx: StateContext<AuthStateModel>): void {
    ctx.dispatch(new Navigate(['auth/login']));
    this.ngZone.runOutsideAngular(() => BootController.getBootControl().restart());
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>): Observable<object> {
    return this.authService.logout().pipe(
      tap({
        next: () => this.resetApp(ctx),
        error: error => {
          this.resetApp(ctx);
          return this.handleError(ctx, error, false);
        }
      })
    );
  }

  @Action(ResetAuthState)
  resetAuthState(ctx: StateContext<AuthStateModel>): void {
    ctx.setState({ ...defaultAuthState });
  }

  @Action(ResetStates)
  resetStates(ctx: StateContext<AuthStateModel>): void {
    ctx.dispatch(new ResetAuthState());
    ctx.dispatch(new ResetAppState());
    ctx.dispatch(new ResetBedState());
    ctx.dispatch(new ResetSleeperState());
    ctx.dispatch(new ResetSessionsState());
    ctx.dispatch(new ResetHealthState());
    ctx.dispatch(new ResetEdpState());
    ctx.dispatch(new ResetWhyIsThisState());
    ctx.dispatch(new ResetSettingsState());
    ctx.dispatch(new ResetRegisterState());
    ctx.dispatch(new ResetSetupState());
    ctx.dispatch(new ResetTacoState());
    ctx.dispatch(new ResetValidateLoginState());
    ctx.dispatch(new ResetForgotPasswordState());
    ctx.dispatch(new ResetPasswordRecoveryState());
  }

  @Action(SetRememberMe)
  setRememberMe(ctx: StateContext<AuthStateModel>, action: SetRememberMe): void {
    localStorage.setItem('isRememberMeClicked', RememberMe[action.payload]);
  }

  @Action(SetRegistrationState)
  setRegistrationState(ctx: StateContext<AuthStateModel>, action: SetRegistrationState): Observable<SleeperRegistrationState> {
    return this.authService.setRegistrationState(action.state).pipe(
      tap({
        next: (result: SleeperRegistrationState) => {
          const userInfo = FunctionsHelper.decrypt<UserInfo>(ctx.getState().userInfo);
          userInfo.registrationState = parseInt(result.registrationState, 10);
          ctx.patchState({
            userInfo: FunctionsHelper.encrypt(userInfo)
          });
        },
        error: error => this.handleError(ctx, error, true)
      })
    );
  }

  @Action(RefreshSleeperType)
  refreshSleeperType(ctx: StateContext<AuthStateModel>): Observable<SleeperInfo> {
    const username = FunctionsHelper.decrypt<string>(ctx.getState().username);
    return this.authService.getSleeperDetails(username).pipe(
      tap({
        next: (response: SleeperInfo) => {
          const sleeperInfo = new SleeperInfo(response.sleeperType, response.features);
          ctx.patchState({ sleeperInfo });
        },
        error: (err: HttpErrorResponse) => ctx.patchState({ error: FunctionsHelper.createSiqError(err.error.Error.Code, err.error.Error.Message), loading: false })
      })
    );
  }

  //#region Private functions
  private handleError(ctx: StateContext<AuthStateModel>, initialError: HttpErrorResponse, showAPIError: boolean): AuthStateModel {
    this.splashScreenService.setAuthLoading(false);
    if (initialError.status >= SiqErrorCodes.BadRequest && initialError.status < SiqErrorCodes.Server && initialError.status !== SiqErrorCodes.Unauthorized) {
      const errorMessage = showAPIError ? initialError.error.Error.Message : ServerErrors.ApiErrors.error400.text;
      this.siqPopupHelper.showAlert('Auth', errorMessage);
    }
    return ctx.patchState({ error: FunctionsHelper.createSiqError(initialError.error.Error.Code, initialError.error.Error.Message), loading: false });
  }

  private handleCognitoLoginError(ctx: StateContext<AuthStateModel>, cognitoError: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.splashScreenService.setAuthLoading(false);
    if (cognitoError.status === SiqErrorCodes.BadRequest) {
      if (cognitoError.error.data.code === CognitoErrorCodes.UserNotFoundException) {
        const login = localStorage.getItem('login') ?? '';
        ctx.dispatch(new NativeLogin(FunctionsHelper.decrypt<CognitoLoginModel>(login)));
      } else if (cognitoError.error.data.code === CognitoErrorCodes.NotAuthorizedException) {
        ctx.patchState({ loginError: FunctionsHelper.createSiqError(SiqErrorCodes.Unauthorized, cognitoError.error.data.message) });
      }
    } else {
      ctx.patchState({ loginError: FunctionsHelper.createSiqError(cognitoError.status, cognitoError.error.data.message) });
    }
    this.mixpanelService.trackLoginError(cognitoError.error);
    // @ts-expect-error this should work as expected
    this.mixpanelService.trackError(cognitoError.url, cognitoError.error.data.code, cognitoError.error.data.message, cognitoError.status);
    throw cognitoError;
  }

  private resetApp(ctx: StateContext<AuthStateModel>): void {
    this.splashScreenService.setAuthLoading(false);
    const loggedInSleeperId = ctx.getState().userInfo ? (FunctionsHelper.decrypt<UserInfo>(ctx.getState().userInfo)).sleeperId : '';
    ctx.dispatch(new ResetStates());
    ctx.dispatch(new UnsubscribeFromBedStatus());
    try {
      const isRememberMeClicked = localStorage.getItem('isRememberMeClicked') as keyof typeof RememberMe;
      if (isRememberMeClicked && !RememberMe[isRememberMeClicked]) {
        localStorage.removeItem('login');
      }
      localStorage.removeItem('isRememberMeClicked');
      localStorage.removeItem('setup');
      localStorage.removeItem('register');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('app');
      localStorage.removeItem('auth');
      localStorage.removeItem('taco');
      localStorage.removeItem(`defaultSleeper${loggedInSleeperId}`);
      this.mixpanelService.trackLogout();
      this.mixpanelService.reset();
    } catch (exception) {
      this.mixpanelService.trackLogout();
      this.mixpanelService.reset();
    }
    ctx.dispatch(new GoToLogin());
  }

  private setTacoCodes(ctx: StateContext<AuthStateModel>, userInfo: UserInfo): void {
    if (!userInfo.licenseAccepted) {
      ctx.dispatch(new SetTacoState(ErrorCodeHelper.tacoCodes.license, false));
    }
    if (!userInfo.privacyPolicyAccepted) {
      ctx.dispatch(new SetTacoState(ErrorCodeHelper.tacoCodes.privacyPolicy, false));
    }
  }

  private openDigitalPopup(ctx: StateContext<AuthStateModel>): void {
    const popupCTA = ServerErrors.ApiErrors.digitalUser;
    const popupData = new PopupData({
      title: popupCTA.title,
      text: popupCTA.text,
      icon: 'breathe-iq-icon',
      screen: 'Auth',
      leftBtnTxt: '',
      rightBtnTxt: 'OK',
      type: ''
    });
    const modal = this.siqPopupHelper.createPopup(popupData);
    modal.componentInstance.onClose.subscribe(() => {
      modal.close();
    });
    modal.componentInstance.onRightAction.subscribe(() => {
      modal.close();
    });
    modal.afterClosed().subscribe(() => {
      ctx.dispatch(new CloseModal(modal));
      modal.componentInstance.onRightAction.unsubscribe();
      modal.componentInstance.onClose.unsubscribe();
    });
  }
  //#endregion
}