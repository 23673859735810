<app-top-bar-navigation [showBackButton]="true" title="Change Timezone" (backBtnClicked)="back()" />
<div class="px-24 py-32">
  <div siqGrid fx-direction="column" class="w-100-p">
    <div siqGrid fx-direction="column" fx-layout-align="center center">
      <span class="time-icon mb-24"></span>
      <span [ngClass]="selectedBed.dualSleep ? 'dual-bed-icon big' : 'single-bed-icon big'"></span>
    </div>

    <div class="w-100-p my-48">
      <app-siq-dropdown  
      [dropdownOptions]="timezonesOptions" 
      [dropdownValues]="timezonesValues" 
      paddingClass="p-24"
      [value]="selectedUserTimezone"
      dropdownInputLabel="Change Timezone"
      [dropdownInputValue]="timezone"
      (selectedValue)="handleDropdownSelect($event)" />
    </div>

    <div siqGrid fx-direction="row" fx-layout-align="end center" fx-layout-align-sm="center center">
      <app-siq-button
        class="mr-12"
        text="Done"
        btnType="primary-btn"
        width="208px"
        (buttonClicked)="back()" />
    </div>
  </div>
</div>
