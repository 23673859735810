import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { SplashScreenService } from '@services/splash-screen.service';
import { Logout, ResetAuthState } from '@store/auth/auth.actions';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'sleepiq-web';
  buildNumber = environment.buildNumber;
  version = environment.version;

  constructor(
    private mixpanelService: MixpanelService,
    private splash: SplashScreenService,
    private router: Router,
    private store: Store) {
    this.splash.setSplashScreen(false);
  }

  @HostListener('window:unload', ['$event'])
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  unloadHandler(event: unknown): void {
    this.mixpanelService.trackAppClose();
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart && event.url.includes('deeplink/v1/welcome')),
    ).subscribe(() => {
      this.store.dispatch(new ResetAuthState());
      this.store.dispatch(new Logout());
    });
    this.mixpanelService.trackSessionStart();
    this.mixpanelService.trackAppOpen();
  }
}
