import { Bed } from "@models/bed/bed.model";
import { WifiNetworkModel } from "@models/wifi-network/wifi-network.model";

export class SelectBed {
  static readonly type = '[Settings] SelectBed';
  constructor(public bed: Bed) {}
}
export class LoadWifiNetworks {
  static readonly type = '[Settings] LoadWifiNetworks';
}
export class LoadWifiStatus {
  static readonly type = '[Settings] LoadWifiStatus';
  constructor(public bed: Bed) {}
}
export class AddWifiNetwork {
  static readonly type = '[Settings] AddWifiNetwork';
  constructor(public payload: WifiNetworkModel) {}
}
export class DeleteWifiNetwork {
  static readonly type = '[Settings] DeleteWifiNetwork';
  constructor(public wifiId: string) {}
}

export class BaselineBed {
  static readonly type = '[Settings] BaselineBed';
  constructor(public bed: Bed) {}
}

export class ResetSettingsState {
  static readonly type = '[Settings] ResetState';
}