import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// Documentation for the ControlValueAccessor: https://angular.dev/api/forms/ControlValueAccessor

@Component({
  selector: 'app-siq-input',
  templateUrl: './siq-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SiqInputComponent),
      multi: true,
    },
  ],
})
export class SiqInputComponent implements ControlValueAccessor {

  @Input() label = '';
  @Input() placeholder = '';
  @Input() type = '';
  @Input() isInvalid = false;
  @Input() isPasswordField: boolean;
  @Input() name: string;
  @Input() maxLength: number;
  @Input() shouldApplyMask = false;
  @Output() controlFocus = new EventEmitter<object>;
  @Input() tabIndex: number;
  @Input() ariaLabel: string;
  disabled = false;
  showResetInput = false;
  isFocused = false;

  private _control: string | number;

  get control(): string | number {
    return this._control;
  }

  set control(val: string | number) {
    this._control = val;
    this.onChange(val);
    this.onTouched();
    this.onDirty();
  }

  // The function takes any per ControlValueAccessor documentation
  // eslint-disable-next-line
  onChange = (value: any) => { };

  //The function takes any per ControlValueAccessor documentation
  // eslint-disable-next-line
  onTouched = () => { };

  onDirty = (): void => {
    this.showResetInput = this.control && this.isFocused ? true : false;
  };

  get borderClass(): string {
    const border = this.isInvalid ? 'invalid-bottom-border-input' : 'valid-bottom-border-input';
    return this.isFocused ? `${border} focus` : border;
  }

  get isMaskedInput(): boolean {
    return this.type === 'mask';
  }

  /**
    * @description
    * Writes a new value to the element. The function takes any per ControlValueAccessor documentation
    * 
    * @usageNotes
    * Write a value to the element
    * 
    * @param obj The new value for the element
    * 
  **/
  // eslint-disable-next-line
  writeValue(obj: any): void {
    this.control = obj;
  }
  /**
    * @description
    * Registers a callback function that is called when the control's value
    * changes in the UI. The function takes any per ControlValueAccessor documentation
    * 
    * @usageNotes
    * Store the change function
    * 
    * @param fn The callback function to register
    * 
  **/
  // eslint-disable-next-line
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
    * @description
    * Registers a callback function that is called by the forms API on initialization
    * to update the form model on blur. The function takes any per ControlValueAccessor documentation
    * 
    * @usageNotes
    * Store the callback function
    * 
    * @param fn The callback function to register
    * 
  **/
  // eslint-disable-next-line
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /**
    * @description
    * Function that is called by the forms API when the control status changes to
    * or from 'DISABLED'.
    * 
    * @param isDisabled The disabled status to set on the element
    * 
  **/
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setPasswordType(type: string): void {
    this.type = type;
  }

  resetField(event: Event): void {
    event.preventDefault();
    this.control = '';
  }
  onFocus(): void {
    this.isFocused = true;
    const focusObject: { [key: string]: boolean; } = {};
    focusObject[this.name] = this.isFocused;
    this.controlFocus.emit(focusObject);
    if (this.control) {
      this.showResetInput = true;
    }
  }

  onBlur(): void {
    this.showResetInput = false;
    this.isFocused = false;
    this.markAsTouched();
    const focusObject: { [key: string]: boolean; } = {};
    focusObject[this.name] = this.isFocused;
    this.controlFocus.emit(focusObject);
  }

  markAsTouched(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  get areaLabelId(): string {
    return `area-label-${this.type}`
  }
}