import { Component, OnDestroy, OnInit } from '@angular/core';
import { BedStatus } from '@models/bed/bed-status.model';
import { Bed } from '@models/bed/bed.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { SiqIconPosition } from '@shared/utils/helpers/enum.helper';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { SettingsSelectors } from '@store/settings/settings.selectors';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-connectivity-home',
  templateUrl: './connectivity-home.component.html',
})
export class ConnectivityHomeComponent implements OnInit, OnDestroy {
  iconPosition = SiqIconPosition.right;
  selectedSleeperBed$: Observable<Bed | null>;
  selectedBedsStatus$: Observable<BedStatus | null>;

  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private mixpanelService: MixpanelService) {}

  ngOnInit(): void {
    FunctionsHelper.scrollToTop();
    this.mixpanelService.trackConnectivityOpen();
    this.selectedSleeperBed$ = this.store.select(SettingsSelectors.selectedBed);
    this.selectedBedsStatus$ = this.store.select(SettingsSelectors.selectedBedStatus);
  }

  back(): void {
    this.store.dispatch(new Navigate(['pages/smart-bed/bed-view']));
  }

  changeWiFi(): void {
    this.store.dispatch(new Navigate(['pages/smart-bed/details/connectivity/networks']));
    this.mixpanelService.trackUpdateWifiOpen();
  }

  openHavingTrouble(): void {
    this.store.dispatch(new Navigate(['pages/smart-bed/details/connectivity/troubleshooting-tips']));
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
