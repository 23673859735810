import { Pipe, PipeTransform } from '@angular/core';
import { PasswordError } from '../helpers/siq-errors.helper';

const PASSWORD_ERROR = 'Must contain at least 8 characters, including 1 uppercase, 1 lowercase, 1 number and 1 special character.';
const PASSWORD_NUMBER_ERROR = 'Your password must contain a number.';
const PASSWORD_SPECIAL_CHARACTER_ERROR = 'Please use one of the following special characters: ^ $ * . [ ] { } ( ) ? - ” ! @ # % & / , > < \' : ; | _ ~ `" -';
const PASSWORD_LENGTH_ERROR = 'Your password must be between 8 and 99 characters in length.';
const PASSWORD_WHITESPACE_ERROR = 'Password cannot contain a space.';

@Pipe({
  name: 'passwordError',
  standalone: true
})
export class PasswordErrorPipe implements PipeTransform {

  transform(passwordError: PasswordError): string {
    let passwordErrorMsg = PASSWORD_ERROR;
    if (passwordError.required) {
      passwordErrorMsg = PASSWORD_ERROR;
    } else if (passwordError.hasNumber) {
      passwordErrorMsg = PASSWORD_NUMBER_ERROR;
    } else if (passwordError.hasCapitalCase) {
      passwordErrorMsg = PASSWORD_ERROR;
    } else if (passwordError.hasLowerCase) {
      passwordErrorMsg = PASSWORD_ERROR;
    } else if (passwordError.hasSpecialCharacters) {
      passwordErrorMsg = PASSWORD_SPECIAL_CHARACTER_ERROR;
    } else if (passwordError.hasWhitespace) {
      passwordErrorMsg = PASSWORD_WHITESPACE_ERROR;
    } else if (passwordError.minlength || passwordError.maxlength) {
      passwordErrorMsg = PASSWORD_LENGTH_ERROR;
    }
    return passwordErrorMsg;
  }
}
