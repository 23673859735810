<div siqGrid fx-direction="column" class="modal-container p-24 background-primary br-8">
  <div siqGrid fx-layout-align="end center" class="cursor-pointer" (click)="onCloseClick()">
    <div class="close-white-icon"></div>
  </div>
  <div siqGrid fx-direction="column" fx-layout-align="center center">
    <span class="siq-text-900 fs-20 white my-48">Edit Sleep Goal</span>
    <app-siq-dropdown 
      class="w-100-p" 
      [dropdownOptions]="dropdownOptions" 
      [dropdownValues]="dropdownValues" 
      [value]="value" 
      (selectedValue)="setNewSleepGoal($event)"/>
  </div>
  <div siqGrid fx-direction="row" fx-layout-align="end center" class="my-48">
    <app-siq-button
      text="Cancel"
      btnType="tertiary-btn"
      (buttonClicked)="onCloseClick()" />
    <app-siq-button
    class="ml-8"
      text="Set"
      btnType="secondary-btn"
      width="72px"
      (buttonClicked)="setSleepGoal()" />
  </div>
</div>
