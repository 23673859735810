<app-top-bar-navigation [showBackButton]="true" title="Wellness Profile" (backBtnClicked)="back()" />
<div siqGrid fx-direction="column">
  <div siqGrid fx-direction="column" fx-layout-align="center center" class="px-12 px-24 py-32">
    <div siqGrid fx-direction="column" fx-layout-align="center center" class="siq-text-900 fs-20 white text-center">
      <span>Simple questions,</span>
      <span>smarter sleep.</span>
    </div>
    <span class="siq-text-400 fs-16 white text-center mt-12">Answer simple questions to receive personalized tips to
      improve your sleep and play a part in sleep innovation. ​</span>
  </div>
  <div siqGrid fx-direction="row" fx-layout-align="center center" class="flex-wrap mt-16">
    <ng-container *ngIf="selectedSleeper$ | async as selectedSleeper">
      <ng-container *ngFor="let category of selectedSleeper.wellnessQuestions;">
        <div class="w-45-p p-4">
          <app-wellness-profile-category [category]="category.icon"
            [allQuestions]="getNumberOfQuestionsForCategory(category)"
            [answeredQuestions]="getAnsweredQuestionsForCategory(category)"
            (categoryClicked)="openWellnessCategory($event)" />
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>