import { ResetPasswordData } from "@models/sleeper/sleeper.model";

export class PasswordRecovery {
  static readonly type = '[PasswordRecovery] PasswordRecovery';
  constructor(public token: string, public passwordToken: boolean) {}
}

export class ResetPassword {
  static readonly type = '[PasswordRecovery] ResetPassword';
  constructor(public data: ResetPasswordData) {}
}

export class ResetPasswordRecoveryState {
  static readonly type = '[PasswordRecovery] ResetPasswordRecoveryState';
}

