import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeletedNetworkResponse, WifiNetwork, WifiNetworkResponse, WifiNetworks, WifiStatus } from '@models/wifi-network/wifi-network.model';
import { Action, State, StateContext } from '@ngxs/store';
import { insertItem, patch } from '@ngxs/store/operators';
import { BedService } from '@services/bed.service';
import { FuzionService } from '@services/fuzion.service';
import { WifiService } from '@services/wifi.service';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { Observable, catchError, delay, forkJoin, from, of, tap, throwError } from 'rxjs';
import { BedPresenceFlag, Side } from 'src/app/core/fuzion/enums';
import { GetWifiInformationResponse } from 'src/app/core/fuzion/model';
import { AddWifiNetwork, BaselineBed, DeleteWifiNetwork, LoadWifiNetworks, LoadWifiStatus, ResetSettingsState, SelectBed } from './settings.actions';
import { SettingsStateModel, defaultState } from './settings.model';

@State<SettingsStateModel>({
  name: 'settings',
  defaults: defaultState
})
@Injectable()
export class SettingsState {

  constructor(private wifiService: WifiService, private bedService: BedService, private fuzionService: FuzionService) { }

  @Action(SelectBed)
  selectBed(ctx: StateContext<SettingsStateModel>, action: SelectBed): void {
    ctx.patchState({ selectedBedId: action.bed.bedId });
    ctx.dispatch(new LoadWifiNetworks());
    ctx.dispatch(new LoadWifiStatus(action.bed));
  }

  @Action(LoadWifiNetworks)
  loadWifiNetworks(ctx: StateContext<SettingsStateModel>): Observable<WifiNetworks> {
    ctx.patchState({ loading: true });
    return this.wifiService.getWifiNetworks().pipe(
      tap({
        next: (result: any) => {
          ctx.patchState({ loading: false, wifiNetworks: result.networks.map((wifi: WifiNetwork) => Object.assign(new WifiNetwork(), wifi)) });
        },
        error: error => this.handleError(ctx, error)
      })
    );
  }

  @Action(LoadWifiStatus)
  loadWifiStatus(ctx: StateContext<SettingsStateModel>, action: LoadWifiStatus): Observable<WifiStatus | GetWifiInformationResponse> {
    const wifiStatus = action.bed.isFuzion ? this.fuzionService.getWifiStatus(action.bed.accountId, action.bed.bedId) : this.wifiService.getWifiStatus(action.bed.bedId);
    return from(wifiStatus).pipe(
      tap({
        next: (response: WifiStatus | GetWifiInformationResponse) => {
          const result = action.bed.isFuzion ? (response as GetWifiInformationResponse).wifiStatus as WifiStatus : response as WifiStatus;
          ctx.setState(
            patch({ 
              wifiStatus: insertItem({ bedId: action.bed.bedId, ...result } as WifiStatus) 
            }
          ));
        },
        error: (err) => this.handleError(ctx, err)
      })
    );
  }

  @Action(AddWifiNetwork)
  addWifiNetwork(ctx: StateContext<SettingsStateModel>, action: AddWifiNetwork): Observable<WifiNetworkResponse> {
    ctx.patchState({ loading: true });
    return this.wifiService.addWifiNetwork(action.payload).pipe(
      tap({
        next: () => {
          ctx.patchState({ loading: false });
          ctx.dispatch(new LoadWifiNetworks());
        },
        error: error => this.handleError(ctx, error)
      })
    );
  }

  @Action(DeleteWifiNetwork)
  deleteWifiNetwork(ctx: StateContext<SettingsStateModel>, action: DeleteWifiNetwork): Observable<DeletedNetworkResponse> {
    ctx.patchState({ loading: true });
    return this.wifiService.deleteWifiNetwork(action.wifiId).pipe(
      tap({
        next: () => {
          ctx.patchState({ loading: false });
          ctx.dispatch(new LoadWifiNetworks());
        },
        error: error => this.handleError(ctx, error)
      })
    );
  }

  @Action(BaselineBed)
  baselineBed(ctx: StateContext<SettingsStateModel>, action: BaselineBed): Observable<object> {
    ctx.patchState({ baselineInProgress: true, error: null });
    let leftSleeperBaseline = of({});
    let rightSleeperBaseline = of({});

    if (action.bed.sleeperLeftId) {
      leftSleeperBaseline = action.bed.isFuzion ? this.fuzionService.setBedPresence(action.bed.accountId, action.bed.bedId, Side.Left, BedPresenceFlag.Out) : this.bedService.baselineBed(action.bed.sleeperLeftId);
    }

    if (action.bed.sleeperRightId) {
      rightSleeperBaseline = action.bed.isFuzion ? this.fuzionService.setBedPresence(action.bed.accountId, action.bed.bedId, Side.Right, BedPresenceFlag.Out) : this.bedService.baselineBed(action.bed.sleeperRightId);
    }

    return forkJoin([leftSleeperBaseline, rightSleeperBaseline]).pipe(
      delay(1000),
      catchError(() => { throw FunctionsHelper.createGenericError(-404, 'Baseline error'); }),
      tap({
        next: () => ctx.patchState({ baselineInProgress: false }),
        error: (error) => {
          return this.handleError(ctx, error);
        }
      })
    );
  }

  @Action(ResetSettingsState)
  resetBedState(ctx: StateContext<SettingsStateModel>): void {
    ctx.setState({ ...defaultState });
  }

  private handleError(ctx: StateContext<SettingsStateModel>, err: HttpErrorResponse): Observable<HttpErrorResponse> {
    ctx.patchState({ error: FunctionsHelper.createSiqError(err.error.Error.Code, err.error.Error.Message), loading: false });
    return throwError(() => err);
  }
}
