import { AccSettingsInfo, AccountSettingsSections, ItemModel } from "@models/account-settings/account-settings-info.model";
import { ListItem } from "@models/app/helpers.model";

export class AccountSettingsStringResource {
  static getSleeperInformation(sectionTitle: string, sleeperEmail: string): AccSettingsInfo {
    return {
      sectionTitle,
      itemModels: [
        { title: 'Email', text: sleeperEmail, id: AccountSettingsSections.email} as ItemModel,
        ...([{ title: 'Password', text: '••••••••••', id: AccountSettingsSections.password} as ItemModel])
      ]
    } as AccSettingsInfo;

  }

  static getMyOrders(numberOfOrders: number): AccSettingsInfo {
    return {
      sectionTitle: 'My Orders',
      itemModels: [
        { title: 'Current Orders', text: `${numberOfOrders} Orders`, id: AccountSettingsSections.orders} as ItemModel
      ]
    } as AccSettingsInfo;
  }

  static getDeviceSetup(sleeperName: string): AccSettingsInfo {
    return {
      sectionTitle: 'Device Setup',
      itemModels: [
        { title: 'Default Sleeper', text: sleeperName, id: AccountSettingsSections.defaultSleeper} as ItemModel
      ]
    } as AccSettingsInfo;
  }

  static getCreateLoginText(sleeperName: string): ItemModel {
    return {
      title: `${sleeperName}'s account isn't quite finished.`,
      text: 'An email verification is needed to confirm login credentials.',
      id: AccountSettingsSections.createLogin
    } as ItemModel;
  }

  static VerificationDialogCTA = {
    title: 'Verification Email Sent',
    text: 'Follow the link in the email to begin using your new email address.'
  }

  static passwordUpdateSuccess(sleeperEmail: string): ItemModel {
    return {
      title: 'Your Password Has Been Updated',
      text: `You will need to log in again on all other devices that use this email: ${sleeperEmail}.`,
      id: 0
    }
  }

  static ForgotPasswordCTA = {
    title: 'We’ll send an email to reset your password.',
    text: 'We’ll send you instructions on how to reset your password.'
  }

  static ForgotPasswordSuccessCTA = {
    title: 'Email Sent',
    text: 'Follow the link in the email to reset your password.'
  }

  static AdditionalBenefits: Array<ListItem> = [
    { icon: 'sleep-science-bulb', text: 'Personalized insights', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
    { icon: 'trends-icon', text: 'Weekly trends and updates', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
    { icon: 'moon-sleeping w-24', text: 'Create smart bed routines​', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
    { icon: 'wellness-report-not-available w-24', text: 'Monthly sleep summary and tips​', textClasses: 'siq-text-400 fs-18 white' } as ListItem
  ];

  static InviteSleeperError = {
    message: 'A user with the given login already exists.'
  }

  static inviteSleeperTitle(firstName: string): string {
    return `Enter a login email for ${firstName}`;
  }

  static inviteSleeperSubtitle(gender: number): string {
    return `This will allow ${this.getPronoun(gender)} to log in with ${this.getPronoun(gender, true)} own username and password.`;
  }

  static cancelInviteCTA(firstName: string): {title: string, text: string} {
    return {
      title: 'Are you sure?',
      text: `If you cancel ${firstName}'s invitation, you will need to invite him again later.`
    }
  }

  private static getPronoun(gender: number, isPossessive = false): string {
    switch(gender) {
      case 0:
        return 'her';
      case 1:
        return isPossessive ? 'his' : 'him';
      default:
        return isPossessive ? 'their' : 'them';
    }
  }
}