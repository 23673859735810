import { CircadianRhythmParams } from "@models/sessions/circadian-rhythm.model";
import { SessionModel } from "@models/sessions/session.model";

export class InitSessionsForCurrentMonth {
  static readonly type = '[Sessions] InitSessionsForCurrentMonth';

  constructor(public month: string) { }
}
export class GetSessionsForTwoPreviousMonths {
  static readonly type = '[Sessions] GetSessionsForTwoPreviousMonths';

  constructor(public month: string, public sleeperId: string) { }
}

export class GetSleepDataForWeek {
  static readonly type = '[Sessions] GetSleepDataForWeek';
  constructor(public week: string, public sleeperId: string | undefined) {}
}

export class GetSleepDataForMonth {
  static readonly type = '[Sessions] GetSleepDataForMonth';
  constructor(public month: string, public sleeperId: string | undefined) {}
}

export class GetSleepDataForYear {
  static readonly type = '[Sessions] GetSleepDataForYear';
  constructor(public year: string, public sleeperId: string | undefined) {}
}

export class SelectDefaultSession {
  static readonly type = '[Sessions] SelectDefaultSession';
}

export class LoadSelectedSessionIccMessages {
  static readonly type = '[Sessions] LoadSelectedSessionIccMessages';
  constructor(public sleepSession: SessionModel) {}
}

export class GetCircadianRhythm {
  static readonly type = '[Sessions] GetCircadianRhythm';
  constructor(public crParams: CircadianRhythmParams ) {}
}

export class ResetSessionsState {
  static readonly type = '[Sessions] ResetSessionsState';
}

export class Get30DaysRolling {
  static readonly type = '[Sessions] Get30DaysRolling';
  constructor(public interval: string | undefined) {}
}

export class GetRollingDataAggregates {
  static readonly type = '[SleepData] GetRollingDataAggregates';
}
