import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SleepHealthIccEntity } from "@models/health/sleep-health-icc.model";
import { SleepHealthData, SleepHealthDataProps, SleepHealthDay, SleepHealthEntity, SleepHealthTiming, SleepHealthTimingGoal, SleepHealthTimingGoalData } from "@models/health/sleep-health.model";
import { ReportContent, SleeperDownloadedReport, WellnessReportEntityModel } from "@models/health/wellness-report.model";
import { Action, State, StateContext } from "@ngxs/store";
import { insertItem, patch, updateItem } from "@ngxs/store/operators";
import { HealthService } from "@services/health.service";
import { SiqDateFormats } from "@shared/utils/helpers/date-formats.helper";
import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import { SiqPopupHelper } from "@shared/utils/helpers/siq-popup.helper";
import * as moment from "moment";
import { Observable, tap, throwError } from "rxjs";
import { DownloadWellnessReport, GetSleepHealth, GetSleepHealthIcc, GetWellnessReport, ResetHealthState } from "./health.actions";
import { HealthStateModel, defaultHealthState } from "./health.model";

@State<HealthStateModel>({
  name: 'health',
  defaults: defaultHealthState
})
@Injectable()
export class HealthState {

  constructor(private healthService: HealthService, private siqPopupHelper: SiqPopupHelper) { }

  @Action(GetSleepHealth)
  getSleepHealth(ctx: StateContext<HealthStateModel>, action: GetSleepHealth): Observable<SleepHealthEntity> {
    ctx.patchState({ loading: true });
    const endDate = moment(action.sessionDate).format('YYYY-MM-DD');
    const startDate = moment(action.sessionDate).add(-92, 'days').format('YYYY-MM-DD');
    return this.healthService.getSleepHealth(action.sleeper.accountId, action.sleeper.sleeperId, { startDate, endDate }).pipe(
      tap({
        next: (response: SleepHealthEntity) => {
          const sleepHealth = new SleepHealthEntity(response);
          if (sleepHealth.days.length > 0) {
            sleepHealth.days = sleepHealth.days.sort((e1, e2) => FunctionsHelper.compareByDate<SleepHealthDay>(e1, e2, 'sessionDate'));
            sleepHealth.days = this.fillSleepHealthMissingDays(sleepHealth.days);

            sleepHealth.days = this.formatSleepHealthData(sleepHealth.days);
            const hasSleeperSleepHealth = ctx.getState().sleepHealth.find((sh) => sh.sleeperId === action.sleeper.sleeperId);
            if (hasSleeperSleepHealth) {
              ctx.setState(
                patch({
                  sleepHealth: updateItem((sh) => sh.sleeperId === action.sleeper.sleeperId, sleepHealth)
                })
              );
            } else {
              ctx.setState(
                patch({
                  sleepHealth: insertItem(sleepHealth)
                })
              );
            }
          } else {
            // Case where no data screen should be shown
            // User has a firstRecordedSession (she is not a new user), but no sleep health data in 92 days
            if (action.sleeper.firstSessionRecorded) {
              sleepHealth.days = this.createNoDataSleepHealthDays(sleepHealth.days, sleepHealth.endDate);
              ctx.setState(
                patch({
                  sleepHealth: insertItem(sleepHealth)
                })
              );
            }
          }
          ctx.patchState({ loading: false });
        },
        error: (err: HttpErrorResponse) => { return this.handleError(ctx, err); }
      })
    );
  }

  @Action(GetSleepHealthIcc)
  getSleepHealthIcc(ctx: StateContext<HealthStateModel>, action: GetSleepHealthIcc): Observable<SleepHealthIccEntity> {
    ctx.patchState({ loadingSleepHealthIcc: true });
    return this.healthService.getSleepHealthIcc(action.accountId, action.sleeperId, action.payload).pipe(
      tap({
        next: (response: SleepHealthIccEntity) => {
          ctx.patchState({
            sleepHealthIcc: new SleepHealthIccEntity(response), loadingSleepHealthIcc: false
          });
        },
        error: (err: HttpErrorResponse) => {
          ctx.patchState({ loadingSleepHealthIcc: false });
          return this.handleError(ctx, err);
        }
      })
    );
  }

  @Action(GetWellnessReport)
  getWellnessReport(ctx: StateContext<HealthStateModel>, action: GetWellnessReport): Observable<WellnessReportEntityModel> {
    return this.healthService.getWellnessReport(action.accountId, action.sleeperId, action.params).pipe(
      tap({
        next: (response: WellnessReportEntityModel) => {
          const sleeperWellnessReport = ctx.getState().wellnessReport.find((report) => report.sleeperId === action.sleeperId);
          if (sleeperWellnessReport) {
            ctx.setState(
              patch({
                wellnessReport: updateItem((sh) => sh.sleeperId === action.sleeperId, new WellnessReportEntityModel(response))
              })
            );
          } else {
            ctx.setState(
              patch({
                wellnessReport: insertItem(new WellnessReportEntityModel(response))
              })
            );
          }

          response.sleepData.forEach((report) => {
            if (!Object.prototype.hasOwnProperty.call(report, 'error')) {
              ctx.dispatch(new DownloadWellnessReport(action.accountId, action.sleeperId, report.date));
            }
          });
        },
        error: err => this.handleError(ctx, err)
      })
    );
  }

  @Action(DownloadWellnessReport)
  downloadWellnessReport(ctx: StateContext<HealthStateModel>, action: DownloadWellnessReport): Observable<string> {
    return this.healthService.downloadWellnessReport(action.accountId, action.sleeperId, action.date).pipe((
      tap({
        next: (report: string) => {
          const sleeperReports = ctx.getState().sleeperDownloadedReport?.find((sleeperReports) => sleeperReports.sleeperId === action.sleeperId);
          if (sleeperReports) {
            const hasReport = sleeperReports.reports.find((report) => moment(report.date).isSame(action.date));
            if(!hasReport) {
              ctx.setState(
                patch({
                  sleeperDownloadedReport: updateItem<SleeperDownloadedReport>(report => report.sleeperId === action.sleeperId,
                    new SleeperDownloadedReport(
                      action.sleeperId,
                      [...sleeperReports.reports, new ReportContent(report, action.date)]
                    )
                  )
                })
              );
            }
          } else {
            ctx.setState(
              patch({
                sleeperDownloadedReport: insertItem(
                  new SleeperDownloadedReport(
                    action.sleeperId,
                    [new ReportContent(report, action.date)]
                  )
                )
              })
            );
          }
        },
        error: (error) => this.handleError(ctx, error)
      })
    ));
  }

  @Action(ResetHealthState)
  resetHealthState(ctx: StateContext<HealthStateModel>): void {
    ctx.setState({ ...defaultHealthState });
  }

  //#region Private functions

  private fillSleepHealthMissingDays(sleepHealthDays: Array<SleepHealthDay>): Array<SleepHealthDay> {
    let i = sleepHealthDays.length - 1;
    // this adds missing days in front of the array
    while (moment(sleepHealthDays[0].sessionDate).isBefore(moment(moment().format(SiqDateFormats.Date)))) {
      let fakeSleepHealthDay: SleepHealthDataProps = {
        sessionDate: moment(sleepHealthDays[0].sessionDate).add(1, 'day').format(SiqDateFormats.Date),
        sessionStartDate: moment(sleepHealthDays[0].sessionStartDate).add(1, 'day').format(SiqDateFormats.ISO8601),
        sessionEndDate: moment(sleepHealthDays[0].sessionEndDate).add(1, 'day').format(SiqDateFormats.ISO8601),
        absenceCode: -2,
      };
      const firstAvailableData = this.getFirstAvailableSleepHealthData(sleepHealthDays, 0);
      const timingFromTheWeek = this.findTimingGoalFromTheWeek(sleepHealthDays, fakeSleepHealthDay.sessionDate);
      const dayDiff = timingFromTheWeek ? moment(fakeSleepHealthDay.sessionDate).diff(moment(timingFromTheWeek.sessionDate), 'days') : 0;
      if (firstAvailableData) {
        fakeSleepHealthDay = {
          ...fakeSleepHealthDay,
          data: this.createFakeSleepHealthData(firstAvailableData, timingFromTheWeek, dayDiff)
        };
      }
      sleepHealthDays.unshift(new SleepHealthDay(fakeSleepHealthDay));
    }
    // this adds missing sessions at the back of the array
    while (sleepHealthDays.length < 93) {
      const fakeSleepHealthDay = {
        sessionDate: moment(sleepHealthDays[i].sessionDate).add(-1, 'day').format(SiqDateFormats.Date),
        sessionStartDate: moment(sleepHealthDays[i].sessionStartDate).add(-1, 'day').format(SiqDateFormats.ISO8601),
        sessionEndDate: moment(sleepHealthDays[i].sessionEndDate).add(-1, 'day').format(SiqDateFormats.ISO8601),
        absenceCode: -2,
      };
      i++;
      sleepHealthDays.push(new SleepHealthDay(fakeSleepHealthDay));
    }
    return sleepHealthDays;
  }

  private formatSleepHealthData(sleepHealthDays: Array<SleepHealthDay>): Array<SleepHealthDay> {
    for (let i = 0; i < sleepHealthDays.length; i++) {
      if (i - 1 < 0) continue;
      if (moment(sleepHealthDays[i - 1].sessionDate).diff(moment(sleepHealthDays[i].sessionDate), 'days') > 1) {
        let fakeSleepHealthDay: SleepHealthDataProps = {
          sessionDate: moment(sleepHealthDays[i].sessionDate).add(1, 'day').format(SiqDateFormats.Date),
          sessionStartDate: moment(sleepHealthDays[i].sessionStartDate).add(1, 'day').format(SiqDateFormats.ISO8601),
          sessionEndDate: moment(sleepHealthDays[i].sessionEndDate).add(1, 'day').format(SiqDateFormats.ISO8601),
          absenceCode: -2
        };
        const firstAvailableData = this.getFirstAvailableSleepHealthData(sleepHealthDays, i);
        const timingFromTheWeek = this.findTimingGoalFromTheWeek(sleepHealthDays, fakeSleepHealthDay.sessionDate);
        const dayDiff = timingFromTheWeek ? moment(fakeSleepHealthDay.sessionDate).diff(moment(timingFromTheWeek.sessionDate), 'days') : 0;
        if (firstAvailableData) {
          fakeSleepHealthDay = {
            ...fakeSleepHealthDay,
            data: this.createFakeSleepHealthData(firstAvailableData, timingFromTheWeek, dayDiff)
          };
        }
        sleepHealthDays.splice(i, 0, new SleepHealthDay(fakeSleepHealthDay));
        i--;
      }
    }
    return sleepHealthDays;
  }

  private getFirstAvailableSleepHealthData(sleepHealthDays: Array<SleepHealthDay>, index: number): SleepHealthDay | null {
    for (let i = index; i < sleepHealthDays.length; i++) {
      if (Object.prototype.hasOwnProperty.call(sleepHealthDays[i], 'data')) {
        return sleepHealthDays[i];
      }
    }
    return null;
  }

  private findTimingGoalFromTheWeek(sleepHealthDays: Array<SleepHealthDay>, sessionDate: string): SleepHealthDay | null {
    const timingFromTheWeek = sleepHealthDays.find((s) => moment(s.sessionDate).isSame(moment(sessionDate), 'week') && Object.hasOwnProperty.call(s, 'data') && s.data?.timing?.goal);
    return timingFromTheWeek ? timingFromTheWeek : null;
  }

  private createFakeSleepHealthData(firstAvailableData: SleepHealthDay, timingFromTheWeek: SleepHealthDay | null, dayDiff: number): SleepHealthData {
    return {
      duration: {
        value: null,
        goal: firstAvailableData.data ? firstAvailableData.data.duration.goal : null,
        threshold: firstAvailableData.data ? firstAvailableData.data.duration.threshold : null,
        goalMet: false
      },
      efficiency: {
        value: null,
        goal: firstAvailableData.data && Object.prototype.hasOwnProperty.call(firstAvailableData.data.efficiency, 'goal') ? firstAvailableData.data?.efficiency.goal : null,
        threshold: firstAvailableData.data && Object.prototype.hasOwnProperty.call(firstAvailableData.data.efficiency, 'goal') ? firstAvailableData.data?.efficiency.threshold : 0,
        goalMet: false
      },
      timing: timingFromTheWeek ? this.createShTimingObject(timingFromTheWeek, dayDiff) : null
    };
  }

  private createShTimingObject(timingFromTheWeek: SleepHealthDay, dayDiff: number): SleepHealthTiming | null {
    return {
      goalMet: false,
      value: null,
      goal: timingFromTheWeek.data?.timing?.goal ? timingFromTheWeek.data?.timing?.goal instanceof SleepHealthTimingGoal ? {
        bedTime: this.createTimingGoalProperty(timingFromTheWeek.data?.timing?.goal.bedTime.startDate, timingFromTheWeek.data?.timing?.goal.bedTime.endDate, dayDiff),
        wakeTime: this.createTimingGoalProperty(timingFromTheWeek.data?.timing?.goal.wakeTime.startDate, timingFromTheWeek.data?.timing?.goal.wakeTime.endDate, dayDiff)
      } : {
        reason: timingFromTheWeek.data?.timing?.goal?.reason
      } : null
    };
  }

  private createTimingGoalProperty(start: string, end: string, dayDiff: number): SleepHealthTimingGoalData {
    return {
      startDate: moment(start).add(dayDiff, 'day').format(SiqDateFormats.ISO8601),
      endDate: moment(end).add(dayDiff, 'day').format(SiqDateFormats.ISO8601),
    };
  }

  private createNoDataSleepHealthDays(sleepHealthDays: Array<SleepHealthDay>, startDate: string): Array<SleepHealthDay> {
    const date = moment(startDate).add(-6, 'days');
    while (moment(date).isSameOrBefore(moment(startDate))) {
      const fakeSleepHealthDay: SleepHealthDataProps = {
        sessionDate: moment(date).format(SiqDateFormats.Date),
        sessionStartDate: moment(date).format(SiqDateFormats.ISO8601),
        sessionEndDate: moment(date).format(SiqDateFormats.ISO8601),
        absenceCode: -2
      };
      sleepHealthDays.push(new SleepHealthDay(fakeSleepHealthDay));
      date.add(1, 'day');
    }
    return sleepHealthDays.reverse();
  }

  private handleError(ctx: StateContext<HealthStateModel>, error: HttpErrorResponse): Observable<HttpErrorResponse> {
    ctx.patchState({ error: FunctionsHelper.createSiqError(error.error.Error.Code, error.error.Error.Message), loading: false });
    if (error.status > 400 && error.status < 500 && error.status !== 401) {
      this.siqPopupHelper.showAlert('Health');
    }
    return throwError(() => error);
  }
  //#endregion
}