import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { WellnessReportParams } from '@models/health/wellness-report.model';
import { Store } from '@ngxs/store';
import { SiqDateFormats } from '@shared/utils/helpers/date-formats.helper';
import { GetSleepHealthIcc, GetWellnessReport } from '@store/health/health.actions';
import { HealthSelectors } from '@store/health/health.selectors';
import { SessionsSelectors } from '@store/sessions/sessions.selectors';
import { SleepersSelectors } from '@store/sleepers/sleepers.selectors';
import * as moment from 'moment';
import { catchError, first, map, mergeMap, of } from 'rxjs';

export const HealthIccGuard: CanActivateFn = () => {
  const store = inject(Store);
  const selectedSleeper = store.selectSnapshot(SleepersSelectors.selectedSleeper);
  const selectedSession = store.selectSnapshot(SessionsSelectors.selectedSession);
  return store.select(HealthSelectors.sleepHealthRolling7Days).pipe(
    first(),
    mergeMap(rollingDays => {
      if (rollingDays?.at(-1)?.isData) {
        //@ts-expect-error should not be null
        return store.dispatch(new GetSleepHealthIcc(selectedSleeper?.accountId, selectedSleeper?.sleeperId, { sessionStartDate: selectedSession.originalStartDate, isEdited: selectedSession.isEdited })).pipe(
          map(() => true),
          catchError(() => of(true))
        );
      }
      return of(true);
    })
  );
};

export const WellnessReportGuard: CanActivateFn = () => {
  const store = inject(Store);
  const selectedSleeper = store.selectSnapshot(SleepersSelectors.selectedSleeper);
  const endDate = moment().add(-1, 'month').endOf('month').format(SiqDateFormats.Date);
  const sleeperWellnessReport = store.selectSnapshot(HealthSelectors.sleeperWellnessReport);
  if (!sleeperWellnessReport) {
    // @ts-expect-error sleeper won't be null here
     store.dispatch(new GetWellnessReport(selectedSleeper?.accountId, selectedSleeper?.sleeperId, new WellnessReportParams(endDate))).pipe(
      map(() => of(true)),
      catchError(() => of(true))
    );
  }
  return of(true);
};