import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PopupData } from '@models/app/modal-data.model';
import { FormControlParams, ValidateOrderModel } from '@models/auth/register.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { OrderNumberValidation } from '@shared/utils/helpers/enum.helper';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { Regex } from '@shared/utils/helpers/regex.helper';
import { RegistrationStringResource } from '@shared/utils/helpers/registration.helper';
import { FindMyOrderErrors, ServerErrors } from '@shared/utils/helpers/siq-errors.helper';
import { GoToLogin } from '@store/auth/auth.actions';
import { ValidateOrderNumber } from '@store/register/register.actions';
import { Subject, takeUntil } from 'rxjs';

interface RegisterForm {
  orderNumber: FormControl<string | null>;
  validationProp: FormControl<string | null>;
}

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
})
export class RegisterFormComponent implements OnInit, OnDestroy {
  registerForm: FormGroup<RegisterForm>;
  isFormSubmitted = false;
  controlFocusState = {};
  validationProperty = OrderNumberValidation.orderAndEmail;
  errorMessages = FindMyOrderErrors.Errors;

  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private dialog: MatDialog, private mixpanelService: MixpanelService) { }

  get validationPropErrorMessage(): string {
    return this.checkValidationType('orderAndEmail') ? this.errorMessages.emailAddress : this.errorMessages.phoneNumber;
  }

  get formControlParams(): FormControlParams {
    return RegistrationStringResource.getFormControlParams(this.checkValidationType('orderAndEmail'));
  }

  get isFormValid(): boolean {
    return this.registerForm.valid;
  }

  ngOnInit(): void {
    FunctionsHelper.scrollToTop();
    this.createRegisterForm();
  }

  back(): void {
    if (this.checkValidationType('orderAndEmail')) {
      this.store.dispatch(new Navigate(['auth/login']));
    } else {
      this.validationProperty = OrderNumberValidation.orderAndEmail;
      this.resetRegisterForm();
    }
  }

  isInvalidField(form: FormGroup<RegisterForm>, field: string): boolean {
    if (!this.controlFocusState[field]) {
      if (form.controls[field]?.touched) {
        return form && !this.isFormSubmitted
          ? this.checkIsInputValid(form.controls[field])
          : false;
      }
    }
    return false;
  }

  checkIsInputValid(item: FormControl): boolean {
    return !item?.valid;
  }

  setControlFocusState(focusState: object, field: string): void {
    this.controlFocusState = focusState;
    if (this.isInvalidField(this.registerForm, field)) {
      if (field === 'validationProp') {
        this.mixpanelService.trackRegistrationValidationError(this.validationPropErrorMessage);
      } else {
        this.mixpanelService.trackRegistrationValidationError(this.errorMessages.orderNumber);
      }
    }
  }

  onSubmit(): void {
    const orderNumber = this.getFormControlValue('orderNumber');
    const isOrderAndEmail = this.checkValidationType('orderAndEmail');
    const data = isOrderAndEmail ?
      new ValidateOrderModel({ orderNumber, email: this.getFormControlValue('validationProp') }) :
      new ValidateOrderModel({ orderNumber, phoneNumber: this.getFormControlValue('validationProp').replaceAll('-', '') });
    this.mixpanelService.trackFindMyOrder(isOrderAndEmail ? 'find order email' : 'find order phone', orderNumber);
    this.store.dispatch(new ValidateOrderNumber(data, this.validationProperty)).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe({
      error: (error) => {
        this.handleOrderNumberValidationError(error.status);
      }
    });
  }

  private getFormControlValue(control: string): string {
    return this.registerForm.get(control)?.value;
  }

  private createRegisterForm(): void {
    this.checkValidationType('orderAndEmail') ?
      this.registerForm = new FormGroup<RegisterForm>({
        orderNumber: new FormControl('', [Validators.required, Validators.minLength(11), Validators.pattern(Regex.orderNumber)]),
        validationProp: new FormControl('', [Validators.required, Validators.pattern(Regex.email)])
      }) :
      this.registerForm = new FormGroup<RegisterForm>({
        orderNumber: new FormControl('', [Validators.required, Validators.minLength(11), Validators.pattern(Regex.orderNumber)]),
        validationProp: new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(12)])
      });
  }

  private checkValidationType(type: string): boolean {
    return this.validationProperty === OrderNumberValidation[type];
  }

  private getPopupParams(errorStatus: number): PopupData | null {
    let title: string, text: string, rightBtnTxt: string, leftBtnTxt: string, icon: string, type: string;
    if (errorStatus === 400) {
      title = ServerErrors.ApiErrors.accountActivated.title;
      text = ServerErrors.ApiErrors.accountActivated.text;
      rightBtnTxt = 'Go to Login';
      leftBtnTxt = '';
      icon = 'dark-checkmark';
      type = 'green';
      return new PopupData({ title, text, rightBtnTxt, leftBtnTxt, icon, type, screen: 'Validate order' });
    } else if (errorStatus === 404) {
      title = RegistrationStringResource.ValidateOrderNumberError.title;
      text = RegistrationStringResource.ValidateOrderNumberError.text;
      rightBtnTxt = this.checkValidationType('orderAndEmail') ? 'Use Phone Number' : 'Try Again';
      leftBtnTxt = this.checkValidationType('orderAndEmail') ? '' : 'Start Over';
      icon = 'warning-icon';
      type = 'yellow';
      return new PopupData({ title, text, rightBtnTxt, leftBtnTxt, icon, type, screen: 'Validate order' });
    } else if (errorStatus > 400 && errorStatus < 500) {
      title = ServerErrors.ApiErrors.error400.title;
      text = ServerErrors.ApiErrors.error400.text;
      rightBtnTxt = 'OK';
      leftBtnTxt = '';
      icon = 'warning-icon';
      type = 'yellow';
      return new PopupData({ title, text, rightBtnTxt, leftBtnTxt, icon, type, screen: 'Validate order' });
    }
    return null;
  }

  private handleOrderNumberValidationError(errorStatus: number): void {
    const popupParams = this.getPopupParams(errorStatus);
    if (popupParams) {
      const modal = FunctionsHelper.createPopup(this.dialog, popupParams.title, popupParams.text, popupParams.screen, popupParams.icon, popupParams.type, popupParams.rightBtnTxt, popupParams.leftBtnTxt);
      modal.componentInstance.onClose.subscribe(() => {
        modal.close();
        this.mixpanelService.trackPopupClose(popupParams.text, 'x');
      });
      modal.componentInstance.onRightAction.subscribe(() => {
        modal.close();
        this.mixpanelService.trackPopupClose(popupParams.text, popupParams.rightBtnTxt);
        if (errorStatus === 404) {
          if (this.checkValidationType('orderAndEmail')) {
            this.validationProperty = OrderNumberValidation.orderAndPhoneNumber;
            this.resetRegisterForm();
          }
        }
      });
      modal.componentInstance.onLeftAction.subscribe(() => {
        modal.close();
        this.mixpanelService.trackPopupClose(popupParams.text, popupParams.leftBtnTxt);
        if (errorStatus === 404) {
          localStorage.removeItem('email');
          this.store.dispatch(new GoToLogin());
        }
      });
      modal.backdropClick().subscribe(() => {
        this.mixpanelService.trackPopupClose(popupParams.text, 'outside');
      });
      modal.afterClosed().subscribe(() => {
        if (errorStatus === 400) {
          this.store.dispatch(new GoToLogin());
          if (this.checkValidationType('orderAndEmail')) {
            localStorage.removeItem('login');
            localStorage.setItem('email', FunctionsHelper.encrypt(this.getFormControlValue('validationProp')));
          }
        }
        modal.componentInstance.onRightAction.unsubscribe();
        modal.componentInstance.onLeftAction.unsubscribe();
        modal.componentInstance.onClose.unsubscribe();
      });
    }
  }

  private resetRegisterForm(): void {
    this.registerForm.reset();
    this.createRegisterForm();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.registerForm.reset();
  }
}
