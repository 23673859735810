/* tslint-disable-no-implicit-any */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SetupSleeperData, SetupSleeperForm, SetupSleeperValues } from '@models/app/helpers.model';
import { PopupData } from '@models/app/modal-data.model';
import { Bed } from '@models/bed/bed.model';
import { Store } from '@ngxs/store';
import { BedSide } from '@shared/utils/helpers/enum.helper';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { RegistrationStringResource } from '@shared/utils/helpers/registration.helper';
import { SiqPopupHelper } from '@shared/utils/helpers/siq-popup.helper';
import { YearHelper } from '@shared/utils/helpers/year.helper';
import { CloseModal } from '@store/app/app.actions';
import { Logout } from '@store/auth/auth.actions';
import { CheckForBiqDetails, SetSleeperDataProperty } from '@store/setup/setup.actions';
import { SetupSelectors } from '@store/setup/setup.selectors';
import { Subject, filter, takeUntil } from 'rxjs';

const DEFAULT_GENDER_VALUE = 9;

@Component({
  selector: 'app-setup-sleeper-form',
  templateUrl: './setup-sleeper-form.component.html'
})
export class SetupSleeperFormComponent implements OnInit, OnDestroy {
  @Input() isPartnerSetup: boolean;
  @Output() redirectToTheNextScreen = new EventEmitter<void>();
  controlFocusState = {};
  dropdownDefaultValues: SetupSleeperValues;
  isFormSubmitted = false;
  selectedBed: Bed;
  form: FormGroup<SetupSleeperForm>;
  setupData = RegistrationStringResource.sleeperData();

  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private siqPopupHelper: SiqPopupHelper) { }

  ngOnInit(): void {
    const bedData = this.store.selectSnapshot(SetupSelectors.bedSetupData);
    this.store.select(SetupSelectors.selectedBed).pipe(
      takeUntil(this._unsubscribeAll),
      filter((bed): bed is Bed => !!bed)
    ).subscribe(bed => {
      this.store.dispatch(new CheckForBiqDetails(this.isPartnerSetup));
      this.selectedBed = bed;
      this.dropdownDefaultValues = RegistrationStringResource.sleeperDataDefaultValues(this.selectedBed.isKidsBed);
      const bedSide = this.isPartnerSetup ? (Number(!(bedData?.side)) ?? null) : (bedData?.side ?? null);
      const sleeperData = this.store.selectSnapshot(SetupSelectors.sleeperData);
      this.form = FunctionsHelper.createSetupSleeperForm(bed, bedSide, sleeperData);
    });
  }

  getQuestion(property: string): string {
    return RegistrationStringResource.sleeperSetupQuestions(this.isPartnerSetup)[property];
  }

  getDropdownValue(property: string): number {
    const value = this.getFormControlValue(property);
    return value !== null ? Number(value) : this.dropdownDefaultValues[property];
  }

  hasErrors(field: string): boolean {
    if (!this.controlFocusState[field]) {
      return this.form.controls[field].touched && !this.form.controls[field].valid;
    }
    return false;
  }

  setControlFocusState(focusState: object): void {
    this.controlFocusState = focusState;
    // set firstName value into state on focus out
    if (!focusState['firstName']) {
      this.store.dispatch(new SetSleeperDataProperty('firstName', this.getFormControlValue('firstName') ?? ''));
    }
  }

  isInvalidField(field: string): boolean {
    if (!this.controlFocusState[field]) {
      if (this.form.controls[field]?.touched) {
        return this.form && !this.isFormSubmitted
          ? this.checkIsInputValid(this.form.controls[field])
          : false;
      }
    }
    return false;
  }

  getValue(property: string): string {
    const value = property === 'birthYear' ? parseInt(this.form.controls.birthYear.value ?? '', 10) : this.form.get(property)?.value;
    const optionIndex = this.setupData[`${property}Values`].indexOf(value);
    return this.setupData[`${property}Options`][optionIndex];
  }

  handleDropdownSelect(selectedValue: number | string, property: string): void {
    this.form.controls[property].setValue(selectedValue);
    // to convert year into appropriate type
    if (property === 'birthYear') selectedValue = selectedValue.toString();
    this.store.dispatch(new SetSleeperDataProperty(property, selectedValue));
  }

  next(): void {
    this.isFormSubmitted = true;
    const setupData = this.form.getRawValue() as SetupSleeperData;
    // set other sleeper properties
    this.setBedPropertiesIntoSleeperData(setupData);
    if (setupData.birthYear && YearHelper.checkKidYears(setupData.birthYear)) {
      this.openKidPrivacyPolicyPopup();
    } else {
      const biqUser = this.store.selectSnapshot(SetupSelectors.biqUser);
      if (this.isPartnerSetup && biqUser?.sleepPartner?.email) {
        this.completeSleeperSetup();
      } else {
        this.completeSleeperSetup();
      }
    }
  }

  private setBedPropertiesIntoSleeperData(setupData: SetupSleeperData): void {
    // 1. case: user did not answer on gender question 
    if (setupData.gender === null) {
      setupData.gender = DEFAULT_GENDER_VALUE;
      this.store.dispatch(new SetSleeperDataProperty('gender', DEFAULT_GENDER_VALUE));
    }
    // 2. case: set bedId, side and duration
    const bedId = setupData.bedId;
    if (bedId)
      this.store.dispatch(new SetSleeperDataProperty('bedId', bedId));

    const side = setupData.side;
    if (side === BedSide.Left || side === BedSide.Right)
      this.store.dispatch(new SetSleeperDataProperty('side', side));

    const duration = setupData.duration;
    if (duration)
      this.store.dispatch(new SetSleeperDataProperty('duration', duration));
  }

  private completeSleeperSetup(): void {
    this.redirectToTheNextScreen.emit();
  }

  private getFormControlValue(control: string): string | number | null {
    return this.form.get(control)?.value;
  }

  private checkIsInputValid(item: FormControl): boolean {
    return !item?.valid;
  }

  private openKidPrivacyPolicyPopup(): void {
    const kidPrivacyPolicyCTA = RegistrationStringResource.kidPrivacyPolicyPopupData;
    const popupData = new PopupData({
      title: kidPrivacyPolicyCTA['title'],
      text: kidPrivacyPolicyCTA['text'],
      icon: 'warning-icon',
      type: 'yellow',
      screen: 'Setup Sleeper',
      rightBtnTxt: 'Acknowledge',
      leftBtnTxt: 'Decline'
    });
    const modal = this.siqPopupHelper.createPopup(popupData);
    modal.componentInstance.onClose.subscribe(() => {
      this.store.dispatch(new CloseModal(modal));
    });
    modal.componentInstance.onRightAction.subscribe(() => {
      this.store.dispatch(new CloseModal(modal));
      this.completeSleeperSetup();
    });
    modal.componentInstance.onLeftAction.subscribe(() => {
      this.store.dispatch(new CloseModal(modal));
      this.store.dispatch(new Logout());
    });
    modal.afterClosed().subscribe(() => {
      this.store.dispatch(new CloseModal(modal));
      modal.componentInstance.onRightAction.unsubscribe();
      modal.componentInstance.onLeftAction.unsubscribe();
      modal.componentInstance.onClose.unsubscribe();
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
