<div siqGrid fx-direction="row" [class.w-100-p]="isStretched">
  <div class="border-right primary-400" [class.w-100-p]="isStretched" [class.px-32]="!(breakpoints$ | async)?.isSmallScreen"  [class.px-8]="(breakpoints$ | async)?.isSmallScreen">
    <app-siq-metrics-preview 
    [icon]="bedtimeIcon"
    [text]="rightText"
    [subText]="idealTimes('bedTime')"
    [textClass]="textClass"
    [subTextClass]="subTextBedtimeClass"/>
  </div>
  <div [class.w-100-p]="isStretched" [class.px-32]="!(breakpoints$ | async)?.isSmallScreen"  [class.px-8]="(breakpoints$ | async)?.isSmallScreen">
    <app-siq-metrics-preview 
    [icon]="wakeTimeIcon"
    [text]="leftText"
    [subText]="idealTimes('wakeTime')"
    [textClass]="textClass"
    [subTextClass]="subTextWakeTimeClass"/>
  </div>
</div>


