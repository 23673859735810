import { Component, OnDestroy, OnInit } from '@angular/core';
import { Bed } from '@models/bed/bed.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { BedSettingsResources } from '@shared/utils/helpers/bed-settings.helper';
import { BaselineBed } from '@store/settings/settings.actions';
import { SettingsSelectors } from '@store/settings/settings.selectors';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-baseline-bed-home',
  templateUrl: './baseline-bed-home.component.html'
})
export class BaselineBedHomeComponent implements OnInit, OnDestroy {

  stepsBeforeBaseline = BedSettingsResources.StepsBeforeBaseline;
  selectedBed: Bed;

  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.select(SettingsSelectors.selectedBed).pipe(
      takeUntil(this._unsubscribeAll),
      filter((bed): bed is Bed => !!bed)
    ).subscribe((bed) => this.selectedBed = bed)
  }

  baselineBed(): void {
    this.store.dispatch(new BaselineBed(this.selectedBed));
    this.store.dispatch(new Navigate(['pages/smart-bed/details/baseline-bed/baseline-in-progress']));
  }

  back(): void {
    this.store.dispatch(new Navigate(['pages/smart-bed/bed-view']));
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
