import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SingleMultipleQuestions } from '@models/app/helpers.model';
import { Sleeper, UpdateSleeperProperty } from '@models/sleeper/sleeper.model';
import { Category, QuestionProperties, RadioButtonQuestion, SleeperWellnessQuestion, WellnessCategory } from '@models/sleeper/wellness-profile.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { BreakpointState, ResizeService } from '@services/resize.service';
import { WellnessProfileAction } from '@shared/utils/helpers/enum.helper';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { ProfileStringResource } from '@shared/utils/helpers/profile.helper';
import { SetSleeperWellnessProfile, UpdateSleeper } from '@store/sleepers/sleepers.actions';
import { SleepersSelectors } from '@store/sleepers/sleepers.selectors';
import { Observable, Subject, filter, takeUntil } from 'rxjs';

interface WellnessQuestionForm {
  [key: string]: FormControl<number | Array<number> | Array<object> | null>;
}

const STRING_ANSWERS = ['temporaryHealthIssues', 'chronicHealthIssues', 'sleepGoals'];

@Component({
  selector: 'app-answer-questions',
  templateUrl: './answer-questions.component.html'
})
export class AnswerQuestionsComponent implements OnInit, OnDestroy {

  selectedSleeper: Sleeper;
  categoryId: number;
  category: WellnessCategory | null;
  notAnsweredQuestion = 'Please select...';
  wellnessQuestionForm: FormGroup<WellnessQuestionForm>;
  sleeperWellnessAnswers: UpdateSleeperProperty;
  sleeperQuestions: Array<SleeperWellnessQuestion>;
  breakpoints$: Observable<BreakpointState>;
  updateSleeper = false;
  disableSaveBtn = false;

  private _unsubscribeAll = new Subject<void>();

  constructor(private route: ActivatedRoute, private store: Store, private mixpanelService: MixpanelService, private resizeService: ResizeService) { }

  get title(): string {
    return this.isChild ? `${this.selectedSleeper?.firstName}'s Sleeper Profile` : 'Wellness Profile';
  }

  get isSleeperCategory(): boolean | null {
    return this.categoryId === Category.Sleeper;
  }

  get isChild(): boolean {
    return this.selectedSleeper?.isChild;
  }

  ngOnInit(): void {
    FunctionsHelper.scrollToTop();
    this.breakpoints$ = this.resizeService.isMobile;
    this.categoryId = Number(this.route.snapshot.paramMap.get('categoryId'));
    this.category = this.store.selectSnapshot(SleepersSelectors.getWellnessQuestionsCategory)(this.categoryId);
    this.store.select(SleepersSelectors.selectedSleeper).pipe(
      takeUntil(this._unsubscribeAll),
      filter((sleeper): sleeper is Sleeper => !!sleeper && !!sleeper.wellnessProfile)
    ).subscribe((sleeper) => {
      this.selectedSleeper = sleeper;
      if (this.isSleeperCategory) {
        this.sleeperQuestions = ProfileStringResource.getSleeperWellnessProfileQuestions(this.selectedSleeper);
      }
      this.createWellnessQuestionForm();
    });
  }

  back(): void {
    this.mixpanelService.trackWellnessProfileCategoryCancelUpdate();
    if (this.selectedSleeper.isChild) {
      this.store.dispatch(new Navigate(['pages/profile']));
    } else {
      this.store.dispatch(new Navigate(['pages/profile/details/wellness/home']));
    }
  }

  getSleeperSelectedValue(question: string): number {
    const value = this.wellnessQuestionForm.controls[question].value;
    let valueToNumber = -1;
    if (value !== null) {
      // @ts-expect-error this is only for dropdown questions
      valueToNumber = typeof value === 'string' ? parseInt(value, 10) : value;
    }
    return valueToNumber;
  }

  getWellnessProfileAnswer(question: QuestionProperties): string {
    if (this.wellnessQuestionForm) {
      const value = this.wellnessQuestionForm.controls[question.name].value;
      return question.answers?.find((answer) => answer.id.toString() === value?.toString())?.text ?? '';
    }
    return '';
  }

  handleDropdownSelect(selectedItem: number, question: string): void {
    this.wellnessQuestionForm.controls[question].setValue(selectedItem);
  }

  handleSleeperAnswers(sleeperAnswers: UpdateSleeperProperty): void {
    this.updateSleeper = true;
    sleeperAnswers = {
      ...sleeperAnswers,
      // @ts-expect-error birthYear won't be null, since it's always present in the form
      birthYear: sleeperAnswers['birthYear'].toString()
    };
    this.sleeperWellnessAnswers = sleeperAnswers;
  }

  handleSingleMultipleChoiceQuestions(singleMultipleAnswers: Array<number | object>, question: string): void {
    this.wellnessQuestionForm.controls[question].setValue(singleMultipleAnswers[question]);
  }

  setSleeperError(hasSleeperError: boolean): void {
    this.disableSaveBtn = hasSleeperError;
  }

  saveAndFinish(): void {
    const wellnessAnswers = this.formatWellnessAnswers(this.wellnessQuestionForm.getRawValue());
    this.store.dispatch(new SetSleeperWellnessProfile(this.selectedSleeper.sleeperId, wellnessAnswers, WellnessProfileAction.Update)).subscribe(() => {
      if (this.category) {
        this.mixpanelService.trackWellnessProfileCategoryUpdate(this.category.name);
      }
      if (!this.updateSleeper) {
        this.back();
      }
    });
    if (this.updateSleeper) {
      if (this.selectedSleeper.sleepGoal !== this.sleeperWellnessAnswers['sleepGoal']) {
        this.mixpanelService.trackSleepGoalChange(this.selectedSleeper.sleepGoal, this.sleeperWellnessAnswers['sleepGoal'] as number);
      }
      this.store.dispatch(new UpdateSleeper(this.selectedSleeper.sleeperId, this.sleeperWellnessAnswers)).subscribe(() => {
        this.back();
      });
    }
  }

  getSingleMultipleQuestion(question: string): SingleMultipleQuestions {
    return this.wellnessQuestionForm.controls[question] as unknown as SingleMultipleQuestions;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private createWellnessQuestionForm(): void {
    const wellnessQuestionControls = {};
    this.category?.questions.forEach((question) => {
      if(question.isDropdownQuestion) {
        const formControl = this.selectedSleeper.wellnessProfile ? this.selectedSleeper.wellnessProfile[question.name] : null;
        wellnessQuestionControls[question.name] = new FormControl(formControl);
      } else if (question.isMultipleChoiceQuestion) {
        const formControl = this.selectedSleeper.wellnessProfile ? this.selectedSleeper.wellnessProfile[question.name]: [];
        wellnessQuestionControls[question.name] = new FormControl(formControl)
      } else {
        const formControl = this.createSingleQuestionForm(question);
        wellnessQuestionControls[question.name] = new FormControl(formControl);
      }
    });
    this.wellnessQuestionForm = new FormGroup<WellnessQuestionForm>(wellnessQuestionControls);
  }

  private formatWellnessAnswers(answers: { [key: string]: number | Array<number> | object | null; }): { [key: string]: number | string | Array<number> | Array<object> | null; } {
    const wellnessAnswers = {};
    Object.keys(answers).forEach((key) => {
      if (STRING_ANSWERS.includes(key)) {
        wellnessAnswers[key] = answers[key]?.toString();
      } else {
        wellnessAnswers[key] = answers[key];
      }
    });
    return wellnessAnswers;
  }

  private createSingleQuestionForm(question: QuestionProperties): Array<object> {
    const formControl: Array<object> = [];
    question.subquestions?.forEach((subQuestion) => {
      const key = subQuestion.id;
      const answerToQuestion = this.selectedSleeper.wellnessProfile ? this.selectedSleeper.wellnessProfile[question.name]: [new RadioButtonQuestion({[key]: null})];
      const answer = answerToQuestion.find((it: RadioButtonQuestion) => Object.keys(it)[0] === key);
      formControl.push(answer);
    });
    return formControl;
  }
}
