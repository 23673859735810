import { Bamkey } from './client';  
import { Side, ResetType, ArticulationPreset, Homing, ActLocation, ActuatorMovementStatus, AccessPointAction, FileType, RemoteBinaryType, FootwarmingSetting, DataRecordServiceName, EnableFlag, BedPresence, BedPresenceFlag, CreqCommand, ServiceName, LogLevel, EnabledSetting, LedState, SleepNumberAdjustmentStatus, SleepiqDataState, MattressSize, BaseType, ChamberType, ThermalEnableFlag, NameType, RebootDevice, InstallationStatus, HwSku, NetworkConnectivity, SoftwareDownloadStatus, HeidiRoutineEnable, HeidiApplyType, HeidiImage, HeidiMode, HeidiPresence, HeidiVersionType, HealthStatus, SensorIndex, UblAutoEnable, UblLevel, UblCurrentStatus } from './enums';

export interface BKRequest {
  bamkey: string;
  arguments: Array<string | number | boolean>;
}

export interface BKResponse {
  numberOfResponseArguments: number;

  setResponseValue?(response: string): void;
}

export interface BKCommand {
  request: BKRequest;
  response: BKResponse;
}

export class CancelTargetPresetRequest implements BKRequest {
  bamkey = 'ACCP';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class CancelTargetPresetResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class CancelTargetPresetCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new CancelTargetPresetRequest(sideString);
    this.response = new CancelTargetPresetResponse();
  }
}

export class ArticulationControlFactoryResetRequest implements BKRequest {
  bamkey = 'ACFR';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,articulationResetType: ResetType) {
    this.arguments = [sideString, articulationResetType];
  }
}

export class ArticulationControlFactoryResetResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class ArticulationControlFactoryResetCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,articulationResetType: ResetType) {
    this.request = new ArticulationControlFactoryResetRequest(sideString, articulationResetType);
    this.response = new ArticulationControlFactoryResetResponse();
  }
}

export class GetTargetPresetWithTimerRequest implements BKRequest {
  bamkey = 'ACGP';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetTargetPresetWithTimerResponse implements BKResponse {
  numberOfResponseArguments: number;
  targetPresetWithTimer!: ArticulationPreset;
	remainingTime!: number;

  constructor() {
    this.numberOfResponseArguments = 2
  }

  setResponseValue(response: string): void {
    this.targetPresetWithTimer = Bamkey.extractValue<ArticulationPreset>(response, 0, this.numberOfResponseArguments, false);
		this.remainingTime = Bamkey.extractValue<number>(response, 1, this.numberOfResponseArguments, false);
  }
}

export class GetTargetPresetWithTimerCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetTargetPresetWithTimerRequest(sideString);
    this.response = new GetTargetPresetWithTimerResponse();
  }
}

export class HaltAllActuatorsRequest implements BKRequest {
  bamkey = 'ACHA';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class HaltAllActuatorsResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class HaltAllActuatorsCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new HaltAllActuatorsRequest();
    this.response = new HaltAllActuatorsResponse();
  }
}

export class GetsActuatorHomingStateRequest implements BKRequest {
  bamkey = 'ACHG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetsActuatorHomingStateResponse implements BKResponse {
  numberOfResponseArguments: number;
  bedHomingState!: Homing;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.bedHomingState = Bamkey.extractValue<Homing>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetsActuatorHomingStateCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetsActuatorHomingStateRequest();
    this.response = new GetsActuatorHomingStateResponse();
  }
}

export class StartsActuatorHomingRequest implements BKRequest {
  bamkey = 'ACHS';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class StartsActuatorHomingResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class StartsActuatorHomingCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new StartsActuatorHomingRequest();
    this.response = new StartsActuatorHomingResponse();
  }
}

export class GetArticulationPresetSettingsRequest implements BKRequest {
  bamkey = 'ACPG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,articulationPreset: ArticulationPreset) {
    this.arguments = [sideString, articulationPreset];
  }
}

export class GetArticulationPresetSettingsResponse implements BKResponse {
  numberOfResponseArguments: number;
  presetHeadPosition!: number;
	presetFootPosition!: number;

  constructor() {
    this.numberOfResponseArguments = 2
  }

  setResponseValue(response: string): void {
    this.presetHeadPosition = Bamkey.extractValue<number>(response, 0, this.numberOfResponseArguments, false);
		this.presetFootPosition = Bamkey.extractValue<number>(response, 1, this.numberOfResponseArguments, false);
  }
}

export class GetArticulationPresetSettingsCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,articulationPreset: ArticulationPreset) {
    this.request = new GetArticulationPresetSettingsRequest(sideString, articulationPreset);
    this.response = new GetArticulationPresetSettingsResponse();
  }
}

export class SetArticulationPresetSettingsRequest implements BKRequest {
  bamkey = 'ACPS';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,articulationPreset: ArticulationPreset,presetHeadPosition: number,presetFootPosition: number) {
    this.arguments = [sideString, articulationPreset, presetHeadPosition, presetFootPosition];
  }
}

export class SetArticulationPresetSettingsResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetArticulationPresetSettingsCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,articulationPreset: ArticulationPreset,presetHeadPosition: number,presetFootPosition: number) {
    this.request = new SetArticulationPresetSettingsRequest(sideString, articulationPreset, presetHeadPosition, presetFootPosition);
    this.response = new SetArticulationPresetSettingsResponse();
  }
}

export class SetTargetPresetWithTimerRequest implements BKRequest {
  bamkey = 'ACSP';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,targetPresetWithTimer: ArticulationPreset,timer: number) {
    this.arguments = [sideString, targetPresetWithTimer, timer];
  }
}

export class SetTargetPresetWithTimerResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetTargetPresetWithTimerCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,targetPresetWithTimer: ArticulationPreset,timer: number) {
    this.request = new SetTargetPresetWithTimerRequest(sideString, targetPresetWithTimer, timer);
    this.response = new SetTargetPresetWithTimerResponse();
  }
}

export class GetActuatorPositionRequest implements BKRequest {
  bamkey = 'ACTG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,actuatorLocation: ActLocation) {
    this.arguments = [sideString, actuatorLocation];
  }
}

export class GetActuatorPositionResponse implements BKResponse {
  numberOfResponseArguments: number;
  currentActuatorPosition!: number;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.currentActuatorPosition = Bamkey.extractValue<number>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetActuatorPositionCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,actuatorLocation: ActLocation) {
    this.request = new GetActuatorPositionRequest(sideString, actuatorLocation);
    this.response = new GetActuatorPositionResponse();
  }
}

export class HaltActuatorRequest implements BKRequest {
  bamkey = 'ACTH';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,actuatorLocation: ActLocation) {
    this.arguments = [sideString, actuatorLocation];
  }
}

export class HaltActuatorResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class HaltActuatorCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,actuatorLocation: ActLocation) {
    this.request = new HaltActuatorRequest(sideString, actuatorLocation);
    this.response = new HaltActuatorResponse();
  }
}

export class GetActuatorMovementStatusRequest implements BKRequest {
  bamkey = 'ACTM';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetActuatorMovementStatusResponse implements BKResponse {
  numberOfResponseArguments: number;
  rightHead!: ActuatorMovementStatus;
	rightFoot!: ActuatorMovementStatus;
	leftHead!: ActuatorMovementStatus;
	leftFoot!: ActuatorMovementStatus;

  constructor() {
    this.numberOfResponseArguments = 4
  }

  setResponseValue(response: string): void {
    this.rightHead = Bamkey.extractValue<ActuatorMovementStatus>(response, 0, this.numberOfResponseArguments, false);
		this.rightFoot = Bamkey.extractValue<ActuatorMovementStatus>(response, 1, this.numberOfResponseArguments, false);
		this.leftHead = Bamkey.extractValue<ActuatorMovementStatus>(response, 2, this.numberOfResponseArguments, false);
		this.leftFoot = Bamkey.extractValue<ActuatorMovementStatus>(response, 3, this.numberOfResponseArguments, false);
  }
}

export class GetActuatorMovementStatusCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetActuatorMovementStatusRequest();
    this.response = new GetActuatorMovementStatusResponse();
  }
}

export class SetActuatorTargetPositionRequest implements BKRequest {
  bamkey = 'ACTS';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,actuatorLocation: ActLocation,targetActuatorPosition: number) {
    this.arguments = [sideString, actuatorLocation, targetActuatorPosition];
  }
}

export class SetActuatorTargetPositionResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetActuatorTargetPositionCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,actuatorLocation: ActLocation,targetActuatorPosition: number) {
    this.request = new SetActuatorTargetPositionRequest(sideString, actuatorLocation, targetActuatorPosition);
    this.response = new SetActuatorTargetPositionResponse();
  }
}

export class GetCurrentPresetRequest implements BKRequest {
  bamkey = 'AGCP';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetCurrentPresetResponse implements BKResponse {
  numberOfResponseArguments: number;
  currentPreset!: ArticulationPreset;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.currentPreset = Bamkey.extractValue<ArticulationPreset>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetCurrentPresetCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetCurrentPresetRequest(sideString);
    this.response = new GetCurrentPresetResponse();
  }
}

export class GetTargetPresetInfoWithoutTimerRequest implements BKRequest {
  bamkey = 'AGTP';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetTargetPresetInfoWithoutTimerResponse implements BKResponse {
  numberOfResponseArguments: number;
  targetPreset!: ArticulationPreset;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.targetPreset = Bamkey.extractValue<ArticulationPreset>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetTargetPresetInfoWithoutTimerCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetTargetPresetInfoWithoutTimerRequest(sideString);
    this.response = new GetTargetPresetInfoWithoutTimerResponse();
  }
}

export class GetAccessPointStatusRequest implements BKRequest {
  bamkey = 'APSG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetAccessPointStatusResponse implements BKResponse {
  numberOfResponseArguments: number;
  accessPointStatus!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.accessPointStatus = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class GetAccessPointStatusCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetAccessPointStatusRequest();
    this.response = new GetAccessPointStatusResponse();
  }
}

export class AccessPointStartStopRequest implements BKRequest {
  bamkey = 'APSS';
  arguments: Array<string | number | boolean>;

  constructor(accessPointAction: AccessPointAction) {
    this.arguments = [accessPointAction];
  }
}

export class AccessPointStartStopResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class AccessPointStartStopCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(accessPointAction: AccessPointAction) {
    this.request = new AccessPointStartStopRequest(accessPointAction);
    this.response = new AccessPointStartStopResponse();
  }
}

export class SetActuatorTargetPositionWithTimerRequest implements BKRequest {
  bamkey = 'ASTM';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,actuatorLocation: ActLocation,targetActuatorPosition: number) {
    this.arguments = [sideString, actuatorLocation, targetActuatorPosition];
  }
}

export class SetActuatorTargetPositionWithTimerResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetActuatorTargetPositionWithTimerCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,actuatorLocation: ActLocation,targetActuatorPosition: number) {
    this.request = new SetActuatorTargetPositionWithTimerRequest(sideString, actuatorLocation, targetActuatorPosition);
    this.response = new SetActuatorTargetPositionWithTimerResponse();
  }
}

export class SetTargetPresetWithoutTimerRequest implements BKRequest {
  bamkey = 'ASTP';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,targetPreset: ArticulationPreset) {
    this.arguments = [sideString, targetPreset];
  }
}

export class SetTargetPresetWithoutTimerResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetTargetPresetWithoutTimerCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,targetPreset: ArticulationPreset) {
    this.request = new SetTargetPresetWithoutTimerRequest(sideString, targetPreset);
    this.response = new SetTargetPresetWithoutTimerResponse();
  }
}

export class MultipleBamkeysViaJSONRequest implements BKRequest {
  bamkey = 'BAMG';
  arguments: Array<string | number | boolean>;

  constructor(input: string) {
    this.arguments = [input];
  }
}

export class MultipleBamkeysViaJSONResponse implements BKResponse {
  numberOfResponseArguments: number;
  results!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.results = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class MultipleBamkeysViaJSONCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(input: string) {
    this.request = new MultipleBamkeysViaJSONRequest(input);
    this.response = new MultipleBamkeysViaJSONResponse();
  }
}

export class DisconnectDeviceRequest implements BKRequest {
  bamkey = 'BDCD';
  arguments: Array<string | number | boolean>;

  constructor(disconnectMacAddress: string) {
    this.arguments = [disconnectMacAddress];
  }
}

export class DisconnectDeviceResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class DisconnectDeviceCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(disconnectMacAddress: string) {
    this.request = new DisconnectDeviceRequest(disconnectMacAddress);
    this.response = new DisconnectDeviceResponse();
  }
}

export class GetCentralDevicesMetadataRequest implements BKRequest {
  bamkey = 'BGCD';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetCentralDevicesMetadataResponse implements BKResponse {
  numberOfResponseArguments: number;
  pairedDevicesInformation!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.pairedDevicesInformation = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class GetCentralDevicesMetadataCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetCentralDevicesMetadataRequest();
    this.response = new GetCentralDevicesMetadataResponse();
  }
}

export class GetFileOverBLERequest implements BKRequest {
  bamkey = 'BGTF';
  arguments: Array<string | number | boolean>;

  constructor(filename: string) {
    this.arguments = [filename];
  }
}

export class GetFileOverBLEResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class GetFileOverBLECommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(filename: string) {
    this.request = new GetFileOverBLERequest(filename);
    this.response = new GetFileOverBLEResponse();
  }
}

export class CreatebosonondemandtunnelRequest implements BKRequest {
  bamkey = 'BOTC';
  arguments: Array<string | number | boolean>;

  constructor(bosonOnDemandConfigData: string) {
    this.arguments = [bosonOnDemandConfigData];
  }
}

export class CreatebosonondemandtunnelResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class CreatebosonondemandtunnelCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(bosonOnDemandConfigData: string) {
    this.request = new CreatebosonondemandtunnelRequest(bosonOnDemandConfigData);
    this.response = new CreatebosonondemandtunnelResponse();
  }
}

export class UnpairDeviceRequest implements BKRequest {
  bamkey = 'BUPD';
  arguments: Array<string | number | boolean>;

  constructor(unpairMacAddress: string) {
    this.arguments = [unpairMacAddress];
  }
}

export class UnpairDeviceResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class UnpairDeviceCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(unpairMacAddress: string) {
    this.request = new UnpairDeviceRequest(unpairMacAddress);
    this.response = new UnpairDeviceResponse();
  }
}

export class RequestAndWaitForVersionInformationRequest implements BKRequest {
  bamkey = 'CTST';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class RequestAndWaitForVersionInformationResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class RequestAndWaitForVersionInformationCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new RequestAndWaitForVersionInformationRequest(sideString);
    this.response = new RequestAndWaitForVersionInformationResponse();
  }
}

export class GetDeviceIPAddressRequest implements BKRequest {
  bamkey = 'DIPG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetDeviceIPAddressResponse implements BKResponse {
  numberOfResponseArguments: number;
  devicePublicIpAddress!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.devicePublicIpAddress = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetDeviceIPAddressCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetDeviceIPAddressRequest();
    this.response = new GetDeviceIPAddressResponse();
  }
}

export class UploadDiagnosticsResultsOverBLERequest implements BKRequest {
  bamkey = 'DSBU';
  arguments: Array<string | number | boolean>;

  constructor(fileType: FileType) {
    this.arguments = [fileType];
  }
}

export class UploadDiagnosticsResultsOverBLEResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class UploadDiagnosticsResultsOverBLECommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(fileType: FileType) {
    this.request = new UploadDiagnosticsResultsOverBLERequest(fileType);
    this.response = new UploadDiagnosticsResultsOverBLEResponse();
  }
}

export class CancelCurrentDiagnosticRunRequest implements BKRequest {
  bamkey = 'DSRC';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class CancelCurrentDiagnosticRunResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class CancelCurrentDiagnosticRunCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new CancelCurrentDiagnosticRunRequest();
    this.response = new CancelCurrentDiagnosticRunResponse();
  }
}

export class GetSimpleDiagnosticRunResultsRequest implements BKRequest {
  bamkey = 'DSRG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetSimpleDiagnosticRunResultsResponse implements BKResponse {
  numberOfResponseArguments: number;
  runResults!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.runResults = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class GetSimpleDiagnosticRunResultsCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetSimpleDiagnosticRunResultsRequest();
    this.response = new GetSimpleDiagnosticRunResultsResponse();
  }
}

export class StartDiagnosticRunRequest implements BKRequest {
  bamkey = 'DSRS';
  arguments: Array<string | number | boolean>;

  constructor(runJson: string) {
    this.arguments = [runJson];
  }
}

export class StartDiagnosticRunResponse implements BKResponse {
  numberOfResponseArguments: number;
  startStatus!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.startStatus = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class StartDiagnosticRunCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(runJson: string) {
    this.request = new StartDiagnosticRunRequest(runJson);
    this.response = new StartDiagnosticRunResponse();
  }
}

export class UploadDiagnosticRunResultRequest implements BKRequest {
  bamkey = 'DSRU';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class UploadDiagnosticRunResultResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class UploadDiagnosticRunResultCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new UploadDiagnosticRunResultRequest();
    this.response = new UploadDiagnosticRunResultResponse();
  }
}

export class IDForCurrentRunRequest implements BKRequest {
  bamkey = 'DSTA';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class IDForCurrentRunResponse implements BKResponse {
  numberOfResponseArguments: number;
  runId!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.runId = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class IDForCurrentRunCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new IDForCurrentRunRequest();
    this.response = new IDForCurrentRunResponse();
  }
}

export class GetUpdateStatusRequest implements BKRequest {
  bamkey = 'DTUG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetUpdateStatusResponse implements BKResponse {
  numberOfResponseArguments: number;
  updateStatus!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.updateStatus = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class GetUpdateStatusCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetUpdateStatusRequest();
    this.response = new GetUpdateStatusResponse();
  }
}

export class StartHDOfflineUpdateRequest implements BKRequest {
  bamkey = 'DTUS';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class StartHDOfflineUpdateResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class StartHDOfflineUpdateCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new StartHDOfflineUpdateRequest();
    this.response = new StartHDOfflineUpdateResponse();
  }
}

export class SetThrottlingParametersForSRLRequest implements BKRequest {
  bamkey = 'FBTS';
  arguments: Array<string | number | boolean>;

  constructor(srlThrottlingRate_: number,srlThrottlingWindow_: number,srlThrottlingInterval_: string) {
    this.arguments = [srlThrottlingRate_, srlThrottlingWindow_, srlThrottlingInterval_];
  }
}

export class SetThrottlingParametersForSRLResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetThrottlingParametersForSRLCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(srlThrottlingRate_: number,srlThrottlingWindow_: number,srlThrottlingInterval_: string) {
    this.request = new SetThrottlingParametersForSRLRequest(srlThrottlingRate_, srlThrottlingWindow_, srlThrottlingInterval_);
    this.response = new SetThrottlingParametersForSRLResponse();
  }
}

export class GetFuzionRemoteAlertsRequest implements BKRequest {
  bamkey = 'FRAG';
  arguments: Array<string | number | boolean>;

  constructor(macAddress: string) {
    this.arguments = [macAddress];
  }
}

export class GetFuzionRemoteAlertsResponse implements BKResponse {
  numberOfResponseArguments: number;
  alertInformation!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.alertInformation = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class GetFuzionRemoteAlertsCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(macAddress: string) {
    this.request = new GetFuzionRemoteAlertsRequest(macAddress);
    this.response = new GetFuzionRemoteAlertsResponse();
  }
}

export class GetNextRemoteBinaryRequest implements BKRequest {
  bamkey = 'FRBG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetNextRemoteBinaryResponse implements BKResponse {
  numberOfResponseArguments: number;
  firmwareTypeForRemoteDevice!: RemoteBinaryType;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.firmwareTypeForRemoteDevice = Bamkey.extractValue<RemoteBinaryType>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetNextRemoteBinaryCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetNextRemoteBinaryRequest();
    this.response = new GetNextRemoteBinaryResponse();
  }
}

export class GetFuzionRemoteInfoRequest implements BKRequest {
  bamkey = 'FRIG';
  arguments: Array<string | number | boolean>;

  constructor(macAddress: string) {
    this.arguments = [macAddress];
  }
}

export class GetFuzionRemoteInfoResponse implements BKResponse {
  numberOfResponseArguments: number;
  remoteInformation!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.remoteInformation = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class GetFuzionRemoteInfoCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(macAddress: string) {
    this.request = new GetFuzionRemoteInfoRequest(macAddress);
    this.response = new GetFuzionRemoteInfoResponse();
  }
}

export class GetFuzionRemoteMacAddressesRequest implements BKRequest {
  bamkey = 'FRMG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetFuzionRemoteMacAddressesResponse implements BKResponse {
  numberOfResponseArguments: number;
  remoteDeviceAddresses!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.remoteDeviceAddresses = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class GetFuzionRemoteMacAddressesCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetFuzionRemoteMacAddressesRequest();
    this.response = new GetFuzionRemoteMacAddressesResponse();
  }
}

export class RequestRemoteFirmwareVersionsRequest implements BKRequest {
  bamkey = 'FRVG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class RequestRemoteFirmwareVersionsResponse implements BKResponse {
  numberOfResponseArguments: number;
  fuzionRemoteManifestVersion!: string;
	fuzionRemoteBootloaderVersion!: string;
	fuzionRemoteMainAppVersion!: string;
	fuzionRemoteRecoveryAppVersion!: string;
	fuzionRemoteBleMainAppVersion!: string;
	fuzionRemoteBleRecoveryAppVersion!: string;
	fuzionRemoteUiAssetsVersion!: string;

  constructor() {
    this.numberOfResponseArguments = 7
  }

  setResponseValue(response: string): void {
    this.fuzionRemoteManifestVersion = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
		this.fuzionRemoteBootloaderVersion = Bamkey.extractValue<string>(response, 1, this.numberOfResponseArguments, false);
		this.fuzionRemoteMainAppVersion = Bamkey.extractValue<string>(response, 2, this.numberOfResponseArguments, false);
		this.fuzionRemoteRecoveryAppVersion = Bamkey.extractValue<string>(response, 3, this.numberOfResponseArguments, false);
		this.fuzionRemoteBleMainAppVersion = Bamkey.extractValue<string>(response, 4, this.numberOfResponseArguments, false);
		this.fuzionRemoteBleRecoveryAppVersion = Bamkey.extractValue<string>(response, 5, this.numberOfResponseArguments, false);
		this.fuzionRemoteUiAssetsVersion = Bamkey.extractValue<string>(response, 6, this.numberOfResponseArguments, false);
  }
}

export class RequestRemoteFirmwareVersionsCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new RequestRemoteFirmwareVersionsRequest();
    this.response = new RequestRemoteFirmwareVersionsResponse();
  }
}

export class GetFootwarmingPresenceRequest implements BKRequest {
  bamkey = 'FWPG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetFootwarmingPresenceResponse implements BKResponse {
  numberOfResponseArguments: number;
  footwarmingPresenceFlag!: number;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.footwarmingPresenceFlag = Bamkey.extractValue<number>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetFootwarmingPresenceCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetFootwarmingPresenceRequest(sideString);
    this.response = new GetFootwarmingPresenceResponse();
  }
}

export class GetFootwarmingSettingsRequest implements BKRequest {
  bamkey = 'FWTG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetFootwarmingSettingsResponse implements BKResponse {
  numberOfResponseArguments: number;
  footwarmingLevel!: FootwarmingSetting;
	remainingTime!: number;
	totalRemainingTime!: number;

  constructor() {
    this.numberOfResponseArguments = 3
  }

  setResponseValue(response: string): void {
    this.footwarmingLevel = Bamkey.extractValue<FootwarmingSetting>(response, 0, this.numberOfResponseArguments, false);
		this.remainingTime = Bamkey.extractValue<number>(response, 1, this.numberOfResponseArguments, false);
		this.totalRemainingTime = Bamkey.extractValue<number>(response, 2, this.numberOfResponseArguments, false);
  }
}

export class GetFootwarmingSettingsCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetFootwarmingSettingsRequest(sideString);
    this.response = new GetFootwarmingSettingsResponse();
  }
}

export class SetFootwarmingSettingsRequest implements BKRequest {
  bamkey = 'FWTS';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,footwarmingLevel: FootwarmingSetting,footwarmingTimerValue: number) {
    this.arguments = [sideString, footwarmingLevel, footwarmingTimerValue];
  }
}

export class SetFootwarmingSettingsResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetFootwarmingSettingsCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,footwarmingLevel: FootwarmingSetting,footwarmingTimerValue: number) {
    this.request = new SetFootwarmingSettingsRequest(sideString, footwarmingLevel, footwarmingTimerValue);
    this.response = new SetFootwarmingSettingsResponse();
  }
}

export class HealthRecordEnableSetRequest implements BKRequest {
  bamkey = 'HRES';
  arguments: Array<string | number | boolean>;

  constructor(serviceName: DataRecordServiceName,enabled: EnableFlag) {
    this.arguments = [serviceName, enabled];
  }
}

export class HealthRecordEnableSetResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class HealthRecordEnableSetCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(serviceName: DataRecordServiceName,enabled: EnableFlag) {
    this.request = new HealthRecordEnableSetRequest(serviceName, enabled);
    this.response = new HealthRecordEnableSetResponse();
  }
}

export class HealthRecordFlushSetRequest implements BKRequest {
  bamkey = 'HRFS';
  arguments: Array<string | number | boolean>;

  constructor(serviceName: DataRecordServiceName) {
    this.arguments = [serviceName];
  }
}

export class HealthRecordFlushSetResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class HealthRecordFlushSetCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(serviceName: DataRecordServiceName) {
    this.request = new HealthRecordFlushSetRequest(serviceName);
    this.response = new HealthRecordFlushSetResponse();
  }
}

export class GetAlgoInformationRequest implements BKRequest {
  bamkey = 'LAIG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetAlgoInformationResponse implements BKResponse {
  numberOfResponseArguments: number;
  birthYear!: number;
	height!: number;
	weight!: number;
	bedType!: number;
	personType!: number;

  constructor() {
    this.numberOfResponseArguments = 5
  }

  setResponseValue(response: string): void {
    this.birthYear = Bamkey.extractValue<number>(response, 0, this.numberOfResponseArguments, false);
		this.height = Bamkey.extractValue<number>(response, 1, this.numberOfResponseArguments, false);
		this.weight = Bamkey.extractValue<number>(response, 2, this.numberOfResponseArguments, false);
		this.bedType = Bamkey.extractValue<number>(response, 3, this.numberOfResponseArguments, false);
		this.personType = Bamkey.extractValue<number>(response, 4, this.numberOfResponseArguments, false);
  }
}

export class GetAlgoInformationCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetAlgoInformationRequest(sideString);
    this.response = new GetAlgoInformationResponse();
  }
}

export class GetAlgoMotionStatusRequest implements BKRequest {
  bamkey = 'LAMG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetAlgoMotionStatusResponse implements BKResponse {
  numberOfResponseArguments: number;
  motionStatusRaFlag!: number;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.motionStatusRaFlag = Bamkey.extractValue<number>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetAlgoMotionStatusCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetAlgoMotionStatusRequest(sideString);
    this.response = new GetAlgoMotionStatusResponse();
  }
}

export class GetAlgoThresholdLevelRequest implements BKRequest {
  bamkey = 'LATG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetAlgoThresholdLevelResponse implements BKResponse {
  numberOfResponseArguments: number;
  ignoreHeadThresholdLevel!: number;
	ignoreFootThresholdLevel!: number;

  constructor() {
    this.numberOfResponseArguments = 2
  }

  setResponseValue(response: string): void {
    this.ignoreHeadThresholdLevel = Bamkey.extractValue<number>(response, 0, this.numberOfResponseArguments, false);
		this.ignoreFootThresholdLevel = Bamkey.extractValue<number>(response, 1, this.numberOfResponseArguments, false);
  }
}

export class GetAlgoThresholdLevelCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetAlgoThresholdLevelRequest();
    this.response = new GetAlgoThresholdLevelResponse();
  }
}

export class GetBedPresenceRequest implements BKRequest {
  bamkey = 'LBPG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetBedPresenceResponse implements BKResponse {
  numberOfResponseArguments: number;
  bedPresence!: BedPresence;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.bedPresence = Bamkey.extractValue<BedPresence>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetBedPresenceCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetBedPresenceRequest(sideString);
    this.response = new GetBedPresenceResponse();
  }
}

export class SetBedPresenceRequest implements BKRequest {
  bamkey = 'LBPS';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,bedPresenceFlag: BedPresenceFlag) {
    this.arguments = [sideString, bedPresenceFlag];
  }
}

export class SetBedPresenceResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetBedPresenceCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,bedPresenceFlag: BedPresenceFlag) {
    this.request = new SetBedPresenceRequest(sideString, bedPresenceFlag);
    this.response = new SetBedPresenceResponse();
  }
}

export class GetLastCREQCommandRequest implements BKRequest {
  bamkey = 'LBTG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetLastCREQCommandResponse implements BKResponse {
  numberOfResponseArguments: number;
  creqCommand!: CreqCommand;
	creqCommandTimestamp!: number;

  constructor() {
    this.numberOfResponseArguments = 2
  }

  setResponseValue(response: string): void {
    this.creqCommand = Bamkey.extractValue<CreqCommand>(response, 0, this.numberOfResponseArguments, false);
		this.creqCommandTimestamp = Bamkey.extractValue<number>(response, 1, this.numberOfResponseArguments, false);
  }
}

export class GetLastCREQCommandCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetLastCREQCommandRequest();
    this.response = new GetLastCREQCommandResponse();
  }
}

export class SetCREQCommandRequest implements BKRequest {
  bamkey = 'LBTS';
  arguments: Array<string | number | boolean>;

  constructor(creqCommand: CreqCommand) {
    this.arguments = [creqCommand];
  }
}

export class SetCREQCommandResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetCREQCommandCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(creqCommand: CreqCommand) {
    this.request = new SetCREQCommandRequest(creqCommand);
    this.response = new SetCREQCommandResponse();
  }
}

export class GetLogLevelRequest implements BKRequest {
  bamkey = 'LOGG';
  arguments: Array<string | number | boolean>;

  constructor(serviceName: ServiceName) {
    this.arguments = [serviceName];
  }
}

export class GetLogLevelResponse implements BKResponse {
  numberOfResponseArguments: number;
  logInfo!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.logInfo = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class GetLogLevelCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(serviceName: ServiceName) {
    this.request = new GetLogLevelRequest(serviceName);
    this.response = new GetLogLevelResponse();
  }
}

export class SetLogLevelRequest implements BKRequest {
  bamkey = 'LOGS';
  arguments: Array<string | number | boolean>;

  constructor(serviceName: ServiceName,logLevel: LogLevel,expiration: number) {
    this.arguments = [serviceName, logLevel, expiration];
  }
}

export class SetLogLevelResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetLogLevelCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(serviceName: ServiceName,logLevel: LogLevel,expiration: number) {
    this.request = new SetLogLevelRequest(serviceName, logLevel, expiration);
    this.response = new SetLogLevelResponse();
  }
}

export class TestLoopbackRequest implements BKRequest {
  bamkey = 'LOOP';
  arguments: Array<string | number | boolean>;

  constructor(testData: string) {
    this.arguments = [testData];
  }
}

export class TestLoopbackResponse implements BKResponse {
  numberOfResponseArguments: number;
  testData!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.testData = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class TestLoopbackCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(testData: string) {
    this.request = new TestLoopbackRequest(testData);
    this.response = new TestLoopbackResponse();
  }
}

export class GetResponsiveAirEnabledStatusRequest implements BKRequest {
  bamkey = 'LRAG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetResponsiveAirEnabledStatusResponse implements BKResponse {
  numberOfResponseArguments: number;
  responsiveAirEnableFlag!: EnabledSetting;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.responsiveAirEnableFlag = Bamkey.extractValue<EnabledSetting>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetResponsiveAirEnabledStatusCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetResponsiveAirEnabledStatusRequest(sideString);
    this.response = new GetResponsiveAirEnabledStatusResponse();
  }
}

export class SetResponsiveAirEnabledStatusRequest implements BKRequest {
  bamkey = 'LRAS';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,responsiveAirEnableFlag: EnabledSetting) {
    this.arguments = [sideString, responsiveAirEnableFlag];
  }
}

export class SetResponsiveAirEnabledStatusResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetResponsiveAirEnabledStatusCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,responsiveAirEnableFlag: EnabledSetting) {
    this.request = new SetResponsiveAirEnabledStatusRequest(sideString, responsiveAirEnableFlag);
    this.response = new SetResponsiveAirEnabledStatusResponse();
  }
}

export class GetSoftwareReadablePartIdentifiersRequest implements BKRequest {
  bamkey = 'PART';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetSoftwareReadablePartIdentifiersResponse implements BKResponse {
  numberOfResponseArguments: number;
  partsIdentifiers!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.partsIdentifiers = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class GetSoftwareReadablePartIdentifiersCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetSoftwareReadablePartIdentifiersRequest();
    this.response = new GetSoftwareReadablePartIdentifiersResponse();
  }
}

export class CalibrateAtmosphericOffsetRequest implements BKRequest {
  bamkey = 'PCAL';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class CalibrateAtmosphericOffsetResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class CalibrateAtmosphericOffsetCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new CalibrateAtmosphericOffsetRequest();
    this.response = new CalibrateAtmosphericOffsetResponse();
  }
}

export class DeleteIoTCoreCredentialsRequest implements BKRequest {
  bamkey = 'PDCS';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class DeleteIoTCoreCredentialsResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class DeleteIoTCoreCredentialsCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new DeleteIoTCoreCredentialsRequest();
    this.response = new DeleteIoTCoreCredentialsResponse();
  }
}

export class GetPumpGPIOBitsRequest implements BKRequest {
  bamkey = 'PGPG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetPumpGPIOBitsResponse implements BKResponse {
  numberOfResponseArguments: number;
  pumpGpioBits!: number;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.pumpGpioBits = Bamkey.extractValue<number>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetPumpGPIOBitsCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetPumpGPIOBitsRequest();
    this.response = new GetPumpGPIOBitsResponse();
  }
}

export class SetPumpGPIOBitsRequest implements BKRequest {
  bamkey = 'PGPS';
  arguments: Array<string | number | boolean>;

  constructor(pumpGpioBits: number) {
    this.arguments = [pumpGpioBits];
  }
}

export class SetPumpGPIOBitsResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetPumpGPIOBitsCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(pumpGpioBits: number) {
    this.request = new SetPumpGPIOBitsRequest(pumpGpioBits);
    this.response = new SetPumpGPIOBitsResponse();
  }
}

export class GetAllLEDStatesRequest implements BKRequest {
  bamkey = 'PLDG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetAllLEDStatesResponse implements BKResponse {
  numberOfResponseArguments: number;
  bleLedState!: LedState;
	wifiLedState!: LedState;
	internetLedState!: LedState;
	cloudLedState!: LedState;

  constructor() {
    this.numberOfResponseArguments = 4
  }

  setResponseValue(response: string): void {
    this.bleLedState = Bamkey.extractValue<LedState>(response, 0, this.numberOfResponseArguments, false);
		this.wifiLedState = Bamkey.extractValue<LedState>(response, 1, this.numberOfResponseArguments, false);
		this.internetLedState = Bamkey.extractValue<LedState>(response, 2, this.numberOfResponseArguments, false);
		this.cloudLedState = Bamkey.extractValue<LedState>(response, 3, this.numberOfResponseArguments, false);
  }
}

export class GetAllLEDStatesCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetAllLEDStatesRequest();
    this.response = new GetAllLEDStatesResponse();
  }
}

export class GetPrimaryMACAddressRequest implements BKRequest {
  bamkey = 'PMAC';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetPrimaryMACAddressResponse implements BKResponse {
  numberOfResponseArguments: number;
  primaryMacAddress!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.primaryMacAddress = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetPrimaryMACAddressCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetPrimaryMACAddressRequest();
    this.response = new GetPrimaryMACAddressResponse();
  }
}

export class GetAtmosphericOffsetRequest implements BKRequest {
  bamkey = 'POFG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetAtmosphericOffsetResponse implements BKResponse {
  numberOfResponseArguments: number;
  atmosphericOffset!: number;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.atmosphericOffset = Bamkey.extractValue<number>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetAtmosphericOffsetCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetAtmosphericOffsetRequest(sideString);
    this.response = new GetAtmosphericOffsetResponse();
  }
}

export class PressureRangeCheckRequest implements BKRequest {
  bamkey = 'PRCG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class PressureRangeCheckResponse implements BKResponse {
  numberOfResponseArguments: number;
  rightMinimumRawAdcValue!: number;
	leftMinimumRawAdcValue!: number;
	rightMaximumRawAdcValue!: number;
	leftMaximumRawAdcValue!: number;

  constructor() {
    this.numberOfResponseArguments = 4
  }

  setResponseValue(response: string): void {
    this.rightMinimumRawAdcValue = Bamkey.extractValue<number>(response, 0, this.numberOfResponseArguments, false);
		this.leftMinimumRawAdcValue = Bamkey.extractValue<number>(response, 1, this.numberOfResponseArguments, false);
		this.rightMaximumRawAdcValue = Bamkey.extractValue<number>(response, 2, this.numberOfResponseArguments, false);
		this.leftMaximumRawAdcValue = Bamkey.extractValue<number>(response, 3, this.numberOfResponseArguments, false);
  }
}

export class PressureRangeCheckCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new PressureRangeCheckRequest();
    this.response = new PressureRangeCheckResponse();
  }
}

export class GetRawPressureValueRequest implements BKRequest {
  bamkey = 'PRVG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetRawPressureValueResponse implements BKResponse {
  numberOfResponseArguments: number;
  rawAdcValue!: number;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.rawAdcValue = Bamkey.extractValue<number>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetRawPressureValueCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetRawPressureValueRequest(sideString);
    this.response = new GetRawPressureValueResponse();
  }
}

export class GetPSIValueRequest implements BKRequest {
  bamkey = 'PSIG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetPSIValueResponse implements BKResponse {
  numberOfResponseArguments: number;
  psi!: number;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.psi = Bamkey.extractValue<number>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetPSIValueCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetPSIValueRequest(sideString);
    this.response = new GetPSIValueResponse();
  }
}

export class GetAmbientSleepNumberRequest implements BKRequest {
  bamkey = 'PSNA';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetAmbientSleepNumberResponse implements BKResponse {
  numberOfResponseArguments: number;
  ambientSleepNumber!: number;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.ambientSleepNumber = Bamkey.extractValue<number>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetAmbientSleepNumberCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetAmbientSleepNumberRequest(sideString);
    this.response = new GetAmbientSleepNumberResponse();
  }
}

export class GetUserSleepNumberRequest implements BKRequest {
  bamkey = 'PSNG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetUserSleepNumberResponse implements BKResponse {
  numberOfResponseArguments: number;
  userSleepNumber!: number;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.userSleepNumber = Bamkey.extractValue<number>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetUserSleepNumberCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetUserSleepNumberRequest(sideString);
    this.response = new GetUserSleepNumberResponse();
  }
}

export class InterruptSleepNumberAdjustmentRequest implements BKRequest {
  bamkey = 'PSNI';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class InterruptSleepNumberAdjustmentResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class InterruptSleepNumberAdjustmentCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new InterruptSleepNumberAdjustmentRequest();
    this.response = new InterruptSleepNumberAdjustmentResponse();
  }
}

export class StartSleepNumberAdjustmentRequest implements BKRequest {
  bamkey = 'PSNS';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,userSleepNumber: number) {
    this.arguments = [sideString, userSleepNumber];
  }
}

export class StartSleepNumberAdjustmentResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class StartSleepNumberAdjustmentCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,userSleepNumber: number) {
    this.request = new StartSleepNumberAdjustmentRequest(sideString, userSleepNumber);
    this.response = new StartSleepNumberAdjustmentResponse();
  }
}

export class GetSleepNumberAdjustmentStatusRequest implements BKRequest {
  bamkey = 'PSNU';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetSleepNumberAdjustmentStatusResponse implements BKResponse {
  numberOfResponseArguments: number;
  sleepNumberAdjustmentStatus!: SleepNumberAdjustmentStatus;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.sleepNumberAdjustmentStatus = Bamkey.extractValue<SleepNumberAdjustmentStatus>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetSleepNumberAdjustmentStatusCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetSleepNumberAdjustmentStatusRequest(sideString);
    this.response = new GetSleepNumberAdjustmentStatusResponse();
  }
}

export class SetAlertMessageRequest implements BKRequest {
  bamkey = 'REAS';
  arguments: Array<string | number | boolean>;

  constructor(alertType: string,alertMessage: string) {
    this.arguments = [alertType, alertMessage];
  }
}

export class SetAlertMessageResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetAlertMessageCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(alertType: string,alertMessage: string) {
    this.request = new SetAlertMessageRequest(alertType, alertMessage);
    this.response = new SetAlertMessageResponse();
  }
}

export class StartRemoteFirmwareUpdateRequest implements BKRequest {
  bamkey = 'REFU';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class StartRemoteFirmwareUpdateResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class StartRemoteFirmwareUpdateCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new StartRemoteFirmwareUpdateRequest();
    this.response = new StartRemoteFirmwareUpdateResponse();
  }
}

export class SetRemoteInformationRequest implements BKRequest {
  bamkey = 'REIS';
  arguments: Array<string | number | boolean>;

  constructor(bootloaderVersion: string,mainApplicationVersion: string,recoveryVersion: string,bleMainVersion: string,bleRecoveryVersion: string,uiAssetsVersion: string,hardwareVersion: string,batteryLevel: string) {
    this.arguments = [bootloaderVersion, mainApplicationVersion, recoveryVersion, bleMainVersion, bleRecoveryVersion, uiAssetsVersion, hardwareVersion, batteryLevel];
  }
}

export class SetRemoteInformationResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetRemoteInformationCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(bootloaderVersion: string,mainApplicationVersion: string,recoveryVersion: string,bleMainVersion: string,bleRecoveryVersion: string,uiAssetsVersion: string,hardwareVersion: string,batteryLevel: string) {
    this.request = new SetRemoteInformationRequest(bootloaderVersion, mainApplicationVersion, recoveryVersion, bleMainVersion, bleRecoveryVersion, uiAssetsVersion, hardwareVersion, batteryLevel);
    this.response = new SetRemoteInformationResponse();
  }
}

export class SetLogMessageRequest implements BKRequest {
  bamkey = 'RELS';
  arguments: Array<string | number | boolean>;

  constructor(logMessage: string) {
    this.arguments = [logMessage];
  }
}

export class SetLogMessageResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetLogMessageCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(logMessage: string) {
    this.request = new SetLogMessageRequest(logMessage);
    this.response = new SetLogMessageResponse();
  }
}

export class GetApplicationVersionRequest implements BKRequest {
  bamkey = 'SAPP';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetApplicationVersionResponse implements BKResponse {
  numberOfResponseArguments: number;
  fuzionApplicationVersion!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.fuzionApplicationVersion = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetApplicationVersionCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetApplicationVersionRequest();
    this.response = new GetApplicationVersionResponse();
  }
}

export class GetAlgoVersionRequest implements BKRequest {
  bamkey = 'SAVR';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetAlgoVersionResponse implements BKResponse {
  numberOfResponseArguments: number;
  fuzionAlgoVersion!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.fuzionAlgoVersion = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetAlgoVersionCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetAlgoVersionRequest();
    this.response = new GetAlgoVersionResponse();
  }
}

export class GetGooseApplicationVersionRequest implements BKRequest {
  bamkey = 'SGAV';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetGooseApplicationVersionResponse implements BKResponse {
  numberOfResponseArguments: number;
  gooseApplicationVersion!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.gooseApplicationVersion = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetGooseApplicationVersionCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetGooseApplicationVersionRequest();
    this.response = new GetGooseApplicationVersionResponse();
  }
}

export class GetGooseBootloaderVersionRequest implements BKRequest {
  bamkey = 'SGBV';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetGooseBootloaderVersionResponse implements BKResponse {
  numberOfResponseArguments: number;
  gooseBootloaderVersion!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.gooseBootloaderVersion = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetGooseBootloaderVersionCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetGooseBootloaderVersionRequest();
    this.response = new GetGooseBootloaderVersionResponse();
  }
}

export class GetManifestNameRequest implements BKRequest {
  bamkey = 'SMNG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetManifestNameResponse implements BKResponse {
  numberOfResponseArguments: number;
  currentManifestName!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.currentManifestName = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetManifestNameCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetManifestNameRequest();
    this.response = new GetManifestNameResponse();
  }
}

export class GetManifestUpdateNeededInfoRequest implements BKRequest {
  bamkey = 'SMUN';
  arguments: Array<string | number | boolean>;

  constructor(targetManifestName: string) {
    this.arguments = [targetManifestName];
  }
}

export class GetManifestUpdateNeededInfoResponse implements BKResponse {
  numberOfResponseArguments: number;
  manifestUpdateNeeded!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.manifestUpdateNeeded = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetManifestUpdateNeededInfoCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(targetManifestName: string) {
    this.request = new GetManifestUpdateNeededInfoRequest(targetManifestName);
    this.response = new GetManifestUpdateNeededInfoResponse();
  }
}

export class GetManifestVersionRequest implements BKRequest {
  bamkey = 'SMVG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetManifestVersionResponse implements BKResponse {
  numberOfResponseArguments: number;
  currentManifestVersion!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.currentManifestVersion = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetManifestVersionCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetManifestVersionRequest();
    this.response = new GetManifestVersionResponse();
  }
}

export class GetSleepNumberControlsRequest implements BKRequest {
  bamkey = 'SNCG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetSleepNumberControlsResponse implements BKResponse {
  numberOfResponseArguments: number;
  sleepNumberAdjustmentStatus!: SleepNumberAdjustmentStatus;
	ambientSleepNumber!: number;
	userSleepNumber!: number;

  constructor() {
    this.numberOfResponseArguments = 3
  }

  setResponseValue(response: string): void {
    this.sleepNumberAdjustmentStatus = Bamkey.extractValue<SleepNumberAdjustmentStatus>(response, 0, this.numberOfResponseArguments, false);
		this.ambientSleepNumber = Bamkey.extractValue<number>(response, 1, this.numberOfResponseArguments, false);
		this.userSleepNumber = Bamkey.extractValue<number>(response, 2, this.numberOfResponseArguments, false);
  }
}

export class GetSleepNumberControlsCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetSleepNumberControlsRequest(sideString);
    this.response = new GetSleepNumberControlsResponse();
  }
}

export class GetFavoriteSleepNumberRequest implements BKRequest {
  bamkey = 'SNFG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetFavoriteSleepNumberResponse implements BKResponse {
  numberOfResponseArguments: number;
  favoriteSleepNumber!: number;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.favoriteSleepNumber = Bamkey.extractValue<number>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetFavoriteSleepNumberCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetFavoriteSleepNumberRequest(sideString);
    this.response = new GetFavoriteSleepNumberResponse();
  }
}

export class SetFavoriteSleepNumberRequest implements BKRequest {
  bamkey = 'SNFS';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,favoriteSleepNumber: number) {
    this.arguments = [sideString, favoriteSleepNumber];
  }
}

export class SetFavoriteSleepNumberResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetFavoriteSleepNumberCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,favoriteSleepNumber: number) {
    this.request = new SetFavoriteSleepNumberRequest(sideString, favoriteSleepNumber);
    this.response = new SetFavoriteSleepNumberResponse();
  }
}

export class GetSleepIQPrivacyStateRequest implements BKRequest {
  bamkey = 'SPRG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetSleepIQPrivacyStateResponse implements BKResponse {
  numberOfResponseArguments: number;
  sleepiqPrivacyState!: SleepiqDataState;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.sleepiqPrivacyState = Bamkey.extractValue<SleepiqDataState>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetSleepIQPrivacyStateCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetSleepIQPrivacyStateRequest();
    this.response = new GetSleepIQPrivacyStateResponse();
  }
}

export class SetSleepIQPrivacyStateRequest implements BKRequest {
  bamkey = 'SPRS';
  arguments: Array<string | number | boolean>;

  constructor(sleepiqPrivacyState: SleepiqDataState) {
    this.arguments = [sleepiqPrivacyState];
  }
}

export class SetSleepIQPrivacyStateResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetSleepIQPrivacyStateCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sleepiqPrivacyState: SleepiqDataState) {
    this.request = new SetSleepIQPrivacyStateRequest(sleepiqPrivacyState);
    this.response = new SetSleepIQPrivacyStateResponse();
  }
}

export class GetRootFilesystemVersionRequest implements BKRequest {
  bamkey = 'SRFS';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetRootFilesystemVersionResponse implements BKResponse {
  numberOfResponseArguments: number;
  fuzionRfsVersion!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.fuzionRfsVersion = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetRootFilesystemVersionCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetRootFilesystemVersionRequest();
    this.response = new GetRootFilesystemVersionResponse();
  }
}

export class SetNetworkInitializationConfigDataRequest implements BKRequest {
  bamkey = 'SWCF';
  arguments: Array<string | number | boolean>;

  constructor(systemInitializationConfigData: string) {
    this.arguments = [systemInitializationConfigData];
  }
}

export class SetNetworkInitializationConfigDataResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetNetworkInitializationConfigDataCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(systemInitializationConfigData: string) {
    this.request = new SetNetworkInitializationConfigDataRequest(systemInitializationConfigData);
    this.response = new SetNetworkInitializationConfigDataResponse();
  }
}

export class StartGooseUpdateRequest implements BKRequest {
  bamkey = 'SWGU';
  arguments: Array<string | number | boolean>;

  constructor(gooseFirmwareFile: string) {
    this.arguments = [gooseFirmwareFile];
  }
}

export class StartGooseUpdateResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class StartGooseUpdateCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(gooseFirmwareFile: string) {
    this.request = new StartGooseUpdateRequest(gooseFirmwareFile);
    this.response = new StartGooseUpdateResponse();
  }
}

export class GetWifiScanResultsRequest implements BKRequest {
  bamkey = 'SWSC';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetWifiScanResultsResponse implements BKResponse {
  numberOfResponseArguments: number;
  wifiScanResults!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.wifiScanResults = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class GetWifiScanResultsCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetWifiScanResultsRequest();
    this.response = new GetWifiScanResultsResponse();
  }
}

export class GetWifiInformationRequest implements BKRequest {
  bamkey = 'SWST';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetWifiInformationResponse implements BKResponse {
  numberOfResponseArguments: number;
  wifiStatus!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.wifiStatus = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class GetWifiInformationCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetWifiInformationRequest();
    this.response = new GetWifiInformationResponse();
  }
}

export class GetUpdateSoftwareFlagRequest implements BKRequest {
  bamkey = 'SWUG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetUpdateSoftwareFlagResponse implements BKResponse {
  numberOfResponseArguments: number;
  softwareUpdateFlag!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.softwareUpdateFlag = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetUpdateSoftwareFlagCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetUpdateSoftwareFlagRequest();
    this.response = new GetUpdateSoftwareFlagResponse();
  }
}

export class SetUpdateSoftwareFlagRequest implements BKRequest {
  bamkey = 'SWUS';
  arguments: Array<string | number | boolean>;

  constructor(updateSoftwareFlag: boolean) {
    this.arguments = [updateSoftwareFlag];
  }
}

export class SetUpdateSoftwareFlagResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetUpdateSoftwareFlagCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(updateSoftwareFlag: boolean) {
    this.request = new SetUpdateSoftwareFlagRequest(updateSoftwareFlag);
    this.response = new SetUpdateSoftwareFlagResponse();
  }
}

export class GetBedConfigurationRequest implements BKRequest {
  bamkey = 'SYAG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetBedConfigurationResponse implements BKResponse {
  numberOfResponseArguments: number;
  mattressSize!: MattressSize;
	baseType!: BaseType;
	chamberType!: ChamberType;

  constructor() {
    this.numberOfResponseArguments = 3
  }

  setResponseValue(response: string): void {
    this.mattressSize = Bamkey.extractValue<MattressSize>(response, 0, this.numberOfResponseArguments, false);
		this.baseType = Bamkey.extractValue<BaseType>(response, 1, this.numberOfResponseArguments, false);
		this.chamberType = Bamkey.extractValue<ChamberType>(response, 2, this.numberOfResponseArguments, false);
  }
}

export class GetBedConfigurationCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetBedConfigurationRequest();
    this.response = new GetBedConfigurationResponse();
  }
}

export class SetBedConfigurationRequest implements BKRequest {
  bamkey = 'SYAS';
  arguments: Array<string | number | boolean>;

  constructor(mattressSize: MattressSize,baseType: BaseType,chamberType: ChamberType) {
    this.arguments = [mattressSize, baseType, chamberType];
  }
}

export class SetBedConfigurationResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetBedConfigurationCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(mattressSize: MattressSize,baseType: BaseType,chamberType: ChamberType) {
    this.request = new SetBedConfigurationRequest(mattressSize, baseType, chamberType);
    this.response = new SetBedConfigurationResponse();
  }
}

export class GetSystemConfigurationRequest implements BKRequest {
  bamkey = 'SYCG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetSystemConfigurationResponse implements BKResponse {
  numberOfResponseArguments: number;
  chamberType!: ChamberType;
	pressureControlEnabledFlag!: EnableFlag;
	articulationEnableFlag!: EnableFlag;
	underbedLightEnableFlag!: EnableFlag;
	rapidSleepSettingEnableFlag!: EnableFlag;
	thermalControlEnabledFlag!: ThermalEnableFlag;
	rightHeadActuator!: EnableFlag;
	rightFootActuator!: EnableFlag;
	leftHeadActuator!: EnableFlag;
	leftFootActuator!: EnableFlag;
	flatPreset!: EnableFlag;
	favoritePreset!: EnableFlag;
	snorePreset!: EnableFlag;
	zeroGravityPreset!: EnableFlag;
	watchTvPreset!: EnableFlag;
	readPreset!: EnableFlag;

  constructor() {
    this.numberOfResponseArguments = 16
  }

  setResponseValue(response: string): void {
    this.chamberType = Bamkey.extractValue<ChamberType>(response, 0, this.numberOfResponseArguments, false);
		this.pressureControlEnabledFlag = Bamkey.extractValue<EnableFlag>(response, 1, this.numberOfResponseArguments, false);
		this.articulationEnableFlag = Bamkey.extractValue<EnableFlag>(response, 2, this.numberOfResponseArguments, false);
		this.underbedLightEnableFlag = Bamkey.extractValue<EnableFlag>(response, 3, this.numberOfResponseArguments, false);
		this.rapidSleepSettingEnableFlag = Bamkey.extractValue<EnableFlag>(response, 4, this.numberOfResponseArguments, false);
		this.thermalControlEnabledFlag = Bamkey.extractValue<ThermalEnableFlag>(response, 5, this.numberOfResponseArguments, false);
		this.rightHeadActuator = Bamkey.extractValue<EnableFlag>(response, 6, this.numberOfResponseArguments, false);
		this.rightFootActuator = Bamkey.extractValue<EnableFlag>(response, 7, this.numberOfResponseArguments, false);
		this.leftHeadActuator = Bamkey.extractValue<EnableFlag>(response, 8, this.numberOfResponseArguments, false);
		this.leftFootActuator = Bamkey.extractValue<EnableFlag>(response, 9, this.numberOfResponseArguments, false);
		this.flatPreset = Bamkey.extractValue<EnableFlag>(response, 10, this.numberOfResponseArguments, false);
		this.favoritePreset = Bamkey.extractValue<EnableFlag>(response, 11, this.numberOfResponseArguments, false);
		this.snorePreset = Bamkey.extractValue<EnableFlag>(response, 12, this.numberOfResponseArguments, false);
		this.zeroGravityPreset = Bamkey.extractValue<EnableFlag>(response, 13, this.numberOfResponseArguments, false);
		this.watchTvPreset = Bamkey.extractValue<EnableFlag>(response, 14, this.numberOfResponseArguments, false);
		this.readPreset = Bamkey.extractValue<EnableFlag>(response, 15, this.numberOfResponseArguments, false);
  }
}

export class GetSystemConfigurationCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetSystemConfigurationRequest();
    this.response = new GetSystemConfigurationResponse();
  }
}

export class GetFuzionConfiguredNameRequest implements BKRequest {
  bamkey = 'SYNG';
  arguments: Array<string | number | boolean>;

  constructor(fuzionNameTypes: NameType) {
    this.arguments = [fuzionNameTypes];
  }
}

export class GetFuzionConfiguredNameResponse implements BKResponse {
  numberOfResponseArguments: number;
  name!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.name = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetFuzionConfiguredNameCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(fuzionNameTypes: NameType) {
    this.request = new GetFuzionConfiguredNameRequest(fuzionNameTypes);
    this.response = new GetFuzionConfiguredNameResponse();
  }
}

export class RebootSystemRequest implements BKRequest {
  bamkey = 'SYRS';
  arguments: Array<string | number | boolean>;

  constructor(rebootDevice: RebootDevice) {
    this.arguments = [rebootDevice];
  }
}

export class RebootSystemResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class RebootSystemCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(rebootDevice: RebootDevice) {
    this.request = new RebootSystemRequest(rebootDevice);
    this.response = new RebootSystemResponse();
  }
}

export class GetSystemSettingsAppliedStatusRequest implements BKRequest {
  bamkey = 'SYSA';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetSystemSettingsAppliedStatusResponse implements BKResponse {
  numberOfResponseArguments: number;
  bedInstallationStatus!: InstallationStatus;
	hardwareSku!: HwSku;

  constructor() {
    this.numberOfResponseArguments = 2
  }

  setResponseValue(response: string): void {
    this.bedInstallationStatus = Bamkey.extractValue<InstallationStatus>(response, 0, this.numberOfResponseArguments, false);
		this.hardwareSku = Bamkey.extractValue<HwSku>(response, 1, this.numberOfResponseArguments, false);
  }
}

export class GetSystemSettingsAppliedStatusCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetSystemSettingsAppliedStatusRequest();
    this.response = new GetSystemSettingsAppliedStatusResponse();
  }
}

export class GetSystemStatusRequest implements BKRequest {
  bamkey = 'SYST';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetSystemStatusResponse implements BKResponse {
  numberOfResponseArguments: number;
  networkConnectivityStatus!: NetworkConnectivity;
	fuzionDeviceSoftwareDownloadStatus!: SoftwareDownloadStatus;

  constructor() {
    this.numberOfResponseArguments = 2
  }

  setResponseValue(response: string): void {
    this.networkConnectivityStatus = Bamkey.extractValue<NetworkConnectivity>(response, 0, this.numberOfResponseArguments, false);
		this.fuzionDeviceSoftwareDownloadStatus = Bamkey.extractValue<SoftwareDownloadStatus>(response, 1, this.numberOfResponseArguments, false);
  }
}

export class GetSystemStatusCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetSystemStatusRequest();
    this.response = new GetSystemStatusResponse();
  }
}

export class SetSystemTimeRequest implements BKRequest {
  bamkey = 'SYTS';
  arguments: Array<string | number | boolean>;

  constructor(dateTime: string) {
    this.arguments = [dateTime];
  }
}

export class SetSystemTimeResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetSystemTimeCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(dateTime: string) {
    this.request = new SetSystemTimeRequest(dateTime);
    this.response = new SetSystemTimeResponse();
  }
}

export class GetSystemUptimeRequest implements BKRequest {
  bamkey = 'SYUP';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetSystemUptimeResponse implements BKResponse {
  numberOfResponseArguments: number;
  uptime!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.uptime = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetSystemUptimeCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetSystemUptimeRequest();
    this.response = new GetSystemUptimeResponse();
  }
}

export class GetDataAcquisitionRateRequest implements BKRequest {
  bamkey = 'TARG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetDataAcquisitionRateResponse implements BKResponse {
  numberOfResponseArguments: number;
  sensorDataAcquisitionRate!: number;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.sensorDataAcquisitionRate = Bamkey.extractValue<number>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetDataAcquisitionRateCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetDataAcquisitionRateRequest();
    this.response = new GetDataAcquisitionRateResponse();
  }
}

export class ChangeRoutineEnableFlagRequest implements BKRequest {
  bamkey = 'THER';
  arguments: Array<string | number | boolean>;

  constructor(id: string,enabled: HeidiRoutineEnable) {
    this.arguments = [id, enabled];
  }
}

export class ChangeRoutineEnableFlagResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class ChangeRoutineEnableFlagCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(id: string,enabled: HeidiRoutineEnable) {
    this.request = new ChangeRoutineEnableFlagRequest(id, enabled);
    this.response = new ChangeRoutineEnableFlagResponse();
  }
}

export class ApplyHeidiFirmwareRequest implements BKRequest {
  bamkey = 'THFA';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,applyType: HeidiApplyType) {
    this.arguments = [sideString, applyType];
  }
}

export class ApplyHeidiFirmwareResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class ApplyHeidiFirmwareCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,applyType: HeidiApplyType) {
    this.request = new ApplyHeidiFirmwareRequest(sideString, applyType);
    this.response = new ApplyHeidiFirmwareResponse();
  }
}

export class IsHeidiUpdatingRequest implements BKRequest {
  bamkey = 'THFS';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class IsHeidiUpdatingResponse implements BKResponse {
  numberOfResponseArguments: number;
  firmwareTransferInProgress!: boolean;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.firmwareTransferInProgress = Bamkey.extractValue<boolean>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class IsHeidiUpdatingCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new IsHeidiUpdatingRequest();
    this.response = new IsHeidiUpdatingResponse();
  }
}

export class UpdateHeidiFirmwareRequest implements BKRequest {
  bamkey = 'THFU';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,filepath: string,heidiImage: HeidiImage) {
    this.arguments = [sideString, filepath, heidiImage];
  }
}

export class UpdateHeidiFirmwareResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class UpdateHeidiFirmwareCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,filepath: string,heidiImage: HeidiImage) {
    this.request = new UpdateHeidiFirmwareRequest(sideString, filepath, heidiImage);
    this.response = new UpdateHeidiFirmwareResponse();
  }
}

export class GetHeidiModeRequest implements BKRequest {
  bamkey = 'THMG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetHeidiModeResponse implements BKResponse {
  numberOfResponseArguments: number;
  heidiModeState!: HeidiMode;
	remainingTime!: number;

  constructor() {
    this.numberOfResponseArguments = 2
  }

  setResponseValue(response: string): void {
    this.heidiModeState = Bamkey.extractValue<HeidiMode>(response, 0, this.numberOfResponseArguments, false);
		this.remainingTime = Bamkey.extractValue<number>(response, 1, this.numberOfResponseArguments, false);
  }
}

export class GetHeidiModeCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetHeidiModeRequest(sideString);
    this.response = new GetHeidiModeResponse();
  }
}

export class SetHeidiModeRequest implements BKRequest {
  bamkey = 'THMS';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,heidiModeControl: HeidiMode,timer: number) {
    this.arguments = [sideString, heidiModeControl, timer];
  }
}

export class SetHeidiModeResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetHeidiModeCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,heidiModeControl: HeidiMode,timer: number) {
    this.request = new SetHeidiModeRequest(sideString, heidiModeControl, timer);
    this.response = new SetHeidiModeResponse();
  }
}

export class GetHeidiPresenceRequest implements BKRequest {
  bamkey = 'THPG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetHeidiPresenceResponse implements BKResponse {
  numberOfResponseArguments: number;
  heidiPresence!: HeidiPresence;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.heidiPresence = Bamkey.extractValue<HeidiPresence>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetHeidiPresenceCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetHeidiPresenceRequest(sideString);
    this.response = new GetHeidiPresenceResponse();
  }
}

export class GetAllHeidiRoutineNamesRequest implements BKRequest {
  bamkey = 'THRA';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetAllHeidiRoutineNamesResponse implements BKResponse {
  numberOfResponseArguments: number;
  routineNames!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.routineNames = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class GetAllHeidiRoutineNamesCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetAllHeidiRoutineNamesRequest();
    this.response = new GetAllHeidiRoutineNamesResponse();
  }
}

export class CreateThermalRoutineRequest implements BKRequest {
  bamkey = 'THRC';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,heidiMode: HeidiMode,days: string,time: string,timer: number,enabled: HeidiRoutineEnable) {
    this.arguments = [sideString, heidiMode, days, time, timer, enabled];
  }
}

export class CreateThermalRoutineResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class CreateThermalRoutineCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,heidiMode: HeidiMode,days: string,time: string,timer: number,enabled: HeidiRoutineEnable) {
    this.request = new CreateThermalRoutineRequest(sideString, heidiMode, days, time, timer, enabled);
    this.response = new CreateThermalRoutineResponse();
  }
}

export class RebootHeidiRequest implements BKRequest {
  bamkey = 'THRE';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class RebootHeidiResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class RebootHeidiCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new RebootHeidiRequest(sideString);
    this.response = new RebootHeidiResponse();
  }
}

export class GetFriendlyHeidiRoutineJsonRequest implements BKRequest {
  bamkey = 'THRF';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetFriendlyHeidiRoutineJsonResponse implements BKResponse {
  numberOfResponseArguments: number;
  routineJsonArray!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.routineJsonArray = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class GetFriendlyHeidiRoutineJsonCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetFriendlyHeidiRoutineJsonRequest();
    this.response = new GetFriendlyHeidiRoutineJsonResponse();
  }
}

export class GetHeidiRoutineRequest implements BKRequest {
  bamkey = 'THRG';
  arguments: Array<string | number | boolean>;

  constructor(name: string) {
    this.arguments = [name];
  }
}

export class GetHeidiRoutineResponse implements BKResponse {
  numberOfResponseArguments: number;
  sideString!: Side;
	heidiMode!: HeidiMode;
	days!: string;
	time!: string;
	timer!: number;
	enabled!: HeidiRoutineEnable;

  constructor() {
    this.numberOfResponseArguments = 6
  }

  setResponseValue(response: string): void {
    this.sideString = Bamkey.extractValue<Side>(response, 0, this.numberOfResponseArguments, false);
		this.heidiMode = Bamkey.extractValue<HeidiMode>(response, 1, this.numberOfResponseArguments, false);
		this.days = Bamkey.extractValue<string>(response, 2, this.numberOfResponseArguments, false);
		this.time = Bamkey.extractValue<string>(response, 3, this.numberOfResponseArguments, false);
		this.timer = Bamkey.extractValue<number>(response, 4, this.numberOfResponseArguments, false);
		this.enabled = Bamkey.extractValue<HeidiRoutineEnable>(response, 5, this.numberOfResponseArguments, false);
  }
}

export class GetHeidiRoutineCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(name: string) {
    this.request = new GetHeidiRoutineRequest(name);
    this.response = new GetHeidiRoutineResponse();
  }
}

export class RemoveHeidiRoutineRequest implements BKRequest {
  bamkey = 'THRR';
  arguments: Array<string | number | boolean>;

  constructor(name: string) {
    this.arguments = [name];
  }
}

export class RemoveHeidiRoutineResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class RemoveHeidiRoutineCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(name: string) {
    this.request = new RemoveHeidiRoutineRequest(name);
    this.response = new RemoveHeidiRoutineResponse();
  }
}

export class SetHeidiRoutineRequest implements BKRequest {
  bamkey = 'THRS';
  arguments: Array<string | number | boolean>;

  constructor(id: string,sideString: Side,heidiMode: HeidiMode,days: string,time: string,timer: number,enabled: HeidiRoutineEnable) {
    this.arguments = [id, sideString, heidiMode, days, time, timer, enabled];
  }
}

export class SetHeidiRoutineResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetHeidiRoutineCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(id: string,sideString: Side,heidiMode: HeidiMode,days: string,time: string,timer: number,enabled: HeidiRoutineEnable) {
    this.request = new SetHeidiRoutineRequest(id, sideString, heidiMode, days, time, timer, enabled);
    this.response = new SetHeidiRoutineResponse();
  }
}

export class GetHeidiVersionRequest implements BKRequest {
  bamkey = 'THVG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,heidiVersionType: HeidiVersionType) {
    this.arguments = [sideString, heidiVersionType];
  }
}

export class GetHeidiVersionResponse implements BKResponse {
  numberOfResponseArguments: number;
  heidiVersion!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.heidiVersion = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetHeidiVersionCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,heidiVersionType: HeidiVersionType) {
    this.request = new GetHeidiVersionRequest(sideString, heidiVersionType);
    this.response = new GetHeidiVersionResponse();
  }
}

export class GetRoutinesRequest implements BKRequest {
  bamkey = 'TPRA';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetRoutinesResponse implements BKResponse {
  numberOfResponseArguments: number;
  persistedJson!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.persistedJson = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class GetRoutinesCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetRoutinesRequest();
    this.response = new GetRoutinesResponse();
  }
}

export class CreateRoutineRequest implements BKRequest {
  bamkey = 'TPRC';
  arguments: Array<string | number | boolean>;

  constructor(routineJson: string) {
    this.arguments = [routineJson];
  }
}

export class CreateRoutineResponse implements BKResponse {
  numberOfResponseArguments: number;
  routineJson!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.routineJson = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class CreateRoutineCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(routineJson: string) {
    this.request = new CreateRoutineRequest(routineJson);
    this.response = new CreateRoutineResponse();
  }
}

export class DeleteRoutineRequest implements BKRequest {
  bamkey = 'TPRD';
  arguments: Array<string | number | boolean>;

  constructor(routineId: string) {
    this.arguments = [routineId];
  }
}

export class DeleteRoutineResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class DeleteRoutineCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(routineId: string) {
    this.request = new DeleteRoutineRequest(routineId);
    this.response = new DeleteRoutineResponse();
  }
}

export class GetActiveRoutinesRequest implements BKRequest {
  bamkey = 'TPRR';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetActiveRoutinesResponse implements BKResponse {
  numberOfResponseArguments: number;
  routineIds!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.routineIds = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class GetActiveRoutinesCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetActiveRoutinesRequest();
    this.response = new GetActiveRoutinesResponse();
  }
}

export class SetRoutineRequest implements BKRequest {
  bamkey = 'TPRS';
  arguments: Array<string | number | boolean>;

  constructor(routineJson: string) {
    this.arguments = [routineJson];
  }
}

export class SetRoutineResponse implements BKResponse {
  numberOfResponseArguments: number;
  routineJson!: object;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.routineJson = Bamkey.extractValue<object>(response, 0, this.numberOfResponseArguments, true);
  }
}

export class SetRoutineCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(routineJson: string) {
    this.request = new SetRoutineRequest(routineJson);
    this.response = new SetRoutineResponse();
  }
}

export class GetHealthStatusOfTemperatureStripRequest implements BKRequest {
  bamkey = 'TSHG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side) {
    this.arguments = [sideString];
  }
}

export class GetHealthStatusOfTemperatureStripResponse implements BKResponse {
  numberOfResponseArguments: number;
  temperatureStripHealthStatus!: HealthStatus;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.temperatureStripHealthStatus = Bamkey.extractValue<HealthStatus>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetHealthStatusOfTemperatureStripCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side) {
    this.request = new GetHealthStatusOfTemperatureStripRequest(sideString);
    this.response = new GetHealthStatusOfTemperatureStripResponse();
  }
}

export class GetSensorTemperatureRequest implements BKRequest {
  bamkey = 'TSTG';
  arguments: Array<string | number | boolean>;

  constructor(sideString: Side,sensorIndexString: SensorIndex) {
    this.arguments = [sideString, sensorIndexString];
  }
}

export class GetSensorTemperatureResponse implements BKResponse {
  numberOfResponseArguments: number;
  temperatureOfSensor!: string;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.temperatureOfSensor = Bamkey.extractValue<string>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetSensorTemperatureCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sideString: Side,sensorIndexString: SensorIndex) {
    this.request = new GetSensorTemperatureRequest(sideString, sensorIndexString);
    this.response = new GetSensorTemperatureResponse();
  }
}

export class GetUploadRateRequest implements BKRequest {
  bamkey = 'TURG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetUploadRateResponse implements BKResponse {
  numberOfResponseArguments: number;
  sensorDataUploadRate!: number;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.sensorDataUploadRate = Bamkey.extractValue<number>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetUploadRateCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetUploadRateRequest();
    this.response = new GetUploadRateResponse();
  }
}

export class SetUploadRateRequest implements BKRequest {
  bamkey = 'TURS';
  arguments: Array<string | number | boolean>;

  constructor(sensorDataUploadRate: number) {
    this.arguments = [sensorDataUploadRate];
  }
}

export class SetUploadRateResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetUploadRateCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(sensorDataUploadRate: number) {
    this.request = new SetUploadRateRequest(sensorDataUploadRate);
    this.response = new SetUploadRateResponse();
  }
}

export class GetUnderbedLightAutoModeStatusRequest implements BKRequest {
  bamkey = 'UBAG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetUnderbedLightAutoModeStatusResponse implements BKResponse {
  numberOfResponseArguments: number;
  underbedLightAutoModeEnableStatus!: UblAutoEnable;
	underbedLightAutoModeIntensityLevel!: UblLevel;

  constructor() {
    this.numberOfResponseArguments = 2
  }

  setResponseValue(response: string): void {
    this.underbedLightAutoModeEnableStatus = Bamkey.extractValue<UblAutoEnable>(response, 0, this.numberOfResponseArguments, false);
		this.underbedLightAutoModeIntensityLevel = Bamkey.extractValue<UblLevel>(response, 1, this.numberOfResponseArguments, false);
  }
}

export class GetUnderbedLightAutoModeStatusCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetUnderbedLightAutoModeStatusRequest();
    this.response = new GetUnderbedLightAutoModeStatusResponse();
  }
}

export class SetUnderbedLightAutoModeRequest implements BKRequest {
  bamkey = 'UBAS';
  arguments: Array<string | number | boolean>;

  constructor(underbedLightAutoModeEnableStatus: UblAutoEnable,underbedLightAutoModeIntensityLevel: UblLevel) {
    this.arguments = [underbedLightAutoModeEnableStatus, underbedLightAutoModeIntensityLevel];
  }
}

export class SetUnderbedLightAutoModeResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetUnderbedLightAutoModeCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(underbedLightAutoModeEnableStatus: UblAutoEnable,underbedLightAutoModeIntensityLevel: UblLevel) {
    this.request = new SetUnderbedLightAutoModeRequest(underbedLightAutoModeEnableStatus, underbedLightAutoModeIntensityLevel);
    this.response = new SetUnderbedLightAutoModeResponse();
  }
}

export class GetUnderbedLightErrorStatusRequest implements BKRequest {
  bamkey = 'UBLE';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetUnderbedLightErrorStatusResponse implements BKResponse {
  numberOfResponseArguments: number;
  underbedLightConnectionCurrentStatus!: UblCurrentStatus;

  constructor() {
    this.numberOfResponseArguments = 1
  }

  setResponseValue(response: string): void {
    this.underbedLightConnectionCurrentStatus = Bamkey.extractValue<UblCurrentStatus>(response, 0, this.numberOfResponseArguments, false);
  }
}

export class GetUnderbedLightErrorStatusCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetUnderbedLightErrorStatusRequest();
    this.response = new GetUnderbedLightErrorStatusResponse();
  }
}

export class GetUnderbedLightSettingsRequest implements BKRequest {
  bamkey = 'UBLG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetUnderbedLightSettingsResponse implements BKResponse {
  numberOfResponseArguments: number;
  underbedLightIntensityLevel!: UblLevel;
	underbedLightTimerValue!: number;

  constructor() {
    this.numberOfResponseArguments = 2
  }

  setResponseValue(response: string): void {
    this.underbedLightIntensityLevel = Bamkey.extractValue<UblLevel>(response, 0, this.numberOfResponseArguments, false);
		this.underbedLightTimerValue = Bamkey.extractValue<number>(response, 1, this.numberOfResponseArguments, false);
  }
}

export class GetUnderbedLightSettingsCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetUnderbedLightSettingsRequest();
    this.response = new GetUnderbedLightSettingsResponse();
  }
}

export class SetUnderbedLightSettingsRequest implements BKRequest {
  bamkey = 'UBLS';
  arguments: Array<string | number | boolean>;

  constructor(underbedLightIntensityLevel: UblLevel,underbedLightTimerValue: number) {
    this.arguments = [underbedLightIntensityLevel, underbedLightTimerValue];
  }
}

export class SetUnderbedLightSettingsResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class SetUnderbedLightSettingsCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor(underbedLightIntensityLevel: UblLevel,underbedLightTimerValue: number) {
    this.request = new SetUnderbedLightSettingsRequest(underbedLightIntensityLevel, underbedLightTimerValue);
    this.response = new SetUnderbedLightSettingsResponse();
  }
}

export class GetWatchdogServiceStatusReportRequest implements BKRequest {
  bamkey = 'WSDG';
  arguments: Array<string | number | boolean>;

  constructor() {
    this.arguments = [];
  }
}

export class GetWatchdogServiceStatusReportResponse implements BKResponse {
  numberOfResponseArguments: number;
  
  constructor() {
    this.numberOfResponseArguments = 0
  }
}

export class GetWatchdogServiceStatusReportCommand implements BKCommand {
  request: BKRequest;
  response: BKResponse;

  constructor() {
    this.request = new GetWatchdogServiceStatusReportRequest();
    this.response = new GetWatchdogServiceStatusReportResponse();
  }
}
