import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { Navigate } from "@ngxs/router-plugin";
import { Store } from "@ngxs/store";
import { SettingsSelectors } from "@store/settings/settings.selectors";
import { filter, map, take } from "rxjs";

export const NetworksGuard: CanActivateFn = () => {
  const store = inject(Store);
  return store.select(SettingsSelectors.loading).pipe(
    filter((loading) => !loading),
    take(1),
    map(() => {
      // This is to make sure that wifi network API call is performed
      const networks = store.selectSnapshot(SettingsSelectors.networks);
      if (networks && networks.length === 0) {
        // case: there is no networks
        // user will be redirected to add new network form
        store.dispatch(new Navigate(['pages/smart-bed/details/connectivity/networks/add-new-network']));
        return false;
      }
      return true;
    })
  );
};

