<app-top-bar-navigation [showBackButton]="true" title="Set Up Smart Bed" (backBtnClicked)="back()"/>
<div class="px-24 py-32">
  <div siqGrid fx-direction="column">
    <div siqGrid fx-direction="row" fx-layout-align="center center">
      <span class="siq-text-900 fs-24 white text-center">Let's start by personalizing your smart bed</span>
    </div>
    <div class="py-48">
      <form siqGrid fx-direction="column" [formGroup]="setupBedForm" class="w-100-p">
        <app-siq-input
          formControlName="name" 
          name="name"
          class="mt-24 px-12" 
          type="text" 
          placeholder="Enter bed name"
          label="What would you like to name your smart bed?"
          [isInvalid]="hasErrors('name') || isBedNameTooLong"
          (controlFocus)="setControlFocusState($event)"/>
        <ng-container *ngIf="hasErrors('name') && !isBedNameTooLong">
          <span class="siq-text-400 fs-14 red px-12 mt-12"> {{bedNameError}} </span>
        </ng-container>
        <ng-container *ngIf="showCharacterCount && !hasErrors('name')">
          <div siqGrid fx-direction="row" fx-layout-align="end center" class="w-100-p mt-12">
            <span class="siq-text-400 fs-14 primary-200 px-12" [class.red]="isBedNameTooLong">{{characterCount}}/22</span>
          </div>
        </ng-container>

        <div class="mt-24">
          <app-siq-dropdown  
            [dropdownOptions]="timezonesOptions" 
            [dropdownValues]="timezonesValues" 
            paddingClass="p-24"
            [value]="selectedTimezone"
            dropdownInputLabel="What timezone will your bed be in?"
            [dropdownInputValue]="timezone"
            (selectedValue)="handleDropdownSelect($event)" />
        </div>

        <ng-container *ngIf="selectedBed.dualSleep">
          <span class="siq-text-400 fs-16 white mt-24 px-12">What side of the bed do you sleep on?</span>
          <div siqGrid fx-direction="column" fx-layout-align="center center" class="mt-12">
            <div siqGrid fx-direction="row" fx-layout-align="center center" class="w-256">
              <div siqGrid fx-direction="column" fx-layout-align="center end" class="w-50-p">
                <span class="down-arrow-icon cursor-pointer mb-12" (click)="selectSide(1)"></span>
              </div>
              <div siqGrid fx-direction="column" fx-layout-align="center start" class="w-50-p ml-32">
                <span class="down-arrow-icon cursor-pointer mb-12" (click)="selectSide(0)"></span>
              </div>
            </div>
            <div siqGrid fx-direction="row">
              <div [ngClass]="leftSideIcon" class="cursor-pointer mt-24" (click)="selectSide(1)"></div>
              <div [ngClass]="rightSideIcon" class="cursor-pointer mt-24" (click)="selectSide(0)"></div>
            </div>
          </div>
  
          <div siqGrid fx-direction="column" fx-layout-align="center start" class="mt-48 px-12">
            <span class="siq-text-400 fs-16 white mb-24">Does someone sleep on the other side?</span>
            <div siqGrid fx-direction="row">
              <ng-container *ngFor="let answer of radioButtonsAnswers">
                  <app-siq-radio-button [selectedOption]="shouldAddSecondSleeper" direction="row" [answer]="answer" (selected)="addSecondSleeper($event)"/>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </form>

      <div siqGrid fx-direction="row" fx-layout-align="end center" class="px-12 mt-48">
        <app-siq-button
          text="Next"
          btnType="primary-btn"
          width="178px"
          [isDisabled]="!isFormValid || isBedNameTooLong"
          (buttonClicked)="next()" />
      </div>
    </div>
  </div>
</div>