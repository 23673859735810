import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BreakpointState, ResizeService } from '@services/resize.service';
import { RegistrationStringResource } from '@shared/utils/helpers/registration.helper';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-download-card',
  templateUrl: './app-download-card.component.html'
})
export class AppDownloadCardComponent implements OnInit, OnDestroy {
  breakpoints$: Observable<BreakpointState>;
  showPhoneBigScreen: boolean;
  showPhoneSmallScreen: boolean;
  breakpoint: BreakpointState;
  isButtonClicked = false;
  text = RegistrationStringResource.downloadTheAppCardText;

  private _unsubscribeAll = new Subject<void>();
  
  constructor(private el: ElementRef, private resizeService: ResizeService) {}

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.adjustStyles();
  }

  get icon(): string {
    return 'assets/icons/phone-image.svg';
  }

  ngOnInit(): void {
    this.breakpoints$ = this.resizeService.isMobile;
    this.adjustStyles();

    this.resizeService.isMobile.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((res) => {
      this.breakpoint = res;
    });
  }

  private adjustStyles(): void {
    const parentWidth = this.el.nativeElement.offsetWidth;
    // to show/hide phone image
    if(parentWidth > 500) {
      this.setPhoneImagesState(true, false);
    } else {
      this.setPhoneImagesState(false, true);
    }
  }

  private setPhoneImagesState(bigScreen: boolean, smallScreen: boolean): void {
    this.showPhoneBigScreen = bigScreen;
    this.showPhoneSmallScreen = smallScreen;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
