import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SetSleepersPasswordResponse } from "@models/app/helpers.model";
import { PopupData } from "@models/app/modal-data.model";
import { CognitoLoginModel } from "@models/auth/cognito.model";
import { RegisterTokenModel } from "@models/auth/register.model";
import { ResetPasswordResponse } from "@models/sleeper/sleeper.model";
import { Action, State, StateContext } from "@ngxs/store";
import { AuthService } from "@services/auth.service";
import { SplashScreenService } from "@services/splash-screen.service";
import { OrderNumberValidation } from "@shared/utils/helpers/enum.helper";
import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import { ServerErrors } from "@shared/utils/helpers/siq-errors.helper";
import { CloseModal, HasECIMIdentity } from "@store/app/app.actions";
import { CognitoLogin, GoToLogin } from "@store/auth/auth.actions";
import { Observable, tap, throwError } from "rxjs";
import { ResetRegisterState, SetPasswordAndActivate, ValidateOrderNumber } from "./register.actions";
import { RegisterStateModel, defaultRegisterState } from "./register.model";
import { MixpanelService } from "@services/mixpanel.service";
import { Navigate } from "@ngxs/router-plugin";

const ERROR_STATUS = [417, 401];

@State<RegisterStateModel>({
  name: 'register',
  defaults: defaultRegisterState
})
@Injectable()
export class RegisterState {

  constructor(
    private authService: AuthService,
    private splashScreenService: SplashScreenService,
    private dialog: MatDialog,
    private mixpanelService: MixpanelService
  ) { }

  @Action(ValidateOrderNumber)
  validateOrderNumber(ctx: StateContext<RegisterStateModel>, action: ValidateOrderNumber): Observable<RegisterTokenModel> {
    ctx.patchState({ loading: true });
    return this.authService.validateOrderNumber(action.data, action.validationType).pipe(
      tap({
        next: (result: RegisterTokenModel) => {
          if (Object.prototype.hasOwnProperty.call(result, 'token')) {
            const registerToken = { ...result, disableEmailUpdate: action.validationType === OrderNumberValidation.orderAndEmail };
            ctx.patchState({ registerToken, error: null, loading: false });
            ctx.dispatch(new HasECIMIdentity(false));
            ctx.dispatch(new Navigate(['auth/register/order-found']));
          } else {
            ctx.patchState({ loading: false });
            localStorage.removeItem('login');
            if (action.validationType === OrderNumberValidation.orderAndEmail) {
              localStorage.setItem('email', FunctionsHelper.encrypt(action.data['email']));
            }
            ctx.dispatch(new HasECIMIdentity(true));
            ctx.dispatch(new Navigate(['auth/register/order-found']));
          }

        },
        error: (error) => this.handleError(ctx, error)
      })
    );
  }

  @Action(SetPasswordAndActivate)
  setPasswordAndActivate(ctx: StateContext<RegisterStateModel>, action: SetPasswordAndActivate): Observable<SetSleepersPasswordResponse | ResetPasswordResponse> {
    return this.authService.setPasswordAndActivate(action.data).pipe(
      tap({
        next: () => {
          const login = { Email: action.data.login, Password: action.data.password } as CognitoLoginModel;
          this.splashScreenService.setAuthLoading(true);
          ctx.patchState({ registerToken: null });
          ctx.dispatch(new CognitoLogin(login));
          this.mixpanelService.trackRegistrationAccountCreation('yes');
        },
        error: (error) => {
          if (error.status === 417) {
            localStorage.removeItem('login');
            localStorage.setItem('email', FunctionsHelper.encrypt(action.data.login));
          }
          this.handleSetPasswordAndActivateErrors(ctx, error);
        }
      }),
    );
  }

  @Action(ResetRegisterState)
  resetRegisterState(ctx: StateContext<RegisterStateModel>): void {
    ctx.setState({ ...defaultRegisterState });
  }

  private handleError(ctx: StateContext<RegisterStateModel>, err: HttpErrorResponse): Observable<HttpErrorResponse> {
    ctx.patchState({ error: FunctionsHelper.createSiqError(err.error.Error.Code, err.error.Error.Message), loading: false });
    return throwError(() => err);
  }

  private handleSetPasswordAndActivateErrors(ctx: StateContext<RegisterStateModel>, err: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.mixpanelService.trackRegistrationAccountCreation('no');
    ctx.patchState({ error: FunctionsHelper.createSiqError(err.error.Error.Code, err.error.Error.Message), loading: false });
    if (err.status >= 400 && err.status < 500) {
      const popupParams = this.getPopupParams(err);
      const modal = FunctionsHelper.createPopup(this.dialog, popupParams.title, popupParams.text, popupParams.screen, popupParams.icon, popupParams.type, popupParams.rightBtnTxt);

      modal.componentInstance.onClose.subscribe(() => {
        modal.close();
      });

      modal.componentInstance.onRightAction.subscribe(() => {
        modal.close();
      });

      modal.afterClosed().subscribe(() => {
        if (ERROR_STATUS.includes(err.status)) {
          ctx.dispatch(new GoToLogin());
        }
        ctx.dispatch(new CloseModal(modal));
        modal.componentInstance.onRightAction.unsubscribe();
        modal.componentInstance.onClose.unsubscribe();
      });
    }
    return throwError(() => err);
  }

  private getPopupParams(err: HttpErrorResponse): PopupData {
    let title: string, text: string, rightBtnTxt: string, icon: string, type: string;
    switch (err.status) {
      case 417:
        title = ServerErrors.ApiErrors.accountActivated.title;
        text = ServerErrors.ApiErrors.accountActivated.text;
        rightBtnTxt = 'Go to Login';
        icon = 'dark-checkmark';
        type = 'green';
        break;
      case 400: {
        title = ServerErrors.ApiErrors.error400.title;
        text = err.error.Error.Message,
          rightBtnTxt = 'OK';
        icon = 'warning-icon';
        type = 'yellow';
        break;
      }
      case 401: {
        title = ServerErrors.ApiErrors.requestTimeout.title;
        text = ServerErrors.ApiErrors.requestTimeout.text;
        rightBtnTxt = 'OK';
        icon = 'warning-icon';
        type = 'yellow';
        break;
      }
      default:
        title = ServerErrors.ApiErrors.error400.title;
        text = ServerErrors.ApiErrors.error400.text;
        rightBtnTxt = 'OK';
        icon = 'warning-icon';
        type = 'yellow';
        break;
    }
    return new PopupData({ title, text, rightBtnTxt, leftBtnTxt: '', icon, type, screen: 'Set Password And Activate' });
  }
}
