export interface RegisterTokenModel {
  order: string;
  email: string;
  phone: string;
  disableEmailUpdate?: boolean; 
  token?: string;
  cognitoUsername?: string;
  // if customer validated order with phone number, 
  // email should be editable all other cases field should be disabled
}

export class ValidateOrderModel {
  orderNumber: string;
  [key: string]: string; // keeps email or phone number as a string

  constructor(objectAction: ValidateOrderModel) {
    Object.assign(this, objectAction);
  }
}

export interface FormControlParams {
  type: string, 
  placeholder: string,
  label: string
}