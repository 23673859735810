import { SiqError } from "@models/app/helpers.model";
import { Sleeper } from "@models/sleeper/sleeper.model";

export interface SleepersStateModel {
  sleepers: Array<Sleeper> | [];
  selectedSleeper: Sleeper | null;
  defaultSleeperId: string | null;
  loading: boolean;
  error: SiqError | null;
}

export const defaultSleeperState = {
  sleepers: [],
  selectedSleeper: null,
  defaultSleeperId: null,
  loading: false,
  error: null
}