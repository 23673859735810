import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SleepersIccMessages } from '@models/health/sleep-health-icc.model';
import { CircadianRhythmResponse } from '@models/sessions/circadian-rhythm.model';
import { Rolling30DaysSleepData, RollingDataAggregates } from '@models/sessions/rolling-data-aggregates';
import { SleepDataStructure, YearHistorySleepData } from '@models/sessions/sleep-data.model';
import { Observable, catchError, map, throwError } from 'rxjs';
import { EnvironmentsService } from './environments.service';
import { MixpanelService } from './mixpanel.service';
import { SiqApiService } from './siq-api.service';
import { SplashScreenService } from './splash-screen.service';

@Injectable({
  providedIn: 'root'
})
export class SessionsService extends SiqApiService {

  constructor(
    httpClient: HttpClient,
    environmentService: EnvironmentsService,
    mixpanelService: MixpanelService,
    dialog: MatDialog,
    splashScreenService: SplashScreenService) {
    super(httpClient, environmentService, mixpanelService, dialog, splashScreenService);
  }

  getSleepData(date: string, interval: string, sleeper: string, includeSlices: boolean): Observable<SleepDataStructure> {
    return this.makeSiqRequest<SleepDataStructure>('sleepData', 'siq', {
      date,
      interval,
      sleeper,
      includeSlices: !!includeSlices
    }, 'GET', false).pipe(
      map((response: SleepDataStructure | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  getSleepDataByYear(date: string, sleeperId: string | undefined): Observable<YearHistorySleepData> {
    return this.makeSiqRequest<YearHistorySleepData>('sleepData/byYear', 'siq', {
      startDate: date,
      sleeperId
    }, 'GET', false).pipe(
      map((response: YearHistorySleepData | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  getSleepCategoryIccMessages(sleeperId: string, startDate: string, endDate: string): Observable<SleepersIccMessages> {
    return this.makeSiqRequest<SleepersIccMessages>(`v2/sleeper/${sleeperId}/messages`, 'siq', {
      startDate,
      endDate
    }, 'GET', false).pipe(
      map((response: SleepersIccMessages | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  get30DaysRolling(sleeperId: string | undefined, date: string | undefined, interval: string | undefined): Observable<Rolling30DaysSleepData> {
    return this.makeSiqRequest<Rolling30DaysSleepData>(`sn/v1/sleeper/${sleeperId}/sleepData/30DaysRolling`, 'siq', {
      date,
      interval
    }, 'GET', false).pipe(
      map((response: Rolling30DaysSleepData | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  getRollingDataAggregates(accountId: string | undefined, sleeperId: string | undefined, date: string | undefined): Observable<RollingDataAggregates> {
    return this.makeSiqRequest<RollingDataAggregates>(`sn/v1/accounts/${accountId}/sleepers/${sleeperId}/sleepDataAggregates`, 'siq', {
      date
    }, 'GET', false).pipe(
      map((response: RollingDataAggregates | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  getCircadianRhythm(accountId: string, sleeperId: string, date: string): Observable<CircadianRhythmResponse> {
    return this.makeSiqRequest<CircadianRhythmResponse>(`sn/v2/accounts/${accountId}/sleepers/${sleeperId}/circadianRhythm`, 'siq', {
      startDate: date,
      endDate: date
    }, 'GET', false).pipe(
      map((response: CircadianRhythmResponse | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }
}
