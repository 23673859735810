import { CognitoLoginData, CognitoLoginModel, CognitoQuery } from "@models/auth/cognito.model";
export class SetLoginData {
  static readonly type = '[Auth] SetLoginData';
  constructor(public payload: CognitoLoginModel) { }
}

export class SetCognitoAuth {
  static readonly type = '[Auth] SetCognitoAuth';
  constructor(public payload: CognitoQuery<CognitoLoginData>) { }
}

export class CognitoLogin {
  static readonly type = '[Auth] CognitoLogin';
  constructor(public payload: CognitoLoginModel) { }
}

export class NativeLogin {
  static readonly type = '[Auth] NativeLogin';
  constructor(public payload: CognitoLoginModel) { }
}

export class ReLogin {
  static readonly type = '[Auth] ReLogin';
}

export class ReSiqLogin {
  static readonly type = '[Auth] ReSiqLogin';
}

export class ResetStates {
  static readonly type = '[Auth] ResetStates';
}

export class GoToLogin {
  static readonly type = '[Auth] GoToLogin';
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class SetRememberMe {
  static readonly type = '[Auth] SetRememberMe';
  constructor(public payload: number) { }
}

export class SetRegistrationState {
  static readonly type = '[Auth] SetRegistrationState';
  constructor(public state: number) { }
}

export class UnpackToken {
  static readonly type = '[Auth] UnpackToken';
}

export class RefreshSleeperType {
  static readonly type = '[Auth] RefreshSleeperType';
}

export class GetUserInformation {
  static readonly type = '[Auth] GetUserInformation';
}

export class ResetAuthState {
  static readonly type = '[Auth] ResetAuthState';
}
