
export enum AccessPointAction {
  Start = "start",
	Stop = "stop"
}

export enum ActLocation {
  Head = "head",
	Foot = "foot"
}

export enum ActuatorMovementStatus {
  NotMoving = 0,
	Moving = 1
}

export enum ArticulationPreset {
  None = "none",
	InProgress = "in_progress",
	Flat = "flat",
	Favorite = "favorite",
	Snore = "snore",
	ZeroG = "zero_g",
	WatchTV = "watch_tv",
	Read = "read"
}

export enum BaseType {
  Unknown = "unknown",
	NoBase = "no_base",
	IntegratedBase = "integrated_base",
	FlexFit1 = "ff1",
	FlexFit2 = "ff2",
	FlexFit3 = "ff3",
	Climate360 = "c360"
}

export enum BedPresence {
  OutOfBed = "out",
	InBed = "in",
	Unknown = "unknown"
}

export enum BedPresenceFlag {
  Out = "out",
	In = "in"
}

export enum ChamberType {
  Single = "single",
	Dual = "dual"
}

export enum CreqCommand {
  StopBoson = 0,
	StartBoson = 1,
	StopServices = 2,
	UpdateFirmware = 3,
	Reboot = 4
}

export enum DataRecordServiceName {
  All = "all",
	ArticulationControl = "articulation",
	Biosignal = "biosignal",
	BLEManagement = "ble_mgmt",
	Boson = "boson",
	Cloud = "cloud",
	Diagnostics = "diagnostics",
	Footwarming = "footwarming",
	Led = "led",
	Logging = "logging",
	Messaging = "messaging",
	Network = "network",
	UnderbedLight = "ubl",
	Button = "button",
	Persistence = "persistence",
	Power = "power",
	PressureControl = "pressure_control",
	Remote = "remote",
	ResponsiveAir = "responsive_air",
	Routine = "routine",
	SoftwareManagement = "software_mgmt",
	SoftwareWatchdog = "software_watchdog",
	System = "system",
	TemperatureSensor = "temperature_sensor",
	Thermal = "thermal"
}

export enum EnableFlag {
  Yes = "yes",
	No = "no"
}

export enum EnabledSetting {
  RADisabled = 0,
	RAEnabled = 1,
	RAPlusEnabled = 2
}

export enum FileType {
  Report = "report",
	Metadata = "metadata"
}

export enum FootwarmingSetting {
  Off = "off",
	Low = "low",
	Medium = "medium",
	High = "high"
}

export enum HealthStatus {
  Normal = "normal",
	WrongSensorCount = "wrong_sensor_count",
	ReadSensorPositionError = "read_sensor_position_error",
	DuplicateSensorPositionError = "duplicate_sensor_position_error",
	ReadSensorTemperatureError = "read_sensor_temperature_error",
	TemperatureStripNotConnected = "temperature_strip_not_connected",
	TemperatureOutOfRangeError = "temperature_out_of_range"
}

export enum HeidiApplyType {
  App = "app",
	AppNoCheck = "app_no_check",
	Recovery = "recovery"
}

export enum HeidiImage {
  App = "app",
	Recovery = "recovery"
}

export enum HeidiMode {
  HeidiOffMode = "off",
	LowCooling = "cooling_pull_low",
	MediumCooling = "cooling_pull_med",
	HighCooling = "cooling_pull_high",
	SpecialHighCooling = "cooling_push_high",
	LowHeating = "heating_push_low",
	MediumHeating = "heating_push_med",
	HighHeating = "heating_push_high",
	FanTestPull = "fan_test_pull",
	FanTestPush = "fan_test_push",
	HeaterTestPush = "heater_test_push"
}

export enum HeidiPresence {
  NotPresent = "false",
	Present = "true"
}

export enum HeidiRoutineEnable {
  True = "true",
	False = "false"
}

export enum HeidiVersionType {
  App = "app",
	Update = "update",
	Recovery = "recovery",
	Bootloader = "bootloader"
}

export enum Homing {
  Done = "done",
	InProgress = "in_progress",
	Required = "required",
	Error = "error"
}

export enum HwSku {
  Unknown = "unknown",
	SKUA = "SKUA",
	SKUB = "SKUB",
	SKUC = "SKUC"
}

export enum InstallationStatus {
  None = "none",
	Done = "done",
	ArticulationError = "articulation_error"
}

export enum LedState {
  Off = "off",
	On = "on",
	BlinkFast = "blink_fast",
	BlinkSlow = "blink_slow"
}

export enum LogLevel {
  Emergency = 0,
	Alert = 1,
	Critical = 2,
	Error = 3,
	Warning = 4,
	Notice = 5,
	Info = 6,
	Debug = 7,
	All = 8
}

export enum MattressSize {
  Twin = "twin",
	TwinXL = "twinxl",
	Full = "full",
	Double = "double",
	Queen = "queen",
	King = "king",
	KingSplit = "king-split",
	KingFlextop = "king-flex"
}

export enum NameType {
  FuzionBedName = "fuzion_bed_name",
	RightSleeperName = "right_sleeper_name",
	LeftSleeperName = "left_sleeper_name"
}

export enum NetworkConnectivity {
  NoConfig = "no_config",
	NetworkCred = "network_cred",
	DetectingSSID = "detecting_ssid",
	SSIDNotFound = "ssid_not_found",
	BadWiFiPwd = "bad_wifi_pwd",
	WiFiAssociated = "wifi_associated",
	WiFiDisassociated = "wifi_disassociated",
	DNSResolution = "dns_resolution",
	ServerLoginAttempted = "server_login_attempted",
	ConnectedOverBLOH = "connected_over_bloh"
}

export enum RebootDevice {
  Maverick = "maverick",
	Goose = "goose",
	Both = "both"
}

export enum RemoteBinaryType {
  Bootloader = "bootloader",
	MainApp = "main_app",
	Recovery = "recovery",
	BLEMainApp = "ble_main_app",
	BLERecovery = "ble_recovery",
	UIAssets = "ui_assets",
	None = "none"
}

export enum ResetType {
  Flat = "flat",
	Favorite = "favorite",
	Snore = "snore",
	ZeroG = "zero_g",
	WatchTV = "watch_tv",
	Read = "read",
	All = "all"
}

export enum SensorIndex {
  T0 = "0",
	T1 = "1",
	T2 = "2",
	T3 = "3",
	T4 = "4"
}

export enum ServiceName {
  All = "all",
	ArticulationControl = "articulation",
	Biosignal = "biosignal",
	BLEConnection = "ble_conn",
	BLEManagement = "ble_mgmt",
	Boson = "boson",
	Cloud = "cloud",
	Diagnostics = "diagnostics",
	Footwarming = "footwarming",
	Led = "led",
	Logging = "logging",
	Messaging = "messaging",
	Network = "network",
	Peripheral = "peripheral",
	Persistence = "persistence",
	Power = "power",
	PressureAcquisition = "pressure",
	Provisioning = "provisioning",
	Remote = "remote",
	ResponsiveAir = "responsive_air",
	Routine = "routine",
	SleepSensorData = "sleep_sensor_data",
	SoftwareManagement = "software_mgmt",
	SoftwareWatchdog = "software_watchdog",
	System = "system",
	TemperatureSensor = "temperature_sensor",
	ThermalControl = "thermal_control"
}

export enum Side {
  Left = "left",
	Right = "right"
}

export enum SleepNumberAdjustmentStatus {
  Inactive = 0,
	Active = 1
}

export enum SleepiqDataState {
  Unknown = "unknown",
	ActiveData = "active",
	PausedData = "paused"
}

export enum SoftwareDownloadStatus {
  NoUpdateNeeded = "no_update_needed",
	DownloadingFuzionApp = "downloading_fuzion_app",
	ApplyingFuzionApp = "applying_fuzion_app",
	DownloadingFuzionRfs = "downloading_fuzion_rfs",
	ApplyingFuzionRfs = "applying_fuzion_rfs",
	DownloadingFuzionGoose = "downloading_fuzion_goose",
	ApplyingFuzionGoose = "applying_fuzion_goose",
	DownloadingHeidi = "downloading_heidi",
	ApplyingFuzionHeidi = "applying_heidi",
	DownloadingFuzionRemote = "downloading_fuzion_remote",
	ApplyingFuzionRemote = "applying_fuzion_remote",
	WaitingToApply = "waiting_to_apply",
	Done = "done"
}

export enum StatusCode {
  Success = 0,
	BamkeyProtocolFailEmptyResponse = 16,
	BamkeyProtocolFailUnknownResponse = 17,
	BamkeyProtocolFailUnknownStatus = 18,
	BamkeyProtocolFailInvalidBamkey = 19,
	BamkeyProtocolFailTimeout = 20,
	BamkeyProtocolFailKeystoreFail = 21,
	FormatFailInputOverflow = 32,
	FormatFailEncodingError = 33,
	ParseFailElementCountOverflow = 48,
	ParseFailTooFewReturnValues = 49,
	ParseFailTooManyReturnValues = 50,
	CastFail = 64,
	CastFailInvalidInteger = 65,
	CastFailCannotRepresentInteger = 66,
	CastFailInvalidFloat = 67,
	CastFailCannotRepresentFloat = 68,
	CastFailStringOverflow = 69
}

export enum ThermalEnableFlag {
  None = "none",
	HeatingAndCooling = "heat_cool",
	OnlyCooling = "cool"
}

export enum UblAutoEnable {
  True = "true",
	False = "false"
}

export enum UblCurrentStatus {
  Normal = "normal",
	UnderCurrent = "under_current",
	OverCurrent = "over_current"
}

export enum UblLevel {
  Off = "off",
	Low = "low",
	Medium = "medium",
	High = "high"
}
