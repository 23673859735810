import { BannerData, ListItem } from "@models/app/helpers.model";
import { PopupData } from "@models/app/modal-data.model";

export class SleepStringResource {
  static getDrawerTitle(metricType: string): string {
    return `Why is ${metricType.toLowerCase()} important?`;
  }

  static getHowHistoryIsCalculatedTitle(): string {
    return 'How is this calculated?';
  }

  static biosignalSubtext(metricType: string): string | null {
    switch (metricType) {
      case 'avgHeartRate':
        return 'BPM AVG';
      case 'avgRespirationRate':
        return 'BrPM AVG';
      case 'hrv':
        return null;
      default:
        return null;
    }
  }

  static getWhyIsImportantText(type: string): string {
    const whyIsImportantText = {
      'Heart Rate': 'Your heart rate plays a key factor in your wellness. Sometimes changes to your typical heart rate can indicate a change in your health and disruptions in your restful sleep which can impact your SleepIQ<sup>®</sup> score.',
      'Breath Rate': 'Your breath rate also plays a key factor in your wellness. Sometimes changes in your breathing during sleep can indicate a change in your health and can impact your SleepIQ<sup>®</sup> score.'
    };

    return whyIsImportantText[type];
  }

  static getHowHistoryIsCalculatedText(): string {
    return 'Your sleep history shows your personal sleep trends over time. If you have more than one sleep session in a day, your longest sleep session will be shown.';
  }

  static bannerData(): BannerData {
    return {
      title: 'Tracking Biosignals',
      text: 'Sometimes your smart bed is not able to record enough data throughout the night to calculate your biosignals for the entire sleep session. This may be caused by your sleeping position or weight changes. If you have questions, please call customer service for further troubleshooting.',
      icon: 'info-primary',
      type: 'blue'
    } as BannerData;
  }

  static get sleepUniqueCardText(): string {
    return "Your smart bed uses your personal sleep data to understand your one-of-a-kind needs​.";
  }

  static deliveryChecklist(): Array<ListItem> {
    return [
      { icon: 'check-icon wh-20 mb-8', text: 'WiFi name and password.', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
      { icon: 'check-icon wh-20 mb-8', text: 'Optional remote if purchased.', textClasses: 'siq-text-400 fs-16 white' } as ListItem,
      { icon: 'check-icon wh-20 mb-8', text: 'Adult 18 years or older must be present.', textClasses: 'siq-text-400 fs-16 white' } as ListItem
    ];
  }

  static sleepHistoryOnboardingText(): string {
    return 'Your historical sleep data will appear here after the first time you sleep in your new smart bed.';
  }

  static OptimizeYourDayCTA = {
    hasIdealSchedule: 'Get into your best mental and physical routine to get the most out of your day.',
    noIdealSchedule: 'Your optimized schedule is being calculated. Check back in 7 days to find out more.'
  };

  static IdealScheduleCTA = {
    hasIdealSchedule: 'Your ideal sleep schedule is based on your weekly sleep patterns.',
    noIdealSchedule: 'Your ideal sleep schedule is based on your weekly sleep patterns. Your smart bed will calculate your ideal schedule over the next 7 days.'
  };

  static NoSleepSessionCTA = {
    title: 'No sleep data was recorded​',
    text: 'Check out the application for all of your sleep data.'
  };

  static HiddenSleepSessionCTA = {
    title: 'Your sleep session was removed.',
    text: 'You may restore it using the application.'
  };

  static getTrackMyDeliveryCTA(orderNumber: string): PopupData {
    return new PopupData({
      title: `${orderNumber} <br/> Order Number Copied`,
      text: 'Your order number has been copied to your clipboard. Paste it into the Order Number field on the next page to track your order and manage your delivery.',
      icon: 'dark-checkmark',
      type: 'green',
      leftBtnTxt: 'Cancel',
      rightBtnTxt: 'Continue',
      screen: 'onboarding'
    });
  }
}
