import { BedStatusHelper } from "@shared/utils/helpers/enum.helper";

export class BedStatusEntity {
  beds!: Array<BedStatus>;
}

export class BedSide {
  alertDetailedMessage!: string;
  alertId!: number;
  isInBed!: boolean;
  lastLink!: string;
  pressure!: number;
  sleepNumber!: number;

  constructor(bedSide: BedSide) {
    Object.assign(this, bedSide);
  }
}

export class BedStatus {
  bedId!: string;
  leftSide!: BedSide;
  rightSide!: BedSide;
  status!: number;

  constructor(bedStatus: BedStatus) {
    bedStatus.leftSide = new BedSide(bedStatus.leftSide);
    bedStatus.rightSide = new BedSide(bedStatus.rightSide);
    Object.assign(this, bedStatus);
  }

  get isOnline(): boolean {
    return this.status === BedStatusHelper.online;
  }

  get statusDesc(): string {
    switch(this.status) {
      case BedStatusHelper.offline:
        return 'Offline';
      case BedStatusHelper.online:
        return 'Online';
      default:
        return 'Unconfigured';
    }
  }

  get isRightSideInBed(): boolean {
    return this.rightSide.isInBed;
  }

  get isLeftSideInBed(): boolean {
    return this.leftSide.isInBed;
  }
}