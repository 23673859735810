import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sleeper } from '@models/sleeper/sleeper.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { BreakpointState, ResizeService } from '@services/resize.service';
import { SiqIconPosition } from '@shared/utils/helpers/enum.helper';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { ProfileStringResource } from '@shared/utils/helpers/profile.helper';
import { RegistrationStringResource } from '@shared/utils/helpers/registration.helper';
import { SetRegistrationState } from '@store/auth/auth.actions';
import { OptInSleeper } from '@store/sleepers/sleepers.actions';
import { SleepersSelectors } from '@store/sleepers/sleepers.selectors';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sleep-research',
  templateUrl: './sleep-research.component.html'
})
export class SleepResearchComponent implements OnInit, OnDestroy {
  sleepScienceResearchText = ProfileStringResource.sleepScienceResearchText();
  accountOwner: Sleeper | null;
  iconPosition = SiqIconPosition.right;
  breakpoints$: Observable<BreakpointState>;

  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private resizeService: ResizeService, private mixpanelService: MixpanelService) {}

  get optInInstructions(): string {
    return RegistrationStringResource.optInInstructions(this.accountOwner?.email ?? '');
  }

  ngOnInit(): void {
    FunctionsHelper.scrollToTop();
    this.store.dispatch(new SetRegistrationState(13));
    this.breakpoints$ = this.resizeService.isMobile;
    this.store.select(SleepersSelectors.accountOwner).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(accountOwner => this.accountOwner = accountOwner);
  }

  skip(): void {
    this.redirectTo('congratulation');
    this.mixpanelService.trackRegistrationCompleteSleepStudy('Skip');
  }

  optIn(): void {
    if(this.accountOwner) {
      this.store.dispatch(new OptInSleeper(this.accountOwner)).subscribe(() => {
        this.redirectTo('congratulation');
        this.mixpanelService.trackRegistrationCompleteSleepStudy('Count Me In');
      });
    }
  }

  openLearnMore(): void {
    this.redirectTo('learn-more');
    this.mixpanelService.trackRegistrationCompleteSleepStudyDetails();
  }

  private redirectTo(page: string): void {
    this.store.dispatch(new Navigate([`pages/setup/${page}`]));
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
