import { Observable ,  Subject } from 'rxjs';

export class BootController {
  private static instance: BootController;
  private _reboot: Subject<boolean> = new Subject();
  private reboot$ = this._reboot.asObservable();

  static getBootControl(): BootController {
    if (!BootController.instance) {
      BootController.instance = new BootController();
    }
    return BootController.instance;
  }

  public watchReboot(): Observable<boolean> {
    return this.reboot$;
  }

  public restart(): void {
    this._reboot.next(true);
  }
}
