import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BreakpointState, ResizeService } from '@services/resize.service';
import { ButtonsModule } from '@shared/buttons.module';
import { SiqCommonModule } from '@shared/siq-common.module';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-siq-card',
  templateUrl: './siq-card.component.html',
  styleUrls: ['./siq-card.component.scss'],
  standalone: true,
  imports: [SiqCommonModule, ButtonsModule]
})
export class SiqCardComponent implements OnInit {

  @Output() btnClick = new EventEmitter();
  @Input() isDYK = false;
  @Input() theme: string;
  @Input() infoBoxIcon: string | null;
  @Input() headerText: string;
  @Input() text: string;
  @Input() textSize = 'fs-20';
  @Input() btnText: string;
  @Input() hasBorder: boolean;
  @Input() infoBoxType: string;
  @Input() cardIcon: string;
  @Input() textSpacingClass: string;
  @Input() textColor = 'white';
  @Input() btnType = 'secondary-btn';
  @Input() btnMargin = "mt-12";

  breakpoints$: Observable<BreakpointState>;

  constructor(private resizeService: ResizeService) { }

  get image(): string {
    return this.cardIcon ? // if there is a cardIcon this indicates dyk tip
      this.infoBoxIcon ? this.infoBoxIcon : this.imagePath('sn-logo')
      //@ts-expect-error infoBoxIcon won't be null
      : this.imagePath(this.infoBoxIcon);
  }

  get textClasses(): string {
    return `w-88-p ${this.textColor} ${this.textSpacingClass} ${this.isDYK ? 'fs-14': 'fs-16'}`;
  }

  get headerTextSize(): string {
    return this.isDYK ? 'fs-18': this.textSize;
  }

  ngOnInit(): void {
    this.breakpoints$ = this.resizeService.isMobile;
  }

  private imagePath(path: string): string {
    return `/assets/icons/${path}.svg`;
  }

  onClick(): void {
    this.btnClick.emit();
  }

}
