import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { BreakpointState, ResizeService } from '@services/resize.service';
import { SiqCommonModule } from '@shared/siq-common.module';
import { SiqCardComponent } from '@shared/standalone-components/siq-card/siq-card.component';
import { StaticContentModule } from '@shared/static-content.module';
import { LinksHelper } from '@shared/utils/helpers/links-helper';
import { YearHelper } from '@shared/utils/helpers/year.helper';
import { BedsSelectors } from '@store/beds/beds.selectors';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-info-bar',
  templateUrl: './info-bar.component.html',
  standalone: true,
  imports: [SiqCommonModule, SiqCardComponent, StaticContentModule]
})
export class InfoBarComponent implements OnInit, OnDestroy {
  breakpoints$: Observable<BreakpointState>;
  contactUsLink = LinksHelper.contactUs;
  searchSupportArticlesLink: string;
  legalLink = LinksHelper.license;
  privacyPolicyLink = LinksHelper.privacyPolicy;
  openSourceLink = LinksHelper.openSource;
  currentYear = YearHelper.currentYear;

  private _unsubscribeAll = new Subject<void>();

  constructor(
    private resizeService: ResizeService, 
    private store: Store, 
    private mixpanelService: MixpanelService) {}

  ngOnInit(): void {
    this.breakpoints$ = this.resizeService.isMobile;

    this.store.select(BedsSelectors.selectedSleeperBed).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(selectedBed => {
      this.searchSupportArticlesLink = LinksHelper.searchSupportArticles(selectedBed);
    })
  }

  trackMixpanelEvent(link: string): void {
    const eventMapping: { [key: string]: () => void } = {
      'contactUs': () => this.mixpanelService.trackSupportSleepTeamOpen(),
      'supportArticles': () => this.mixpanelService.trackSupportArticlesOpen(),
      'legal': () => this.mixpanelService.trackProfilePrivacyLegal(),
      'privacyPolicy': () => this.mixpanelService.trackProfilePrivacyPolicy(),
      'license': () => this.mixpanelService.trackProfilePrivacyLicense()
    };

    const eventFunction = eventMapping[link];
    if (eventFunction) {
      eventFunction();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
