import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { ServerErrors } from '@shared/utils/helpers/siq-errors.helper';
import { catchError, map, ObjectUnsubscribedError, Observable, throwError, timeout, TimeoutError } from 'rxjs';
import { EnvironmentsService } from './environments.service';
import { MixpanelService } from './mixpanel.service';
import { SplashScreenService } from './splash-screen.service';
import { ApplicationConfig } from '@models/app/application-config.model';

@Injectable({
  providedIn: 'root'
})
export class SiqApiService {

  isShownAlert = false;

  constructor(
    private httpClient: HttpClient,
    private environmentsService: EnvironmentsService,
    private mixpanelService: MixpanelService,
    public dialog: MatDialog,
    private splashScreenService: SplashScreenService) { }

  /*
    @param requestType can be siq or nsiq(native siq request)
  */

  makeSiqRequest<T>(endpoint: string, requestType: string, params: object, type: string, skipAuth: boolean, responseType = 'json'): Observable<T | HttpErrorResponse> {
    const APIEndpoint = this.environmentsService.APIURL + '/rest/';
    let headers;


    headers = new HttpHeaders({
      'X-App-Version': '5.3.0',
      'Accept-Version': '5.3.0',
      'X-Use-Auth': requestType,
      'X-App-Platform': 'web'
    });

    if (skipAuth) {
      headers = headers.append('X-Skip-Interceptor', '');
    }

    if (!navigator.onLine) {
      window.alert('No Internet connection. Please try again later.');
      throw throwError(() => FunctionsHelper.createGenericError(503));
    }

    const urlParams = this.getUrlParams(params, type);

    const url = APIEndpoint + endpoint + urlParams;

    const options: object = {
      body: params,
      headers,
      withCredentials: true,
      responseType
    };

    return this.httpClient.request<T>(type, url, options).pipe(
      timeout({ each: 30000 }),
      catchError((initialError: HttpErrorResponse) => {
        return this.catchSiqError(initialError, endpoint, url, requestType);
      })
    );
  }

  makeCognitoRequest<T>(type: string, params: object, skipAuth: boolean): Observable<T | HttpErrorResponse> {
    const APIEndpoint = this.environmentsService.COGNITOURL + '/v1/token';
    const ClientID = this.environmentsService.CLIENTID;

    let headers;

    headers = new HttpHeaders({
      'X-Use-Auth': 'cognito'
    });

    if (skipAuth) {
      headers = headers.append('X-Skip-Interceptor', '');
    }

    if (!navigator.onLine) {
      window.alert('No Internet connection. Please try again later.');
      throw throwError(() => FunctionsHelper.createGenericError(503));
    }

    const options = {
      body: !skipAuth ? { ...params } : { ClientID, ...params },
      headers,
    };

    return this.httpClient.request<T>(type, APIEndpoint, options).pipe(
      map((data: T) => data),
      catchError((initialError: HttpErrorResponse) => {
        return this.catchSiqError(initialError, '/v1/token', APIEndpoint, 'cognito');
      })
    );
  }

  makeEdpRequest<T>(endpoint: string, params: object, type: string): Observable<T | HttpErrorResponse> {
    const APIEndpoint = this.environmentsService.EDPURL + '/';

    const headers = new HttpHeaders({
      'X-App-Version': '5.3.0',
      'Accept-Version': '5.3.0',
      'Ocp-Apim-Subscription-Key': this.environmentsService.EDPSUBSRCIPTION,
      'X-Use-Auth': 'edp',
      'X-App-Platform': 'web'
    });

    const urlParams = this.getUrlParams(params, type);
    const options = {
      body: params,
      headers
    };

    const url = `${APIEndpoint}${endpoint}${urlParams}`;

    return this.httpClient.request<T>(type, url, options).pipe(
      timeout({ each: 30000 }),
      catchError((initialError: HttpErrorResponse) => {
        return this.catchSiqError(initialError, endpoint, url, 'edp');
      })
    );
  }

  makeTextRequest(endpoint: string): Observable<string | HttpErrorResponse> {
    const url = this.environmentsService.APIURL + '/rest/' + endpoint + '?dataType=text';

    const headers = new HttpHeaders({
      'X-App-Version': '5.3.0',
      'Accept-Version': '5.3.0',
      'X-Skip-Interceptor': '',
      'X-Use-Auth': 'siq',
      'X-App-Platform': 'web'
    });

    return this.httpClient.get(url, { headers, responseType: 'text' }).pipe(
      timeout({ each: 30000 }),
      catchError((initialError: HttpErrorResponse) => {
        return this.catchSiqError(initialError, endpoint, url, 'siq');
      })
    );
  }

  makeSleepNumberRequest<T>(endpoint: string, params: object, type: string): Observable<T | HttpErrorResponse> {
    const APIEndpoint = this.environmentsService.SLEEPNUMBERURL + '/api/storefront/';

    const headers = new HttpHeaders({
      'X-Use-Auth': 'sn'
    });

    if (!navigator.onLine) {
      window.alert('No Internet connection. Please try again later.');
      throw throwError(() => FunctionsHelper.createGenericError(503));
    }

    const urlParams = this.getUrlParams(params, type);
    //eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const url = APIEndpoint + endpoint + urlParams;
    const options = {
      body: params,
      headers,
    };

    return this.httpClient.request<T>(type, url, options).pipe(
      timeout({ each: 30000 }),
      map(data => data),
      catchError((initialError: HttpErrorResponse) => {
        return this.catchSiqError(initialError, endpoint, url, 'sn');
      })
    );
  }

  // This might change based on the contract changes
  makePlansRequest<T>(endpoint: string, params: object, type: string, isBiqRequest?: boolean): Observable<T | HttpErrorResponse> {
    const APIEndpoint = `${isBiqRequest ? this.environmentsService.BIQURL : this.environmentsService.PLANSURL}/rest/`;

    const headers = new HttpHeaders({
      'X-Use-Auth': 'siq',
      'X-Request-Origin': 'sn-web'
    });

    if (!navigator.onLine) {
      window.alert('No Internet connection. Please try again later.');
      throw throwError(() => FunctionsHelper.createGenericError(503));
    }

    const urlParams = this.getUrlParams(params, type);
    //eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const url = APIEndpoint + endpoint + urlParams;
    const options = {
      body: params,
      headers,
    };

    return this.httpClient.request<T>(type, url, options).pipe(
      timeout({ each: 30000 }),
      map(data => data),
      catchError((initialError: HttpErrorResponse) => {
        return this.catchSiqError(initialError, endpoint, url, 'sn');
      })
    );


  }

  getConfig(): Observable<ApplicationConfig> {
    return this.makeSiqRequest<ApplicationConfig>('sn/v3/configs/app', 'siq', {}, 'GET', true).pipe(
      map((response: ApplicationConfig | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  //#region Private functions

  private getUrlParams(params: object, type: string): string {
    let urlParams = new HttpParams();
    if (type === 'GET') {
      for (const key in params) {
        if (Object.hasOwnProperty.call(params, key)) {
          urlParams = urlParams.set(key, params[key]);
        }
      }
    }
    if (urlParams.toString() === '') {
      return urlParams.toString();
    }
    return '?' + urlParams.toString();
  }

  private catchSiqError(initialError: HttpErrorResponse, endpoint: string, url: string, originFrom: string): Observable<HttpErrorResponse> {
    this.splashScreenService.setAuthLoading(false);
    this.splashScreenService.setBedsLoading(false);
    this.splashScreenService.setSessionLoading(false);
    if (initialError instanceof TimeoutError && !this.filterSpecificEndpointsError(endpoint)) {
      this.showAlert(ServerErrors.ApiErrors.requestTimeout.title, ServerErrors.ApiErrors.requestTimeout.text);
      this.mixpanelService.trackError(url, 0, 'Timeout', 0);
      return throwError(() => FunctionsHelper.createGenericError(-504, `App Timeout for ${endpoint}`));
    } else if (!(initialError instanceof ObjectUnsubscribedError)) {
      switch (originFrom) {
        case 'siq':
        case 'nsiq':
          this.mixpanelService.trackError(url, initialError.error.Error?.Code, initialError.error.Error?.Message, initialError.status);
          break;
        case 'cognito':
          this.mixpanelService.trackError(url, initialError.error.data.code, initialError.error.data.message, initialError.status);
          break;
        case 'sn':
          this.mixpanelService.trackError(url, 0, '', initialError.status);
          break;
        case 'edp':
          this.mixpanelService.trackError(url, initialError.error.code, initialError.error.message, initialError.status);
          break;
      }
      this.handleAPIError(initialError, endpoint);
    }
    return throwError(() => initialError);
  }

  private handleAPIError(initialError: HttpErrorResponse, endpoint: string): void {
    if (this.filterSpecificEndpointsError(endpoint)) {
      return;
    }
    if (initialError.status >= 500) {
      this.showAlert(ServerErrors.ServerMaintenance.title, ServerErrors.ServerMaintenance.text
      );
    }
  }

  private showAlert(title: string, text: string): void {
    if (!this.isShownAlert) {
      this.isShownAlert = true;
      const modal = FunctionsHelper.createPopup(this.dialog, title, text, 'API Service', 'warning-icon', 'yellow', 'OK');
      modal.componentInstance.onRightAction.subscribe(() => {
        modal.close();
      });
      modal.afterClosed().subscribe(() => {
        this.isShownAlert = false;
        modal.componentInstance.onRightAction.unsubscribe();
      });
    }
  }

  private filterSpecificEndpointsError(endpoint: string): boolean {
    return endpoint.includes('license') || endpoint.includes('privacy-policy');
  }
  //#endregion
}
