<div siqGrid fx-direction="column">
  <div siqGrid fx-layout-align="space-between center" fx-direction="row" class="background-transparent cursor-pointer" [style.height]="height">
      <div siqGrid *ngFor="let tab of tabs; let i = index"
           (click)="selectTab(i)"
           fx-layout-align="center center"
           [style.width]="'calc(100% / ' + tabs.length +')'">
          <div class="tab-text siq-text-900 fs-14" [ngClass]="isTabSelected(i) ? 'white' : 'primary-200'">{{ tab }}</div>
      </div>
  </div>
  <div class="active-tab background-white"
       [style.width]="'calc(100% / ' + tabs.length +')'"
       [style.margin-left]="'calc(' + selectedTab + '*(100% / ' + tabs.length +'))'">
  </div>
</div>
