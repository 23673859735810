import { SetupSleeperData } from "@models/app/helpers.model";
import { Sleeper, UpdateSleeperEmailProp, UpdateSleeperPasswordProp, UpdateSleeperProperty } from "@models/sleeper/sleeper.model";
import { WellnessProfileAction } from "@shared/utils/helpers/enum.helper";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export class LoadSleepers {
  static readonly type = '[Sleepers] LoadSleepers';
}

export class RefreshSleepers {
  static readonly type = '[Sleepers] RefreshSleepers';
}

export class SetDefaultSelectedSleeper {
  static readonly type = '[Sleepers] SetDefaultSelectedSleeper';
}

export class SelectDefaultSleeper {
  static readonly type = '[Sleepers] SelectDefaultSleeper';
  constructor(public sleeperId: string) { }
}

export class SelectSleeper {
  static readonly type = '[Sleepers] SelectSleeper';
  constructor(public sleeperId: string) { }
}
export class ResetSleeperState {
  static readonly type = '[Sleepers] ResetSleeperState';
}

export class UpdateSleeper {
  static readonly type = '[Sleepers] UpdateSleeper';
  constructor(public sleeperId: string, public payload: UpdateSleeperProperty | SetupSleeperData) { }
}

export class UpdateSleepersSide {
  static readonly type = '[Sleepers] UpdateSleepersSide';
  constructor(public bedId: string) { }
}

export class LoadAllTimeAverages {
  static readonly type = '[Sleepers] LoadAllTimeAverages';
  constructor(public sleeperId: string | undefined) { }
}

export class LoadSleepStudy {
  static readonly type = '[Sleepers] LoadSleepStudy';
  constructor(public sleeper: Sleeper) { }
}

export class OptInSleeper {
  static readonly type = '[Sleepers] OptInSleeper';
  constructor(public sleeper: Sleeper) { }
}

export class OptOutSleeper {
  static readonly type = '[Sleepers] OptOutSleeper';
  constructor(public sleeper: Sleeper) { }
}

export class UpdateSleeperEmail {
  static readonly type = '[Sleepers] UpdateSleeperEmail';
  constructor(public sleeper: Sleeper | null, public data: UpdateSleeperEmailProp) { }
}

export class UpdateSleeperPassword {
  static readonly type = '[Sleepers] UpdateSleeperPassword';
  constructor(public sleeper: Sleeper | null, public data: UpdateSleeperPasswordProp) { }
}
export class UpdateNativePassword {
  static readonly type = '[Sleepers] UpdateNativePassword';
  constructor(public sleeperId: string, public data: UpdateSleeperPasswordProp) { }
}

export class LoadSleeperWellnessProfile {
  static readonly type = '[Sleepers] LoadSleeperWellnessProfile';
  constructor(public sleeperId: string) { }
}

export class LoadSleeperWellnessQuestions {
  static readonly type = '[Sleepers] LoadSleeperWellnessQuestions';
  constructor(public sleeperId: string) { }
}

export class SetSleeperWellnessProfile {
  static readonly type = '[Sleepers] AddSleeperWellnessProfile';
  constructor(public sleeperId: string, public data: UpdateSleeperProperty, public actionType: WellnessProfileAction) { }
}
export class CancelInviteSleeperEmail {
  static readonly type = '[Sleepers] CancelInviteSleeperEmail';
  constructor(public accountId: string, public sleeperId: string) { }
}
export class ResendInvitation {
  static readonly type = '[Sleepers] ResendInvitation';
  constructor(public accountId: string, public sleeperId: string) { }
}