import { Component, HostListener, Input } from '@angular/core';
import { SwitchElement, SwitchElementAction } from '@models/app/switch.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { PrivacyMode } from '@shared/utils/helpers/enum.helper';
import { AddNewSleeper, SetPrivacyModeBannerState } from '@store/app/app.actions';
import { AppSelectors } from '@store/app/app.selectors';
import { BedsSelectors } from '@store/beds/beds.selectors';
import { SelectSleeper } from '@store/sleepers/sleepers.actions';

@Component({
  selector: 'app-siq-switcher',
  templateUrl: './siq-switcher.component.html',
  styleUrls: ['./siq-switcher.component.scss']
})
export class SiqSwitcherComponent {

  @Input() selectedElement: SwitchElement<string>;
  @Input() switcherElements: Array<SwitchElement<string>>;
  isOpened = false;

  constructor(private store: Store, private mixpanelService: MixpanelService) { }

  get isClickable(): boolean {
    return this.switcherElements.length > 1 || Object.prototype.hasOwnProperty.call(this.selectedElement, 'action');
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!(event.target as Element).className.includes('switcher-element') && this.isOpened) {
      this.isOpened = !this.isOpened;
    }
  }

  handleOpenSwitcher(event: Event): void {
    event.stopPropagation();
    this.isOpened = !this.isOpened;
  }

  selectItem(element: SwitchElement<string>): void {
    this.selectedElement = element;
    this.isOpened = false;
    this.store.dispatch(new SelectSleeper(element.key));
    this.mixpanelService.trackSwitchSleeper(element.key);
    // to set the privacy banner after switching sleeper in sleeper switcher
    const selectedSleeperBed = this.store.selectSnapshot(BedsSelectors.selectedSleeperBed);
    const isPrivacyBannerDismissedWithLater = this.store.selectSnapshot(AppSelectors.isPrivacyBannerDismissedWithLater);
    if(!isPrivacyBannerDismissedWithLater && selectedSleeperBed)
      this.store.dispatch(new SetPrivacyModeBannerState(this.store.selectSnapshot(BedsSelectors.getPrivacyStatusForBed)(selectedSleeperBed?.bedId) ?? PrivacyMode.Off));

    this.store.dispatch(new Navigate(['pages/refresh']));
  }

  onAction(action: SwitchElementAction): void {
    switch (action) {
      case SwitchElementAction.AddNewSleeper: {
        const selectedSleeperBed = this.store.selectSnapshot(BedsSelectors.selectedSleeperBed);
        if (selectedSleeperBed) {
          this.store.dispatch(new AddNewSleeper(selectedSleeperBed, 'pages/sleep'));
        }
        return;
      }
      default:
        return;
    }
  }
}
