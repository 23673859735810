import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

// This pipe could be used to convert minutes or seconds into hours and minutes
// If the value is in seconds we need to provide 'seconds' as a format
// For minutes we need to use 'minutes' as a format

@Pipe({
  name: 'timeConverter'
})
export class TimeConverterPipe implements PipeTransform {
  transform(value: string | number | null | undefined, format: string): string {
    if (typeof value === 'string') {
      return value;
    }
    if(value) {
      if(format === 'seconds') {
        if(value > 0 && value < 60) return '1m';
        else value = Math.round(value / 60);
      }
      const momentDuration = moment.duration(value, 'minutes');
      return momentDuration.hours() ?
      `${momentDuration.hours()}h ${momentDuration.minutes()}m`:
      `${momentDuration.minutes()}m`;
    }
    return '0m';
  }
}
