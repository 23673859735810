import { Component, Input } from '@angular/core';
import { ListItem, SleepScienceLearnMore } from '@models/app/helpers.model';
import { SiqCommonModule } from '@shared/siq-common.module';
import { SiqListItemsComponent } from '../siq-list-items/siq-list-items.component';

@Component({
  selector: 'app-learn-more-content',
  templateUrl: './learn-more-content.component.html',
  standalone: true,
  imports: [SiqCommonModule, SiqListItemsComponent]
})
export class LearnMoreContentComponent {
  @Input() learnMoreInfo: SleepScienceLearnMore;
  @Input() listItemsTitle: string | null;
  @Input() listItems: Array<ListItem>;
}
