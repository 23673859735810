import { Component, OnDestroy, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { AppSelectors } from '@store/app/app.selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-order-found',
  templateUrl: './order-found.component.html'
})
export class OrderFoundComponent implements OnInit, OnDestroy {
  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private mixpanelService: MixpanelService) {}

  ngOnInit(): void {
    FunctionsHelper.scrollToTop();
    this.mixpanelService.trackOrderFoundSplash();
    setTimeout(() => {
      this.store.select(AppSelectors.hasECIMIdentity).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(hasECIMIdentity => {
        if(hasECIMIdentity) {
          this.store.dispatch(new Navigate(['auth/login']));
        } else {
          localStorage.removeItem('email');
          this.store.dispatch(new Navigate(['auth/register/setup-login']));
        }
      });

    }, 3000);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
