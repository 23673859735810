import { SiqIconPosition } from "@shared/utils/helpers/enum.helper";

export class PopupData {
  screen: string;
  type: string;
  icon: string;
  title: string;
  text: string;
  leftBtnTxt: string;
  rightBtnTxt: string;
  hideLeftButton? : boolean;
  hideRightButton?: boolean;
  leftBtnIcon?: PopupBtnIcon;
  rightBtnIcon?: PopupBtnIcon;

  constructor(data: PopupData) {
    Object.assign(this, data);
  }
}

export interface PopupModel {
  width: string;
  height: string;
  disableClose: boolean;
  data: PopupData;
}

export class PopupBtnIcon {
  position: SiqIconPosition
  icon: string;

  constructor(position: SiqIconPosition, icon: string) {
    this.position = position;
    this.icon = icon;
  }
}