import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SetupBedData } from '@models/app/helpers.model';
import { Bed } from '@models/bed/bed.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { BedSettingsResources } from '@shared/utils/helpers/bed-settings.helper';
import { BedSide } from '@shared/utils/helpers/enum.helper';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { Timezones } from '@shared/utils/helpers/timezones.helper';
import { ZipCode } from '@shared/utils/helpers/zipCode.helper';
import { UpdateBed } from '@store/beds/beds.actions';
import { SetAddSecondSleeper, SetBedData } from '@store/setup/setup.actions';
import { SetupSelectors } from '@store/setup/setup.selectors';
import { Subject, filter, first, takeUntil } from 'rxjs';

interface SetupBedForm {
  name: FormControl<string | null>;
  timezone: FormControl<string | null>;
  side: FormControl<number | null>;
}

@Component({
  selector: 'app-setup-bed',
  templateUrl: './setup-bed.component.html',
})
export class SetupBedComponent implements OnInit, OnDestroy {

  setupBedForm: FormGroup<SetupBedForm>;
  selectedBed: Bed;
  controlFocusState = {};
  characterCount = 0;
  timezone = 'US/Central';
  timezonesValues = Timezones.values;
  timezonesOptions = Timezones.options;
  timezoneValueDefault = 5;
  timezoneOptionDefault = 'US/Central';
  bedNameError = BedSettingsResources.Errors.bedName;
  radioButtonsAnswers = [{ id: 0, text: 'Yes' }, { id: 1, text: 'No' }];
  answeredSetupSleeper = false;
  shouldAddSecondSleeper: number | null;

  private _unsubscribeAll = new Subject<void>;

  constructor(private store: Store, private mixpanelService: MixpanelService) { }

  ngOnInit(): void {
    FunctionsHelper.scrollToTop();
    this.store.select(SetupSelectors.selectedBed).pipe(
      first(),
      filter((bed): bed is Bed => !!bed)
    ).subscribe(bed => {
      this.selectedBed = bed;
      if (!this.selectedBed.dualSleep) {
        this.answeredSetupSleeper = true;
        this.store.dispatch(new SetAddSecondSleeper(1));
      } else {
        const shouldAddSecondSleeper = this.store.selectSnapshot(SetupSelectors.shouldAddSecondSleeper);
        this.store.dispatch(new SetAddSecondSleeper(shouldAddSecondSleeper));
      }
      const bedName = this.getBedData('name');
      const bedTimezone = this.getBedData('timezone');
      const bedSide = this.getBedData('side');
      if (bedName) {
        this.characterCount = (bedName as string).length;
      }
      this.setupBedForm = new FormGroup<SetupBedForm>({
        name: new FormControl(bedName ? bedName as string : '', [Validators.required]),
        timezone: new FormControl(bedTimezone ? this.setBedDataTimezone(bedTimezone as string) : this.setBedTimezone(bed), [Validators.required]),
        side: new FormControl(bedSide !== null ? bedSide as number : this.setBedSize(bed), [Validators.required])
      });
    });

    this.setupBedForm.controls.name.valueChanges.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((name) => {
      this.characterCount = name ? name.length : 0;
    });

    // set selected value in radio button based on bed data in the state
    this.store.select(SetupSelectors.shouldAddSecondSleeper).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((value) => {
      if (value !== null) {
        this.answeredSetupSleeper = true;
      }
      this.shouldAddSecondSleeper = value;
    });
  }

  get selectedTimezone(): number {
    const formTimezone = this.setupBedForm.controls.timezone.value;
    const userTimezone = this.timezonesOptions.findIndex((timezone) => timezone === this.setupBedForm.controls.timezone.value);
    return formTimezone && userTimezone ? userTimezone : this.timezoneValueDefault;
  }

  get showCharacterCount(): boolean {
    return this.controlFocusState['name'];
  }

  // NOTE: API is not restricted to 22 characters
  get isBedNameTooLong(): boolean {
    return this.characterCount > 22;
  }

  get isFormValid(): boolean {
    return this.setupBedForm.valid && this.answeredSetupSleeper;
  }

  get leftSideIcon(): string {
    return this.setupBedForm.controls.side.value === 1 ? 'selected-bed-left-side' : 'unselected-bed-left-side';
  }

  get rightSideIcon(): string {
    return this.setupBedForm.controls.side.value === 0 ? 'selected-bed-right-side' : 'unselected-bed-right-side';
  }

  hasErrors(field: string): boolean {
    if (!this.controlFocusState[field]) {
      return this.setupBedForm.controls[field].touched && !this.setupBedForm.controls[field].valid;
    }
    return false;
  }

  setControlFocusState(focusState: object): void {
    this.controlFocusState = focusState;
    if (this.hasErrors('name') || this.isBedNameTooLong) {
      const errorMessage = this.isBedNameTooLong ? 'Exceed 22 characters' : this.bedNameError;
      this.mixpanelService.trackRegistrationValidationError(errorMessage);
    }
  }

  handleDropdownSelect(selectedIndex: number): void {
    this.timezone = this.timezonesOptions[selectedIndex];
    this.setupBedForm.controls.timezone.setValue(this.timezone);
  }

  back(): void {
    this.store.dispatch(new Navigate(['pages/setup/select-bed']));
  }

  next(): void {
    this.mixpanelService.trackProceedNextAction('set up smart bed');
    const bedData = new SetupBedData(this.setupBedForm.getRawValue() as SetupBedData);
    this.store.dispatch(new SetBedData(bedData));
    this.store.dispatch(new UpdateBed(this.selectedBed.bedId, bedData)).subscribe({
      next: () => {
        this.store.dispatch(new Navigate(['pages/setup/setup-sleeper']));
        this.mixpanelService.trackSmartBedSetupComplete(this.getBedData('name') as string, this.getBedData('timezone') as string);
      }
    });
  }

  addSecondSleeper(selected: number): void {
    this.store.dispatch(new SetAddSecondSleeper(selected));
    this.answeredSetupSleeper = true;
    const action = selected === 0 ? 'yes' : 'no';
    this.mixpanelService.trackRegistrationProfileOtherSide(action);
  }

  selectSide(side: number): void {
    this.setupBedForm.controls.side.setValue(side);
    const selectedSide = side === 1 ? 'right' : 'left';
    this.mixpanelService.trackRegistrationBedSideSelect(this.selectedBed.bedId, selectedSide);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private getBedData(property: string): string | number | null {
    const bedData = this.store.selectSnapshot(SetupSelectors.bedSetupData);
    return bedData?.[property] ?? null;
  }

  private setBedDataTimezone(timezone: string): string {
    this.timezone = timezone;
    return timezone;
  }

  private setBedTimezone(bed: Bed): string {
    let timezone = this.timezoneOptionDefault;
    if (bed.timezone) {
      timezone = bed.timezone;
    } else {
      const zip = Number(bed.zipcode.split('-')[0]);
      if (zip) {
        const i = ZipCode.zip.indexOf(zip);
        timezone = ZipCode.offsetMap[ZipCode.timezone[i]];
      }
    }
    this.timezone = timezone;
    return timezone;
  }

  private setBedSize(bed: Bed): number | null {
    // 1 - left side is a default side
    // null - so that user has to select a side
    return bed.dualSleep ? null : BedSide.Left;
  }
}
