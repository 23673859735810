import { SleepHealthIccParams } from "@models/health/sleep-health-icc.model";
import { WellnessReportParams } from "@models/health/wellness-report.model";
import { Sleeper } from "@models/sleeper/sleeper.model";

export class GetSleepHealth {
  static readonly type = '[Health] GetSleepHealth';
  constructor(public sleeper: Sleeper, public sessionDate: string) { }
}
export class GetSleepHealthIcc {
  static readonly type = '[Health] GetSleepHealthIcc';
  constructor(public accountId: string, public sleeperId:  string, public payload: SleepHealthIccParams) { }
}
export class GetWellnessReport {
  static readonly type = '[Health] GetWellnessReport';
  constructor(public accountId: string, public sleeperId: string, public params: WellnessReportParams) { }
}

export class DownloadWellnessReport {
  static readonly type = '[Health] DownloadWellnessReport';
  constructor(public accountId: string, public sleeperId: string, public date: string) { }
}

export class ResetHealthState {
  static readonly type = '[Health] ResetHealthState';
}