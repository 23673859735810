import { ListItem } from "@models/app/helpers.model";
import { HowWeCalculate, WayToImprove } from "@models/health/sleep-health-trends.model";

export class HealthStringResource {

  static WellnessReportSubText = {
    available: 'See how your sleep health changes over time.',
    unavailable: 'See how your sleep is trending over time through every stage of life.​'
  };

  static WellnessReportBtnText = {
    available: 'See My Reports​',
    unavailable: 'What To Expect​'
  };

  static WellnessReportTitleHeader = {
    title: `HealthIQ<sup>®</sup> Wellness Reports`
  }

  static getDrawerTitle(drawerType: string, trendsType: string): string {
    switch (drawerType) {
      case 'whyIsImportant':
        return `Why ${trendsType} Is Important`;
      case 'waysToImprove':
        return 'Quick Ways to Improve';
      case 'howWeCalculate':
        return `How We Calculate ${trendsType}`;
      default:
        return '';
    }
  }

  static getWhyIsImportantText(type: string): string {
    const whyIsImportantText = {
      Duration: 'Getting enough sleep is foundational to your sleep health. A healthy sleep duration may help boost memory, improve reaction times, and enhance alertness.',
      Efficiency: 'Sleep efficiency is a key part to your overall sleep health. Getting more restful sleep can lead to overall health improvements.',
      Timing: 'Keeping a consistent sleep schedule helps regulate your circadian rhythm and improves your sleep health.'
    };

    return whyIsImportantText[type];
  }

  static getWaysToImproveContent(type: string): WayToImprove[] {
    const waysToImproveText = {
      Duration: [
        new WayToImprove('duration-goal', 'Set a realistic sleep goal each night. Visit your wellness profile if you need to adjust your sleep goal.'),
        new WayToImprove('bell', 'Keep a consistent bedtime routine.')
      ],
      Efficiency: [
        new WayToImprove('', 'Stay comfortable in your bed by occasionally adjusting your Sleep Number<sup>®</sup> setting.'),
        new WayToImprove('weight', 'Get consistent exercise.'),
        new WayToImprove('cutlery', 'Avoid late night meals.')
      ],
      Timing: [
        new WayToImprove('bell', 'Set a bedtime reminder.'),
        new WayToImprove('snore', 'Keep a consistent bedtime and wake time during the week and on weekends.'),
        new WayToImprove('sun', 'Expose yourself to bright, natural light in the morning.')
      ]
    };

    return waysToImproveText[type];
  }

  static getHowWeCalculateText(type: string): HowWeCalculate {
    const howWeCalculate = {
      Duration: new HowWeCalculate('Your sleep duration is how long you slept compared to your sleep goal.', 'Your sleep goal'),
      Efficiency: new HowWeCalculate('Your sleep efficiency is how well you slept, measured by restful sleep, and compared to your monthly restful sleep average.', 'Last month’s restful sleep average'),
      Timing: new HowWeCalculate('Your sleep timing is when you slept compared to your ideal sleep schedule. Your ideal sleep schedule is based on your weekly sleep patterns.', 'Your ideal schedule')
    };
    return howWeCalculate[type];
  }

  static getLegendText(type: string): string[] {
    const legendText = {
      Duration: ['Sleep goal met', 'Sleep goal missed'],
      Efficiency: ['Efficiency met', 'Efficiency missed'],
      Timing: ['Ideal', 'Inconsistent', 'Ideal bed/wake time']
    };

    return legendText[type];
  }

  static getLegendSymbolsClasses(type: string): string[] {
    const legendSymbolsClasses = {
      Duration: ['circle wh-12 blue', 'diamond-shape'],
      Efficiency: ['circle wh-12 green', 'diamond-shape'],
      Timing: ['circle wh-12 yellow', 'diamond-shape', 'rectangle-shape']
    };

    return legendSymbolsClasses[type];
  }

  static get getNoIdealScheduleMessage(): string {
    return 'A consistent sleep schedule reinforces your body\'s sleep and wake cycle. Your smart bed will calculate your ideal schedule over the next 7 days.';
  }

  static newUserOnboardingMessage(): string {
    return 'Your sleep health is being calculated. After 7 days of sleep, your sleep health trends will be available.';
  }

  static comingSoonText(): string {
    return 'Your wellness report will be available within the first few days of the month. Check back soon to access your report.';
  }

  static WellnessReportWhatToExpect: Array<ListItem> = [
    { icon: 'trends-icon', text: 'Monthly trends and advice', textClasses: 'siq-text-400 fs-18 white' } as ListItem,
    { icon: 'biosignals-icon', text: 'Monthly view of your biometrics', textClasses: 'siq-text-400 fs-18 white' } as ListItem,
    { icon: 'circadian-rhythm-icon', text: 'Monthly view of circadian rhythm', textClasses: 'siq-text-400 fs-18 white' } as ListItem
  ];
}