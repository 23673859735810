import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

export interface BreakpointState {
  screenSize: string;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isSmallScreen: boolean; // <= 375px
  isSmallerThan900: boolean;
  isSmallerThan800: boolean;
  windowWidth: number;
}

@Injectable({
  providedIn: 'root'
})
export class ResizeService {

  declare public isMobile: Observable<BreakpointState>;
  private resizeSubject = new BehaviorSubject<number>(self.innerWidth);
  private desktopBreakpoint = 1024;
  private tabletBreakpoint = 600;
  private smallScreenBreakpoint = 375;
  private isSmallerThan900Breakpoint = 900;
  private isSmallerThan800Breakpoint = 800;
  
  constructor() {
    this.isMobile = this.resizeSubject.asObservable().pipe(
      map((windowWidth) => {
        return {
          screenSize: this.getScreenSize(windowWidth),
          isDesktop: windowWidth > this.desktopBreakpoint,
          isTablet: windowWidth >= this.tabletBreakpoint && windowWidth <= this.desktopBreakpoint,
          isMobile: windowWidth < this.tabletBreakpoint,
          isSmallScreen: windowWidth <= this.smallScreenBreakpoint,
          isSmallerThan900: windowWidth <= this.isSmallerThan900Breakpoint,
          isSmallerThan800: windowWidth <= this.isSmallerThan800Breakpoint,
          windowWidth
        } as BreakpointState
      })
    )
    this.addResizeListener();
  }

  getScreenSize(windowWidth: number): string {
    if(windowWidth >= this.desktopBreakpoint) {
      return 'desktop';
    } else if(windowWidth >= this.tabletBreakpoint && windowWidth <= this.desktopBreakpoint) {
      return 'tablet';
    } else if(windowWidth > this.smallScreenBreakpoint &&  windowWidth < this.tabletBreakpoint) {
      return 'mobile'
    } else {
      return 'smallScreen'
    }
  }

  private addResizeListener(): void {
    window.addEventListener('resize', () => {
      const windowWidth = self.innerWidth;
      this.resizeSubject.next(windowWidth);
    })
  }

}