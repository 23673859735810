<div siqGrig fx-direction="column" class="mt-48">
  <div siqGrid fx-direction="column" fx-layout-align="center center" class="px-12">
    <span [class]="learnMoreInfo.icon"></span>
    <span class="mt-24 siq-text-900 fs-20 white">{{ learnMoreInfo.title }}</span>
    <span *ngIf="learnMoreInfo.description" class="mt-12 siq-text-400 fs-16 white text-center" [innerHTML]="learnMoreInfo.description"></span>
  </div>
  <p *ngIf="listItemsTitle" class="mt-48 mb-0 px-12 siq-text-900 fs-20 white">{{ listItemsTitle }}</p>
  <app-siq-list-items [items]="listItems" itemsPadding="px-12" textMargin="ml-12"/>
</div>

