import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { Store } from "@ngxs/store";
import { LoadSleeperWellnessQuestions } from "@store/sleepers/sleepers.actions";
import { SleepersSelectors } from "@store/sleepers/sleepers.selectors";
import { map, of } from "rxjs";

export const WellnessQuestionsGuard: CanActivateFn = () => {
  const store = inject(Store);
  const selectedSleeper = store.selectSnapshot(SleepersSelectors.selectedSleeper);
  if (selectedSleeper && !selectedSleeper.wellnessQuestions) {
    return store.dispatch(new LoadSleeperWellnessQuestions(selectedSleeper.sleeperId)).pipe(
      map(() => true)
    );
  }
  if (selectedSleeper?.wellnessQuestions) {
    return of(true);
  }
  return of(false);
};