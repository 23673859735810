import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Bed, UpdateBedProperty } from '@models/bed/bed.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { BreakpointState, ResizeService } from '@services/resize.service';
import { BedSettingsResources } from '@shared/utils/helpers/bed-settings.helper';
import { UpdateBed } from '@store/beds/beds.actions';
import { SettingsSelectors } from '@store/settings/settings.selectors';
import { Observable, Subject, filter, takeUntil } from 'rxjs';

interface ChangeBedNameForm {
  name: FormControl<string | null>;
}

@Component({
  selector: 'app-change-bed-name',
  templateUrl: './change-bed-name.component.html',
})
export class ChangeBedNameComponent implements OnInit, OnDestroy {

  breakpoints$: Observable<BreakpointState>;
  changeBedNameForm: FormGroup<ChangeBedNameForm>;
  selectedBed: Bed;
  controlFocusState = {};
  characterCount = 0;
  isFormSubmitted = false;
  bedNameError = BedSettingsResources.Errors.bedName;

  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private resizeService: ResizeService, private mixpanelService: MixpanelService) { }

  ngOnInit(): void {
    this.breakpoints$ = this.resizeService.isMobile;
    this.store.select(SettingsSelectors.selectedBed).pipe(
      takeUntil(this._unsubscribeAll),
      filter((bed): bed is Bed => !!bed)
    ).subscribe((bed) => {
      this.selectedBed = bed;
      this.characterCount = this.selectedBed.name.length;
      this.changeBedNameForm = new FormGroup<ChangeBedNameForm>({
        name: new FormControl(this.selectedBed.name, [Validators.required]),
      });
    });

    this.changeBedNameForm.valueChanges.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((value) => {
      this.characterCount = value.name ? value.name.length : 0;
    });
  }

  get hasErrors(): boolean {
    if (!this.controlFocusState['name']) {
      return this.changeBedNameForm.controls.name.touched && !this.changeBedNameForm.controls.name.valid;
    }
    return false;
  }

  get showCharacterCount(): boolean {
    return !this.isFormSubmitted && this.controlFocusState['name'];
  }

  // NOTE: API is not restricted to 22 characters
  get isBedNameTooLong(): boolean {
    return this.characterCount > 22;
  }

  setControlFocusState(focusState: object): void {
    this.controlFocusState = focusState;
  }

  back(): void {
    this.store.dispatch(new Navigate(['pages/smart-bed/bed-view']));
  }

  done(): void {
    this.isFormSubmitted = true;
    if (this.changeBedNameForm.valid) {
      this.store.dispatch(new UpdateBed(this.selectedBed.bedId, this.changeBedNameForm.value as UpdateBedProperty))
        .subscribe({
          next: () => {
            this.back();
            this.mixpanelService.trackBedNameUpdate(this.selectedBed.bedId);
          },
          error: () => this.isFormSubmitted = false
        });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
