import { SleepHealthIcc } from "@models/health/sleep-health-icc.model";
import { SleepHealthDay, SleepHealthEntity, SleepHealthTimingGoal, SleepHealthTimingGoalNoData } from "@models/health/sleep-health.model";
import { NotAvailableReport, PreviousMonthReport, WellnessReport, WellnessReportEntityModel, WellnessReportsByYear } from "@models/health/wellness-report.model";
import { Selector, createSelector } from "@ngxs/store";
import { SessionsStateModel } from "@store/sessions/sessions.model";
import { SessionsState } from "@store/sessions/sessions.state";
import { SleepersStateModel } from "@store/sleepers/sleepers.model";
import { SleepersState } from "@store/sleepers/sleepers.state";
import * as moment from "moment";
import { HealthStateModel } from "./health.model";
import { HealthState } from "./health.state";
export class HealthSelectors {

  @Selector([HealthState])
  static loading(state: HealthStateModel): boolean {
    return state.loading;
  }

  @Selector([HealthState, SleepersState])
  static sleeperSleepHealth(state: HealthStateModel, sleeperState: SleepersStateModel): SleepHealthEntity | null {
    const sleeperSleepHealth = state.sleepHealth.find((sh) => sh.sleeperId === sleeperState.selectedSleeper?.sleeperId);
    return sleeperSleepHealth ? sleeperSleepHealth : null;
  }

  @Selector([HealthState, SleepersState, SessionsState])
  static sleepHealthRolling7Days(state: HealthStateModel, sleeperState: SleepersStateModel, sessionsState: SessionsStateModel): Array<SleepHealthDay> | null {
    const sleeperSleepHealth = state.sleepHealth.find((sh) => sh.sleeperId === sleeperState.selectedSleeper?.sleeperId);
    const sleeperSleepHealthDays = sleeperSleepHealth?.days.filter((sh) => moment(sh.sessionDate).isSameOrBefore(moment(sessionsState.selectedSession?.date))) ?? null;
    return sleeperSleepHealthDays?.splice(0, 7).reverse() ?? null;
  }

  static getSleepHealthIcc(category: string): (state: HealthStateModel) => SleepHealthIcc | null {
    return createSelector([HealthState], (state: HealthStateModel): SleepHealthIcc | null => {
      const sleepHealthIcc = state.sleepHealthIcc?.messages.find(msg => msg.type.toLowerCase() === category);
      return sleepHealthIcc ?? null;
    });
  }

  @Selector([HealthState])
  static isWellnessReportFetched(state: HealthStateModel): boolean {
    return state.wellnessReport.length > 0;
  }

  @Selector([HealthState, SleepersState])
  static sleeperWellnessReport(state: HealthStateModel, sleepersState: SleepersStateModel): WellnessReportEntityModel | null {
    const sleeperReports = this.findSleeperWellnessReport(state, sleepersState.selectedSleeper?.sleeperId);
    return sleeperReports ?? null;
  }

  @Selector([HealthState, SleepersState])
  static lastAvailableReport(state: HealthStateModel, sleepersState: SleepersStateModel): WellnessReport | NotAvailableReport | null {
    const sleeperReports = this.findSleeperWellnessReport(state, sleepersState.selectedSleeper?.sleeperId);
    const lastAvailableReport = sleeperReports?.sleepData.find((report) => !Object.prototype.hasOwnProperty.call(report, 'error'));
    return lastAvailableReport ? lastAvailableReport : null;
  }

  @Selector([HealthState, SleepersState])
  static lastMonthReport(state: HealthStateModel, sleepersState: SleepersStateModel): PreviousMonthReport | NotAvailableReport | null {
    const report = this.findSleeperWellnessReport(state, sleepersState.selectedSleeper?.sleeperId)?.sleepData[0];
    if (Object.hasOwnProperty.call(report, 'error')) {
      return new NotAvailableReport(report as NotAvailableReport);
    } else {
      if(report) {
        return Object.assign(new PreviousMonthReport({...report, isEdited: false, sleepQuotient: report['sleepIQ']} as PreviousMonthReport));
      }
    }
    return null;
  }

  @Selector([HealthState, SleepersState])
  static otherWellnessReportsByYears(state: HealthStateModel, sleepersState: SleepersStateModel): Array<WellnessReportsByYear> {
    const reports = this.findSleeperWellnessReport(state, sleepersState.selectedSleeper?.sleeperId)?.sleepData;
    const reportsByYear: Array<WellnessReportsByYear> = [];
    if(reports) {
      reports.slice(1).forEach(report => {
        if (Object.hasOwnProperty.call(report, 'error')) {
          const index = reportsByYear.findIndex(r => r.year === report.year);
          index === -1 ? 
            reportsByYear.push(new WellnessReportsByYear(report.year, [new NotAvailableReport(report as NotAvailableReport)])) :
            reportsByYear[index].yearReports.push(new NotAvailableReport(report as NotAvailableReport))
        } else {
          const index = reportsByYear.findIndex(r => r.year === report.year);
          index === -1 ?
            reportsByYear.push(new WellnessReportsByYear(report.year, [new WellnessReport(report as WellnessReport)])) :
            reportsByYear[index].yearReports.push(new WellnessReport(report as WellnessReport))
        }
      });
    }
    return reportsByYear;
  }

  @Selector([HealthState, SleepersState])
  static showIdealSchedule(state: HealthStateModel, sleeperState: SleepersStateModel): boolean {
    const session = this.findSession(state, sleeperState);
    return session !== null;
  }

  @Selector([HealthState, SleepersState])
  static idealScheduleData(state: HealthStateModel, sleeperState: SleepersStateModel): SleepHealthTimingGoal | SleepHealthTimingGoalNoData | null | undefined {
    const session = this.findSession(state, sleeperState);
    return session ? session.data?.timing?.goal : null;
  }

  static getSleepersDownloadedReport(sleeperId: string, date: string): (state: HealthStateModel) => string | null {
    return createSelector([HealthState], (state: HealthStateModel): string | null => {
      const sleepersReports = state.sleeperDownloadedReport.find((report) => report.sleeperId === sleeperId);
      if(sleepersReports) {
        return sleepersReports.reports.find((report) => report.date === date)?.report ?? null
      }
      return null
    })
  } 

  private static findSleeperWellnessReport(state: HealthStateModel, sleeperId: string | undefined): WellnessReportEntityModel | null | undefined {
    if(sleeperId) {
      return state.wellnessReport.find(report => report.sleeperId === sleeperId);
    }
    return null;
  }

  // This function uses recursion to find the latest available timing goal in sleep health data
  private static findSessionInSleepHealthData(sleeperSleepHealth: SleepHealthEntity, numOfWeekToSubtract = 0, index = 0): SleepHealthDay | null {
    if (index >= sleeperSleepHealth.days.length) {
      return null; 
    }

    const latestSession = sleeperSleepHealth.days.at(0);
    const currentSession = sleeperSleepHealth.days.at(index);
    // to handle the case when there is no data on Sunday
    if(moment(currentSession?.sessionDate).day() === 0 && !currentSession?.data?.timing?.goal) {
      numOfWeekToSubtract += 1;
    }

    if(moment(latestSession?.sessionDate).subtract(numOfWeekToSubtract, 'weeks').isSame(currentSession?.sessionDate, 'week') && currentSession?.data?.timing?.goal) 
      return currentSession;

    return this.findSessionInSleepHealthData(sleeperSleepHealth, numOfWeekToSubtract, index + 1);
  }

  // This function checks two use cases for timing goal
  // 1. when the latest session has goal - that goal could be used
  // 2. when the latest session is no data session or doesn't have the goal - use the recursion to find latest available goal
  private static findSession(state: HealthStateModel, sleeperState: SleepersStateModel): SleepHealthDay | null {
    const sleeperSleepHealth = state.sleepHealth.find((sh) => sh.sleeperId === sleeperState.selectedSleeper?.sleeperId);
    if(sleeperSleepHealth) {
      const latestSession = sleeperSleepHealth?.days.at(0);
      // if the latest session has goal
      if(latestSession?.data?.timing?.goal) return latestSession;

      // if the latest session doesn't have goal
      if(!latestSession?.data?.timing?.goal) {
        // find the last available session that has goal
        return this.findSessionInSleepHealthData(sleeperSleepHealth);
      }

      return null;
    }

    return null;
  }
}