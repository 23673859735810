import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SetSleepersPassword } from '@models/app/helpers.model';
import { RegisterTokenModel } from '@models/auth/register.model';
import { Store } from '@ngxs/store';
import { SiqIconPosition } from '@shared/utils/helpers/enum.helper';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { Regex } from '@shared/utils/helpers/regex.helper';
import { RegistrationStringResource } from '@shared/utils/helpers/registration.helper';
import { PasswordError } from '@shared/utils/helpers/siq-errors.helper';
import { PasswordErrorPipe } from '@shared/utils/pipes/password-error.pipe';
import { SetPasswordAndActivate } from '@store/register/register.actions';
import { RegisterSelectors } from '@store/register/register.selectors';
import { Subject, filter, takeUntil } from 'rxjs';

interface SetupPasswordForm {
  login: FormControl<string | null>;
  password: FormControl<string | null>;
  token: FormControl<string | null>;
}

@Component({
  selector: 'app-setup-login',
  templateUrl: './setup-login.component.html',
})
export class SetupLoginComponent implements OnInit, OnDestroy {
  setupPasswordForm: FormGroup<SetupPasswordForm>;
  iconPosition = SiqIconPosition.right;
  registerToken: RegisterTokenModel;
  controlFocusState = {};
  isFormSubmitted = false;

  private _unsubscribeAll = new Subject<void>();

  constructor(private passwordErrorPipe: PasswordErrorPipe, private store: Store) { }

  ngOnInit(): void {
    this.store.select(RegisterSelectors.registerToken).pipe(
      takeUntil(this._unsubscribeAll),
      filter((token): token is RegisterTokenModel => !!token)
    ).subscribe((registerToken: RegisterTokenModel) => {
      this.registerToken = registerToken;
      const isEmailDisabled = registerToken.disableEmailUpdate ? registerToken.disableEmailUpdate : false;
      this.setupPasswordForm = new FormGroup<SetupPasswordForm>({
        login: new FormControl({ value: registerToken.email, disabled: isEmailDisabled }, [Validators.required, Validators.pattern(Regex.email)]),
        password: FunctionsHelper.passwordFormControl,
        token: new FormControl(registerToken.token ?? null, [Validators.required]),
      });
    });
  }

  get passwordText(): string {
    const error = this.setupPasswordForm.controls.password.errors;
    return error && !this.controlFocusState['password'] ? this.passwordErrorPipe.transform(error as PasswordError) : RegistrationStringResource.passwordHint;
  }

  get isDisabled(): boolean {
    return this.registerToken.disableEmailUpdate ? 
      this.setupPasswordForm.controls.password.valid :
      this.setupPasswordForm.controls.password.valid && this.setupPasswordForm.controls.login.valid
  }

  hasErrors(field: string): boolean {
    return this.isInvalidField(field);
  }

  isInvalidField(field: string): boolean {
    if (!this.controlFocusState[field]) {
      if (this.setupPasswordForm.controls[field]?.touched) {
        return this.setupPasswordForm && !this.isFormSubmitted
          ? this.checkIsInputValid(this.setupPasswordForm.controls[field])
          : false;
      }
    }
    return false;
  }

  checkIsInputValid(item: FormControl): boolean {
    return !item?.valid;
  }

  setControlFocusState(focusState: object): void {
    this.controlFocusState = focusState;
  }

  createAccount(): void {
    this.isFormSubmitted = true;
    if (this.setupPasswordForm.valid) {
      this.store.dispatch(new SetPasswordAndActivate(this.setupPasswordForm.getRawValue() as SetSleepersPassword)).subscribe({
        error: () => {
          this.isFormSubmitted = false;
          if (this.registerToken.disableEmailUpdate) {
            this.resetControl('password');
          } else {
            this.resetControl('login');
            this.resetControl('password');
          }
        }
      });
    }
  }

  private resetControl(control: string): void {
    this.setupPasswordForm.controls[control].reset();
    this.setupPasswordForm.controls[control].markAsUntouched();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.setupPasswordForm.reset();
  }
}
