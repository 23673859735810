import { Router } from '@angular/router';
import { SiqIconPosition } from '@shared/utils/helpers/enum.helper';
import { Component, ElementRef, Input, OnInit, ViewChild, Renderer2, AfterViewInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-siq-button',
  templateUrl: './siq-button.component.html',
  styleUrls: ['./siq-button.component.scss']
})
export class SiqButtonComponent implements OnInit, AfterViewInit {
  @Output() buttonClicked = new EventEmitter();
  @ViewChild('button') el: ElementRef = new ElementRef('');
  @Input() text = '';
  @Input() subText = '';
  @Input() btnType = '';
  @Input() icon = '';
  @Input() iconPosition = SiqIconPosition.left;
  @Input() width = '';
  @Input() isDisabled = false;
  @Input() isPressed = false;
  @Input() isListItemBtn = false;
  @Input() isChangeServer = false;
  @Input() isActiveServer = false;
  @Input() isCursorPointer = true;
  @Input() isPendingEmail = false;
  @Input() tabIndex: number;
  @Input() ariaLabel: string;
  iconPathLeft = '';
  iconPathRight = '';

  constructor(private renderer: Renderer2, private router: Router) {}

  get btnClass(): string {
    const justifyContent = this.subText ? 'space-between' : 'center';
    const alignItems = this.isChangeServer ? 'align-top' : 'align-center';
    const borderColor = this.isChangeServer ? 'border-primary' : 'border-blue';
    return `${justifyContent} ${alignItems} ${this.btnType} ${borderColor}`;
  }

  get hasTextUnderline(): boolean {
    return this.isChangeServer && this.isPressed;
  }

  get hasSubText(): boolean {
    return this.subText !== '';
  }

  private get isChangeBedName(): boolean {
    return this.router.url.includes('pages/smart-bed/details/change-bed-name');
  }

  ngOnInit(): void {
    if(this.isPendingEmail) this.iconPathLeft = `assets/icons/info-icon-yellow.svg`;
    else this.iconPathLeft = `assets/icons/${this.icon}.svg`;
    this.iconPathRight = `assets/icons/${this.icon}.svg`;
  }
  
  ngAfterViewInit(): void {
    this.width ?
      this.renderer.setStyle(this.el.nativeElement, 'width', this.width) :
      this.renderer.setStyle(this.el.nativeElement, 'width', 'fit-content');
  }

  checkIconPosition(position: string): boolean {
    return this.iconPosition === SiqIconPosition[position];
  }

  handleButtonClick(): void {
    if(!this.isChangeBedName)
      this.buttonClicked.emit();
  }

  handleMouseDown(): void {
    if(this.isChangeBedName)
      this.buttonClicked.emit();
  }
}