import { WhyIsThisImportantEntity } from "@models/app/why-is-this-important.model";

export interface WhyIsThisStateModel {
  content: WhyIsThisImportantEntity | null;
  redirectToTab: number;
}

export const defaultWhyIsThisState = {
  content: null,
  redirectToTab: 0
};