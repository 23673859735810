import { UserInfo } from "@models/auth/cognito.model";
import { BedStatus } from "@models/bed/bed-status.model";
import { Bed } from "@models/bed/bed.model";
import { Selector } from "@ngxs/store";
import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import { AuthStateModel } from "@store/auth/auth.model";
import { AuthState } from "@store/auth/auth.state";
import { SleepersStateModel } from "@store/sleepers/sleepers.model";
import { SleepersState } from "@store/sleepers/sleepers.state";
import { BedsStateModel } from "./beds.model";
import { BedsState } from "./beds.state";
import { BedStatusHelper, PrivacyMode } from "@shared/utils/helpers/enum.helper";

export class BedsSelectors {

  @Selector([BedsState])
  static loading(state: BedsStateModel): boolean {
    return state.loading;
  }

  @Selector([BedsState])
  static beds(state: BedsStateModel): Array<Bed> | null {
    return state.beds;
  }

  @Selector([BedsState]) // Or configuredBeds
  static bedsWithSleepers(state: BedsStateModel): Array<Bed> {
    return state.beds.filter((bed) => bed.sleeperLeftId !== '0' || bed.sleeperRightId !== '0');
  }

  @Selector([BedsState])
  static unconfiguredBeds(state: BedsStateModel): Array<Bed> {
    const unconfiguredBeds = state.bedsStatus.filter((bed) => bed.status === BedStatusHelper.unconfigured);
    return state.beds.filter((bed) => {
      return unconfiguredBeds.find((unconfiguredBed) => unconfiguredBed.bedId === bed.bedId);
    });
  }

  @Selector([BedsState, AuthState])
  static bedsSortedByLoggedInSleeper(state: BedsStateModel, authSate: AuthStateModel): Array<Bed> | null {
    return this.bedsWithSleepers(state).sort((x, y) => {
      const loggedInSleeperId = (FunctionsHelper.decrypt<UserInfo>(authSate.userInfo))?.sleeperId;
      return (Number(!(loggedInSleeperId === x.sleeperLeftId || loggedInSleeperId === x.sleeperRightId)) -
        Number(!(loggedInSleeperId === y.sleeperLeftId || loggedInSleeperId === y.sleeperRightId)));
    });
  }

  @Selector([BedsState])
  static bedsStatus(state: BedsStateModel): Array<BedStatus> {
    return state.bedsStatus;
  }

  @Selector([BedsState, SleepersState])
  static selectedSleeperBed(state: BedsStateModel, sleeperState: SleepersStateModel): Bed | null{
    return state.beds.find(bed => bed.bedId === sleeperState.selectedSleeper?.bedId) ?? null;
  }

  @Selector([BedsState, SleepersState])
  static selectedSleeperBedStatus(state: BedsStateModel, sleeperState: SleepersStateModel): BedStatus | null {
    return state.bedsStatus.find((it) => it.bedId === sleeperState.selectedSleeper?.bedId) ?? null;
  }

  @Selector([BedsState])
  static getBedById(state: BedsStateModel): (id: string) => Bed | null {
    return (id: string) => {
      const bed = state.beds.find(b => b.bedId === id);
      return bed ? bed : null;
    };
  }

  @Selector([BedsState])
  static getPrivacyStatusForBed(state: BedsStateModel): (bedId: string) => PrivacyMode | null {
    return (bedId: string) => {
      const privacyModeStatus = state.privacyModeStatus.find(privacyStatus => privacyStatus.bedId === bedId);
      return privacyModeStatus ? privacyModeStatus.pauseMode : null;
    };
  }

  @Selector([BedsState])
  static bedsForSetup(state: BedsStateModel): Bed[] | null {
    return state.beds.filter((bed) => bed.sleeperLeftId === '0' && bed.sleeperRightId === '0');
  }
}