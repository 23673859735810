import { Directive, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { MiddleColumnScrollService } from '@services/middle-scroll.service';

@Directive({
  selector: '[appScrollable]'
})
export class ScrollableDirective implements AfterViewInit {

  constructor(private el: ElementRef, private scrollService: MiddleColumnScrollService) {}

  ngAfterViewInit(): void {
    this.attachScrollListener();
  }

  private attachScrollListener(): void {
    const middleColumn = this.el.nativeElement;
    middleColumn.addEventListener('scroll', this.onScroll.bind(this));
  }

  @HostListener('window:scroll', []) 
  onScroll(): void {
    // Handle scroll event in the middle column here
    const middleColumn = this.el.nativeElement;
    const isScrolledToBottom = middleColumn.scrollHeight - middleColumn.scrollTop === middleColumn.clientHeight;

    this.scrollService.setIsScrolledToBottom(isScrolledToBottom);
  }
}