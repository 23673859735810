import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static matchControls(controlName1: string, controlName2: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password: string = control.get(controlName1)?.value; // get password from our newPassword form control
      const confirmPassword: string = control.get(controlName2)?.value; // get password from our confirmPassword form control
      // compare is the password math
      if (password !== confirmPassword) {
  
        // if they don't match, set an error in our confirmPassword form control
        control.get(controlName2)?.setErrors({ NoPasswordMatch: true });
      } else {
        control.get(controlName2)?.setErrors(null);
      }
      return control.get(controlName2)?.valid ? null: { NoPasswordMatch: true }
    }
  }
}
