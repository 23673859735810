import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { Navigate } from "@ngxs/router-plugin";
import { Store } from "@ngxs/store";
import { SplashScreenService } from "@services/splash-screen.service";
import { from, map } from "rxjs";

export const DetailsGuard: CanActivateFn = () => {
  const store = inject(Store);
  const splashScreenService = inject(SplashScreenService);

  return from(splashScreenService.splashScreenSubject).pipe(
    map((loading) => {
      if (loading) {
        store.dispatch(new Navigate(['pages/sleep']));
        return false;
      }
      return true;
    })
  );
};