<div siqGrid fx-direction="column" fx-layout-align="center center">
  <div siqGrid fx-direction="column" fx-layout-align="top center" class="particle-wave-background">
    <span [ngClass]="noDataIcon" class="mt-32"></span>
  </div>
  <div siqGrid fx-direction="column" fx-layout-align="center center" class="mt-neg-68">
    <span class="siq-text-900 fs-24 white text-center px-24">{{noDataText}}</span>
    <span class="siq-text-400 fs-16 white text-center mt-16 px-24" [ngClass]="subtextWidth" [class.w-50-p]="hasMultipleRows" [class.w-53-p]="hasMultipleRows && (breakpoints$ | async)?.isSmallerThan900" [class.w-80-p]="hasMultipleRows && (breakpoints$ | async)?.isMobile" [innerHTML]="noDataSubtext"></span>
  </div>
  <ng-container *ngIf="showAppDownload">
    <span class=" mobile-app-screens my-48"></span>
      <app-store-btns direction="row" [sourceScreen]="sourceScreen"/>
  </ng-container>
</div>

