import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SiqError } from '@models/app/helpers.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { LottieHelper } from '@shared/utils/helpers/lottie-helper';
import { ErrorCodeHelper, ServerErrors } from '@shared/utils/helpers/siq-errors.helper';
import { CloseModal } from '@store/app/app.actions';
import { SettingsSelectors } from '@store/settings/settings.selectors';
import { Observable, Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-baseline-bed-progress',
  templateUrl: './baseline-bed-progress.component.html',
})
export class BaselineBedProgressComponent implements OnInit, OnDestroy {

  baselineInProgress$: Observable<boolean>;
  loadingConfig = LottieHelper.createAnimationOptions('loader_normal', true);
  successConfig = LottieHelper.createAnimationOptions('success', false);

  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.baselineInProgress$ = this.store.select(SettingsSelectors.baselineInProgress);
    this.store.select(SettingsSelectors.error).pipe(
      filter((error): error is SiqError => !!error && error.code === ErrorCodeHelper.baselineError),
      takeUntil(this._unsubscribeAll)
    ).subscribe(() => {
      this.showBaselineError();
    });
  }

  back(): void {
    this.store.dispatch(new Navigate(['pages/smart-bed/details/baseline-bed']));
  }

  returnToSettings(): void {
    this.store.dispatch(new Navigate(['pages/smart-bed']));
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private showBaselineError(): void {
    const modal = FunctionsHelper.createPopup(this.dialog, ServerErrors.ApiErrors.requestFailed.title, ServerErrors.ApiErrors.requestFailed.text, 'Baseline bed', 'warning-icon', 'yellow', 'OK');
    modal.componentInstance.onClose.subscribe(() => {
      this.store.dispatch(new CloseModal(modal));
    });
    modal.componentInstance.onRightAction.subscribe(() => {
      this.store.dispatch(new CloseModal(modal));
    });
    modal.afterClosed().subscribe(() => {
      this.back();
      modal.componentInstance.onRightAction.unsubscribe();
      modal.componentInstance.onClose.unsubscribe();
    });
  }
}
