<div siqGrid 
  fx-direction="row" 
  fx-layout-align="space-between center" 
  class="menu-item background-transparent p-12 br-8" 
  [class.clicked-item]="item.clicked"
  [ngClass]="isHealthDisabled ? 'pointer-events-none' : 'cursor-pointer'"
  (click)="handleItemClick()">
  <div siqGrid fx-layout-align="space-between center" >
    <img class="menu-item-icon" [class.mr-16]="(sleeperInfo$ | async)?.isDigitalSleeper" [src]="iconPath" alt="icon"/>
    <div [class.text-content]="!(sleeperInfo$ | async)?.isDigitalSleeper" [ngClass]="isHealthDisabled ? 'primary-500' : item.clicked ? 'white' : 'primary-200'">
      <span siqGrid="block" class="siq-text-900 fs-18 text-capitalize">{{itemTitle}}</span>
      <span class="siq-text-900 fs-18">{{itemSubtitle}}</span>
    </div>
  </div>
  <div *ngIf="item.clicked" class="arrow-right mr-4"></div>
</div>
