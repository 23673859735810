<app-top-bar-navigation [showBackButton]="true" title="Baseline Bed" (backBtnClicked)="back()" />
<div class="px-24 py-32">
  <div siqGrid fx-direction="column" class="background-primary br-16 px-12 py-48">
    <div siqGrid fx-direction="column" fx-layout-align="center center">
      <span class="blue-bed-icon wh-48"></span>
      <span class="siq-text-900 fs-20 white mt-24">Baseline Bed</span>
      <span class="siq-text-400 fs-16 white text-center mt-12">Baselining reads the air pressure of your mattress,
        allowing your smart bed to better detect when you get in and out of bed and improve accuracy.</span>
    </div>

    <div siqGrid fx-direction="column" class="my-48">
      <span class="siq-text-900 fs-20 white">Before You Baseline Your Bed</span>
      <app-siq-list-items [items]="stepsBeforeBaseline" itemsLayoutAlign="center start" textMargin="ml-12" />
    </div>

    <div siqGrid fx-direction="row" fx-layout-align="center center">
      <app-siq-button text="Baseline My Bed" btnType="primary-btn" width="178px" (buttonClicked)="baselineBed()" />
    </div>
  </div>
</div>