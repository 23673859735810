import * as moment from "moment";

export interface SleepHealthParams {
  startDate: string;
  endDate: string;
}

export class SleepHealthEntity {
  days: Array<SleepHealthDay>;
  endDate: string;
  sleeperId: string;
  startDate: string;

  constructor(sleepHealth: SleepHealthEntity) {
    sleepHealth.days = sleepHealth.days.map((day) => new SleepHealthDay(day));
    Object.assign(this, sleepHealth);
  }
}

export interface SleepHealthDataProps {
  data?: SleepHealthData;
  sessionDate: string;
  sessionEndDate: string;
  sessionStartDate: string;
  editedSessionEnd?: string;
  editedSessionStart?: string;
  absenceCode: number;
}

export class SleepHealthDay {
  data?: SleepHealthData;
  sessionDate: string;
  sessionEndDate: string;
  sessionStartDate: string;
  editedSessionEnd?: string;
  editedSessionStart?: string;
  absenceCode: number;

  constructor(day: SleepHealthDataProps) {
    if (day.data) {
      day.data = new SleepHealthData(day.data);
    }

    Object.assign(this, day);
  }

  get isData(): boolean {
    return this.data ? this.absenceCode !== -2 : false;
  }

  get day(): string {
    return moment(this.sessionDate).format('ddd');
  }
}

export class SleepHealthData {
  duration: SleepHealthMetrics;
  efficiency: SleepHealthMetrics;
  timing: SleepHealthTiming | null;
  outOfBed?: number;
  restful?: number;
  restless?: number;
  sleepIQScore?: number;

  constructor(data: SleepHealthData) {
    data.duration = new SleepHealthMetrics(data.duration);
    data.efficiency = new SleepHealthMetrics(data.efficiency);
    data.timing = data.timing ? new SleepHealthTiming(data.timing) : null;
    Object.assign(this, data);

  }
}

export class SleepHealthMetrics {
  goal: number | null;
  goalMet: boolean;
  threshold: number | null;
  value: number | null;

  constructor(metrics: SleepHealthMetrics) {
    Object.assign(this, metrics);
  }
}

export class SleepHealthTiming {
  goal: SleepHealthTimingGoal | SleepHealthTimingGoalNoData | null;
  goalMet: boolean;
  value: SleepHealthTimingValue | null;

  constructor(timing: SleepHealthTiming) {
    timing.value = timing.value ? new SleepHealthTimingValue(timing.value) : null;
    if (timing.goal) {
      if ('reason' in timing.goal) {
        timing.goal = new SleepHealthTimingGoalNoData(timing.goal);
      } else {
        timing.goal = new SleepHealthTimingGoal(timing.goal);
      }
    }
    Object.assign(this, timing);
  }
}

export class SleepHealthTimingGoal {
  bedTime: SleepHealthTimingGoalData;
  wakeTime: SleepHealthTimingGoalData;

  constructor(goal: SleepHealthTimingGoal) {
    goal.bedTime = new SleepHealthTimingGoalData(goal.bedTime);
    goal.wakeTime = new SleepHealthTimingGoalData(goal.wakeTime);
    Object.assign(this, goal);
  }
}

export class SleepHealthTimingGoalNoData {
  bedTime?: SleepHealthTimingGoalData;
  wakeTime?: SleepHealthTimingGoalData;
  reason: SleepHealthTimingGoalReason;

  constructor(value: SleepHealthTimingGoalNoData) {
    this.reason = new SleepHealthTimingGoalReason(value.reason);
    Object.assign(this, value);
  }
}

export class SleepHealthTimingValue {
  bedTime: string;
  wakeTime: string;

  constructor(value: SleepHealthTimingValue) {
    Object.assign(this, value);
  }
}

class SleepHealthTimingGoalReason {
  code: number;
  text: string;

  constructor(goalReason: SleepHealthTimingGoalReason) {
    Object.assign(this, goalReason);
  }
}

export class SleepHealthTimingGoalData {
  endDate: string;
  startDate: string;

  constructor(goalData: SleepHealthTimingGoalData) {
    Object.assign(this, goalData);
  }
}
