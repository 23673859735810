import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SiqApiService } from '@services/siq-api.service';
import { Observable, catchError, map, throwError } from 'rxjs';
import { BamkeyResponse } from '../fuzion/client';
import { BKRequest } from '../fuzion/model';

export interface BamkeyBody {
  sourceApplication: string;
  key: string;
  args?: string
}

@Injectable({
  providedIn: 'root'
})
export class FuzionDeviceService extends SiqApiService {

  sendCommand(accountId: string, bedId: string, command: BKRequest): Observable<any> {
    const body = this.createBody(command.bamkey, command.arguments);
    return this.makeSiqRequest<BamkeyResponse>(`sn/v1/accounts/${accountId}/beds/${bedId}/bamkey`, 'siq', body, 'PUT', false).pipe(
      map((response : BamkeyResponse | HttpErrorResponse) => {
        if(response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  private createBody(key: string, args: Array<string | number | boolean>): BamkeyBody  {
    const body: BamkeyBody = {
      sourceApplication: 'Consumer Website',
      key
    };
    if (args.length > 0) {
      body.args = args.join(' ');
    }
    return body;
  }
}
