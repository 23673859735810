import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BannerData } from '@models/app/helpers.model';
import { SiqCommonModule } from '@shared/siq-common.module';

@Component({
  selector: 'app-siq-banner',
  templateUrl: './siq-banner.component.html',
  standalone: true,
  imports: [SiqCommonModule]
})
export class SiqBannerComponent {
  @Input() data: BannerData;
  @Input() width: string;
  @Output() closeBannerClicked = new EventEmitter(false);

  onCloseClick(): void {
    this.closeBannerClicked.emit(true);
  }
}
