<div siqGrid fx-direction="column"  class="switcher-container">
  <div 
    siqGrid 
    fx-direction="row" 
    fx-layout-align="space-between center" 
    class="background-primary p-12 br-8" 
    [ngClass]="isClickable ? 'cursor-pointer' : 'pointer-events-none'" 
    (click)="handleOpenSwitcher($event)">
    <span class="siq-text-900 fs-14 white">{{selectedElement.value}}</span>
    <div *ngIf="isClickable" class="arrow-icon-down ml-12"></div>
  </div>
  <div *ngIf="isOpened" class="switcher-menu background-primary mt-48 br-8">
    <div *ngFor="let element of switcherElements; let i = index">
      <div 
        siqGrid
        fx-direction="row" 
        fx-layout-align="space-between center" 
        class="switcher-element cursor-pointer p-12" 
        [class.border-bottom-400]="i < switcherElements.length - 1"
        (click)="selectItem(element)">
        <span class="siq-text-900 fs-14 white">{{element.value}}</span>
        <div *ngIf="selectedElement.key === element.key" class="check-icon"></div>
      </div>
    </div>
    <ng-container *ngIf="selectedElement.action">
      <div class="border-top-400 p-12 cursor-pointer" siqGrid fx-direction="row" fx-layout-align="space-between center" (click)="onAction(selectedElement.action)">
        <div siqGrid fx-direction="row" fx-layout-align="start center">
          <span [ngClass]="selectedElement.iconLeft"></span>
          <span class="siq-text-900 fs-14 ml-12 white">{{selectedElement.actionName}}</span>
        </div>
        <span [ngClass]="selectedElement.iconRight"></span>
      </div>
    </ng-container>
  </div>
</div>
