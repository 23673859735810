/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, HostListener, Renderer2 } from '@angular/core';
import { Regex } from '../helpers/regex.helper';

@Directive({
  selector: '[appInputFormat]'
})
export class InputFormatDirective {

  constructor(private renderer: Renderer2) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let inputValue = input.value.replace(/\D/g, '') // Remove non-numeric characters 
    inputValue = this.formatPhoneNumber(inputValue); // Add phone mask

    this.renderer.setProperty(input, 'value', inputValue);
  }

  private formatPhoneNumber(value: string): string {
    if(value.length > 10) value = value.slice(0, 10);
    if (!value) {
      return '';
    }
  
    const match = value.match(Regex.phoneNumberMask);
    if (match) {
      return match.slice(1).filter(Boolean).join('-');
    }
    return value;
  }

}