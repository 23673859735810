import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SiqNotification } from '@models/app/helpers.model';
import { Bed } from '@models/bed/bed.model';
import { Store } from '@ngxs/store';
import { PrivacyMode } from '@shared/utils/helpers/enum.helper';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { SetPrivacyModeBannerState } from '@store/app/app.actions';
import { AppSelectors } from '@store/app/app.selectors';
import { AuthSelectors } from '@store/auth/auth.selectors';
import { BedsSelectors } from '@store/beds/beds.selectors';
import { Observable, Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  @Input() isValidPasswordRecovery: boolean | null;
  siqNotification$: Observable<SiqNotification | null>;
  privacyBannerState: PrivacyMode;
  selectedSleeperBed: Bed;

  private _unsubscribeAll = new Subject<void>();

  constructor(private router: Router, private store: Store) { }

  ngOnInit(): void {
    this.siqNotification$ = this.store.select(AppSelectors.siqNotification);

    this.store.select(AppSelectors.showPrivacyModeBanner).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => this.privacyBannerState = res);

    this.store.select(BedsSelectors.selectedSleeperBed).pipe(
      takeUntil(this._unsubscribeAll),
      filter((bed): bed is Bed => !!bed)
    ).subscribe(bed => this.selectedSleeperBed = bed);

    // to set privacy mode banner on page refresh
    // case when user clicks later to hide the banner, then refresh - banner should be visible again
    this.store.dispatch(new SetPrivacyModeBannerState(this.privacyModeForSelectedSleeperBed));
  }

  get showLeftNavigationMenu(): boolean {
    return FunctionsHelper.checkRoutesToHideLayoutParts(this.store.selectSnapshot(AuthSelectors.cognitoAuth), this.router);
  }

  get showLayoutWithContentInTheMiddle(): boolean {
    return this.checkRouterUrl('auth/login') || this.checkRouterUrl('update-your-password') || this.checkRouterUrl('password-recovery');
  }

  get showFooter(): boolean {
    return this.checkRouterUrl('auth/login') || (this.checkRouterUrl('password-recovery') && this.isValidPasswordRecovery === true);
  }

  get showPrivacyModeBanner(): boolean {
    if(this.checkRouterUrl('pages/select-default-sleeper')) return false;
    return this.privacyBannerState === PrivacyMode.On && this.privacyModeForSelectedSleeperBed === PrivacyMode.On;
  }

  private get privacyModeForSelectedSleeperBed(): PrivacyMode {
    return this.store.selectSnapshot(BedsSelectors.getPrivacyStatusForBed)(this.selectedSleeperBed?.bedId) ?? PrivacyMode.Off;
  }

  private checkRouterUrl(url: string): boolean {
    return this.router.url.includes(url);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
