<app-top-bar-navigation [showBackButton]="true" title="WiFi Network" (backBtnClicked)="back()"/>
<div siqGrid fx-direction="column" fx-layout-align="center center" class="py-32 px-24">
  <ng-container *ngIf="wifiForm">
    <form siqGrid fx-direction="column" [formGroup]="wifiForm" class="w-100-p">
      <app-siq-input
         class="px-12"
         formControlName="networkName"  
         name="networkName"
         type="text" 
         placeholder="Type Network Name"
         label="Network name (SSID)"
         [isInvalid]="isInvalidField(wifiForm, 'networkName')"
         (controlFocus)="setControlFocusState($event)" />
      <span *ngIf="isInvalidField(wifiForm, 'networkName')" class="siq-text-400 fs-14 red mt-12 px-12">{{ formErrors['networkName'] }}</span>
      
      <app-siq-input
        formControlName="password" 
        name="password"
        class="mt-24 px-12" 
        type="password" 
        [isPasswordField]="true"
        placeholder="Enter Password" 
        label="Password"
        [isInvalid]="isInvalidField(wifiForm, 'password')"
        (controlFocus)="setControlFocusState($event)" />
      <span *ngIf="isInvalidField(wifiForm, 'password')" class="siq-text-400 fs-14 red mt-12 px-12">{{ formErrors['password'] }}</span>

      <app-siq-input
        formControlName="confirmPassword"
        name="confirmPassword"
        class="mt-24 px-12" 
        type="password" 
        [isPasswordField]="true"
        placeholder="Confirm password" 
        label="Re-enter password"
        [isInvalid]="isInvalidField(wifiForm, 'confirmPassword')"
        (controlFocus)="setControlFocusState($event)" />
      <span *ngIf="isInvalidField(wifiForm, 'confirmPassword')" class="siq-text-400 fs-14 red mt-12 px-12">{{ formErrors['passwordsDoNotMatch'] }}</span>
     </form>
  </ng-container>

  <div siqGrid fx-direction="row" fx-layout-align="start center" class="w-100-p mt-32 ml-12">
    <app-siq-slide-toggle (toggleState)="setShowAdvancedOptions($event)"/>
    <span class="siq-text-400 fs-6 white ml-12">Show advanced options</span>
  </div>

  <div *ngIf="showAdvancedOptions" siqGrid fx-direction="column" class="w-100-p mt-32">
    <app-siq-dropdown  
      [dropdownOptions]="networkTypesOptions" 
      [dropdownValues]="networkTypesValues" 
      paddingClass="p-24"
      [value]="0"
      dropdownInputLabel="What is your network type?"
      [dropdownInputValue]="networkType"
      (selectedValue)="handleDropdownSelect($event, wifiPropertiesEnum.NetworkType)" />
      
    <ng-container *ngIf="isWPA2EnterpriseSelected">
      <app-siq-dropdown 
        class="mt-32"
        [dropdownOptions]="authenticationProtocolOptions" 
        [dropdownValues]="authenticationProtocolValues" 
        paddingClass="p-24"
        [value]="0"
        dropdownInputLabel="Authentication Protocol"
        [dropdownInputValue]="authenticationProtocol"
        (selectedValue)="handleDropdownSelect($event, wifiPropertiesEnum.AuthenticationProtocol)" />

      <form siqGrid fx-direction="column" [formGroup]="usernameForm" class="w-100-p">
        <app-siq-input
          class="px-12 mt-32"
          formControlName="username"  
          name="username"
          type="text"
          label="Username"
          [isInvalid]="isInvalidField(usernameForm, 'username')"
          (controlFocus)="setControlFocusState($event)" />
        <span *ngIf="isInvalidField(usernameForm, 'username')" class="siq-text-400 fs-14 red mt-12 px-12">{{ formErrors['username'] }}</span>
      </form>
    </ng-container>
  </div>

  <div siqGrid fx-direction="row" fx-layout-align="end center" fx-layout-align-sm="center center" class="w-100-p mt-48 px-12">
    <app-siq-button
      text="Done"
      btnType="primary-btn"
      width="208px"
      [isDisabled]="isDisabled"
      (buttonClicked)="addNewNetwork()" />
  </div>
</div>