import { WhyIsThisImportantEntity } from "@models/app/why-is-this-important.model";

export class OpenWhyIsThisContent {
  static readonly type = '[WhyIsThis] OpenWhyIsThisContent'
  constructor(public whyIsThisContent: WhyIsThisImportantEntity) {}
}

export class SetRedirectToTab {
  static readonly type = '[WhyIsThis] SetRedirectToTab'
  constructor(public tabId: number) {}
}
export class ResetWhyIsThisState {
  static readonly type = '[WhyIsThis] ResetWhyIsThisState'
}
