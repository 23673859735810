import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SwitchModel } from '@models/app/switch.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { BreakpointState, ResizeService } from '@services/resize.service';
import { SiqIconPosition } from '@shared/utils/helpers/enum.helper';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-top-bar-navigation',
  templateUrl: './top-bar-navigation.component.html',
  styleUrls: ['./top-bar-navigation.component.scss']
})
export class TopBarNavigationComponent implements OnInit {
  @Input() showBackButton = false;
  @Input() title = '';
  @Input() subtitle: string | undefined = ''; // subtitle could be session date or some text
  @Input() showSleepHistory = false;
  @Input() switchElements: SwitchModel<string> | null;
  @Input() isHealthOnboarding = false;
  @Input() isOnboarding = false;
  @Input() showSkipLabel = false;
  @Output() backBtnClicked = new EventEmitter<boolean>();
  @Output() skipLabelClicked = new EventEmitter<boolean>();
  iconPosition = SiqIconPosition.left;
  breakpoints$: Observable<BreakpointState>;

  constructor(private resizeService: ResizeService, private store: Store, private router: Router) {}

  ngOnInit(): void {
    this.breakpoints$ = this.resizeService.isMobile;
  }

  handleBackClick(): void {
    this.backBtnClicked.emit(true);
  }

  openSleepHistory(): void {
    if(this.router.url.includes('onboarding')) 
      this.store.dispatch(new Navigate(['pages/sleep/onboarding/history']));
    else
      this.store.dispatch(new Navigate(['pages/sleep/details/history']));
  }

  handleSkipBtn(): void {
    this.skipLabelClicked.emit(true);
  }
}
