import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SetSleepersPassword, SetSleepersPasswordResponse, SleeperRegistrationState, ValidateEmailToken } from '@models/app/helpers.model';
import { CognitoLoginData, CognitoLoginModel, CognitoQuery, CognitoRefreshToken, CognitoRefreshTokenModel, UserInfo } from '@models/auth/cognito.model';
import { LoginModel, LoginResponse } from '@models/auth/login.model';
import { RegisterTokenModel, ValidateOrderModel } from '@models/auth/register.model';
import { ForgotPasswordBody, ResetPasswordData, ResetPasswordResponse } from '@models/sleeper/sleeper.model';
import { OrderNumberValidation } from '@shared/utils/helpers/enum.helper';
import { Observable, catchError, map, throwError } from 'rxjs';
import { EnvironmentsService } from './environments.service';
import { MixpanelService } from './mixpanel.service';
import { SiqApiService } from './siq-api.service';
import { SplashScreenService } from './splash-screen.service';
import { SleeperInfo } from '@models/auth/sleeper-type.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends SiqApiService {

  constructor(
    httpClient: HttpClient,
    environmentService: EnvironmentsService,
    mixpanelService: MixpanelService,
    dialog: MatDialog,
    splashScreenService: SplashScreenService) {
    super(httpClient, environmentService, mixpanelService, dialog, splashScreenService);
  }

  cognitoLogin(data: CognitoLoginModel): Observable<CognitoQuery<CognitoLoginData>> {
    return this.makeCognitoRequest<CognitoQuery<CognitoLoginData>>('POST', { ...data, Email: data.Email.toLowerCase() }, true).pipe(
      map((response: CognitoQuery<CognitoLoginData> | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  nativeLogin(data: LoginModel): Observable<LoginResponse> {
    return this.makeSiqRequest<LoginResponse>('login', 'nsiq', data, 'PUT', true).pipe(
      map((response: LoginResponse | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  getSleeperDetails(username: string): Observable<SleeperInfo> {
    return this.makePlansRequest<SleeperInfo>(`sn/v1/users/${username}/details`, {}, 'GET').pipe(
      map((response: SleeperInfo | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  getUserInfo(): Observable<UserInfo> {
    return this.makeSiqRequest<UserInfo>('user/jwt', 'siq', {}, 'GET', false).pipe(
      map((response: UserInfo | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  cognitoRefreshToken(data: CognitoRefreshTokenModel): Observable<CognitoQuery<CognitoRefreshToken>> {
    return this.makeCognitoRequest<CognitoQuery<CognitoRefreshToken>>('PUT', data, true).pipe(
      map((response: CognitoQuery<CognitoRefreshToken> | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  logout(): Observable<object> {
    return this.makeSiqRequest<object>('logout', 'siq', {}, 'PUT', true).pipe(
      map((response: object | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  forgotPassword(forgotPasswordBody: ForgotPasswordBody): Observable<object> {
    return this.makeSiqRequest<object>('login/forgotPassword', 'siq', forgotPasswordBody, 'POST', true).pipe(
      map((response: object | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  setPasswordAndActivate(data: SetSleepersPassword | ResetPasswordData): Observable<SetSleepersPasswordResponse | ResetPasswordResponse> {
    return this.makeSiqRequest<SetSleepersPasswordResponse | ResetPasswordResponse>('login/setPasswordAndActivate', 'siq', data, 'POST', true).pipe(
      map((response: SetSleepersPasswordResponse | ResetPasswordResponse | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  validateOrderNumber(data: ValidateOrderModel, validationType: OrderNumberValidation): Observable<RegisterTokenModel> {
    const url = validationType === OrderNumberValidation.orderAndEmail ? `email/${encodeURIComponent(data['email'])}` : `phone/${data['phoneNumber']}`;
    return this.makeSiqRequest<RegisterTokenModel>(`sn/v2/order/${data.orderNumber}/${url}`, 'siq', {}, 'GET', true).pipe(
      map((response: RegisterTokenModel | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  setRegistrationState(state: number): Observable<SleeperRegistrationState> {
    return this.makeSiqRequest<SleeperRegistrationState>(`registration/${state}`, 'siq', {}, 'POST', false).pipe(
      map((response: SleeperRegistrationState | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  validateEmailToken(token: string): Observable<object | ValidateEmailToken> {
    return this.makeSiqRequest<object | ValidateEmailToken>(`emailToken/${token}`, 'siq', {}, 'GET', true).pipe(
      map((response: object | ValidateEmailToken | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  validateToken(token: string): Observable<object> {
    return this.makeSiqRequest<object>(`token/${token}`, 'siq', {}, 'GET', true).pipe(
      map((response: object | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }
}
