import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import { Component, ComponentSize, ComponentSizeCustomerFriendly, ProductClassificationExcluded, Status } from "./component.model";
import { PrivacyMode } from "@shared/utils/helpers/enum.helper";

export class BedEntity {
  beds!: Array<Bed>;

  constructor(listOfBeds: BedEntity) {
    this.beds = listOfBeds.beds.map((bed: Bed) => new Bed(bed));
  }
}

export class UpdateBedProperty {
  [key: string]: string | number | boolean | null;

  constructor(objectAction: UpdateBedProperty) {
    Object.assign(this, objectAction);
  }
}

export interface BedPrivacyMode {
  accountId: string;
  bed: string;
  pauseMode: PrivacyMode;
}

export class Bed {
  bedId!: string;
  accountId!: string;
  name!: string;
  dualSleep!: boolean;
  sleeperRightId!: string;
  sleeperLeftId!: string;
  size!: string;
  generation!: string;
  generationToExchange!: string;
  isKidsBed!: boolean;
  hasSnore!: boolean;
  fccId!: string;
  macAddress!: string;
  zipcode!: string;
  timezone!: string;
  registrationDate!: string;
  returnRequestStatus!: number;
  status!: number;
  components!: Array<Component>;

  constructor(bed?: Bed) {
    if (bed) {
      bed.components = bed.components.map((it: Component) => new Component(it));
      Object.assign(this, bed);
    }
  }

  get isThreeSixty(): boolean {
    return this.generation === "360";
  }

  get isLegacy(): boolean {
    return this.generation === "legacy";
  }

  get isFuzion(): boolean {
    return this.generation === "fuzion";
  }

  get is1080(): boolean {
    const componentIndex = this.components.findIndex((c) => c.type !== 'Temperature Strip' && (c.status === Status.Installed || c.status === Status.InstalledByExchange));
    const subGeneration = componentIndex > -1 ? this.components[componentIndex].subgeneration : this.components[0].subgeneration;
    return subGeneration === 'S360';
  }

  get isOnline(): boolean {
    return this.status === 1;
  }

  get mattressComponents(): Array<Component> {
    return this.components.filter(c => c.type.toLowerCase().indexOf('bed') >= 0);
  }

  get mainMattress(): Component | null {
    const pendingExchange = this.mattressComponents.find(c => c.status === Status.PendingExchange);
    const installed = this.mattressComponents.find(c => c.status === Status.Installed);
    const notInstalled = this.mattressComponents.find(c => c.status === Status.NotInstalled);
    const installedByExchange = this.mattressComponents.find(c => c.status === Status.InstalledByExchange);
    const removed = this.mattressComponents.find(c => c.status === Status.Removed);
    const removedByExchange = this.mattressComponents.find(c => c.status === Status.RemovedByExchange);
    return installed || installedByExchange || notInstalled || pendingExchange || removed || removedByExchange || null;
  }

  get mainBase(): Component | null {
    return this.components.find(
      (c) =>
        c.type.toLowerCase().indexOf("base") >= 0 && this.getComponentStatus(c)
    ) ?? null;
  }

  get pumpComponent(): Component | null {
    return this.components.find(
      (c) =>
        c.type.toLowerCase() === 'pump' && this.getComponentStatus(c)
    ) ?? null;
  }

  get isT360(): boolean {
    const pumpComponent = this.pumpComponent;
    return (pumpComponent && pumpComponent.subgeneration === 'T360') ?? false;
  }

  // legacy: bed model
  // 360 beds: bed model, base model
  // fuzion: Climate360 size (size taken from the bed API response, NOT from the components)
  // fuzion 1080(s360) beds: bed model, bed size, base, Sleep Number Smart bed
  get customerFriendlyName(): string {
    const bedInformation = [];
    if (this.isLegacy || this.isThreeSixty || this.is1080) { // since 180 is sub generation of fuzion
      if (this.mainMattress) {
        bedInformation.push(this.mainMattress.model);
      }
      if (this.isThreeSixty) {
        if (this.mainBase) {
          if (this.mainBase.model.includes('FlexFit')) {
            bedInformation.push(`${this.mainBase.model.replace('FlexFit', 'FlexFit<sup>TM</sup>')}`);
          } else {
            bedInformation.push(`${this.mainBase.model}`);
          }
        }
      } else if (this.is1080) {
        bedInformation.push(this.bedNameBasedOnMattressSize);
        bedInformation.push(this.productClassificationName);
        bedInformation.push('Sleep Number<sup>®</sup> Smart Bed');
      }
    } else {
      if(this.isT360) {
        bedInformation.push('ClimateCool<sup>TM</sup>');
        bedInformation.push(this.bedNameBasedOnMattressSize);
        bedInformation.push(this.productClassificationName);
      } else {
        bedInformation.push(`Climate360<sup>®</sup> ${FunctionsHelper.transformBedSizeString(this.size)}`);
      }
    }
    return FunctionsHelper.transformArrayToString(bedInformation, this.isT360);
  }

  get orderNumber(): string | undefined {
    return this.mainMattress?.reference.slice(0, 11);
  }

  get purchaseDate(): string | undefined {
    const mattress = this.components.find(
      (component) =>
        // Component check if it is not pending return
        component.type.toLowerCase().includes("bed") &&
        !component.hasPendingReturn
    );
    return mattress?.purchaseDate;
  }

  get hasSleepersOnBed(): boolean {
    return this.sleeperLeftId !== "0" || this.sleeperRightId !== "0";
  }

  get bedNameBasedOnMattressSize(): string {
    if (this.mainMattress) {
      switch (this.mainMattress.size) {
        case ComponentSize.KingFlex:
          return ComponentSizeCustomerFriendly.KingFlex;
        case ComponentSize.SplitKing:
          return ComponentSizeCustomerFriendly.SplitKing;
        case ComponentSize.TwinXl:
          return ComponentSizeCustomerFriendly.TwinXl;
        default:
          return `${this.mainMattress.size?.toLowerCase().charAt(0).toUpperCase()}${this.mainMattress.size?.slice(1).toLowerCase()}`;
      }
    }
    return '';
  }

  get productClassificationName(): string {
    if (this.mainBase) {
      const classification = this.mainBase?.productclassification?.toLowerCase();
      if (classification &&
        ProductClassificationExcluded.FlexFitC.toLowerCase() !== classification && ProductClassificationExcluded.Undefined.toLowerCase() !== classification) {
        return `FlexFit<sup>TM</sup> ${classification[classification.length - 1]}`;
      }
    }
    return '';
  }

  getComponentStatus(component: Component): boolean {
    return component.status === Status.Installed ||
      component.status === Status.NotInstalled ||
      component.status === Status.InstalledByExchange;
  }
}