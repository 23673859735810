import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SingleMultipleQuestions } from '@models/app/helpers.model';
import { QuestionProperties, RadioButtonQuestion, WellnessProfile } from '@models/sleeper/wellness-profile.model';

export interface SingleMultipleQuestionForm {
  [key: string]: SingleMultipleQuestions
}
@Component({
  selector: 'app-single-multiple-questions',
  templateUrl: './single-multiple-questions.component.html',
})
export class SingleMultipleQuestionsComponent implements OnInit {
  @Input() question: QuestionProperties;
  @Input() wellnessProfile: WellnessProfile;
  @Input() singleMultipleQuestions: SingleMultipleQuestions;
  @Output() updatedAnswers = new EventEmitter<Array<number | object>>;
  singleMultipleQuestionForm: FormGroup<SingleMultipleQuestionForm>;
  noneOfTheAboveAnswer = -1;

  ngOnInit(): void {
    this.singleMultipleQuestionForm = new FormGroup<SingleMultipleQuestionForm>({[this.question.name]: this.singleMultipleQuestions});
  }

  isCheckboxChecked(id: number): boolean {
    const value = this.formValue<Array<number>>();
    return value ? value.includes(id) : false;
  }

  isRadioButtonSelected(control: string): number | null {
    const value =  this.formValue<Array<object>>();
    const selectedValue = value.find((val) => Object.keys(val)[0] === control);
    return selectedValue ? selectedValue[control] : null;
  }

  handleCheckboxSelect(isSelected: boolean, selectedItem: number): void {
    let value = this.formValue<Array<number>>();
    if (isSelected) {
      if (selectedItem === this.noneOfTheAboveAnswer) {
        // deselect all the other answers and select only "None of the above"
        value = [this.noneOfTheAboveAnswer];
      } else {
        // if there is "None of the above" answer remove it, since user is selecting something that is not "None of the above"
        if (value.includes(this.noneOfTheAboveAnswer)) {
          value = value.filter((it) => it !== this.noneOfTheAboveAnswer);
        }
        value.push(selectedItem);
      }
    } else {
      // remove deselected answer
      value = value.filter((it) => it !== selectedItem);
    }
    
    this.setFormValue(value);
    this.updatedAnswers.emit(this.singleMultipleQuestionForm.getRawValue() as unknown as Array<number>);
  }

  handleRadioButtonSelect(selectedItem: number, id: string): void {
    let value = this.formValue<Array<object>>();
    value = value.map((item) => {
      if (Object.keys(item)[0] === id) {
        return new RadioButtonQuestion({ [id]: selectedItem });
      }
      return item;
    });
    this.setFormValue(value);
    this.updatedAnswers.emit(this.singleMultipleQuestionForm.getRawValue() as unknown as Array<object>);
  }

  private formValue<T>(): T {
    const value = this.singleMultipleQuestionForm.controls[this.question.name].value as T;
    return JSON.parse(JSON.stringify(value)); // create a deep copy
  }

  private setFormValue(value: Array<object> | Array<number>): void {
    this.singleMultipleQuestionForm.controls[this.question.name].setValue(value);
  }
}
