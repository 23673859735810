import { SetupBedData, SetupSleeperData, SiqError } from "@models/app/helpers.model";
import { BiqUser } from "@models/sleeper/biq-user.model";
export interface SetupStateModel {
  selectedBedId: string | null;
  selectedSleeperId: string | null;
  selectedSide: number | null;
  dualBed: boolean | null;
  bedData: SetupBedData | null;
  sleeperData: SetupSleeperData | null;
  shouldAddSecondSleeper: number | null;
  isSettingFirstSleeper: boolean;
  biqUser: BiqUser | null
  loading: boolean;
  error: SiqError | null;
}

export const defaultSetupState = {
  selectedBedId: null,
  selectedSleeperId: null,
  selectedSide: null,
  dualBed: false,
  bedData: null,
  sleeperData: null,
  shouldAddSecondSleeper: null,
  isSettingFirstSleeper: false,
  biqUser: null,
  loading: false,
  error: null
};