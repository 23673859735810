import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BannerData, SetupSleeperIntroduction } from '@models/app/helpers.model';
import { BiqUser } from '@models/sleeper/biq-user.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { ResizeService } from '@services/resize.service';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { RegistrationStringResource } from '@shared/utils/helpers/registration.helper';
import { SetAddSecondSleeper, SetupAddSleeper } from '@store/setup/setup.actions';
import { SetupSelectors } from '@store/setup/setup.selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-setup-partner',
  templateUrl: './setup-partner.component.html'
})
export class SetupPartnerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('container') container: ElementRef = new ElementRef('');
  bannerWidth = '';
  showBanner = false;
  isMobile = false;
  sleeperIntroduction: SetupSleeperIntroduction;
  bannerData: BannerData;
  biqUser: BiqUser | null;

  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store, private resizeService: ResizeService, private mixpanelService: MixpanelService) { }

  ngOnInit(): void {
    FunctionsHelper.scrollToTop();
    this.biqUser = this.store.selectSnapshot(SetupSelectors.biqUser);

    this.store.select(SetupSelectors.isBiqUser(true)).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(isBiqUser => {
      this.sleeperIntroduction = RegistrationStringResource.setupSleeperIntroduction(isBiqUser, true);
      this.bannerData = RegistrationStringResource.bannerData(isBiqUser);
    });
  }

  ngAfterViewInit(): void {
    this.resizeService.isMobile.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((res) => {
      this.isMobile = res.isMobile;
      this.bannerWidth = `${this.container.nativeElement.offsetWidth}px`;
    });
  }

  skip(): void {
    this.mixpanelService.trackProceedSkipAction('set up sleep partner', FunctionsHelper.getProceedSkipActionFlow());
    this.store.dispatch(new SetAddSecondSleeper(null));
    FunctionsHelper.redirectToTheNextStepInRegistration(this.store);
  }

  changeBannerState(bannerState: boolean): void {
    this.showBanner = bannerState;
    if (this.showBanner)
      this.mixpanelService.trackBannerDisplay('set up sleep partner', this.bannerData);
  }

  next(): void {
    this.mixpanelService.trackProceedNextAction('set up sleep partner');
    this.store.dispatch(new SetAddSecondSleeper(null));
    if (this.biqUser?.sleepPartner?.email) {
      this.store.dispatch(new SetupAddSleeper());
      FunctionsHelper.redirectToTheNextStepInRegistration(this.store);
    } else {
      this.store.dispatch(new Navigate(['pages/setup/create-login']));
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
