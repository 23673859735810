import { OnChanges, OnDestroy } from '@angular/core';
/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Actions, ofActionCompleted, ofActionErrored } from '@ngxs/store';
import { SiqButtonColors } from '@shared/utils/helpers/enum.helper';
import { ChangeServer, CloseModal, GeneratePdf, OpenLinkInNewTab } from '@store/app/app.actions';
import { CognitoLogin, GetUserInformation, NativeLogin, SetRegistrationState } from '@store/auth/auth.actions';
import { UpdateBed } from '@store/beds/beds.actions';
import { ForgotPassword } from '@store/forgot-password/forgot-password.actions';
import { SetPasswordAndActivate, ValidateOrderNumber } from '@store/register/register.actions';
import { AddWifiNetwork } from '@store/settings/settings.actions';
import { SetupAddSleeper } from '@store/setup/setup.actions';
import { CancelInviteSleeperEmail, SetSleeperWellnessProfile, UpdateNativePassword, UpdateSleeper, UpdateSleeperEmail, UpdateSleeperPassword } from '@store/sleepers/sleepers.actions';
import { AcceptTaco } from '@store/taco/taco.actions';
import { Subject } from 'rxjs';

const ErroredActions = [CognitoLogin, NativeLogin, UpdateSleeperEmail, UpdateSleeperPassword, ForgotPassword, UpdateSleeper, UpdateBed,ValidateOrderNumber, SetPasswordAndActivate, AcceptTaco, SetSleeperWellnessProfile, SetRegistrationState, UpdateNativePassword, SetupAddSleeper, GetUserInformation, CancelInviteSleeperEmail];
const CompletedActions = [OpenLinkInNewTab, ChangeServer, CloseModal, AddWifiNetwork, UpdateBed, GeneratePdf, GetUserInformation];
@Directive({
  selector: '[buttonState]'
})
export class ButtonStateDirective implements OnInit, OnChanges, OnDestroy {
  @Input('buttonState') isDisabled = false;
  @Input('isPressed') isPressed = false;
  @Input('isListItemBtn') isListItemBtn = false;
  @Input('btnType') btnType = '';
  @Input('isActiveServer') isActiveServer: boolean;
  pressedBtnColor = '';
  nonPressedBtnColor = '';

  private _unsubscribeAll = new Subject<void>();

  constructor(private el: ElementRef, private renderer: Renderer2, private actions$: Actions) {
    // to enable multiple clicks on a button after some specific actions
    this.actions$.pipe(
      ofActionCompleted(...CompletedActions)
    ).subscribe(() => {
      this.isPressed = false;
      this.isDisabled = false;
      this.setPressedState();
    });

    // to allow the button to be clicked again when some action is dispatched with error
    this.actions$.pipe(
      ofActionErrored(...ErroredActions)
    ).subscribe(() => {
      this.isPressed = false;
      this.isDisabled = false;
      this.setPressedState();
    });

    // change isPressed state
    this.renderer.listen(this.el.nativeElement, 'click', () => {
      this.isPressed = true;
      this.nonPressedBtnColor = this.btnType === 'primary-btn' ? SiqButtonColors.primaryBtnNonPressed : SiqButtonColors.nonPrimaryBtnNonPressed;
      this.setPressedState();
    });
  }

  ngOnInit(): void {
    this.defineButtonColors();
    this.setDisabledState();
    this.setPressedState();
    // to set the color of change server button in case the server is active
    this.setButtonColor(this.isActiveServer ? SiqButtonColors.changeServerBtnPressed : '');
  }

  ngOnChanges(): void {
    this.setDisabledState();
    this.setPressedState();
  }

  defineButtonColors(): void {
    this.pressedBtnColor = this.btnType === 'primary-btn' ? SiqButtonColors.primaryBtnPressed : SiqButtonColors.nonPrimaryBtnPressed;
  }

  setButtonColor(color: string): void {
    this.renderer.setStyle(this.el.nativeElement, 'background-color', color);
  }

  setButtonOpacity(opacityValue: number): void {
    this.renderer.setStyle(this.el.nativeElement, 'opacity', opacityValue);
    if (this.isDisabled) {
      this.avoidPointerEvents();
    }
  }

  setPressedState(): void {
    if (this.isDisabled) {
      this.avoidPointerEvents();
    } else {
      if (this.isPressed) {
        // if the button is list item type we don't need to change the color when it is pressed
        if (!this.isListItemBtn)
          this.setButtonColor(this.pressedBtnColor);
        // disable clicking on the button once when it is clicked
        this.avoidPointerEvents();
      } else {
        // for list item buttons color will never be changed, so we don't need to set it to non pressed color
        if (!this.isListItemBtn)
          this.setButtonColor(this.nonPressedBtnColor);
        this.enablePointerEvents();
      }
    }
  }

  setDisabledState(): void {
    if (this.isDisabled) {
      this.setButtonOpacity(0.5);
    } else {
      this.setButtonOpacity(1);
    }
  }

  avoidPointerEvents(): void {
    this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none');
  }

  enablePointerEvents(): void {
    this.renderer.removeStyle(this.el.nativeElement, 'pointer-events');
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}