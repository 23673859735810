<app-top-bar-navigation [showBackButton]="true" title="Baseline Bed" (backBtnClicked)="back()" />
<div class="px-24 py-32">
  <div siqGrid fx-direction="column" fx-layout-align="center center">
    <ng-container *ngIf="baselineInProgress$ | async else success">
      <span class="siq-text-900 fs-24 white text-center">Connecting to bed...</span>
      <ng-lottie [options]="loadingConfig" width="100px" height="200px">
      </ng-lottie>
    </ng-container>
    <ng-template #success>
      <span class="siq-text-900 fs-24 white text-center">Baseline Successful!</span>
      <ng-lottie [options]="successConfig" width="212px" height="212px">
      </ng-lottie>
    </ng-template>
    <span class="dual-bed-icon-rotated mt-neg-48"></span>
  </div>
  <div siqGrid fx-direction="row" fx-layout-align="end center" class="mt-48">
    <app-siq-button text="Return to Settings" btnType="primary-btn" width="178px" (buttonClicked)="returnToSettings()" />
  </div>
</div>