import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BKCommand, BKRequest } from './model';

export interface IDeviceCommunication {
  sendCommand(accountId: string, bedId: string, bamkey: BKRequest): Observable<BamkeyResponse>;
}

export interface BamkeyResponse {
  cdcResponse: string;
}

interface BamkeyStatusCode {
  [key: number]: string;
}

const BamkeyStatusCodes: BamkeyStatusCode = {
  0: 'Success',
  4: 'Empty response',
  5: 'Unknown Response',
  16: 'Bamkey Protocol Fail Empty Response',
  17: 'Bamkey Protocol Fail Unknown Response',
  18: 'Bamkey Protocol Fail Unknown Status',
  19: 'Bamkey Protocol Fail Invalid Bamkey',
  20: 'Bamkey Protocol Fail Timeout',
  21: 'Bamkey Protocol Fail Keystore Fail',
  32: 'Format Fail Input Overflow',
  33: 'Format Fail Encoding Error',
  48: 'Parse Fail Element Count Overflow',
  49: 'Parse Fail Too Few Return Values',
  50: 'Parse Fail Too Many Return Values',
  64: 'Cast Fail',
  65: 'Cast Fail Invalid Integer',
  66: 'Cast Fail Cannot Represent Integer',
  67: 'Cast Fail Invalid Float',
  68: 'Cast Fail Cannot Represent Float',
  69: 'Cast Fail String Overflow'
};

const BamkeyHelpers = {
  passStatus: 'PASS',
  failStatus: 'FAIL'
};

export class BamkeyError {
  status: number;
  description: string;
  constructor(status: number, description: string) {
    this.status = status;
    this.description = description;
  }
}

export class Bamkey {

  public static extractValue<T>(response: string, index: number, numberOfArguments: number, isJson: boolean): T {
    if (index > numberOfArguments) {
      throw new BamkeyError(64, BamkeyStatusCodes[64]);
    }
    try {
      if (isJson) {
        return JSON.parse(response as string);
      } else {
        return response as T;
      }
    } catch (error) {
      throw new BamkeyError(5, BamkeyStatusCodes[5]);
    }
  }

  public version(): string {
    return 'v.5.0.1';
  }
}

export class BamkeyClient {
  communication: IDeviceCommunication;

  constructor(communication: IDeviceCommunication) {
    this.communication = communication;
  }

  executeCommand<T>(accountId: string, bedId: string, command: BKCommand): Observable<T> {
    return this.communication.sendCommand(accountId, bedId, command.request).pipe(
      map((response) => {
        const parsedResponse = this.parseBamkeyProtocol(response.cdcResponse);
        if (parsedResponse && command.response.numberOfResponseArguments > 0) {
          // @ts-expect-error if number of response argument is bigger than 0 there will be a function
          command.response.setResponseValue(parsedResponse);
        }
        return command.response as T;
      }),
      catchError((error) => throwError(() => error))
    );
  }

  parseBamkeyProtocol(response: string): string {
    const responseArray = [response.substring(0, response.indexOf(':')), response.substring(response.indexOf(':') + 1, response.length)];
    const bamkeyStatus = responseArray[0];
    const bamkeyResponse = responseArray[1];
    if (bamkeyStatus === BamkeyHelpers.passStatus) {
      return bamkeyResponse;
    } else if (bamkeyStatus === BamkeyHelpers.failStatus) {
      const parsedBakmeyResponse = parseInt(bamkeyResponse[0], 10);
      if (!parsedBakmeyResponse) {
        throw new BamkeyError(4, BamkeyStatusCodes[4]);
      } else {
        if (Object.prototype.hasOwnProperty.call(BamkeyStatusCodes, parsedBakmeyResponse)) {
          throw new BamkeyError(parsedBakmeyResponse, BamkeyStatusCodes[parsedBakmeyResponse]);
        } else {
          throw new BamkeyError(5, BamkeyStatusCodes[5]);
        }
      }
    } else {
      throw new BamkeyError(5, BamkeyStatusCodes[5]);
    }
  }
}
