import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IdealTimes, IdealTimesValues } from '@models/app/ideal-times.model';
import { HowWeCalculate, WayToImprove } from '@models/health/sleep-health-trends.model';
import { SleepHealthDay } from '@models/health/sleep-health.model';
import { NoDataSession, SessionModel } from '@models/sessions/session.model';
import { Sleeper, UpdateSleeperProperty } from '@models/sleeper/sleeper.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { EditSleepGoalModalComponent } from '@shared/components/edit-sleep-goal-modal/edit-sleep-goal-modal.component';
import { HealthStringResource } from '@shared/utils/helpers/health.helper';
import { SleepStringResource } from '@shared/utils/helpers/sleep.helper';
import { CloseModal } from '@store/app/app.actions';
import { HealthSelectors } from '@store/health/health.selectors';
import { SessionsSelectors } from '@store/sessions/sessions.selectors';
import { UpdateSleeper } from '@store/sleepers/sleepers.actions';
import { SleepersSelectors } from '@store/sleepers/sleepers.selectors';
import { Observable, Subject, filter, takeUntil } from 'rxjs';

const HEALTH_DETAILS = ['Duration', 'Efficiency', 'Timing'];
const HISTORY_DETAILS = ['Day', 'Week', 'Month', 'Year'];

@Component({
  selector: 'app-siq-drawer',
  templateUrl: './siq-drawer.component.html',
  styleUrls: ['./siq-drawer.component.scss']
})
export class SiqDrawerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() drawerType: string; // whyIsImportant/waysToImprove/howWeCalculate
  @Input() trendsType: string; // Duration/Efficiency/Timing // Heart Rate/Heart Rate Variability/Breath Rate // Day/Week/Month/Year
  @Output() isUpdatedSuccessfully = new EventEmitter<boolean>();
  @Output() isDrawerOpened = new EventEmitter<boolean>();
  latestSleepNumberSetting$: Observable<number | undefined | null>;
  showIdealSchedule$: Observable<boolean>;
  isExpanded = false;
  isButtonClicked = false;
  waysToImproveContent: WayToImprove[];
  selectedSleeper: Sleeper;
  sleepHealthTrends: Array<SleepHealthDay>;
  idealTimes: IdealTimes | null = null;
  noIdealScheduleMessage = HealthStringResource.getNoIdealScheduleMessage;
  howWeCalculateText: HowWeCalculate;
  private _unsubscribeAll = new Subject<void>();

  constructor(private dialog: MatDialog, private store: Store, private mixpanelService: MixpanelService) { }

  get isSleepHistory(): boolean {
    return HISTORY_DETAILS.includes(this.trendsType);
  }

  get isSleepHealth(): boolean {
    return HEALTH_DETAILS.includes(this.trendsType);
  }

  ngOnInit(): void {
    this.waysToImproveContent = HealthStringResource.getWaysToImproveContent(this.trendsType);
    this.howWeCalculateText = HealthStringResource.getHowWeCalculateText(this.trendsType);
    this.latestSleepNumberSetting$ = this.store.select(SessionsSelectors.latestSleepNumberSetting);
    this.showIdealSchedule$ = this.store.select(HealthSelectors.showIdealSchedule);
    this.store.select(SleepersSelectors.selectedSleeper).pipe(
      takeUntil(this._unsubscribeAll),
      filter((sleeper): sleeper is Sleeper => !!sleeper),
    ).subscribe(sleeper => this.selectedSleeper = sleeper);

    this.store.select(HealthSelectors.sleepHealthRolling7Days).pipe(
      takeUntil(this._unsubscribeAll),
      filter((session): session is Array<SleepHealthDay> => !!session),
    ).subscribe(trends => this.sleepHealthTrends = trends);

    this.store.select(HealthSelectors.idealScheduleData).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((idealTimes) => {
      if (idealTimes && idealTimes.bedTime && idealTimes.wakeTime) {
        const bedTime = new IdealTimesValues(idealTimes.bedTime.startDate, idealTimes.bedTime.endDate);
        const wakeTime = new IdealTimesValues(idealTimes.wakeTime.startDate, idealTimes.wakeTime.endDate);
        this.idealTimes = new IdealTimes({ bedTime, wakeTime });
      }
    });
  }

  // To close drawer when we switch between tabs on biosignals details and sleep history
  ngOnChanges(): void {
    this.isExpanded = false;
  }

  get isBiosignalsDetails(): boolean {
    return this.trendsType.includes('Rate');
  }

  get timeConverterFormat(): string {
    return this.checkTrendsType('Duration') ? 'minutes' : 'seconds';
  }

  get drawerTitle(): string {
    return !this.isSleepHistory
      ? this.isBiosignalsDetails ? SleepStringResource.getDrawerTitle(this.trendsType) : HealthStringResource.getDrawerTitle(this.drawerType, this.trendsType)
      : SleepStringResource.getHowHistoryIsCalculatedTitle();
  }

  get whyIsImportantText(): string {
    return !this.isSleepHistory
      ? this.isBiosignalsDetails ? SleepStringResource.getWhyIsImportantText(this.trendsType) : HealthStringResource.getWhyIsImportantText(this.trendsType)
      : SleepStringResource.getHowHistoryIsCalculatedText();
  }

  get arrowIcon(): string {
    return `assets/icons/${this.isExpanded ? 'up-arrow' : 'down-arrow'}.svg`;
  }

  get trendsData(): number | null | undefined {
    return this.checkTrendsType('Duration')
      ? this.selectedSleeper.sleepGoal
      : this.sleepHealthTrends.at(-1)?.data?.efficiency.goal;
  }

  showSleepNumberSetting(index: number): boolean {
    return this.checkTrendsType('Efficiency') && index === 0;
  }

  getWayToImproveIcon(iconTitle: string): string {
    return `assets/icons/${iconTitle}.svg`;
  }

  changeDrawerState(): void {
    if (!this.isButtonClicked) {
      if(!this.isExpanded) this.isDrawerOpened.emit(true);
      this.isExpanded = !this.isExpanded;

      if (this.isSleepHealth) {
        this.mixpanelService.trackQuickTipOpen(this.trendsType.toLowerCase(), this.drawerTitle, this.isExpanded ? 'Expand' : 'Collapse');
      }
    }
  }

  checkTrendsType(type: string): boolean {
    return this.trendsType === type;
  }

  editSleepGoal(): void {
    this.isButtonClicked = true;
    this.mixpanelService.trackSleepGoalEdit();
    const modal = this.dialog.open(EditSleepGoalModalComponent, {
      width: '375px',
      height: 'initial',
      disableClose: false,
      data: this.selectedSleeper?.sleepGoal
    });
    modal.componentInstance.onSave.subscribe((value: number) => {
      this.mixpanelService.trackSleepGoalSet();
      this.store.dispatch(new UpdateSleeper(this.selectedSleeper.sleeperId, new UpdateSleeperProperty({ sleepGoal: value }))).subscribe({
        next: () => {
          this.isUpdatedSuccessfully.emit(true);
        }
      });
      this.store.dispatch(new CloseModal(modal));
      this.isButtonClicked = false;
    });
    modal.afterClosed().subscribe(() => {
      modal.componentInstance.onSave.unsubscribe();
      this.store.dispatch(new CloseModal(modal));
      this.isButtonClicked = false;
    });
  }

  seeCircadianRhythm(): void {
    this.isButtonClicked = true;
    this.mixpanelService.trackOpenCircadianDetails();
    const selectedSession = this.store.selectSnapshot(SessionsSelectors.selectedSession);
    const circadianRhythm = this.store.selectSnapshot(SessionsSelectors.circadianRhythm);
    if (!circadianRhythm  || (selectedSession as SessionModel).isHidden || selectedSession instanceof NoDataSession) {
      this.store.dispatch(new Navigate(['pages/sleep']))
    } else {
      this.store.dispatch(new Navigate(['pages/sleep/details/circadian-rhythm']));
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
