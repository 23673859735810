<ng-container *ngFor="let question of questions; index as i">
  <div [class.mt-24]="i > 0" [formGroup]="wellnessQuestionsForm">
    <ng-container *ngIf="question.isDropdownQuestion else inputQuestion">
      <app-siq-dropdown 
        [dropdownOptions]="question.dropdownOptions" 
        [dropdownValues]="question.dropdownValues"
        [value]="question.questionValue"
        [dropdownInputValue]="getSleeperProfileAnswer(question)" 
        paddingClass="p-24"
        [dropdownInputPlaceholder]="notAnsweredQuestion"
        [dropdownInputLabel]="question.text" 
        [description]="question.infoText"
        (selectedValue)="handleDropdownSelect($event, question.property)" />
    </ng-container>
    <ng-template #inputQuestion>
      <div class="px-12">
        <app-siq-input
          formControlName="firstName"
          placeholder="Name" 
          name="firstName"
          [maxLength]="30"
          type="text" 
          [isInvalid]="hasErrors"
          label="What’s your name?"
          (controlFocus)="setControlFocusState($event)"/>
        <ng-container *ngIf="hasErrors">
          <span class="siq-text-400 fs-14 red mt-12"> First name is required. </span>
        </ng-container>
      </div>
    </ng-template>
  </div>
</ng-container>
