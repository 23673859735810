import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupData } from '@models/app/modal-data.model';
import { MixpanelService } from '@services/mixpanel.service';
import { SiqIconPosition } from '@shared/utils/helpers/enum.helper';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html'
})
export class PopupComponent implements OnInit {
  onLeftAction = new EventEmitter();
  onRightAction = new EventEmitter();
  onClose = new EventEmitter();
  iconPositionFallback = SiqIconPosition.left;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PopupData,
    public dialogRef: MatDialogRef<PopupComponent>,
    private mixpanelService: MixpanelService) { }


  ngOnInit(): void {
    this.mixpanelService.trackPopupOpen(this.data.text, this.data.screen);
  }

  get btnContainerWidth(): string {
    return this.data.hideLeftButton ? 'w-75-p': 'w-50-p';
  }

  onCloseClick(): void {
    this.onClose.emit();
    this.dialogRef.close();
  }

  onLeftActionClick(): void {
    this.onLeftAction.emit();
  }

  onRightActionClick(): void {
    this.onRightAction.emit();
  }
}
