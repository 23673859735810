<div class="learn-more-section p-12 background-primary br-16">
  <app-siq-tabs
    height="60px"
    [tabs]="tabs"
    (tabSelect)="selectTab($event)" />
  <app-learn-more-content [learnMoreInfo]="sleepStudyInfo[selectedTab]" [listItemsTitle]="listItemsTitle" [listItems]="sleepStudyLearnMoreListItems[selectedTab]" /> 
  <p *ngIf="showPrivacyLink" class="mt-24 mb-0 px-12 siq-text-400 fs-16 white">
    Check out our <a class="no-outline rain-blue" href="{{ privacyLink }}" target="_blank">privacy policy</a> to learn more.
  </p>
</div>
