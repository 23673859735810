import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, forwardRef } from '@angular/core';
import { CheckboxControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-siq-checkbox',
  templateUrl: './siq-checkbox.component.html',
  styleUrls: ['./siq-checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => SiqCheckboxComponent)
  }]
})
export class SiqCheckboxComponent extends CheckboxControlValueAccessor {
  @Input() checked: boolean;
  @Input() tabIndex: number;
  @Input() ariaLabel: string;
  @Output() checkboxState = new EventEmitter<boolean>();
  touched = false;
  disabled = false;

  constructor(_renderer: Renderer2, _elementRef: ElementRef) {
    super(_renderer, _elementRef);
  }

  override onChange = (checked: boolean): void => {
    this.checked = checked;
    this.checkboxState.emit(this.checked);
  };

  override onTouched = (): void => {
    this.touched = !this.touched;
  };

  override writeValue(checked: boolean): void {
    this.checked = checked;
  }

  override registerOnChange(onChange: (_: boolean) => void): void {
    this.onChange = onChange;
  }

  override registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  override setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}