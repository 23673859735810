import { ApplicationConfig, HrvConfig, IntegrationEndpoint } from "@models/app/application-config.model";
import { EndpointService } from "@shared/utils/helpers/enum.helper";

export class AppMockData {
  static get appConfig(): ApplicationConfig {
    return new ApplicationConfig({
      hrv: this.hrv,
      integrationEndpoints: this.integrationEndpoints,
      chargebeeSiteName: 'chargebee-site-name'
    });
  }

  static get hrv(): HrvConfig {
    return new HrvConfig({
      formula: '-0.002*x*x + 0.97*x - 19',
      max: 250,
      min: 20
    });
  }

  static get integrationEndpoints(): Array<IntegrationEndpoint> {
    return [
      {
        endpoint: "innercircle.com",
        service: EndpointService.InnerCircle
      },
      {
        endpoint: "digital.com",
        service: EndpointService.Digital
      },
      {
        endpoint: "respiratory-health.com",
        service: EndpointService.RespiratoryHealth
      },
      {
        endpoint: "breathiq.com",
        service: EndpointService.BreathIq
      }
    ];
  }

}