import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { Store } from "@ngxs/store";
import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import { AuthSelectors } from "@store/auth/auth.selectors";
import { GetInnerCircleLicenseState } from "@store/taco/taco.actions";
import { catchError, map, of } from "rxjs";

export const TacoGuard: CanActivateFn = () => {
  const store = inject(Store);
  const userInfo = store.selectSnapshot(AuthSelectors.userInfo);
  const taco = localStorage.getItem('taco');
  if (taco) {
    const tacoCodes = JSON.parse(taco);
    if (!tacoCodes.licenseAccepted || !tacoCodes.privacyAccepted) {
      return store.dispatch(new GetInnerCircleLicenseState()).pipe(
        map(() => true),
        catchError(() => {
          return of(true);
        })
      );
    }
  }

  if (userInfo) {
    FunctionsHelper.handleUserNavigation(store, userInfo);
  }
  return of(false);
};