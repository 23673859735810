export class LoadPrivacyPolicy {
  static readonly type = '[Taco] LoadPrivacyPolicy';
}

export class SetTacoState {
  static readonly type = '[Taco] SetTacoState';
  constructor(public code: number, public accepted: boolean) {}
}
export class GetInnerCircleLicenseState {
  static readonly type = '[Taco] GetInnerCircleLicenseState';
}

export class AcceptTaco {
  static readonly type = '[Taco] AcceptTaco';
  constructor(public tacoToAccept: number, public shouldAcceptInnerCircle: boolean) {}
}

export class AcceptInnerCircle {
  static readonly type = '[Taco] AcceptInnerCircle';
}

export class ResetTacoState {
  static readonly type = '[Taco] ResetTacoState';
}
