import { AfterViewInit, Component, ElementRef, OnDestroy, Renderer2, ViewChild, OnInit } from '@angular/core';
import { Bed } from '@models/bed/bed.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MixpanelService } from '@services/mixpanel.service';
import { BreakpointState, ResizeService } from '@services/resize.service';
import { SiqCommonModule } from '@shared/siq-common.module';
import { BedSettingsResources } from '@shared/utils/helpers/bed-settings.helper';
import { PrivacyMode } from '@shared/utils/helpers/enum.helper';
import { SetPrivacyModeBannerState, SetPrivacyModeDismissedWithLater } from '@store/app/app.actions';
import { BedsSelectors } from '@store/beds/beds.selectors';
import { SelectBed } from '@store/settings/settings.actions';
import { Observable, Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-siq-privacy-banner',
  templateUrl: './siq-privacy-banner.component.html',
  standalone: true,
  imports: [SiqCommonModule]
})
export class SiqPrivacyBannerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('privacyBanner') privacyBanner: ElementRef;
  selectedSleeperBed: Bed;
  breakpoints$: Observable<BreakpointState>;
  privacyModeBannerData = BedSettingsResources.privacyModeBannerData;

  private _unsubscribeAll = new Subject<void>();
  
  constructor(
    private renderer2: Renderer2, 
    private resizeService: ResizeService, 
    private store: Store,
    private mixpanelService: MixpanelService) { }

  ngOnInit(): void {
    this.mixpanelService.trackPrivacyModeBannerDisplay();
    this.breakpoints$ = this.resizeService.isMobile;
    this.store.select(BedsSelectors.selectedSleeperBed).pipe(
      takeUntil(this._unsubscribeAll),
      filter((bed): bed is Bed => !!bed)
    ).subscribe(bed => this.selectedSleeperBed = bed);

    this.store.dispatch(new SetPrivacyModeDismissedWithLater(false));
  }

  ngAfterViewInit(): void {
    this.resizeService.isMobile.pipe((
      takeUntil(this._unsubscribeAll)
    )).subscribe(() => {
      const middleColumnWidth = document.getElementsByClassName('middle-column')[0].clientWidth;
      const notification = this.privacyBanner.nativeElement as HTMLElement;
      this.renderer2.setStyle(notification, 'width', `${middleColumnWidth - 72}px`);
    });
  }

  redirectToPrivacyMode(): void {
    this.store.dispatch(new SelectBed(this.selectedSleeperBed));
    this.store.dispatch(new Navigate(['pages/smart-bed/details/privacy-mode'], undefined, { state: { sourceScreen: 'banner' } }));
    this.mixpanelService.trackPrivacyModeBannerAction('Turn off');
  }

  hidePrivacyBanner(): void {
    this.store.dispatch(new SetPrivacyModeBannerState(PrivacyMode.Off));
    this.store.dispatch(new SetPrivacyModeDismissedWithLater(true));
    this.mixpanelService.trackPrivacyModeBannerAction('Later');
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
