<div siqGrid fx-direction="column" fx-layout-align="space-between stretch" class="p-12 pb-24">

  <div siqGrid fx-layout-align="end center" class="cursor-pointer" (click)="onCloseClick()">
    <span class="close-gray-icon"></span>
  </div>

  <div siqGrid fx-direction="row" fx-layout-align="center center">
    <div siqGrid fx-layout-align="center center" class="circle br-40" [ngClass]="data.type">
      <span [ngClass]="data.icon"></span>
    </div>
  </div>

  <div class="siq-text-900 fs-20 primary text-center mt-24" [innerHTML]="data.title"></div>
  <div siqGrid fx-direction="row" fx-layout-align="center center">
    <div class="siq-text-400 fs-14 primary-300 text-center mt-12 w-100-p" [innerHTML]="data.text"></div>
  </div>

  <div siqGrid fx-direction="row" fx-layout-align="center center" class="w-100-p mt-24">

      <ng-container *ngIf="!data.hideLeftButton">
        <div class="w-50-p text-center">
          <app-siq-button 
            [text]="data.leftBtnTxt" 
            btnType="tertiary-btn text-color-secondary mr-12" 
            width="100%"
            [iconPosition]="data.leftBtnIcon?.position ?? iconPositionFallback"
            [icon]="data.leftBtnIcon?.icon ?? ''"
            (buttonClicked)="onLeftActionClick()" />
        </div>
      </ng-container>

      <ng-container *ngIf="!data.hideRightButton">
        <div [ngClass]="btnContainerWidth" class="text-center">
          <app-siq-button
            [text]="data.rightBtnTxt" 
            btnType="primary-btn" 
            width="100%"
            [iconPosition]="data.rightBtnIcon?.position ?? iconPositionFallback"
            [icon]="data.rightBtnIcon?.icon ?? ''"
            (buttonClicked)="onRightActionClick()" />
        </div>
      </ng-container>

    </div>
</div>