import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApplicationConfig, IntegrationEndpoint } from "@models/app/application-config.model";
import { SetupBedData } from "@models/app/helpers.model";
import { AccountEntity } from "@models/auth/account.model";
import { Bed } from "@models/bed/bed.model";
import { Sleeper } from "@models/sleeper/sleeper.model";
import { Navigate } from "@ngxs/router-plugin";
import { Action, NgxsOnInit, State, StateContext, Store } from "@ngxs/store";
import { EnvironmentsService } from "@services/environments.service";
import { MixpanelService } from "@services/mixpanel.service";
import { SiqApiService } from "@services/siq-api.service";
import { SleeperService } from "@services/sleeper.service";
import { AppConfig } from "@shared/utils/helpers/app-config.helper";
import { BedSide, EndpointService } from "@shared/utils/helpers/enum.helper";
import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import { SiqPopupHelper } from "@shared/utils/helpers/siq-popup.helper";
import { BedsSelectors } from "@store/beds/beds.selectors";
import { SelectSetupBed, SetAddSecondSleeper, SetBedData } from "@store/setup/setup.actions";
import { SleepersSelectors } from "@store/sleepers/sleepers.selectors";
import moment from "moment";
import { Observable, tap, throwError } from "rxjs";
import { AddNewSleeper, ChangeServer, CloseModal, GeneratePdf, HasECIMIdentity, LoadAccountDetails, LoadAppConfig, OpenLinkInNewTab, ResetAppState, SetPrivacyModeBannerState, SetPrivacyModeDismissedWithLater, SetSelectDefaultSleeperShown, SetSiqNotification, SetupSleepersFrom } from "./app.actions";
import { AppStateModel, defaultAppState } from "./app.model";
import { AuthSelectors } from "@store/auth/auth.selectors";

@State<AppStateModel>({
  name: 'app',
  defaults: defaultAppState
})
@Injectable()
export class AppState implements NgxsOnInit {

  constructor(
    private environmentsService: EnvironmentsService,
    private sleeperService: SleeperService,
    private siqApiService: SiqApiService,
    private mixpanelService: MixpanelService,
    private siqPopupHelper: SiqPopupHelper,
    private store: Store) { }

  // Clear or reset hasECIMIdentity after rehydration, since it's per requirement
  // on refresh for state not to be persisted
  ngxsOnInit(ctx: StateContext<AppStateModel>): void {
    ctx.patchState({ hasECIMIdentity: false });
  }

  @Action(SetSelectDefaultSleeperShown)
  setSelectDefaultSleeperShown(ctx: StateContext<AppStateModel>, action: SetSelectDefaultSleeperShown): void {
    ctx.patchState({ selectDefaultSleeperShown: action.payload });
  }

  @Action(ChangeServer)
  changeServer(ctx: StateContext<AppStateModel>, action: ChangeServer): void {
    this.environmentsService.changeActiveServer(action.serverName);
    this.store.dispatch(new LoadAppConfig());
    ctx.dispatch(new Navigate(['auth/login']));
  }

  @Action(LoadAccountDetails)
  loadAccountDetails(ctx: StateContext<AppStateModel>): Observable<AccountEntity> {
    return this.sleeperService.getAccountDetails().pipe(tap({
      next: (accountDetails: AccountEntity) => {
        const loggedInSleeper = this.store.selectSnapshot(SleepersSelectors.loggedInSleeper);
        const selectedSleeper = this.store.selectSnapshot(SleepersSelectors.selectedSleeper);
        const loggedInSleeperBed = this.store.selectSnapshot(BedsSelectors.getBedById)(loggedInSleeper.bedId);
        const username = this.store.selectSnapshot(AuthSelectors.username);
        this.mixpanelService.setProfileProperties(loggedInSleeper, selectedSleeper, loggedInSleeperBed, accountDetails, username);
        if (selectedSleeper) {
          const selectedSleeperBed = this.store.selectSnapshot(BedsSelectors.getBedById)(selectedSleeper.bedId);
          if (selectedSleeperBed) {
            this.mixpanelService.setSuperProperties(loggedInSleeper, selectedSleeper, selectedSleeperBed, username);
          }
        }
      },
      error: err => this.handleError(ctx, err)
    }
    ));
  }

  @Action(LoadAppConfig)
  loadAppConfig(ctx: StateContext<AppStateModel>): Observable<ApplicationConfig> {
    return this.siqApiService.getConfig().pipe(tap({
      next: (config: ApplicationConfig) => {
        const plansEndpoint = this.getServiceEndpoint(config.integrationEndpoints, EndpointService.Digital);
        const biqEndpoint = this.getServiceEndpoint(config.integrationEndpoints, EndpointService.BreathIq);
        this.environmentsService.setDigitalEndpoints(plansEndpoint, biqEndpoint);
        ctx.patchState({ appConfig: new ApplicationConfig(config) });
      },
      error: err => {
        ctx.patchState({
          error: FunctionsHelper.createSiqError(err.error.Error.Code, err.error.Error.Message),
          appConfig: new ApplicationConfig(AppConfig.configFallback),
        });
      }
    }
    ));
  }

  @Action(ResetAppState)
  resetState(ctx: StateContext<AppStateModel>): void {
    ctx.setState({ ...defaultAppState });
  }

  @Action(OpenLinkInNewTab)
  openLinkInNewTab(ctx: StateContext<AppStateModel>, action: OpenLinkInNewTab): void {
    window.open(action.link, '_blank');
  }

  @Action(CloseModal)
  closeModal(ctx: StateContext<AppStateModel>, action: CloseModal): void {
    action.modal.close();
  }

  @Action(SetSiqNotification)
  setSiqNotification(ctx: StateContext<AppStateModel>, action: SetSiqNotification): void {
    ctx.patchState({ notification: action.payload });
  }

  @Action(SetupSleepersFrom)
  setupSleepersFrom(ctx: StateContext<AppStateModel>, action: SetupSleepersFrom): void {
    // when navigating from the app to add new sleepers
    // action gets url where user should be redirected once setup is done
    if (action.setupSleepersFrom) {
      localStorage.setItem('setupSleepersFrom', action.setupSleepersFrom);
    } else {
      localStorage.removeItem('setupSleepersFrom');
    }
  }

  @Action(AddNewSleeper)
  addNewSleeper(ctx: StateContext<AppStateModel>, action: AddNewSleeper): void {
    const rightSleeper = this.getSleeper(action.bed.sleeperRightId);
    const leftSleeper = this.getSleeper(action.bed.sleeperLeftId);
    this.store.dispatch(new SelectSetupBed(action.bed));
    this.store.dispatch(new SetAddSecondSleeper(0));
    this.store.dispatch(new SetBedData(this.createBedData(action.bed, rightSleeper, leftSleeper)));
    this.store.dispatch(new SetupSleepersFrom(action.addSleepersFrom));
    this.store.dispatch(new Navigate(['pages/setup/setup-partner']));
  }

  @Action(SetPrivacyModeBannerState)
  setPrivacyModeBannerState(ctx: StateContext<AppStateModel>, action: SetPrivacyModeBannerState): void {
    ctx.patchState({ showPrivacyModeBanner: action.bannerState });
  }

  @Action(SetPrivacyModeDismissedWithLater)
  setPrivacyModeDismissedWithLater(ctx: StateContext<AppStateModel>, action: SetPrivacyModeDismissedWithLater): void {
    ctx.patchState({ isPrivacyBannerDismissedWithLater: action.isLaterClicked });
  }

  @Action(GeneratePdf)
  generatePdf(ctx: StateContext<AppStateModel>, action: GeneratePdf): void {
    const monthName = moment(action.date).format('MMMM');
    const newWindow = window.open();
    if (newWindow) {
      newWindow.document.write(
        `<title>Wellness report ${monthName}</title>`
      );
      newWindow.document.write(JSON.parse(action.report));
      newWindow.document.close();
      newWindow.focus();
      newWindow.print();
    }
  }

  @Action(HasECIMIdentity)
  hasECIMIdentity(ctx: StateContext<AppStateModel>, action: HasECIMIdentity): void {
    ctx.patchState({ hasECIMIdentity: action.hasECIMIdentity });
  }

  private handleError(ctx: StateContext<AppStateModel>, err: HttpErrorResponse): Observable<HttpErrorResponse> {
    if (err.status >= 400 && err.status < 500 && err.status !== 401) {
      this.siqPopupHelper.showAlert('App');
    }
    ctx.patchState({ error: FunctionsHelper.createSiqError(err.error.Error.Code, err.error.Error.Message) });
    return throwError(() => err);
  }

  private createBedData(bed: Bed, rightSleeper: Sleeper | null, leftSleeper: Sleeper | null): SetupBedData {
    return new SetupBedData({
      name: bed.name,
      timezone: bed.timezone,
      side: this.getSleeperSide(rightSleeper, leftSleeper),
    });
  }

  private getSleeperSide(rightSleeper: Sleeper | null, leftSleeper: Sleeper | null): number {
    if (rightSleeper) {
      return rightSleeper.side ? BedSide.Left : BedSide.Right;
    } else {
      if (leftSleeper) {
        return leftSleeper.side ? BedSide.Left : BedSide.Right;
      }
    }
    return BedSide.Left;
  }

  private getSleeper(sleeperId: string): Sleeper | null {
    const sleepers = this.store.selectSnapshot(SleepersSelectors.sleepers);
    return sleepers?.find((sleeper) => sleeper.sleeperId === sleeperId) ?? null;
  }

  private getServiceEndpoint(integrationEndpoints: Array<IntegrationEndpoint>, endpoint: string): string {
    return integrationEndpoints.find((config) => config.service === endpoint)?.endpoint ?? '';
  }
}
