import { Component, Input, OnInit } from '@angular/core';
import { BreakpointState, ResizeService } from '@services/resize.service';
import { Observable } from 'rxjs';
import { ButtonsModule } from '@shared/buttons.module';
import { SiqCommonModule } from '@shared/siq-common.module';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
  standalone: true,
  imports: [SiqCommonModule, ButtonsModule]
})
export class NoDataComponent implements OnInit {

  @Input() noDataIcon: string;
  @Input() noDataText: string;
  @Input() noDataSubtext: string;
  @Input() showAppDownload: boolean;
  @Input() hasMultipleRows: boolean;
  @Input() subtextWidth: string;
  @Input() sourceScreen: string;

  breakpoints$: Observable<BreakpointState>;

  constructor(private resizeService: ResizeService) {}

  ngOnInit(): void {
    this.breakpoints$ = this.resizeService.isMobile;
  }
}
