import { HrvConfig } from "@models/app/application-config.model";
import { SiqNotification } from "@models/app/helpers.model";
import { Selector } from "@ngxs/store";
import { PrivacyMode } from "@shared/utils/helpers/enum.helper";
import { AppStateModel } from "./app.model";
import { AppState } from "./app.state";

export class AppSelectors {

  @Selector([AppState])
  static hrvConfig(state: AppStateModel): HrvConfig {
    return state.appConfig.hrv
  }

  @Selector([AppState])
  static selectDefaultSleeperShown(state: AppStateModel): boolean {
    return state.selectDefaultSleeperShown;
  }

  @Selector([AppState])
  static siqNotification(state: AppStateModel): SiqNotification | null {
    return state.notification;
  }

  @Selector([AppState])
  static showPrivacyModeBanner(state: AppStateModel): PrivacyMode {
    return state.showPrivacyModeBanner ?? PrivacyMode.Off;
  }

  @Selector([AppState])
  static isPrivacyBannerDismissedWithLater(state: AppStateModel): boolean {
    return state.isPrivacyBannerDismissedWithLater;
  }

  @Selector([AppState])
  static hasECIMIdentity(state: AppStateModel): boolean {
    return state.hasECIMIdentity;
  }
}