<div class="py-48 w-100-p">
  <form siqGrid fx-direction="column" [formGroup]="form">
    <app-siq-input
      [formControl]="form.controls['firstName']"
      name="firstName"
      class="mt-24 px-12" 
      type="text"
      placeholder="Name"
      [maxLength]="30"
      [label]="getQuestion('firstName')"
      [isInvalid]="hasErrors('firstName')"
      (controlFocus)="setControlFocusState($event)"/>
    <span *ngIf="isInvalidField('firstName')" class="siq-text-400 fs-14 red mt-12 px-12">First name is required.</span>

    <ng-container *ngIf="selectedBed.isKidsBed">
      <app-siq-dropdown
        class="mt-24"
        [dropdownOptions]="setupData.birthMonthOptions" 
        [dropdownValues]="setupData.birthMonthValues" 
        paddingClass="p-24"
        [value]="getDropdownValue('birthMonth')"
        [dropdownInputLabel]="getQuestion('birthMonth')"
        [dropdownInputValue]="getValue('birthMonth')"
        dropdownInputPlaceholder="Please select..."
        (selectedValue)="handleDropdownSelect($event, 'birthMonth')" />
    </ng-container>
    
    <app-siq-dropdown
      class="mt-24"
      [dropdownOptions]="setupData.birthYearOptions" 
      [dropdownValues]="setupData.birthYearValues" 
      paddingClass="p-24"
      [value]="getDropdownValue('birthYear')"
      [dropdownInputLabel]="getQuestion('birthYear')"
      [dropdownInputValue]="getValue('birthYear')"
      dropdownInputPlaceholder="Please select..."
      (selectedValue)="handleDropdownSelect($event, 'birthYear')" />

    <app-siq-dropdown  
      class="mt-24"
      [dropdownOptions]="setupData.heightOptions" 
      [dropdownValues]="setupData.heightValues" 
      paddingClass="p-24"
      [value]="getDropdownValue('height')"
      [dropdownInputLabel]="getQuestion('height')"
      [dropdownInputValue]="getValue('height')" 
      dropdownInputPlaceholder="Please select..."
      (selectedValue)="handleDropdownSelect($event, 'height')" />

    <app-siq-dropdown  
      class="mt-24"
      [dropdownOptions]="setupData.weightOptions" 
      [dropdownValues]="setupData.weightValues" 
      paddingClass="p-24"
      [value]="getDropdownValue('weight')"
      [dropdownInputLabel]="getQuestion('weight')"
      [dropdownInputValue]="getValue('weight')" 
      dropdownInputPlaceholder="Please select..."
      (selectedValue)="handleDropdownSelect($event, 'weight')" />

    <app-siq-dropdown  
      class="mt-24"
      [dropdownOptions]="setupData.genderOptions" 
      [dropdownValues]="setupData.genderValues" 
      paddingClass="p-24"
      [value]="getDropdownValue('gender')"
      [dropdownInputLabel]="getQuestion('gender')"
      description="(Optional)"
      [dropdownInputValue]="getValue('gender')" 
      dropdownInputPlaceholder="Please select..."
      (selectedValue)="handleDropdownSelect($event, 'gender')" />

    <app-siq-dropdown  
      class="mt-24"
      [dropdownOptions]="setupData.sleepGoalOptions" 
      [dropdownValues]="setupData.sleepGoalValues" 
      paddingClass="p-24"
      [value]="getDropdownValue('sleepGoal')"
      [dropdownInputLabel]="getQuestion('sleepGoal')"
      description="A good place to start is how long you typically sleep each night."
      [dropdownInputValue]="getValue('sleepGoal')" 
      dropdownInputPlaceholder="Please select..."
      (selectedValue)="handleDropdownSelect($event, 'sleepGoal')" />
  </form>

  <div siqGrid fx-direction="row" fx-layout-align="end center" class="px-12 mt-48">
    <app-siq-button
      text="Next"
      btnType="primary-btn"
      width="208px"
      [isDisabled]="!form.valid"
      (buttonClicked)="next()" />
  </div>
</div>
