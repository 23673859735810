import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentsService } from './environments.service';
import { MixpanelService } from './mixpanel.service';
import { MatDialog } from '@angular/material/dialog';
import { SplashScreenService } from './splash-screen.service';
import { SiqApiService } from './siq-api.service';
import { Observable, catchError, map, throwError } from 'rxjs';
import { DeletedNetworkResponse, WifiNetworkModel, WifiNetworkResponse, WifiNetworks, WifiStatus } from '@models/wifi-network/wifi-network.model';

@Injectable({
  providedIn: 'root'
})
export class WifiService extends SiqApiService {

  constructor(
    httpClient: HttpClient,
    environmentService: EnvironmentsService,
    mixpanelService: MixpanelService,
    dialog: MatDialog,
    splashScreenService: SplashScreenService) {
    super(httpClient, environmentService, mixpanelService, dialog, splashScreenService);
  }

  getWifiNetworks(): Observable<WifiNetworks> {
    return this.makeSiqRequest<WifiNetworks>('wifi', 'siq',  {}, 'GET', false).pipe(
      map((response : WifiNetworks | HttpErrorResponse) => {
        if(response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    )
  }

  addWifiNetwork(data: WifiNetworkModel): Observable<WifiNetworkResponse> {
    return this.makeSiqRequest<WifiNetworkResponse>('wifi', 'siq',  data, 'POST', false).pipe(
      map((response : WifiNetworkResponse | HttpErrorResponse) => {
        if(response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    )
  }

  deleteWifiNetwork(wifiId: string): Observable<DeletedNetworkResponse> {
    return this.makeSiqRequest<DeletedNetworkResponse>(`wifi/${wifiId}`, 'siq',  {}, 'DELETE', false).pipe(
      map((response : DeletedNetworkResponse | HttpErrorResponse) => {
        if(response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    )
  }

  getWifiStatus(bedId: string): Observable<WifiStatus> {
    return this.makeSiqRequest<WifiStatus>(`bed/${bedId}/wifiStatus`, 'siq',  {}, 'GET', false).pipe(
      map((response : WifiStatus | HttpErrorResponse) => {
        if(response instanceof HttpErrorResponse) {
          throw response;
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    )
  }
}
