import { ApplicationConfig } from "@models/app/application-config.model";
import { AppMockData } from "@shared/test/app.mock";

export class AppConfig {

  static get configFallback(): ApplicationConfig {
    return new ApplicationConfig({
      hrv: AppMockData.hrv,
      integrationEndpoints: [],
      chargebeeSiteName: 'chargebee-site-name'
    });
  }
}