import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { Navigate } from "@ngxs/router-plugin";
import { Store } from "@ngxs/store";
import { of } from "rxjs";

export const SleepResearchGuard: CanActivateFn = () => {
  const store = inject(Store);
  const redirectTo = localStorage.getItem('setupSleepersFrom');
  if (redirectTo) {
    store.dispatch(new Navigate(['pages/setup/congratulation']));
    return of(false);
  }
  return of(true);
};