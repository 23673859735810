import { IccMessages } from "@models/health/sleep-health-icc.model";
import { SiqDateFormats } from "@shared/utils/helpers/date-formats.helper";
import * as moment from "moment";

interface SleepSessionProps {
  date: string;
  startDate: string;
  endDate: string;
  originalStartDate: string;
  originalEndDate: string;
  avgHeartRate: number;
  avgRespirationRate: number;
  bedExits: Array<BedExit>;
  fallAsleepDate: string;
  fallAsleepPeriod: number;
  hrv: number;
  hrvActionCode: number | null;
  inBed: number;
  isFinalized: boolean;
  isHidden: boolean;
  longest: boolean;
  outOfBed: number;
  restful: number;
  restless: number;
  sleepIQCalculating: false;
  sleepNumber: number;
  sleepQuotient: number;
  sleepGoalDuration: number;
  sliceList: Array<Slice>;
  snoreData: SnoreData;
  snoreSensitivity: number;
  totalSleepSessionTime: number;
  totalSnoreTime: number;
}


export class SleepSession {
  date: string;
  startDate: string;
  endDate: string;
  originalStartDate: string;
  originalEndDate: string;
  type: string;

  constructor(date: string, startDate: string, endDate: string, originalStartDate: string, originalEndDate: string, type: string) {
    this.date = date;
    this.startDate = startDate;
    this.endDate = endDate;
    this.originalEndDate = originalEndDate;
    this.originalStartDate = originalStartDate;
    this.type = type;
  }

  get formattedDateRange(): string {
    if (moment(this.startDate).isSame(moment(this.endDate), 'day')) {
      return moment(this.startDate).format(SiqDateFormats.Abbreviated);
    } else {
      return `${moment(this.startDate).format(SiqDateFormats.Abbreviated)} - ${moment(this.endDate).format(SiqDateFormats.Abbreviated)}`;
    }
  }

  get isSession(): boolean {
    return this.type === 'Session';
  }
}

export class SessionModel extends SleepSession {
  avgHeartRate: number;
  avgRespirationRate: number;
  bedExits: Array<BedExit>;
  fallAsleepDate: string;
  fallAsleepPeriod: number;
  hrv: number;
  hrvActionCode: number | null;
  inBed: number;
  isFinalized: boolean;
  isHidden: boolean;
  longest: boolean;
  outOfBed: number;
  restful: number;
  restless: number;
  sleepIQCalculating: false;
  sleepNumber: number;
  sleepQuotient: number;
  sleepGoalDuration: number;
  sliceList: Array<Slice>;
  snoreData: SnoreData;
  snoreSensitivity: number;
  totalSleepSessionTime: number;
  totalSnoreTime: number;
  iccMessages: Array<IccMessages>;

  constructor(session: SleepSessionProps) {
    super(session.date, session.startDate, session.endDate, session.originalStartDate, session.originalEndDate, 'Session');
    const sleepSession = { ...session };
    sleepSession.bedExits = sleepSession.bedExits?.map((it: BedExit) => new BedExit(it));
    sleepSession.sliceList = sleepSession.sliceList?.map((it: Slice) => new Slice(it));
    sleepSession.snoreData = new SnoreData(sleepSession.snoreData);
    this.type = 'Session';
    Object.assign(this, sleepSession);
  }

  get isEdited(): boolean {
    return (this.startDate !== this.originalStartDate) || (this.endDate !== this.originalEndDate);
  }

  get isLessThanFourHours(): boolean {
    return moment(this.endDate).diff(moment(this.startDate), 'seconds') < (4 * 60 * 60);
  }
}

export class NoDataSession extends SleepSession {
  constructor(startDate: string, endDate: string) {
    super(moment(endDate).format(SiqDateFormats.Date), startDate, endDate, startDate, endDate, 'NoDataSession');
    this.startDate = startDate;
    this.endDate = endDate;
    this.originalEndDate = endDate;
    this.originalStartDate = startDate;
  }
}

export class BedExit {
  bedExitDuration: number;
  bedExitTime: string;

  constructor(bedExit: BedExit) {
    Object.assign(this, bedExit);
  }
}

export class Slice {
  outOfBedTime: number;
  restfulTime: number;
  restlessTime: number;
  type: number;

  constructor(slice: Slice) {
    Object.assign(this, slice);
  }
}

export interface SnoreLevelChanges {
  startTime: string;
  intensity: number;
}

export interface SnoreActions {
  actionTime: string;
  actionType: string;
  actionNotTakenReason: string;
}

export interface SnoreSense {
  startTime: string;
  endTime: string;
}

export class SnoreData {
  levelChanges: Array<SnoreLevelChanges>;
  snoreActions: Array<SnoreActions>;
  snoreSense: Array<SnoreSense>;

  constructor(snore: SnoreData) {
    Object.assign(this, snore);
  }
}

export class SleepChartOptions {
  tooltip: boolean;
  editable: boolean;

  constructor(options?: SleepChartOptions) {
    if(options) {
      this.tooltip = options.tooltip;
      this.editable = options.editable;
    } else {
      this.tooltip = false;
      this.editable = false;
    }
  }
}

export class SessionMetrics {
  type: string;
  title: string;
  value: string;
  averageValue: string;

  constructor(type: string, title: string, value: string, averageValue: string) {
    this.type = type;
    this.title = title;
    this.value = value;
    this.averageValue = averageValue;
  }
}
