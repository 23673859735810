export enum SiqButtonColors {
  'primaryBtnPressed' = '#B5FAFF',
  'primaryBtnNonPressed' = '#85F5FF',
  'nonPrimaryBtnPressed' = '#003057',
  'nonPrimaryBtnNonPressed' = 'transparent',
  'changeServerBtnPressed' = '#335978'
}

export enum SiqIconPosition {
  'left',
  'right'
}

export enum RememberMe {
  'no',
  'yes'
}

export enum BedStatusHelper {
  online = 1,
  unconfigured = 2,
  offline = 3
}

export enum NetworkType {
  'WEP' = 0,
  'WPA' = 1,
  'WPA2 Personal' = 2
}

export enum SiqErrorCodes {
  Server = 500,
  Forbidden = 403,
  Unauthorized = 401,
  Eula = 409,
  PrivacyPolicy = 50011,
  NotFound = 404,
  BadRequest = 400
}

export enum PrivacyMode {
  Off = 'off',
  On = 'on'
}

export enum FuzionPrivacyMode {
  on = 'paused',
  off = 'active'
}

export enum OrderNumberValidation {
  'orderAndEmail',
  'orderAndPhoneNumber'
}

export enum BedSettingsActionEnum {
  'Connectivity',
  'Baseline',
  'Setup',
  'Timezone',
  'Privacy'
}

export enum WifiPropertiesEnum {
  'NetworkType',
  'AuthenticationProtocol'
}

export enum WellnessProfileAction {
  Add,
  Update
}

export enum WellnessCategoryEnum {
  "sleeper",
  "home",
  "habits",
  "sleep-health"
}

export enum WhyIsThisType {
  Onboarding,
  Health,
  LearnAboutSmartSleep
}

export enum BedSide {
  Right,
  Left
}

export enum ShouldAddPartnerSleeper {
  Yes,
  No
}

export enum HealthDetails {
  Duration = 'Duration',
  Efficiency = 'Efficiency',
  Timing = 'Timing'
}

export enum EndpointService {
  InnerCircle = 'innercircle',
  Digital = 'subsmgmt',
  RespiratoryHealth = 'subscontent',
  BreathIq = 'siqtobiq'
}

export enum CognitoErrorCodes {
  UserNotFoundException = 'UserNotFoundException',
  NotAuthorizedException = 'NotAuthorizedException'
}
