import { AppSetting } from "./application-settings.model";
import { SleeperProfile } from "./sleeper-profile.model";
import { WellnessCategory, WellnessProfile } from "./wellness-profile.model";

const GENDERS = {
  1: 'male',
  0: 'female',
  2: 'non_binary',
  9: 'prefer_not_to_say'
};

export class SleeperEntity {
  sleepers!: Array<Sleeper>;
}

export class UpdateSleeperProperty {
  [key: string]: string | number | boolean | Array<number> | Array<object> | null;

  constructor(objectAction: UpdateSleeperProperty) {
    Object.assign(this, objectAction);
  }
}

export class Sleeper {
  sleeperId: string;
  bedId: string;
  accountId: string;
  username: string;
  firstName: string;
  email: string;
  side: number;
  zipCode: string;
  birthYear: string;
  birthMonth: number;
  isChild: boolean;
  duration: number | null;
  gender: number;
  isAccountOwner: boolean;
  sleepGoal: number;
  height: number;
  weight: number;
  licenseVersion: number;
  timezone: string;
  active: boolean;
  lastLogin: string;
  emailValidated: boolean;
  firstSessionRecorded: string | null;
  privacyPolicyVersion: number;
  profile?: SleeperProfile;
  appSettings?: Array<AppSetting>;
  wellnessProfile: WellnessProfile;
  wellnessQuestions: Array<WellnessCategory>;

  constructor(sleeper?: Sleeper) {
    Object.assign(this, sleeper);
  }

  get hasBed(): boolean {
    return this.bedId !== '0';
  }

  get hasEmail(): boolean {
    return this.email !== 'null';
  }

  get sleepStudy(): AppSetting | null {
    return this.appSettings?.find((it) =>  it.key === 'sleep-study-survey-preference') ?? null
  }

  getGender(): string {
    return GENDERS[this.gender];
  }

  getMarketingAgeGroup(): string {
    const age = (new Date()).getFullYear() - Number(this.birthYear);
    if (age >= 0 && age <= 18) {
      return '< 18';
    } else if (age > 18 && age < 25) {
      return '18 - 24';
    } else if (age >= 25 && age < 35) {
      return '25 - 34';
    } else if (age >= 35 && age < 45) {
      return '35 - 44';
    } else if (age >= 45 && age < 55) {
      return '45 - 54';
    } else if (age >= 55 && age < 65) {
      return '55 - 64';
    } else if (age >= 65 && age < 75) {
      return '65 - 74';
    } else if (age >= 75 && age <= 99) {
      return '75 - 99';
    } else {
      return '> 99';
    }
  }
}

export class UpdateSleeperEmailProp {
  login: string;
  currentPassword: string;

  constructor(sleeperEmail: UpdateSleeperEmailProp) {
    Object.assign(this, sleeperEmail);
  }
}
export class UpdateSleeperPasswordProp {
  currentPassword: string | null;
  newPassword: string | null;

  constructor(sleeperPassword: UpdateSleeperPasswordProp) {
    Object.assign(this, sleeperPassword);
  }
}

export class UpdateSleeperPasswordResponse {
  accountId: string;
  login: string;
  passwordSetInCognito: boolean;

  constructor(response: UpdateSleeperPasswordResponse) {
    Object.assign(this, response);
  }
}
export class ForgotPasswordBody {
  login: string;

  constructor(sleeperEmail: ForgotPasswordBody) {
    Object.assign(this, sleeperEmail);
  }
}

export class ResetPasswordData {
  password: string;
  token: string;
}

export interface ResetPasswordResponse {
  login: string;
}
