export enum AccountSettingsSections {
  'email',
  'password',
  'orders',
  'defaultSleeper',
  'createLogin'
}

export enum AccountSettingsSectionsName {
  AccountInformation = 'Account Information',
  PersonalInformation = 'Personal Information',
  MyOrders = 'My Orders',
  DeviceSetup = 'Device Setup'
}

export interface ItemModel {
  title: string;
  text: string;
  id: AccountSettingsSections;
}

export interface AccSettingsInfo {
  sectionTitle: string;
  itemModels: Array<ItemModel>;
}