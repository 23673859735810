import { Bed } from "@models/bed/bed.model";

const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.selectcomfort.SleepIQ';
const APP_STORE_LINK = 'https://itunes.apple.com/us/app/sleepiq/id811684463?mt=8&uo=4';
const CONTACT_US_LINK = 'https://support.sleepnumber.com/hc/en-us/articles/360043190713-Sleep-Number-app-Data-Privacy-and-Account-Deletion?acid=siq';
const PRIVACY_POLICY_LINK = 'https://www.sleepnumber.com/pages/legal-privacy-policy?acid=siq';
const OPEN_SOURCE_AGREEMENT_LINK = 'https://www.sleepnumberlabs.com/downloads/webopensource.html';
const DELIVERY_LINK = 'https://www.sleepnumber.com/delivery?acid=siq';
const INNER_CIRCLE_LINK = 'https://www.sleepnumber.com/pages/legal-terms-conditions?acid=siq#rewards';
const LICENSE_LINK = 'https://www.sleepnumber.com/pages/legal-terms-conditions?acid=siq'; // EULA
const ORDER_STATUS_LINK = 'https://www.sleepnumber.com/orders/status?acid=siq';
const SMART_SLEEPER_USER_AGREEMENT = 'https://www.sleepnumber.com/pages/smartsleeper-user-agreement?acid=siq';
const SEARCH_SUPPORT_ARTICLES_LINK = 'https://support.sleepnumber.com/hc/en-us/p/support-landing?acid=siq';
const SUPPORT_LINK = 'https://support.sleepnumber.com/hc/en-us';
const SLEEP_NUMBER_LOGIN = 'https://www.sleepnumber.com/login?acid=siq';
const BREATHE_IQ_APP = 'https://diametos.com/deeplink/home';

export class LinksHelper {
  static get googlePlay(): string {
    return GOOGLE_PLAY_LINK;
  }

  static get appStore(): string {
    return APP_STORE_LINK;
  }

  static get contactUs(): string {
    return CONTACT_US_LINK;
  }

  static get privacyPolicy(): string {
    return PRIVACY_POLICY_LINK;
  }

  static get openSource(): string {
    return OPEN_SOURCE_AGREEMENT_LINK;
  }

  static get delivery(): string {
    return DELIVERY_LINK;
  }

  static get innerCircle(): string {
    return INNER_CIRCLE_LINK;
  }

  static get license(): string {
    return LICENSE_LINK;
  }

  static get orderStatus(): string {
    return ORDER_STATUS_LINK;
  }

  static get smartSleeperUserAgreement(): string {
    return SMART_SLEEPER_USER_AGREEMENT;
  }

  static get breatheIqApp(): string {
    return BREATHE_IQ_APP;
  }

  static searchSupportArticles(bed: Bed | null): string {
    const link = SEARCH_SUPPORT_ARTICLES_LINK;
    if (bed) {
      const mainBase = bed.mainBase;
      const mattressComponent = bed.mattressComponents[0];

      return `${link}&slm=${mattressComponent.productclassification}&sls=${mattressComponent.size}&slf=${mainBase?.productclassification ?? null}&slg=${bed.generation}&slr=web`;
    }

    return link;
  }

  static get supportLink(): string {
    return SUPPORT_LINK;
  }

  static get sleepNumberLogin(): string {
    return SLEEP_NUMBER_LOGIN;
  }

}