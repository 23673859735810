export class SwitchModel<T> {
  selectedElement: SwitchElement<T>;
  switcherElements: Array<SwitchElement<T>> = [];

  constructor(switchModel: SwitchModel<T>) {
    Object.assign(this, switchModel);
  }
}

export class SwitchElement<T> {
  key: string;
  value: T;
  iconLeft?: string;
  iconRight?: string;
  action?: SwitchElementAction;

  constructor(key: string, value: T, iconLeft?: string, iconRight?: string, action?: SwitchElementAction) {
    this.key = key;
    this.value = value;
    
    if(iconLeft) {
      this.iconLeft = iconLeft;
    }

    if(iconRight) {
      this.iconRight = iconRight;
    }

    if(action) {
      this.action = action;
    }
  }

  get actionName(): string {
    switch (this.action) {
      case SwitchElementAction.AddNewSleeper:
        return SwitchElementAction.AddNewSleeper;
      default:
        return '';
    }
  }
}

export enum SwitchElementAction {
  AddNewSleeper = 'Add New Sleeper'
}