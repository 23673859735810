export class Rolling30DaysMetrics {
  min: number;
  max: number;
  avg: number;
}
export class Rolling30DaysSession {
  date: string;
  endDate: string;
  originalEndDate: string;
  originalStartDate: string;
  startDate: string;
  sleepIQScore: Rolling30DaysMetrics | null;
  restfullSleep: Rolling30DaysMetrics | null;
  restlessSleep: Rolling30DaysMetrics | null;
  bedExits: Rolling30DaysMetrics | null;
  heartRate: Rolling30DaysMetrics | null;
  respirationRate: Rolling30DaysMetrics | null;
  timeInBed: Rolling30DaysMetrics | null;
  hrv: Rolling30DaysMetrics | null;
}
export class Rolling30Days {
  date: string;
  sessions: Array<Rolling30DaysSession>;
}
export class Rolling30DaysSleepData {
  sleeperId?: string;
  sleepData: Array<Rolling30Days>;
}
export class RollingAggregatesMetrics {
  heartRate: Rolling30DaysMetrics;
  hrv: Rolling30DaysMetrics;
  respirationRate: Rolling30DaysMetrics;
}
export class SleepDataAggregates {
  rolling7Days: RollingAggregatesMetrics;
  rolling30Days: RollingAggregatesMetrics;
}
export class RollingDataAggregates {
  date: string;
  sleeperId: string;
  sleepDataAggregates: SleepDataAggregates;
}