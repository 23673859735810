import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InnerCircleLicenseState } from "@models/app/helpers.model";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { MixpanelService } from "@services/mixpanel.service";
import { TacoService } from "@services/taco.service";
import { FunctionsHelper } from "@shared/utils/helpers/functions.helper";
import { ErrorCodeHelper } from "@shared/utils/helpers/siq-errors.helper";
import { AuthSelectors } from "@store/auth/auth.selectors";
import { Observable, of, tap, throwError } from "rxjs";
import { AcceptInnerCircle, AcceptTaco, GetInnerCircleLicenseState, LoadPrivacyPolicy, ResetTacoState, SetTacoState } from "./taco.actions";
import { TacoStateModel, defaultTacoState } from "./taco.model";
import { SiqPopupHelper } from "@shared/utils/helpers/siq-popup.helper";

@State<TacoStateModel>({
  name: 'taco',
  defaults: defaultTacoState
})
@Injectable()
export class TacoState {

  constructor(
    private tacoService: TacoService,
    private mixpanelService: MixpanelService,
    private store: Store,
    private siqPopupHelper: SiqPopupHelper) { }

  @Action(LoadPrivacyPolicy)
  loadPrivacyPolicy(ctx: StateContext<TacoStateModel>): Observable<string> {
    return this.tacoService.getPrivacyPolicy().pipe(
      tap({
        next: (response: string) => ctx.patchState({ privacyPolicy: this.privacyPolicyResponse(response) }),
        error: error => {
          return this.handleError(ctx, error);
        }
      })
    );
  }

  @Action(GetInnerCircleLicenseState)
  getInnerCircleLicenseState(ctx: StateContext<TacoStateModel>): Observable<InnerCircleLicenseState> {
    return this.tacoService.getInnerCircleLicenseState().pipe(
      tap({
        next: (response: InnerCircleLicenseState) => {
          ctx.dispatch(new SetTacoState(ErrorCodeHelper.tacoCodes.innerCircle, response.accepted));
        },
        error: error => {
          ctx.patchState({ error: FunctionsHelper.createSiqError(error.error.Error.Code, error.error.Error.Message), loading: false });
        }
      })
    );
  }

  @Action(SetTacoState)
  setTacoState(ctx: StateContext<TacoStateModel>, action: SetTacoState): void {
    switch (action.code) {
      case ErrorCodeHelper.tacoCodes.license:
        ctx.patchState({ licenseAccepted: action.accepted });
        break;
      case ErrorCodeHelper.tacoCodes.privacyPolicy:
        ctx.patchState({ privacyAccepted: action.accepted });
        break;
      case ErrorCodeHelper.tacoCodes.innerCircle:
        ctx.patchState({ innerCircleAccepted: action.accepted });
        break;
      default:
        break;
    }
  }

  @Action(AcceptTaco)
  acceptTaco(ctx: StateContext<TacoStateModel>, action: AcceptTaco): Observable<object> {
    let tacoToAccept$ = of({});

    switch (action.tacoToAccept) {
      case ErrorCodeHelper.tacoCodes.license:
        tacoToAccept$ = this.tacoService.acceptEula();
        break;
      case ErrorCodeHelper.tacoCodes.privacyPolicy:
        tacoToAccept$ = this.tacoService.acceptPrivacyPolicy();
        break;
      case ErrorCodeHelper.tacoCodes.licenseAndPrivacy:
        tacoToAccept$ = this.tacoService.acceptEulaAndPrivacy();
        break;
      default:
        break;
    }
    return tacoToAccept$.pipe(
      tap({
        next: () => this.handleSleeperPath(ctx, action.shouldAcceptInnerCircle),
        error: (error) => this.handleError(ctx, error)
      })
    );
  }

  @Action(AcceptInnerCircle)
  acceptInnerCircle(ctx: StateContext<TacoStateModel>): Observable<object> {
    const userInfo = this.store.selectSnapshot(AuthSelectors.userInfo);
    return this.tacoService.acceptInnerCircle().pipe(
      tap({
        next: () => {
          if (userInfo) {
            FunctionsHelper.handleUserNavigation(this.store, userInfo);
          }
        },
        error: (error) => {
          ctx.patchState({ error: FunctionsHelper.createSiqError(error.error.Error.Code, error.error.Error.Message), loading: false });
          if (userInfo) {
            FunctionsHelper.handleUserNavigation(this.store, userInfo);
          }
        }
      })
    );
  }

  @Action(ResetTacoState)
  resetTacoState(ctx: StateContext<TacoStateModel>): void {
    ctx.setState({ ...defaultTacoState });
  }

  private handleError(ctx: StateContext<TacoStateModel>, error: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.siqPopupHelper.showAlert('TACO');
    ctx.patchState({ error: FunctionsHelper.createSiqError(error.error.Error.Code, error.error.Error.Message), loading: false });
    return throwError(() => error);
  }

  private privacyPolicyResponse(privacyPolicyResponse: string): string {
    const privacyText: Array<string> = [];
    privacyPolicyResponse.split('\n').forEach(element => {
      if (element.includes('<a ')) {
        element = element.replace('<a ', '<a class="siq-text-400 fs-16 rain-blue underline" target="_blank" ');
      }
      privacyText.push(element);
    });
    return privacyText.join('\n');
  }

  private handleSleeperPath(ctx: StateContext<TacoStateModel>, shouldAcceptInnerCircle: boolean): void {
    const userInfo = this.store.selectSnapshot(AuthSelectors.userInfo);
    if (shouldAcceptInnerCircle) {
      ctx.dispatch(new AcceptInnerCircle());
    } else {
      if (userInfo) {
        FunctionsHelper.handleUserNavigation(this.store, userInfo);
      }
    }
  }
}

