<div siqGrid [fx-direction]="getComponentDirection(true)" fx-layout-align="top center">
  <div *ngIf="sleepMetricsType" class="mb-8" [class]="rectangleClass"></div>
  <div *ngIf="icon" siqGrid [fx-direction]="direction" fx-layout-align="center center">
    <span [ngClass]="icon"></span>
    <ng-container *ngIf="value">
      <span [ngClass]="valueClass">{{ value }}</span>
    </ng-container>
  </div>
  <div siqGrid [fx-direction]="getComponentDirection(false)" [fx-layout-align]="textLayoutAlign">
    <span [ngClass]="textClass" class="text-center">{{ text }}</span>
    <ng-container *ngIf="subText">
      <span [ngClass]="subTextClass" [innerHTML]="subText"></span>
    </ng-container>
  </div>
  <div *ngIf="averageValue30Days" siqGrid fx-direction="column" fx-layout-align="center center">
    <div class="line-separator w-12 primary-100 my-8"></div>
    <span class="siq-text-400 fs-14 white text-center w-100-p">30-day average</span>
    <span class="siq-text-400 fs-14 white">{{ averageValue30Days }}</span>
  </div>
</div>
