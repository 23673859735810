<ng-container *ngIf="question.isMultipleChoiceQuestion else singleChoiceQuestion">
  <div siqGrid fx-direction="column" class="mt-24 ml-8">
    <span class="siq-text-400 fs-16 white" [innerHTML]="question.text"></span>
    <span class="siq-text-400 fs-14 primary-200 mt-4" [innerHTML]="question.info_text"></span>
  </div>
  <ng-container *ngFor="let item of question.answers">
    <div siqGrid fx-direction="row" class="mt-24 px-12">
      <app-siq-checkbox [checked]="isCheckboxChecked(item.id)"
        (checkboxState)="handleCheckboxSelect($event, item.id)"></app-siq-checkbox>
      <span class="siq-text-400 fs-16 white ml-8">{{item.text}}</span>
    </div>
  </ng-container>
</ng-container>
<ng-template #singleChoiceQuestion>
  <div class="mt-24 px-12">
    <span class="siq-text-400 fs-16 white" [innerHTML]="question.text"></span>
    <ng-container *ngFor="let item of question.subquestions">
      <div siqGrid fx-direction="column" fx-layout-align="center" class="mt-24">
        <span class="siq-text-400 fs-16 white mb-24" [innerHTML]="item.text"></span>
        <div siqGrid fx-direction="row" fx-layout-align="space-around center">
          <ng-container *ngFor="let answer of item.answers">
            <app-siq-radio-button [selectedOption]="isRadioButtonSelected(item.id)" direction="column"
              [answer]="answer" (selected)="handleRadioButtonSelect($event, item.id)"/>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>