import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WifiNetworkModel } from '@models/wifi-network/wifi-network.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { BedSettingsResources } from '@shared/utils/helpers/bed-settings.helper';
import { CustomValidators } from '@shared/utils/helpers/custom-validators.helper';
import { NetworkType, WifiPropertiesEnum } from '@shared/utils/helpers/enum.helper';
import { FunctionsHelper } from '@shared/utils/helpers/functions.helper';
import { AddWifiNetwork } from '@store/settings/settings.actions';
import { SettingsSelectors } from '@store/settings/settings.selectors';
import { Subject, takeUntil } from 'rxjs';

interface WifiForm {
  networkName: FormControl<string | null>;
  password: FormControl<string | null>;
  confirmPassword: FormControl<string | null>;
}

interface UsernameForm {
  username: FormControl<string | null>;
}

@Component({
  selector: 'app-add-new-network',
  templateUrl: './add-new-network.component.html'
})
export class AddNewNetworkComponent implements OnInit, OnDestroy {
  // forms
  wifiForm: FormGroup<WifiForm>;
  usernameForm: FormGroup<UsernameForm>;
  // form validation
  isFormSubmitted = false;
  controlFocusState = {};
  formErrors = BedSettingsResources.connectivityErrorStates;
  // dropdown options and values
  showAdvancedOptions = false;
  networkTypesOptions: Array<string> = BedSettingsResources.NetworkTypes.options;
  networkTypesValues: Array<number> = BedSettingsResources.NetworkTypes.values;
  networkType = 'WPA';
  authenticationProtocolOptions: Array<string> = BedSettingsResources.AuthenticationProtocol.options;
  authenticationProtocolValues: Array<number> = BedSettingsResources.AuthenticationProtocol.values;
  authenticationProtocol = 'PEAP';
  wifiPropertiesEnum = WifiPropertiesEnum;

  private _unsubscribeAll = new Subject<void>();

  constructor(private store: Store) {}

  get isWPA2EnterpriseSelected(): boolean {
    return this.networkType === 'WPA2 Enterprise';
  }

  get isDisabled(): boolean {
    return !this.isWPA2EnterpriseSelected ? !this.wifiForm.valid : !(this.wifiForm.valid && this.usernameForm.valid);
  }

  ngOnInit(): void {
    FunctionsHelper.scrollToTop();
    this.wifiForm = new FormGroup<WifiForm>({
      networkName: new FormControl('', [Validators.required]),
      password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(64)])),
      confirmPassword: new FormControl('', Validators.required),
    }, {
      validators: Validators.compose([
        CustomValidators.matchControls('password', 'confirmPassword')
      ])
    });

    this.usernameForm = new FormGroup<UsernameForm>({
      username: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(64)])
    });
  }

  isInvalidField(form: FormGroup<WifiForm> | FormGroup<UsernameForm>, field: string): boolean {
    if(!this.controlFocusState[field]) {
      if (form.controls[field]?.touched) {
        return form && !this.isFormSubmitted
          ? this.checkIsInputValid(form.controls[field])
          : false;
      } 
    }
    return false;
  }

  checkIsInputValid(item: FormControl): boolean {
    return !item?.valid;
  }

  setControlFocusState(focusState: object): void {
    this.controlFocusState = focusState;
  }

  addNewNetwork(): void {
    this.isFormSubmitted = true;
    if(!this.isDisabled) {
      this.store.dispatch(new AddWifiNetwork(this.createWifiFormObject())).subscribe({
        next: () => {
          this.store.select(SettingsSelectors.loading).pipe(
            takeUntil(this._unsubscribeAll)
          ).subscribe(loading => {
            if(!loading)
              this.back();
          })
        },
        error: () => {
          this.resetForms();
          this.isFormSubmitted = false;
        }
      });
    }
  }

  setShowAdvancedOptions(checked: boolean): void {
    this.showAdvancedOptions = checked;
  }

  handleDropdownSelect(selectedIndex: number, dropdownType: WifiPropertiesEnum): void {
    switch(dropdownType) {
      case WifiPropertiesEnum.NetworkType:
        this.networkType = this.networkTypesOptions[selectedIndex];
        return;
      case WifiPropertiesEnum.AuthenticationProtocol:
        this.authenticationProtocol = this.authenticationProtocolOptions[selectedIndex];
        return;
    }
  }

  back(): void {
    this.store.select(SettingsSelectors.networks).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(networks => {
      networks && networks.length ? this.redirectToRoute('networks') : this.redirectToRoute('');
      this.resetForms();
    });
  }

  private redirectToRoute(route: string): void {
    this.store.dispatch(new Navigate([`pages/smart-bed/details/connectivity/${route}`]));
  }

  private getFormControlValue(form: FormGroup, control: string): string {
    return form.get(control)?.value;
  }

  private createWifiFormObject(): WifiNetworkModel {
    const wifiBody: WifiNetworkModel = Object.assign(new WifiNetworkModel(), {
      ssid: this.getFormControlValue(this.wifiForm, 'networkName'),
      password: this.getFormControlValue(this.wifiForm, 'password'),
      eap: this.authenticationProtocolValues[this.authenticationProtocolOptions.findIndex(el => el === this.authenticationProtocol)]
    });
    if (this.showAdvancedOptions) {
      if (this.isWPA2EnterpriseSelected) {
        if (this.usernameForm.valid) {
          wifiBody.protocol = 1;
          wifiBody.identity = this.getFormControlValue(this.usernameForm, 'username');
        }
      } else {
        wifiBody.protocol = NetworkType[this.networkType];
      }
    }
    return wifiBody;
  }

  private resetForms(): void {
    this.wifiForm.reset();
    this.usernameForm.reset();
    this.isFormSubmitted = false;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
